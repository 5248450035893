export enum MobileOs {
  Android = 'Android',
  Ios = 'iOS',
  WindowsPhone = 'Windows Phone',
}

export const getMobileOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/windows phone/i.test(userAgent)) {
    return MobileOs.WindowsPhone;
  }

  if (/android/i.test(userAgent)) {
    return MobileOs.Android;
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return MobileOs.Ios;
  }

  return null;
};

import { CompanyDto } from '../../shared/types/owner';
import { apiRequest2 } from './../api-request';

export const getFinanciers = async () => {
  const response = await apiRequest2<CompanyDto[]>({
    action: `Protected/Financiers`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response.data;
};

import { Container, Grid, TextField, Typography } from '@mui/material';
import { DefaultButton, FormInputWrapper, PageContent, PageText } from '../layout';

export const Contact = () => {
  return (
    <Container>
      <PageContent>
        <PageText>
          <Typography variant="h2" gutterBottom>
            Kontakta Chassikollen
          </Typography>
          <Typography variant="h6" gutterBottom>
            Du når oss vardagar 08.00-17.00
          </Typography>

          <p>
            Telefon: <a href="tel:0102045070">010-204 50 70</a>
            <br />
            E-post: <a href="mailto:info@chassikollen.se">info@chassikollen.se</a>
          </p>

          <p>Vill du att vi kontaktar dig? Fyll i dina uppgifter så hör vi av oss så fort vi kan!</p>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={8}>
              <FormInputWrapper>
                <TextField required fullWidth label="Namn" variant="outlined" />
              </FormInputWrapper>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormInputWrapper>
                <TextField required fullWidth label="E-post" variant="outlined" />
              </FormInputWrapper>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormInputWrapper>
                <TextField fullWidth label="Telefon" variant="outlined" />
              </FormInputWrapper>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormInputWrapper>
                <TextField fullWidth label="Meddelande" variant="outlined" multiline rows={4} />
              </FormInputWrapper>
            </Grid>
            <Grid item xs={12} sm={8}>
              <DefaultButton size="large">Skicka</DefaultButton>
            </Grid>
          </Grid>
        </PageText>
      </PageContent>
    </Container>
  );
};

import { Container, Typography } from '@mui/material';
import { PageContent, SubContainer } from '../../layout';
import { BreadCrumbs } from '../../navigation/BreadCrumbs';

export const RegistrationCompletePage = () => {
  return (
    <>
      <Container maxWidth={false}>
        <BreadCrumbs />
        <PageContent>
          <SubContainer>
            <Typography variant="h5" gutterBottom>
              Ditt objekt är nu registrerat
            </Typography>
          </SubContainer>
        </PageContent>
      </Container>
    </>
  );
};

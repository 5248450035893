import { createContext } from 'react';
import { TransactionType } from '../shared/types/change-of-ownership';
import { SelectableStatistcsAttribute } from '../shared/types/statistics';

export type ConfigContextType = {
  promoTexts: string[];
  selectableStatistcsAttributes: SelectableStatistcsAttribute[];
  transactionTypes: TransactionType[];
  financedTransactionTypes: string[];
};

export const ConfigContext = createContext<ConfigContextType | null>(null);

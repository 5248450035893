import { Navigate, Route } from 'react-router-dom';
import { useUser } from '../context/UserProvider';
import { SignInPage } from '../pages/SignIn';
import { SignOutPage } from '../pages/SignOut';
import { paths } from './paths';

export const AuthRoutes = () => {
  const { isAuthenticated } = useUser();

  return (
    <>
      <Route
        path={paths.signIn.path}
        element={isAuthenticated ? <Navigate to={paths.myRegistrations.path} /> : <SignInPage />}
      />
      <Route path={paths.signOut.path} element={<SignOutPage />} />
    </>
  );
};

import styled from '@emotion/styled';
import { Divider as MuiDivider, IconButton, FormControlLabel } from '@mui/material';
import React from 'react';
import { Trash2 } from 'react-feather';
import { DefaultOutlinedButton, ListItem, ListItemActions, ListItemText } from '../../../layout';
import { UserInfoDto, UserOwnerDto } from '../../../shared/types/user';
import { AddUserOwnerDialog } from './AddUserOwnerDialog';
import CheckBox from '@mui/material/Checkbox';

type UserSelectProps = {
  ownerId: number;
  userOwners: UserOwnerDto[] | null | undefined;
  onIsSupervisorUpdate?: (userId: number, isSupervisor: boolean) => void;
  onIsRegistrarUpdate?: (userId: number, isRegistrar: boolean) => void;
  onAddUserOwner: (userInfo: UserInfoDto, isRegistrar: boolean, isSupervisor: boolean) => void;
  onDeleteUserOwner: (userId: number) => void;
};

const Divider = styled(MuiDivider)`
  margin: 10px 0;
`;

export const UserSelect = ({
  ownerId,
  userOwners,
  onIsSupervisorUpdate,
  onIsRegistrarUpdate,
  onAddUserOwner,
  onDeleteUserOwner,
}: UserSelectProps) => {
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = React.useState(false);

  const onIsSupervisorClick = (userId: number | undefined, value: boolean) => {
    if (onIsSupervisorUpdate && userId) {
      onIsSupervisorUpdate(userId, value);
    }
  };

  const onIsRegistrarClick = (userId: number | undefined, value: boolean) => {
    if (onIsRegistrarUpdate && userId) {
      onIsRegistrarUpdate(userId, value);
    }
  };

  const onAddUserClick = () => {
    setIsAddUserDialogOpen(true);
  };

  const onDeleteClick = (userId: number | undefined) => {
    if (!userId) {
      return;
    }

    onDeleteUserOwner(userId);
  };

  if (!userOwners) {
    return null;
  }
  return (
    <>
      {userOwners.map((userOwner, i) => (
        <ListItem noPointer key={i}>
          <ListItemText>
            {userOwner.user?.givenName || 'Ny användare'} {userOwner.user?.familyName}
          </ListItemText>
          <ListItemText>{userOwner.user?.socialSecurityNumber || ''}</ListItemText>
          <ListItemText>
            <FormControlLabel
              control={
                <CheckBox
                  checked={userOwner.isSupervisor}
                  onChange={(e) => onIsSupervisorClick(userOwner.userId, e.target.checked)}
                />
              }
              label="Supervisor"
            />
            <FormControlLabel
              control={
                <CheckBox
                  checked={userOwner.isRegistrar}
                  onChange={(e) => onIsRegistrarClick(userOwner.userId, e.target.checked)}
                />
              }
              label="Registrar"
            />
          </ListItemText>
          <ListItemActions>
            <IconButton onClick={() => onDeleteClick(userOwner.userId)}>
              <Trash2 size={20} />
            </IconButton>
          </ListItemActions>
        </ListItem>
      ))}

      <AddUserOwnerDialog
        ownerId={ownerId}
        isOpen={isAddUserDialogOpen}
        onClose={() => setIsAddUserDialogOpen(false)}
        onAddUserOwner={onAddUserOwner}
      />

      <Divider />

      <DefaultOutlinedButton size="small" onClick={onAddUserClick}>
        Lägg till ny användare
      </DefaultOutlinedButton>
    </>
  );
};

import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, LinearProgress, Typography } from '@mui/material';
import { DefaultButton, DefaultTextButton } from '../../../layout';
import { useCompanyByIdQuery, usePutCompanyMutation } from '../../../api';
import { CompanyForm } from './CompanyForm';
import { useState } from 'react';
import { CompanyDto } from '../../../shared/types/owner';
import { UserOwnerList } from '../UserOwnerList';
import { UserOwnerDto } from '../../../shared/types/user';
import { ErrorDialog } from '../../../components/dialogs/ErrorDialog';

type CompanyEditDialogProps = {
  companyId: number;
  onClose: () => void;
};

export const CompanyEditDialog = ({ companyId, onClose }: CompanyEditDialogProps) => {
  const [editedCompany, setEditedCompany] = useState<CompanyDto>();
  const [error, setError] = useState<string | null>(null);

  const companyByIdQuery = useCompanyByIdQuery(companyId);

  const putCompanyMutation = usePutCompanyMutation();

  function onCloseClick(): void {
    setEditedCompany(undefined);
    onClose();
  }

  function onSaveClick(): void {
    console.log('onSaveClick: ', { ...companyByIdQuery.data, ...editedCompany });
    putCompanyMutation.mutate(
      { isLessor: false, isInsurer: false, isFinancier: false, ...companyByIdQuery.data, ...editedCompany },
      {
        onSuccess: () => {
          setEditedCompany(undefined);
          onClose();
        },
        onError: () => {
          setError('Något gick fel när företaget skulle ändras. Försök igen senare.');
        },
      }
    );
  }

  return (
    <>
      <ErrorDialog isOpen={error !== null} message={error} onClose={() => setError(null)}></ErrorDialog>

      <Dialog open={companyId !== null}>
        <>
          <DialogTitle>Redigera {companyByIdQuery.data?.organizationNumber}</DialogTitle>
          <DialogContent>
            {companyByIdQuery.data ? (
              <>
                <CompanyForm
                  company={companyByIdQuery.data}
                  onChange={(name, value) =>
                    setEditedCompany({
                      isLessor: false,
                      isInsurer: false,
                      isFinancier: false,
                      ...editedCompany,
                      [name]: value,
                    })
                  }
                />
                <Divider />

                <Typography variant="subtitle1" gutterBottom>
                  Användare
                  <UserOwnerList
                    ownerId={companyId}
                    userOwners={editedCompany?.userOwners || companyByIdQuery.data.userOwners || []}
                    onChange={(userOwners: UserOwnerDto[]) =>
                      setEditedCompany({
                        isLessor: false,
                        isInsurer: false,
                        isFinancier: false,
                        ...editedCompany,
                        userOwners,
                      })
                    }
                  />
                </Typography>
              </>
            ) : (
              companyByIdQuery.isPending && <LinearProgress />
            )}

            {companyByIdQuery.isError && <Typography variant="body1">Företaget kunde inte laddas.</Typography>}
          </DialogContent>
          <DialogActions>
            <DefaultTextButton onClick={onCloseClick}>Avbryt</DefaultTextButton>
            <DefaultButton onClick={onSaveClick} disabled={!editedCompany}>
              Spara
            </DefaultButton>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};

import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getSortedDetails } from '../../shared/sort/sort-registration-details';
import {
  PostRegistrationDto,
  RegistrationDetailsDto,
  RegistrationDto,
  RegistrationListItemDto,
} from '../../shared/types/registration';
import { apiRequest2 } from '../api-request';

const registrationKeys = {
  all: ['registrations'],
  list: (filter: string, page: number, rowsPerPage: number) => [
    ...registrationKeys.all,
    'list',
    filter,
    page,
    rowsPerPage,
  ],
  count: (filter: string) => [...registrationKeys.all, 'count', filter],
  detail: (registrationId: number | null) => [...registrationKeys.all, 'detail', registrationId],
};

export const useRegistrationsQuery = (filter: string, page: number, rowsPerPage: number) =>
  useQuery({
    queryKey: registrationKeys.list(filter, page, rowsPerPage),
    queryFn: async () => {
      const response = await apiRequest2<RegistrationListItemDto[]>({
        action: `Admin/Registrations`,
        method: 'GET',
        allowAnonymous: false,
        params: {
          filter,
          page,
          rowsPerPage,
        },
      });

      return response.data;
    },
    placeholderData: keepPreviousData,
  });

export const useRegistrationsCountQuery = (filter: string) =>
  useQuery({
    queryKey: registrationKeys.count(filter),
    queryFn: async () => {
      const response = await apiRequest2<number>({
        action: `Admin/Registrations/Count`,
        method: 'GET',
        allowAnonymous: false,
        params: {
          filter,
        },
      });

      return response.data;
    },
    placeholderData: keepPreviousData,
  });

export const useUpdateRegistrationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (registration: PostRegistrationDto) =>
      apiRequest2<RegistrationDto>({
        action: `Admin/Registrations/${registration.id}`,
        method: 'PUT',
        allowAnonymous: false,
        body: JSON.stringify(registration),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: registrationKeys.all,
      });
    },
  });
};

export const useDeleteRegistrationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (registrationId: number) =>
      apiRequest2<void>({
        action: `Admin/Registrations/${registrationId}`,
        method: 'DELETE',
        allowAnonymous: false,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: registrationKeys.all,
      });
    },
  });
};

export const useAdminRegistrationQuery = (registrationId: number | null) =>
  useQuery({
    queryKey: registrationKeys.detail(registrationId),
    queryFn: async () => {
      const response = await apiRequest2<RegistrationDto>({
        action: `Admin/Registrations/${registrationId}`,
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data;
    },
    enabled: !!registrationId,
  });

export const usePostAdminRegistrationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (registration: PostRegistrationDto) =>
      apiRequest2<void, PostRegistrationDto>({
        action: 'Admin/Registrations',
        method: 'POST',
        body: registration,
        allowAnonymous: false,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: registrationKeys.all,
      });
    },
  });
};

export const useAdminRegistrationDetailsQuery = (registrationDetailsId: number | null) =>
  useQuery({
    queryKey: ['registrationDetails', registrationDetailsId],
    queryFn: async () => {
      const response = await apiRequest2<RegistrationDetailsDto>({
        action: `Admin/RegistrationDetails/${registrationDetailsId}`,
        method: 'GET',
        allowAnonymous: false,
      });

      return getSortedDetails(response.data);
    },
    enabled: !!registrationDetailsId,
  });

import { useEffect, useState } from 'react';
import {
  deleteDataAttributeApiAsync,
  deleteDataAttributeGroupApiAsync,
  getDataAtrributesFromApiAsync,
  getDataAttributeGroupsFromApiAsync,
  postDataAttributeGroupToApiAsync,
  postDataAttributeToApiAsync,
  putDataAttributeGroupToApiAsync,
  putDataAttributesGroupOrderToApiAsync,
  putDataAttributesOrderToApiAsync,
  putDataAttributeToApiAsync,
} from '../../../api/admin/data-attribute';
import { DataGridHeader, DataGridHeaderActions, DefaultButton, FilterInput, PageTitle } from '../../../layout';
import { DataAttributeDto, DataAttributeGroupDto } from '../../../shared/types/admin/data-attribute';
import { EditDataAttributeDialog } from './EditDataAttributeDialog';

import { FormControlLabel, FormGroup, LinearProgress, Switch } from '@mui/material';
import { dataAttributeTypeList } from '../../../shared/select-items/data-attribute-select-list';
import { ConfirmDialog } from '../../dialogs/ConfirmDialog';
import { AddDataAttributeDialog } from './AddDataAttributeDialog';
import { AddDataAttributeGroupDialog } from './AddDataAttributeGroupDialog';
import { DataAttributeGroups } from './DataAttributeGroups';
import { EditDataAttributeGroupDialog } from './EditDataAttributeGroupDialog';

const addTypeTextToDataAttributes = (dataAttributes: DataAttributeDto[]) => {
  return dataAttributes.map((dataAttribute) => {
    dataAttribute.typeText = dataAttributeTypeList.find((x) => x.value === dataAttribute.type)?.label;
    return dataAttribute;
  });
};

export const EditDataAttributes = () => {
  const [dataAttributes, setDataAttributes] = useState<DataAttributeDto[]>([]);
  const [filteredDataAttributes, setFilteredDataAttributes] = useState<DataAttributeDto[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [selectedDataAttribute, setSelectedDataAttribute] = useState<DataAttributeDto | null>(null);
  const [isAddDataAttributeGroupDialogOpen, setIsAddDataAttributeGroupDialogOpen] = useState(false);
  const [isEditDataAttributeDialogOpen, setIsEditDataAttributeDialogOpen] = useState(false);
  const [isAddDataAttributeDialogOpen, setIsAddDataAttributeDialogOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const [dataAttributeToDelete, setDataAttributeToDelete] = useState<DataAttributeDto | null>(null);
  const [dataAttributeGroups, setDataAttributeGroups] = useState<DataAttributeGroupDto[]>([]);
  const [isEmptyGroupsVisible, setIsEmptyGroupsVisible] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(0);
  const [isEditDataAttributeGroupDialogOpen, setIsEditDataAttributeGroupDialogOpen] = useState(false);
  const [dataAttributeGroupToEdit, setDataAttributeGroupToEdit] = useState<DataAttributeGroupDto | null>(null);
  const [dataAttributeGroupCanBeDeleted, setDataAttributeGroupCanBeDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const dataAttributes = await getDataAtrributesFromApiAsync();
    if (!dataAttributes.data) {
      return;
    }

    const dataAttributeWithTypeText = addTypeTextToDataAttributes(dataAttributes.data);
    const sortedDataAttributes = dataAttributeWithTypeText.sort((a, b) => a.order - b.order);
    setDataAttributes(sortedDataAttributes);

    const dataAttributeGroups = await getDataAttributeGroupsFromApiAsync();
    if (!dataAttributeGroups.data) {
      return;
    }
    dataAttributeGroups.data.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    setDataAttributeGroups(dataAttributeGroups.data);
    setIsLoading(false);
  };

  useEffect(() => {
    applyFilter();
  }, [dataAttributes, filter]);

  const onEditDataAttribute = (dataAttribute: DataAttributeDto) => {
    setSelectedDataAttribute(dataAttribute);
    setIsEditDataAttributeDialogOpen(true);
  };

  const onEditDataAttributeDialogClose = () => {
    setIsEditDataAttributeDialogOpen(false);
    setIsAddDataAttributeDialogOpen(false);
    setSelectedDataAttribute(null);
    setSelectedGroupId(0);
  };

  const onSaveDataAttributeGroup = async (name: string) => {
    await postDataAttributeGroupToApiAsync({ id: 0, name });
    setIsAddDataAttributeGroupDialogOpen(false);
    await fetchData();
  };
  const onDeleteDataAttributeGroup = async (dataAttributeGroup: DataAttributeGroupDto) => {
    await deleteDataAttributeGroupApiAsync(dataAttributeGroup);
    setIsEditDataAttributeGroupDialogOpen(false);
    await fetchData();
  };

  const onSaveEditedDataAttributeGroup = async (dataAttributeGroup: DataAttributeGroupDto) => {
    await putDataAttributeGroupToApiAsync(dataAttributeGroup);
    await fetchData();
  };

  const onDataAttributeGroupDialogClose = () => {
    setIsAddDataAttributeGroupDialogOpen(false);
    setIsEditDataAttributeGroupDialogOpen(false);
  };

  const onSaveDataAttribute = async (dataAttribute: DataAttributeDto | null) => {
    if (!dataAttribute) {
      return;
    }

    await postDataAttributeToApiAsync(dataAttribute);

    fetchData();

    setIsAddDataAttributeDialogOpen(false);
    setSelectedDataAttribute(null);
    setSelectedGroupId(0);
  };
  const onSaveEditedDataAttribute = async (dataAttribute: DataAttributeDto | null) => {
    if (!dataAttribute) {
      return;
    }

    await putDataAttributeToApiAsync(dataAttribute);

    // update state
    fetchData();

    // close dialog
    setIsEditDataAttributeDialogOpen(false);
    setSelectedDataAttribute(null);
    setSelectedGroupId(0);
  };

  const onDeleteDataAttribute = async (dataAttribute: DataAttributeDto) => {
    setDataAttributeToDelete(dataAttribute);
    setIsConfirmDeleteDialogOpen(true);
  };

  const onDeleteDataAttributeConfirm = async () => {
    if (!dataAttributeToDelete) {
      onDeleteDataAttributeCancel();
      return;
    }
    await deleteDataAttributeApiAsync(dataAttributeToDelete.id);
    await fetchData();
    setIsConfirmDeleteDialogOpen(false);
    setTimeout(() => setDataAttributeToDelete(null), 1000);
  };

  const onDeleteDataAttributeCancel = () => {
    setIsConfirmDeleteDialogOpen(false);
    setTimeout(() => setDataAttributeToDelete(null), 500);
  };

  const applyFilter = () => {
    const filteredDataAttributes = dataAttributes.filter((dataAttribute) =>
      dataAttribute.name.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredDataAttributes(filteredDataAttributes);
  };

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const onNewGroupClick = () => {
    setIsAddDataAttributeGroupDialogOpen(true);
  };

  const onAddDataAttribute = (groupId: number) => {
    setSelectedGroupId(groupId);
    setIsAddDataAttributeDialogOpen(true);
  };

  const onEditDataAttributeGroup = (dataAttributeGroup: DataAttributeGroupDto) => {
    setDataAttributeGroupToEdit(dataAttributeGroup);

    const groupCanBeDeleted =
      dataAttributes.find((dataAttribute) => dataAttribute.groupId === dataAttributeGroup.id) === undefined;

    setDataAttributeGroupCanBeDeleted(groupCanBeDeleted);
    setIsEditDataAttributeGroupDialogOpen(true);
  };

  const onGropuOrderChange = async (groupId: number, direction: 'up' | 'down') => {
    const groupIndex = dataAttributeGroups.findIndex((group) => group.id === groupId);
    if (groupIndex === -1) {
      return;
    }

    const newGroups = [...dataAttributeGroups];
    const groupToMove = newGroups[groupIndex];
    newGroups.splice(groupIndex, 1);

    if (direction === 'up') {
      newGroups.splice(groupIndex - 1, 0, groupToMove);
    } else {
      newGroups.splice(groupIndex + 1, 0, groupToMove);
    }

    newGroups.forEach((group, index) => {
      group.order = index;
    });

    await putDataAttributesGroupOrderToApiAsync(newGroups);

    setDataAttributeGroups(newGroups);
  };

  const onDataAttributeOrderChange = async (dataAttributeId: number, direction: 'up' | 'down') => {
    const dataAttribute = dataAttributes.find((dataAttribute) => dataAttribute.id === dataAttributeId);
    const dataAttributesInGroup = dataAttributes.filter((d) => d.groupId === dataAttribute?.groupId);
    const dataAttributeIndex = dataAttributesInGroup.findIndex((dataAttribute) => dataAttribute.id === dataAttributeId);

    if (dataAttributeIndex === -1) {
      return;
    }

    const newDataAttributesInGroup = [...dataAttributesInGroup];
    const dataAttributeToMove = newDataAttributesInGroup[dataAttributeIndex];
    newDataAttributesInGroup.splice(dataAttributeIndex, 1);

    if (direction === 'up') {
      newDataAttributesInGroup.splice(dataAttributeIndex - 1, 0, dataAttributeToMove);
    } else {
      newDataAttributesInGroup.splice(dataAttributeIndex + 1, 0, dataAttributeToMove);
    }

    newDataAttributesInGroup.forEach((dataAttribute, index) => {
      dataAttribute.order = index;
    });

    const dataAttributesNotInGroup = dataAttributes.filter((d) => d.groupId !== dataAttribute?.groupId);
    const newDataAttributes = [...dataAttributesNotInGroup, ...newDataAttributesInGroup];

    await putDataAttributesOrderToApiAsync(newDataAttributesInGroup);

    setDataAttributes(newDataAttributes);

    applyFilter();
  };

  return (
    <>
      <ConfirmDialog
        isOpen={isConfirmDeleteDialogOpen}
        onConfirm={onDeleteDataAttributeConfirm}
        onCancel={onDeleteDataAttributeCancel}
        title="Är du säker?"
        message={`Vill du verkligen radera attributet "${dataAttributeToDelete?.name}"`}
      ></ConfirmDialog>

      <AddDataAttributeGroupDialog
        isOpen={isAddDataAttributeGroupDialogOpen}
        onSave={onSaveDataAttributeGroup}
        onClose={onDataAttributeGroupDialogClose}
      />
      <EditDataAttributeGroupDialog
        isOpen={isEditDataAttributeGroupDialogOpen}
        onSave={onSaveEditedDataAttributeGroup}
        onDelete={onDeleteDataAttributeGroup}
        onClose={onDataAttributeGroupDialogClose}
        dataAttributeGroup={dataAttributeGroupToEdit}
        dataAttributeGroupCanBeDeleted={dataAttributeGroupCanBeDeleted}
      />

      <AddDataAttributeDialog
        isOpen={isAddDataAttributeDialogOpen}
        onSave={onSaveDataAttribute}
        onClose={onEditDataAttributeDialogClose}
        dataAttributeGroups={dataAttributeGroups}
        selectedGroupId={selectedGroupId}
      />
      <EditDataAttributeDialog
        isOpen={isEditDataAttributeDialogOpen}
        dataAttribute={selectedDataAttribute}
        onSave={onSaveEditedDataAttribute}
        onClose={onEditDataAttributeDialogClose}
        onNewGroupClick={onNewGroupClick}
        dataAttributeGroups={dataAttributeGroups}
      />

      <PageTitle>Redigera attribut</PageTitle>

      <DataGridHeader>
        <FilterInput type="search" value={filter} onChange={(event) => onFilterChange(event)} placeholder="Filter" />
        <DataGridHeaderActions>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch checked={isEmptyGroupsVisible} onClick={() => setIsEmptyGroupsVisible(!isEmptyGroupsVisible)} />
              }
              label="Visa grupper utan attribut"
            />
          </FormGroup>
          <DefaultButton onClick={() => setIsAddDataAttributeGroupDialogOpen(true)}>Ny grupp</DefaultButton>
        </DataGridHeaderActions>
      </DataGridHeader>

      {isLoading ? (
        <LinearProgress />
      ) : (
        <DataAttributeGroups
          dataAttributeGroups={dataAttributeGroups}
          dataAttributes={filteredDataAttributes}
          isEmptyGroupsVisible={isEmptyGroupsVisible}
          onAddDataAttribute={onAddDataAttribute}
          onEditDataAttribute={onEditDataAttribute}
          onDeleteDataAttribute={onDeleteDataAttribute}
          onEditDataAttributeGroup={onEditDataAttributeGroup}
          onGroupOrderChange={onGropuOrderChange}
          onDataAttributeOrderChange={onDataAttributeOrderChange}
        />
      )}
    </>
  );
};

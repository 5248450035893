import { OwnerChangeDto } from '../../shared/types/owner';
import { apiRequest } from '../api-request';

export const postChangeOwnershipToApiAsync = async (ownerChange: OwnerChangeDto) => {
  const response = await apiRequest<void>({
    action: `Protected/Ownership`,
    method: 'POST',
    allowAnonymous: false,
    body: JSON.stringify(ownerChange),
  });

  return response;
};

export const postConfirmOwnershipToApiAsync = async (registrationId: number) => {
  const response = await apiRequest<void>({
    action: `Protected/Ownership/${registrationId}/Confirm`,
    method: 'POST',
    allowAnonymous: false,
  });

  return response;
};

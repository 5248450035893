import { IconButton, Typography } from '@mui/material';
import { Edit, Trash2 } from 'react-feather';
import { DefaultCard, ListHeader, ListHeaderText, ListItem, ListItemActions, ListItemText } from '../../../../layout';
import { CompanyDto } from '../../../../shared/types/owner';

type CompanyListProps = {
  companies: CompanyDto[] | undefined;
  onDeleteCompany: (company: CompanyDto) => void;
  onEditCompany: (company: CompanyDto) => void;
};

export const CompanyList = ({ companies, onEditCompany, onDeleteCompany }: CompanyListProps) => {
  const onEditClick = (company: CompanyDto) => {
    onEditCompany(company);
  };

  const onDeleteClick = (company: CompanyDto) => {
    onDeleteCompany(company);
  };

  return (
    <DefaultCard>
      {companies && (
        <>
          {companies.length > 0 ? (
            <>
              <ListHeader>
                <ListHeaderText>Namn</ListHeaderText>
                <ListHeaderText>Orgnr.</ListHeaderText>
                <ListItemActions></ListItemActions>
              </ListHeader>

              {companies.map((company) => {
                return (
                  <ListItem noPointer key={company.id}>
                    <ListItemText>{company.name}</ListItemText>
                    <ListItemText>{company.organizationNumber}</ListItemText>
                    <ListItemActions>
                      <IconButton onClick={() => onEditClick(company)}>
                        <Edit size={20} />
                      </IconButton>
                      <IconButton onClick={() => onDeleteClick(company)}>
                        <Trash2 size={20} />
                      </IconButton>
                    </ListItemActions>
                  </ListItem>
                );
              })}
            </>
          ) : (
            <Typography variant="body1">Inga företag hittades</Typography>
          )}
        </>
      )}
    </DefaultCard>
  );
};

import styled from '@emotion/styled';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DefaultCard } from '../../layout';
import { paths } from '../../navigation/paths';
import { RegistrationDto } from '../../shared/types/registration';
import { UserRegistrationsList } from './OwnerRegistrationsList';

type UserRegistrationsProps = {
  registrations: RegistrationDto[];
  type: 'confirmed' | 'not-submitted' | 'submitted' | 'change-of-owner';
};

const TopBarWrapper = styled.div<{ detailsOpen: boolean }>`
  display: ${(props) => (props.detailsOpen ? 'none' : 'block')};

  @media (min-width: 600px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const TopBarActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  @media (min-width: 600px) {
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
  }
`;
const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  @media (min-width: 600px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`;
type FilterProps = {
  registrations: RegistrationDto[];
  onFilterChange: (registrations: RegistrationDto[], searchQuery: string) => void;
};
const Filter = ({ registrations, onFilterChange }: FilterProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentSortOrder, setCurrentSortOrder] = useState('name-asc');
  const [includeChildren, setIncludeChildren] = useState(false);

  useEffect(() => {
    const filteredRegistrations = searchQuery ? getFilteredRegistrations(registrations, searchQuery) : registrations;
    const [key, order] = currentSortOrder.split('-') as [string, 'asc' | 'desc'];
    const sortedFiltredRegistrations = getSortedRegistrations(filteredRegistrations, key, order);

    onFilterChange(sortedFiltredRegistrations, searchQuery);
  }, [registrations, searchQuery, currentSortOrder, includeChildren]);

  const getSortedRegistrations = (registrations: RegistrationDto[], key: string, sortOrder: 'desc' | 'asc') => {
    const newSortedRegistrations = registrations.sort((a, b) => {
      const aValue = Object.values(a)[Object.keys(a).indexOf(key ?? '')] as string;
      const bValue = Object.values(b)[Object.keys(b).indexOf(key ?? '')] as string;
      return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    if (includeChildren) {
      return newSortedRegistrations;
    }
    return newSortedRegistrations.filter((registration) => registration.parentId === null);
  };

  const getFilteredRegistrations = (registrations: RegistrationDto[], searchQuery: string) => {
    const newFilteredRegistrations = registrations.filter((registration) => {
      const registrationName = registration.name.toLowerCase();
      const registrationCategory = registration.category.toLowerCase();

      return (
        registrationName.includes(searchQuery.toLowerCase()) || registrationCategory.includes(searchQuery.toLowerCase())
      );
    });

    return newFilteredRegistrations;
  };

  const onSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);

    /*    const filteredRegistrations = searchQuery ? getFilteredRegistrations(registrations, searchQuery) : registrations;
    const [key, order] = currentSortOrder.split('-') as [string, 'asc' | 'desc'];
    const sortedFiltredRegistrations = getSortedRegistrations(filteredRegistrations, key, order);

    onFilterChange(sortedFiltredRegistrations, searchQuery); */
  };

  const onSortChange = (event: SelectChangeEvent) => {
    const sortOrder = event.target.value as string;
    setCurrentSortOrder(sortOrder);

    /* const [key, order] = sortOrder.split('-') as [string, 'asc' | 'desc'];
    const filteredRegistrations = searchQuery ? getFilteredRegistrations(registrations, searchQuery) : registrations;
    const sortedFiltredRegistrations = getSortedRegistrations(filteredRegistrations, key, order);

    onFilterChange(sortedFiltredRegistrations, searchQuery); */
  };

  return (
    <FilterWrapper>
      <FormControl>
        <InputLabel id="sort-select-label">Sortering</InputLabel>
        <Select
          labelId="sort-select-label"
          id="sort-select"
          onChange={onSortChange}
          value={currentSortOrder}
          label="Sortering"
        >
          <MenuItem value="name-asc">Namn A-Ö</MenuItem>
          <MenuItem value="name-desc">Namn Ö-A</MenuItem>
          <MenuItem value="category-asc">Kategori</MenuItem>
          <MenuItem value="created-desc">Datum senast först</MenuItem>
          <MenuItem value="created-asc">Datum äldst först</MenuItem>
        </Select>
      </FormControl>

      <TextField
        label="Sök"
        value={searchQuery}
        onChange={(e) => onSearch(e.target.value)}
        autoComplete="off"
        variant="outlined"
        type="search"
      />

      <FormControlLabel
        control={<Switch checked={includeChildren} onChange={(e) => setIncludeChildren(e.target.checked)} />}
        label="Visa kopplingar i listan"
      />
    </FilterWrapper>
  );
};

export const OwnerRegistrations = ({ registrations, type }: UserRegistrationsProps) => {
  const [selectedRegistrationId, setSelectedRegistrationId] = useState<number | null>(null);
  const [filtredRegistrations, setFiltredRegistrations] = useState<RegistrationDto[]>([]);
  const [searchWords, setSearchWords] = useState<string[]>([]);
  const { id } = useParams();

  let detailsPath = '';

  switch (type) {
    case 'confirmed':
      detailsPath = paths.myRegistrationsRegistrationDetails.path;
      break;
    case 'change-of-owner':
      detailsPath = paths.myRegistrationsChangeOfOwnershipRegistrationsDetails.path;
      break;
  }

  useEffect(() => {
    if (!id) {
      return;
    }

    if (registrations.length === 0) {
      setSelectedRegistrationId(null);
      setFiltredRegistrations([]);
      return;
    }

    setSelectedRegistrationId(id ? parseInt(id) : null);
    // setFiltredRegistrations(registrations);
  }, [id, registrations]);

  const onFilterChange = (registrations: RegistrationDto[], searchQuery: string) => {
    if (registrations.length > 0) {
      setFiltredRegistrations(registrations);
      setSearchWords(searchQuery.split(' '));
    }
  };

  return (
    <>
      <TopBarWrapper detailsOpen={selectedRegistrationId ? true : false}>
        <TopBarActions>
          <Filter registrations={registrations} onFilterChange={onFilterChange} />
        </TopBarActions>
      </TopBarWrapper>

      <DefaultCard>
        {/*  <ListWrapper detailsOpen={selectedRegistrationId ? true : false}>
         */}{' '}
        <UserRegistrationsList
          detailsPath={detailsPath}
          searchWords={searchWords}
          registrations={filtredRegistrations}
        />
        {/*  </ListWrapper>
         */}
        {/* {selectedRegistrationId && (
          <RegistrationDetailsWrapper>
            <RegistrationDetails type={type} registrationId={selectedRegistrationId} />
          </RegistrationDetailsWrapper>
        )} */}
      </DefaultCard>
    </>
  );
};

import styled from '@emotion/styled';
import { QrCode2 } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import QrScanner from 'qr-scanner';
import { useRef, useState } from 'react';
import { DefaultButton } from '../../layout';

const VideoWrapper = styled.div`
  video {
    width: 100%;
    height: 100%;
  }
`;

type QrScannerButtonProps = {
  onResult: (result: string) => void;
};

export const QrScannerButton = ({ onResult }: QrScannerButtonProps) => {
  const [isScanning, setIsScanning] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const qrScanner = useRef<QrScanner>();

  const onScan = (result: QrScanner.ScanResult) => {
    if (!result.data) {
      return;
    }
    setIsScanning(false);
    qrScanner.current?.stop();

    // Detect if this is a chassikollen.se ck-URL
    const data = result.data.startsWith('https://www.chassikollen.se/regnr/')
      ? result.data.substring('https://www.chassikollen.se/regnr/'.length)
      : result.data;

    onResult(data);
  };

  const openQrReader = () => {
    setIsScanning(true);
    qrScanner.current?.stop();

    setTimeout(() => {
      if (videoRef.current) {
        qrScanner.current = new QrScanner(videoRef.current, (result) => onScan(result), {
          highlightScanRegion: true,
          highlightCodeOutline: true,
          onDecodeError: (error) => console.log('onDecodeError', error),
        });
        qrScanner.current.start();
      }
    }, 1000);
  };

  const onDialogClose = () => {
    setIsScanning(false);
    qrScanner.current?.stop();
  };

  return (
    <>
      <Dialog open={isScanning} onClose={onDialogClose}>
        <DialogTitle>Skanna QR-koden</DialogTitle>
        <DialogContent>
          <VideoWrapper>
            <video ref={videoRef} />
          </VideoWrapper>
        </DialogContent>
        <DialogActions>
          <DefaultButton onClick={onDialogClose}>Avbryt</DefaultButton>
        </DialogActions>
      </Dialog>
      <IconButton onClick={openQrReader}>
        <QrCode2 fontSize="large" />
      </IconButton>
    </>
  );
};

import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  FinancingOwnerListItemDto,
  FinancingDetailsDto,
  FinancingOwnerChangeListItemDto,
  FinancingDto,
} from '../../shared/types/financing';
import { apiRequest2 } from '../api-request';

const financingKeys = {
  all: ['financings'],
  byFinancier: (financierId: number, searchTerm: string | undefined) => [
    ...financingKeys.all,
    'byFinancier',
    financierId,
    searchTerm,
  ],
  count: (financierId: number) => [...financingKeys.all, 'count', financierId],
};

export const useFinancingsByFinancierQuery = (financierId: number, searchTerm: string) =>
  useQuery({
    queryKey: financingKeys.byFinancier(financierId, searchTerm),
    queryFn: async () => {
      const response = await apiRequest2<FinancingDto[]>({
        action: `Protected/Financings/Financier/${financierId}`,
        method: 'GET',
        allowAnonymous: false,
        params: { searchTerm: searchTerm },
      });

      return response.data;
    },
    placeholderData: keepPreviousData,
  });

export const useFinancingCountQuery = (financierId: number) =>
  useQuery({
    queryKey: financingKeys.count(financierId),
    queryFn: async () => {
      const response = await apiRequest2<number>({
        action: `Protected/Financings/Count/Financier/` + financierId,
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data;
    },
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60 * 10,
    gcTime: 1000 * 60 * 10,
  });

export const useResolveFinancingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (FinancingId: number) =>
      apiRequest2<void>({
        action: `Protected/Financings/Resolve/${FinancingId}`,
        method: 'PUT',
        allowAnonymous: false,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: financingKeys.all,
      });
    },
  });
};

export const getFinancingByOwner = async (ownerId: number) => {
  const response = await apiRequest2<FinancingDto[]>({
    action: `Protected/Financings/Owner/${ownerId}`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response.data;
};

export const getFinancingOwnerChanges = async () => {
  const response = await apiRequest2<FinancingOwnerChangeListItemDto[]>({
    action: `Protected/Financings/OwnerChanges`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response.data;
};

export const getFinancingOwners = async () => {
  const response = await apiRequest2<FinancingOwnerListItemDto[]>({
    action: `Protected/Financings/Owners`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response.data;
};

export const getFinancingDetails = async (financingId: number) => {
  const response = await apiRequest2<FinancingDetailsDto>({
    action: `Protected/Financings/Details/${financingId}`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response.data;
};

import { Grid, TextField } from '@mui/material';
import { CompanyDto } from '../../../shared/types/owner';
import { FormInputWrapper, FormWrapper } from '../../../layout';

type CompanyFormProps = {
  company: CompanyDto;
  onChange: (name: string, value: string) => void;
};

export const CompanyForm = ({ company, onChange }: CompanyFormProps) => {
  return (
    <FormWrapper>
      <FormInputWrapper>
        <TextField
          size="small"
          label="Företagets namn"
          name="name"
          defaultValue={company.name || ''}
          onChange={(event) => onChange(event.target.name, event.target.value)}
          variant="outlined"
          required
          fullWidth
          error={company?.name === ''}
          helperText={company?.name === '' ? 'Företagets namn är obligatoriskt' : ''}
        />
      </FormInputWrapper>

      <FormInputWrapper>
        <TextField
          size="small"
          label="Telefonnummer"
          name="phoneNumber"
          defaultValue={company?.phoneNumber || ''}
          onChange={(event) => onChange(event.target.name, event.target.value)}
          variant="outlined"
          fullWidth
        />
      </FormInputWrapper>

      <FormInputWrapper>
        <TextField
          size="small"
          label="C/o adress"
          name="coAddress"
          defaultValue={company?.coAddress || ''}
          onChange={(event) => onChange(event.target.name, event.target.value)}
          variant="outlined"
          fullWidth
        />
      </FormInputWrapper>

      <FormInputWrapper>
        <TextField
          size="small"
          label="Gatuadress"
          name="address"
          defaultValue={company?.address || ''}
          onChange={(event) => onChange(event.target.name, event.target.value)}
          variant="outlined"
          fullWidth
        />
      </FormInputWrapper>

      <FormInputWrapper>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Postnummer"
              name="zipCode"
              defaultValue={company?.zipCode || ''}
              onChange={(event) => onChange(event.target.name, event.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              label="Ort"
              name="city"
              defaultValue={company?.city || ''}
              onChange={(event) => onChange(event.target.name, event.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </FormInputWrapper>
    </FormWrapper>
  );
};

import styled from '@emotion/styled';
import { Box, Checkbox, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { Check, MoreVertical, Plus } from 'react-feather';
import { DefaultCard, DefaultTextButton, ListItem, ListItemActions } from '../../../layout';

import {
  DataAttributeDto,
  DataAttributeGroupDto,
  DataAttributeTemplateDto,
} from '../../../shared/types/admin/data-attribute';
import { AddDataAttributeDialog } from '../data-attributes/AddDataAttributeDialog';

type EditDataAttributeTemplatesProps = {
  categoryId: number;
  dataAttributes: DataAttributeDto[];
  dataAttributeGroups: DataAttributeGroupDto[];
  dataAttributeTemplatesInCategory: DataAttributeTemplateDto[];
  setDataAttributeTemplatesInCategory: (dataAttributeTemplates: DataAttributeTemplateDto[]) => void;
  onSaveNewDataAttribute: (dataAttribute: DataAttributeDto) => Promise<void>;
};

const AttributeListWrapper = styled.div`
  margin-top: 10px;
`;

const AttributeListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2px;
`;

const GroupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const EditDataAttributeTemplates = ({
  categoryId,
  dataAttributes,
  dataAttributeGroups,
  dataAttributeTemplatesInCategory,
  setDataAttributeTemplatesInCategory,
  onSaveNewDataAttribute,
}: EditDataAttributeTemplatesProps) => {
  const [dataAttributeTempleToEdit, setDataAttributeTempleToEdit] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isAddDataAttributeDialogOpen, setIsAddDataAttributeDialogOpen] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>(0);

  const isSelected = (dataAttributeId: number) => {
    return dataAttributeTemplatesInCategory.find((d) => d.dataAttributeId === dataAttributeId) ? true : false;
  };

  const onDataAttributeClick = (dataAttributeId: number) => {
    if (dataAttributeTemplatesInCategory.find((t) => t.dataAttributeId === dataAttributeId)) {
      const updatedDataAttributeTemplates = dataAttributeTemplatesInCategory.filter(
        (t) => t.dataAttributeId !== dataAttributeId
      );
      setDataAttributeTemplatesInCategory(updatedDataAttributeTemplates);
      return;
    }

    addDataAttributeToTemplates(dataAttributeId);
  };

  const addDataAttributeToTemplates = (dataAttributeId: number) => {
    const dataAttribute = dataAttributes.find((a) => a.id === dataAttributeId);

    if (!dataAttribute) {
      return;
    }

    const newDataAttributeTemplate: DataAttributeTemplateDto = {
      dataAttributeId: dataAttribute.id,
      dataAttributeName: dataAttribute.name,
      categoryId: categoryId,
      isPartOfId: dataAttribute.isPartOfId,
      isPartOfName: dataAttribute.isPartOfName,
      isRequired: dataAttribute.isRequired,
      isScannable: dataAttribute.isScannable,
      isPublic: dataAttribute.isPublic,
      isUnique: dataAttribute.isUnique,
      dataAttributeOrder: dataAttribute.order,
      type: dataAttribute.type,
      dataAttributeGroupId: dataAttribute.groupId,
    };

    setDataAttributeTemplatesInCategory([...dataAttributeTemplatesInCategory, newDataAttributeTemplate]);
  };

  const onEditDataAttributeTemplateClick = (event: React.MouseEvent<HTMLButtonElement>, dataAttributeId: number) => {
    event.stopPropagation();
    setDataAttributeTempleToEdit(dataAttributeId);
    setAnchorEl(event.currentTarget);
  };

  const onEditDataAttributeMenuClose = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    setDataAttributeTempleToEdit(null);
  };

  const onEditDataAttributeMenuClick = (
    event: React.MouseEvent<HTMLLIElement>,
    dataAttributeId: number,
    key: keyof DataAttributeTemplateDto
  ) => {
    const dataAttributeTemplate = dataAttributeTemplatesInCategory.find((t) => t.dataAttributeId === dataAttributeId);

    if (!dataAttributeTemplate) {
      return;
    }

    const updatedDataAttributeTemplate: DataAttributeTemplateDto = {
      ...dataAttributeTemplate,
      [key]: !dataAttributeTemplate[key],
    };

    const updatedDataAttributeTemplates = dataAttributeTemplatesInCategory.map((t) => {
      if (t.dataAttributeId === dataAttributeId) {
        return updatedDataAttributeTemplate;
      }
      return t;
    });

    console.log(updatedDataAttributeTemplates);

    setDataAttributeTemplatesInCategory(updatedDataAttributeTemplates);

    onEditDataAttributeMenuClose(event);
  };

  const onAddNewDataAttributeClick = (groupId: number) => {
    console.log('add new data attribute');
    setSelectedGroupId(groupId);
    setIsAddDataAttributeDialogOpen(true);
  };

  const isDataAttributeTempleOptionChecked = (dataAttributeId: number, key: keyof DataAttributeTemplateDto) => {
    const dataAttributeTemplate = dataAttributeTemplatesInCategory.find(
      (t) => t.dataAttributeId === dataAttributeId && t[key]
    );

    return dataAttributeTemplate ? true : false;
  };

  const onSaveDataAttributeClick = async (dataAttribute: DataAttributeDto) => {
    console.log('save data attribute', dataAttribute);
    await onSaveNewDataAttribute(dataAttribute);

    setIsAddDataAttributeDialogOpen(false);
    setSelectedGroupId(0);
  };

  const onEditDataAttributeDialogClose = () => {
    setIsAddDataAttributeDialogOpen(false);
  };

  return (
    <>
      <AddDataAttributeDialog
        isOpen={isAddDataAttributeDialogOpen}
        onSave={onSaveDataAttributeClick}
        onClose={onEditDataAttributeDialogClose}
        dataAttributeGroups={dataAttributeGroups}
        selectedGroupId={selectedGroupId}
      />

      <AttributeListWrapper>
        <AttributeListHeader>
          <Typography variant="h6">Attribut</Typography>
        </AttributeListHeader>

        {dataAttributeGroups.map((dataAttributeGroup) => {
          const dataAttributesInGroup = dataAttributes.filter((x) => x.groupId === dataAttributeGroup.id);
          return (
            <DefaultCard key={dataAttributeGroup.id}>
              <GroupHeader>
                <Typography variant="subtitle1" gutterBottom>
                  {dataAttributeGroup.name}
                </Typography>

                <DefaultTextButton size="small" onClick={() => onAddNewDataAttributeClick(dataAttributeGroup.id)}>
                  <Plus size={18} /> Lägg till nytt attribut
                </DefaultTextButton>
              </GroupHeader>

              {dataAttributesInGroup.map((dataAttribute) => {
                return (
                  <ListItem
                    active={isSelected(dataAttribute.id)}
                    key={dataAttribute.id}
                    onClick={() => onDataAttributeClick(dataAttribute.id)}
                    dense
                  >
                    <Box>
                      <Checkbox size="small" disableRipple checked={isSelected(dataAttribute.id)} />
                      {dataAttribute.name}
                    </Box>
                    <ListItemActions>
                      <IconButton
                        onClick={(e) => onEditDataAttributeTemplateClick(e, dataAttribute.id)}
                        disabled={!isSelected(dataAttribute.id)}
                      >
                        <MoreVertical size={20} />
                      </IconButton>
                      <Menu
                        id={`data-attribute-menu-${dataAttribute.id}`}
                        anchorEl={anchorEl}
                        open={dataAttributeTempleToEdit === dataAttribute.id}
                        onClose={onEditDataAttributeMenuClose}
                      >
                        <MenuItem
                          dense
                          onClick={(e) => onEditDataAttributeMenuClick(e, dataAttribute.id, 'isPartOfId')}
                        >
                          {isDataAttributeTempleOptionChecked(dataAttribute.id, 'isPartOfId') ? (
                            <>
                              <ListItemIcon>
                                <Check size={20} />
                              </ListItemIcon>
                              Del av id
                            </>
                          ) : (
                            <ListItemText inset>Del av id</ListItemText>
                          )}
                        </MenuItem>
                        <MenuItem
                          dense
                          onClick={(e) => onEditDataAttributeMenuClick(e, dataAttribute.id, 'isPartOfName')}
                        >
                          {isDataAttributeTempleOptionChecked(dataAttribute.id, 'isPartOfName') ? (
                            <>
                              <ListItemIcon>
                                <Check size={20} />
                              </ListItemIcon>
                              Del av namn
                            </>
                          ) : (
                            <ListItemText inset>Del av namn</ListItemText>
                          )}
                        </MenuItem>
                        <MenuItem
                          dense
                          onClick={(e) => onEditDataAttributeMenuClick(e, dataAttribute.id, 'isRequired')}
                        >
                          {isDataAttributeTempleOptionChecked(dataAttribute.id, 'isRequired') ? (
                            <>
                              <ListItemIcon>
                                <Check size={20} />
                              </ListItemIcon>
                              Obligatorisk
                            </>
                          ) : (
                            <ListItemText inset>Obligatorisk</ListItemText>
                          )}
                        </MenuItem>
                        <MenuItem
                          dense
                          onClick={(e) => onEditDataAttributeMenuClick(e, dataAttribute.id, 'isScannable')}
                        >
                          {isDataAttributeTempleOptionChecked(dataAttribute.id, 'isScannable') ? (
                            <>
                              <ListItemIcon>
                                <Check size={20} />
                              </ListItemIcon>
                              Kan scannas
                            </>
                          ) : (
                            <ListItemText inset>Kan scannas</ListItemText>
                          )}
                        </MenuItem>
                      </Menu>
                    </ListItemActions>
                  </ListItem>
                );
              })}
            </DefaultCard>
          );
        })}
      </AttributeListWrapper>
    </>
  );
};

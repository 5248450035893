import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { useEffect, useState } from 'react';
import { DefaultButton, DefaultTextButton } from '../../layout';
import { CategoryDto } from '../../shared/types/protected/category';
import { SelectCategoryTree } from './SelectCategoryTree';

type SelectCategoryDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelectCategoryChange: (category: CategoryDto | null) => void;
  selectedCategory: CategoryDto | null;
};

export const SelectCategoryDialog = ({
  isOpen,
  onClose,
  selectedCategory,
  onSelectCategoryChange,
}: SelectCategoryDialogProps) => {
  const [newSelectedCategory, setNewSelectedCategory] = useState<CategoryDto | null>(null);

  useEffect(() => {
    if (isOpen) {
      setNewSelectedCategory(selectedCategory);
    }
  }, [isOpen]);

  const onSelectClick = () => {
    onSelectCategoryChange(newSelectedCategory);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <SelectCategoryTree
          selectedCategory={selectedCategory}
          onSelectCategory={(category) => setNewSelectedCategory(category)}
        />
      </DialogContent>
      <DialogActions>
        <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
        <DefaultButton onClick={onSelectClick}>Välj kategpori</DefaultButton>
      </DialogActions>
    </Dialog>
  );
};

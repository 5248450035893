import styled from '@emotion/styled';
import { Business, SentimentSatisfiedRounded } from '@mui/icons-material';
import { IconButton, ListItem, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Check, ChevronLeft, User } from 'react-feather';
import { useUser } from '../../context/UserProvider';
import { ProfileUserOwnerDto } from '../../shared/types/user';
import { OwnerTypeDto } from '../../shared/types/owner';

type SelectUserOwnerProps = {
  hideBackButton?: boolean;
  onClose: () => void;
};

const BackButton = styled(IconButton)`
  margin-left: -12px;
  margin-right: 5px;
`;

const Wrapper = styled.div`
  min-width: 250px;
`;

export const SelectUserOwner = ({ onClose, hideBackButton }: SelectUserOwnerProps) => {
  const { userProfile, updateSelectedOwner, selectedOwner } = useUser();

  const setSelectedUserOwner = (userOwner: ProfileUserOwnerDto) => {
    updateSelectedOwner(userOwner);
    onClose();
  };

  return (
    <Wrapper>
      {!hideBackButton && (
        <ListItem>
          <BackButton onClick={onClose}>
            <ChevronLeft />
          </BackButton>
          <ListItemText>Välj vem du vill företräda</ListItemText>
        </ListItem>
      )}

      {userProfile?.userOwners.map((userOwner) => (
        <MenuItem key={userOwner.ownerId} onClick={() => setSelectedUserOwner(userOwner)}>
          <ListItemIcon>
            {userOwner.ownerId === userProfile.ownerId ? (
              <SentimentSatisfiedRounded />
            ) : userOwner?.ownerType === OwnerTypeDto.Company ? (
              <Business />
            ) : (
              <User />
            )}
          </ListItemIcon>
          <ListItemText>{userOwner.ownerId === userProfile.ownerId ? 'Dig själv' : userOwner?.fullName}</ListItemText>
          {selectedOwner?.ownerId === userOwner.ownerId && <Check />}
        </MenuItem>
      ))}
    </Wrapper>
  );
};

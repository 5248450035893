import { Container, Typography } from '@mui/material';
import { PageContent } from '../layout';

export const Status500 = () => {
  return (
    <Container>
      <PageContent>
        <Typography variant="subtitle1">Just nu har vi tyvärr tekniska problem, försök igen senare.</Typography>
      </PageContent>
    </Container>
  );
};

import styled from '@emotion/styled';
import { Box, IconButton } from '@mui/material';
import { Trash2 } from 'react-feather';
import { ImageDto } from '../../shared/types/image';
import { imageDtoToDataURI } from '../../shared/utils/base64';
type ImagesToUploadPreviewProps = {
  selectedImages: ImageDto[] | null;
  onDeleteImage?: (image: ImageDto) => void;
  small?: boolean;
};

const SelectedImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
`;

const ImagePreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #e0e0e0;
  padding: 5px;

  position: relative;
`;

const ImagePreview = styled.img<{ small?: boolean }>`
  width: 100%;
  height: ${(props) => (props.small ? '100px' : '150px')};
  object-fit: contain;
`;
const DeleteImageButton = styled(IconButton)``;

export const ImagesToUploadPreview = ({ selectedImages, onDeleteImage, small }: ImagesToUploadPreviewProps) => {
  return (
    <>
      {selectedImages && (
        <>
          <SelectedImagesWrapper>
            {selectedImages.map((image, i) => (
              <ImagePreviewWrapper key={i}>
                <Box>
                  <ImagePreview
                    alt=""
                    src={image.base64String ? imageDtoToDataURI(image) : image.url}
                    small={small}
                    width="200px"
                  />
                </Box>
                {onDeleteImage && (
                  <DeleteImageButton onClick={() => onDeleteImage(image)}>
                    <Trash2 size={14} />
                  </DeleteImageButton>
                )}
              </ImagePreviewWrapper>
            ))}
          </SelectedImagesWrapper>
        </>
      )}
    </>
  );
};

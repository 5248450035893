import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { LocalCredentialsDto, LocalResultDto, RefreshedToken } from '../../shared/types/auth';
import { UserProfileDto } from './../../shared/types/user';
import { apiRequest2 } from './../api-request';

const userProfileKeys = {
  all: ['userProfile'],
  byUsername: (username: string | null) => [...userProfileKeys.all, 'byUsername', username],
};

export const useUserProfileQuery = (username: string | null) =>
  useQuery({
    queryKey: userProfileKeys.byUsername(username),
    queryFn: async () => {
      const response = await apiRequest2<UserProfileDto>({
        action: 'Protected/User/Profile',
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data;
    },
    enabled: !!username,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

export const useUserProfileMutation = (username: string | null) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (userProfile: UserProfileDto) => {
      await apiRequest2<void>({
        action: 'Protected/User/Profile',
        method: 'PUT',
        allowAnonymous: false,
        body: JSON.stringify(userProfile),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: userProfileKeys.byUsername(username),
      });
    },
  });
};

export const getRefreshedToken = async () => {
  const response = await apiRequest2<RefreshedToken>({
    action: 'Authentication/Common/Refresh',
    method: 'POST',
    allowAnonymous: false,
  });

  return response.data;
};

export const postLocalSignIn = async (credentials: LocalCredentialsDto) => {
  const response = await apiRequest2<LocalResultDto, typeof credentials>({
    action: 'Authentication/Local/Auth',
    method: 'POST',
    body: credentials,
    allowAnonymous: true,
  });

  return response.data;
};

import { useQuery } from '@tanstack/react-query';
import { getSortedDetails } from '../../shared/sort/sort-registration-details';
import { RegistrationDetailsDto } from '../../shared/types/registration';
import { apiRequest2 } from '../api-request';

export const usePublicRegistrationDetailsQuery = (registrationDetailsId: number | null) =>
  useQuery({
    queryKey: ['registrationDetails', registrationDetailsId],
    queryFn: async () => {
      const response = await apiRequest2<RegistrationDetailsDto>({
        action: `Public/RegistrationDetails/${registrationDetailsId}`,
        method: 'GET',
        allowAnonymous: true,
      });

      return getSortedDetails(response.data);
    },
    enabled: !!registrationDetailsId,
  });

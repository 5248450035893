import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, LinearProgress, Typography } from '@mui/material';
import { DefaultButton, DefaultTextButton } from '../../../layout';
import { usePersonByIdQuery, usePutPersonMutation } from '../../../api';
import { PersonForm } from './PersonForm';
import { useState } from 'react';
import { PersonDto } from '../../../shared/types/owner';
import { UserOwnerList } from '../UserOwnerList';
import { UserOwnerDto } from '../../../shared/types/user';
import { ErrorDialog } from '../../../components/dialogs/ErrorDialog';

type PersonEditDialogProps = {
  personId: number;
  onClose: () => void;
};

export const PersonEditDialog = ({ personId, onClose }: PersonEditDialogProps) => {
  const [editedPerson, setEditedPerson] = useState<PersonDto>();
  const [error, setError] = useState<string | null>(null);

  const personByIdQuery = usePersonByIdQuery(personId);

  const putPersonMutation = usePutPersonMutation();

  function onCloseClick(): void {
    setEditedPerson(undefined);
    onClose();
  }

  function onSaveClick(): void {
    console.log('onSaveClick: ', { ...personByIdQuery.data, ...editedPerson });
    putPersonMutation.mutate(
      { ...personByIdQuery.data, ...editedPerson },
      {
        onSuccess: () => {
          setEditedPerson(undefined);
          onClose();
        },
        onError: () => {
          setError('Något gick fel när företaget skulle ändras. Försök igen senare.');
        },
      }
    );
  }

  return (
    <>
      <ErrorDialog isOpen={error !== null} message={error} onClose={() => setError(null)}></ErrorDialog>

      <Dialog open={personId !== null}>
        <>
          <DialogTitle>Redigera {personByIdQuery.data?.socialSecurityNumber}</DialogTitle>
          <DialogContent>
            {personByIdQuery.data ? (
              <>
                <PersonForm
                  person={personByIdQuery.data}
                  onChange={(name, value) => setEditedPerson({ ...editedPerson, [name]: value })}
                />
                <Divider />

                <Typography variant="subtitle1" gutterBottom>
                  Användare
                  <UserOwnerList
                    ownerId={personId}
                    userOwners={editedPerson?.userOwners || personByIdQuery.data.userOwners || []}
                    onChange={(userOwners: UserOwnerDto[]) => setEditedPerson({ ...editedPerson, userOwners })}
                  />
                </Typography>
              </>
            ) : (
              personByIdQuery.isPending && <LinearProgress />
            )}

            {personByIdQuery.isError && <Typography variant="body1">Företaget kunde inte laddas.</Typography>}
          </DialogContent>
          <DialogActions>
            <DefaultTextButton onClick={onCloseClick}>Avbryt</DefaultTextButton>
            <DefaultButton onClick={onSaveClick} disabled={!editedPerson}>
              Spara
            </DefaultButton>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};

import styled from '@emotion/styled';
import { Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { PageTitle } from '../../../layout';
import { EditCompanies } from './companies/EditCompanies';
import { EditPersons } from './persons/EditPersons';

const TabContent = styled.div`
  padding-top: 20px;
`;

export const EditOwners = () => {
  const [activeTab, setActiveTab] = useState(0);

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <PageTitle>Redigera ägare</PageTitle>
      <Tabs value={activeTab} onChange={onTabChange} aria-label="Ägare">
        <Tab label="Privatpersoner" />
        <Tab label="Företag" />
      </Tabs>

      {activeTab == 0 && (
        <TabContent>
          <EditPersons />
        </TabContent>
      )}
      {activeTab == 1 && (
        <TabContent>
          <EditCompanies />
        </TabContent>
      )}
    </>
  );
};

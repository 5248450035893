import { Alert, Box, LinearProgress, MenuItem, Pagination, Snackbar, TextField } from '@mui/material';
import { useState } from 'react';
import {
  useDeleteRegistrationMutation,
  useRegistrationsCountQuery,
  useRegistrationsQuery,
  useUpdateRegistrationMutation,
} from '../../../api/admin/registrations';
import { DataGridFooter, DataGridHeader, DefaultLinkButton, FilterInput, PageTitle } from '../../../layout';
import { paths } from '../../../navigation/paths';
import { PostRegistrationDto, RegistrationListItemDto } from '../../../shared/types/registration';
import { SavingDialog } from '../../dialogs/SavingDialog';
import { EditRegistrationDialog } from '../../registrations/EditRegistrationDialog';
import { RegistrationList } from '../RegistrationList';
import { ConfirmDialog } from '../../dialogs/ConfirmDialog';
import { useDebounce } from '@uidotdev/usehooks';

export const EditRegistrations = () => {
  const [filter, setFilter] = useState<string>('');
  const [registrationToEdit, setregistrationToEdit] = useState<RegistrationListItemDto>();
  const [registrationToDelete, setRegistrationToDelete] = useState<RegistrationListItemDto>();
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const debouncedFilter = useDebounce(filter, 500);
  const registrationsQuery = useRegistrationsQuery(debouncedFilter, page, rowsPerPage);
  const registrationsCountQuery = useRegistrationsCountQuery(debouncedFilter);

  const updateRegistrationMutation = useUpdateRegistrationMutation();
  const deleteRegistrationMutation = useDeleteRegistrationMutation();

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
    setPage(1);
  };

  const onSaveEditedRegistration = async (registration: PostRegistrationDto | null) => {
    if (!registration) {
      return;
    }
    updateRegistrationMutation.mutate(registration, {
      onSuccess: () => {
        setregistrationToEdit(undefined);
        setIsSaved(true);
      },
      onError: (error) => {
        console.log('error', error);
      },
    });
  };

  const onEditRegistrationDialogClose = () => {
    setregistrationToEdit(undefined);
  };

  const onDeleteRegistrationConfirm = async () => {
    if (registrationToDelete) {
      deleteRegistrationMutation.mutate(registrationToDelete.id, {
        onSuccess: () => {
          setRegistrationToDelete(undefined);
        },
      });
    }
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSaved(false);
  };

  return (
    <>
      <ConfirmDialog
        isOpen={!!registrationToDelete}
        onConfirm={onDeleteRegistrationConfirm}
        onCancel={() => setRegistrationToDelete(undefined)}
        title="Är du säker?"
        message={`Vill du verkligen radera "${registrationToDelete?.name}"`}
      ></ConfirmDialog>
      <PageTitle>Registreringar</PageTitle>

      <DataGridHeader>
        <FilterInput type="search" value={filter} onChange={(event) => onFilterChange(event)} placeholder="Filter" />

        <DefaultLinkButton to={paths.newRegistration.path}>Ny registrering</DefaultLinkButton>
      </DataGridHeader>

      {registrationsQuery.data ? (
        <RegistrationList
          registrations={registrationsQuery.data}
          onDeleteRegistration={setRegistrationToDelete}
          onEditRegistration={setregistrationToEdit}
        />
      ) : (
        <LinearProgress />
      )}

      {registrationsQuery.isError && <div>Det gick inte att hämta registreringar</div>}

      <DataGridFooter>
        {registrationsCountQuery.data ? (
          <Pagination
            count={Math.ceil(registrationsCountQuery.data / rowsPerPage)}
            page={page}
            onChange={(event, value) => setPage(value)}
            showFirstButton
            showLastButton
          />
        ) : (
          <>
            <Box></Box>
          </>
        )}

        <TextField
          select
          value={rowsPerPage}
          onChange={(event) => setRowsPerPage(+event.target.value)}
          helperText="Välj antal rader per sida"
          size="small"
        >
          {[5, 10, 25, 50, 100, 500].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </DataGridFooter>

      <SavingDialog open={updateRegistrationMutation.isPending} />

      <EditRegistrationDialog
        registrationId={registrationToEdit?.id ?? null}
        isOpen={registrationToEdit !== undefined}
        onClose={onEditRegistrationDialogClose}
        onSave={onSaveEditedRegistration}
        isAdmin
      />

      <Snackbar
        open={isSaved}
        autoHideDuration={6000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Registreringen är sparad!
        </Alert>
      </Snackbar>
    </>
  );
};

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { DefaultButton, DefaultTextButton, ListDialogContent } from '../../../layout';
import { dataAttributeTypeList } from '../../../shared/select-items/data-attribute-select-list';
import {
  DataAttributeDto,
  DataAttributeGroupDto,
  DataAttributeTypes,
} from '../../../shared/types/admin/data-attribute';

type EditDataAttributeDialogProps = {
  isOpen: boolean;
  dataAttribute: DataAttributeDto | null;
  dataAttributeGroups: DataAttributeGroupDto[];
  onClose: () => void;
  onSave: (dataAttribute: DataAttributeDto | null) => void;
  onNewGroupClick: () => void;
};

export const EditDataAttributeDialog = ({
  isOpen,
  dataAttribute,
  onClose,
  onSave,
  dataAttributeGroups,
}: EditDataAttributeDialogProps) => {
  const [name, setName] = useState('');
  const [isPartOfId, setIsPartOfId] = useState(false);
  const [isPartOfName, setIsPartOfName] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [isScannable, setIsScannable] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [isUnique, setIsUnique] = useState(false);
  const [type, setType] = useState(DataAttributeTypes.STRING);
  const [groupId, setGroupId] = useState(0);
  const [isNewGroup, setIsNewGroup] = useState(false);

  useEffect(() => {
    if (dataAttribute) {
      setName(dataAttribute.name);
      setIsPartOfId(dataAttribute.isPartOfId);
      setIsPartOfName(dataAttribute.isPartOfName);
      setIsScannable(dataAttribute.isScannable);
      setIsRequired(dataAttribute.isRequired);
      setIsPublic(dataAttribute.isPublic);
      setIsUnique(dataAttribute.isUnique);
      setType(dataAttribute.type);
      setGroupId(dataAttribute.groupId);
    }
  }, [dataAttribute]);

  useEffect(() => {
    if (isNewGroup) {
      console.log(dataAttributeGroups);
      setGroupId(dataAttributeGroups[dataAttributeGroups.length - 1].id);
      setIsNewGroup(false);
    }
  }, [dataAttributeGroups]);

  const onSaveButtonClick = () => {
    const updatedDataAttribute: DataAttributeDto = {
      id: dataAttribute?.id || 0,
      groupId,
      name,
      type,
      isPartOfId,
      isPartOfName,
      isRequired,
      isScannable,
      isPublic,
      isUnique,
      isUsedInTemplate: dataAttribute?.isUsedInTemplate || false,
      order: dataAttribute?.order || 0,
    };

    onSave(updatedDataAttribute);
  };

  return (
    <>
      <Dialog disableEnforceFocus open={isOpen} onClose={onClose}>
        <DialogTitle>Redigera attribut</DialogTitle>

        <ListDialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Namn</InputLabel>
                <OutlinedInput value={name} label="Name" onChange={(e) => setName(e.target.value)} />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Typ</InputLabel>
                <Select label="Typ" value={type} onChange={(e) => setType(e.target.value as DataAttributeTypes)}>
                  {dataAttributeTypeList.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Grupp</InputLabel>
                <Select label="Grupp" value={groupId || ''} onChange={(e) => setGroupId(Number(e.target.value))}>
                  {dataAttributeGroups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={isPartOfId} onChange={(e) => setIsPartOfId(e.target.checked)} />}
              label="Del av Id"
            />
            <FormControlLabel
              control={<Checkbox checked={isPartOfName} onChange={(e) => setIsPartOfName(e.target.checked)} />}
              label="Del av namn"
            />
            <FormControlLabel
              control={<Checkbox checked={isRequired} onChange={(e) => setIsRequired(e.target.checked)} />}
              label="Obligatoriskt"
            />
            <FormControlLabel
              control={<Checkbox checked={isScannable} onChange={(e) => setIsScannable(e.target.checked)} />}
              label="Kan scannas"
            />
            <FormControlLabel
              control={<Checkbox checked={isPublic} onChange={(e) => setIsPublic(e.target.checked)} />}
              label="Kan visas/sökas fast man inte är inloggad"
            />
            <FormControlLabel
              control={<Checkbox checked={isUnique} onChange={(e) => setIsUnique(e.target.checked)} />}
              label="Unikt per registrering, ska inte tas med vid kopiering"
            />
          </FormGroup>
        </ListDialogContent>

        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton onClick={onSaveButtonClick}>Spara</DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { Box, LinearProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { DataGridHeader, DefaultButton, DefaultCard, DefaultTextButton, FilterInput, PageTitle } from '../../../layout';

import styled from '@emotion/styled';
import { getBackendOptions, MultiBackend, NodeModel, Tree, TreeMethods } from '@minoru/react-dnd-treeview';
import { DndProvider } from 'react-dnd';
import { Plus } from 'react-feather';
import {
  deleteCategoryApiAsync,
  getCategoriesFromApiAsync,
  postCategoryToApiAsync,
  putCategoryApiAsync,
} from '../../../api/admin';
import { CategoryDto } from '../../../shared/types/admin/category';
import { AddCategoryDialog } from './AddCategoryDialog';
import { CategoryTreeNode } from './CategoryTreeNode';
import { EditCategoryDialog } from './EditCategoryDialog';

const PlusIcon = styled(Plus)`
  margin-right: 5px;
`;

const TreeWrapper = styled(DefaultCard)`
  padding-right: 20px;
  padding-left: 10px;

  & .draggingSource {
    opacity: 0.5;
  }

  & .dropTarget {
    background-color: #e8f0fe;
  }
`;

const TreeListComponent = styled.div`
  margin: 0;
`;

const TreeListItemComponent = styled.div`
  margin-left: 10px;
`;

export const EditCategories = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [categories, setCategories] = useState<CategoryDto[]>([]);
  const [treeData, setTreeData] = useState<NodeModel<CategoryDto>[]>([]);
  const [filteredTreeData, setFilteredTreeData] = useState<NodeModel<CategoryDto>[]>([]);
  const [isAddCategoryDialogOpen, setIsAddCategoryDialogOpen] = useState(false);
  const [isEditCategoryDialogOpen, setIsEditCategoryDialogOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<CategoryDto | null>(null);

  const handleDrop = (newTree: NodeModel<CategoryDto>[]) => {
    const changedNode = newTree.find((node) => treeData.find((n) => n.id === node.id && n.parent !== node.parent));

    if (changedNode) {
      updateCategoryParent(changedNode.id as number, changedNode.parent as number);
    }

    setTreeData(newTree);
  };

  const fetchData = async () => {
    setIsLoading(true);

    const categories = await getCategoriesFromApiAsync();

    if (categories.data) {
      setCategories(categories.data);

      const treeData: NodeModel<CategoryDto>[] = categories.data.map((category) => ({
        id: category.id as number,
        parent: category.parentId ? category.parentId : 0,
        text: category.name,
        droppable: true,
        data: category,
      }));

      setTreeData(treeData);
    }

    setIsLoading(false);
  };

  const updateCategoryParent = async (categoryId: number, parentId: number) => {
    const category = categories.find((c) => c.id === categoryId);

    if (category) {
      category.parentId = parentId === 0 ? null : parentId;
      await putCategoryApiAsync(categoryId, category);
    }
    setCategories([...categories]);

    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    applyFilter();
  }, [filter, treeData]);

  const onAddCategoryClick = () => {
    setSelectedCategory(null);
    setIsAddCategoryDialogOpen(true);
  };

  const onAddCategory = async (category: CategoryDto | null) => {
    if (category === null) {
      return;
    }

    await postCategoryToApiAsync(category);

    setIsAddCategoryDialogOpen(false);
    setIsEditCategoryDialogOpen(false);

    fetchData();
  };

  const onUpdateCategory = async (category: CategoryDto | null) => {
    if (category === null || !category.id) {
      return;
    }

    await putCategoryApiAsync(category.id, category);

    setIsAddCategoryDialogOpen(false);
    setIsEditCategoryDialogOpen(false);

    fetchData();
  };

  const getNodesParents = (nodes: NodeModel<CategoryDto>[]) => {
    const nodeIds = nodes.map((node) => node.id);
    const parentNodeIds: number[] = [];

    nodes.forEach((node) => {
      let parent = node.parent as number;
      while (parent !== 0) {
        if (!parentNodeIds.includes(parent) && !nodeIds.includes(parent)) {
          parentNodeIds.push(parent);
        }
        parent = treeData.find((n) => n.id === parent)?.parent as number;
      }
    });

    const parentNodes = treeData.filter((node) => parentNodeIds.includes(node.id as number));

    return parentNodes;
  };

  const applyFilter = () => {
    const filteredNodes = treeData.filter((node) => node.text.toLowerCase().includes(filter.toLowerCase()));
    const parentNodes = getNodesParents(filteredNodes);

    const sortedNodes = [...filteredNodes, ...parentNodes].sort((a, b) =>
      a.data?.name ? (a.data?.name > (b.data?.name || 0) ? -1 : 1) : 0
    );

    setFilteredTreeData(sortedNodes);
    //setFilteredTreeData([...filteredNodes, ...parentNodes]);
  };

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const ref = useRef<TreeMethods>(null);
  const onOpenAllClick = () => ref.current?.openAll();
  const onCloseAllClick = () => ref.current?.closeAll();

  const onAddChildCategory = (category: CategoryDto) => {
    setSelectedCategory(category);
    setIsAddCategoryDialogOpen(true);
  };
  const onEditCategory = (category: CategoryDto) => {
    setSelectedCategory(category);
    setIsEditCategoryDialogOpen(true);
  };

  const onDeleteCategory = async (categoryId: string) => {
    await deleteCategoryApiAsync(parseInt(categoryId));

    fetchData();
  };

  return (
    <>
      <PageTitle>Redigera kategorier</PageTitle>

      <DataGridHeader>
        <FilterInput type="search" value={filter} onChange={(event) => onFilterChange(event)} placeholder="Filter" />
        <Box>
          <DefaultTextButton onClick={onOpenAllClick}>Öppna alla</DefaultTextButton>
          <DefaultTextButton onClick={onCloseAllClick}>Stäng alla</DefaultTextButton>
          <DefaultButton onClick={() => onAddCategoryClick()}>
            <PlusIcon />
            Lägg till ny kategori
          </DefaultButton>
        </Box>
      </DataGridHeader>

      {isLoading && <LinearProgress />}

      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        {filteredTreeData.length > 0 && (
          <TreeWrapper>
            <Tree
              tree={filteredTreeData}
              ref={ref}
              rootId={0}
              onDrop={handleDrop}
              render={(node, { depth, isOpen, onToggle, hasChild }) => (
                <CategoryTreeNode
                  onAddChildCategory={onAddChildCategory}
                  onEditCategory={onEditCategory}
                  onDeleteCategory={onDeleteCategory}
                  node={node}
                  depth={depth}
                  isOpen={isOpen}
                  hasChild={hasChild}
                  onToggle={onToggle}
                />
              )}
              listComponent={TreeListComponent}
              listItemComponent={TreeListItemComponent}
              classes={{
                draggingSource: 'draggingSource',
                dropTarget: 'dropTarget',
              }}
              initialOpen={false}
              sort={(a, b) => a.text.localeCompare(b.text)}
            />
          </TreeWrapper>
        )}
      </DndProvider>
      <AddCategoryDialog
        isOpen={isAddCategoryDialogOpen}
        onClose={() => setIsAddCategoryDialogOpen(false)}
        onSave={onAddCategory}
        parentCategoryId={selectedCategory?.id || 0}
        parentCategoryName={selectedCategory?.name || ''}
      />
      <EditCategoryDialog
        isOpen={isEditCategoryDialogOpen}
        onClose={() => setIsEditCategoryDialogOpen(false)}
        onSave={onUpdateCategory}
        category={selectedCategory}
      />
    </>
  );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PersonDto } from '../../shared/types/owner';
import { apiRequest2 } from '../api-request';

const personKeys = {
  all: ['persons'],
  lists: (filter: string, page: number, rowsPerPage: number) => [...personKeys.all, 'list', filter, page, rowsPerPage],
};

export const useGetPersonsQuery = (filter: string, page: number, rowsPerPage: number) =>
  useQuery({
    queryKey: personKeys.lists(filter, page, rowsPerPage),
    queryFn: async () => {
      const response = await apiRequest2<GetPersonsResponse>({
        action: `Admin/Persons`,
        method: 'GET',
        allowAnonymous: false,
        params: {
          filter,
          page,
          rowsPerPage,
        },
      });

      return response.data;
    },
  });

export type GetPersonsResponse = {
  total: number;
  persons: PersonDto[];
};

export const usePostPersonMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (person: PersonDto) =>
      apiRequest2<void>({
        action: `Admin/Persons`,
        method: 'POST',
        allowAnonymous: false,
        body: JSON.stringify(person),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: personKeys.all,
      });
    },
  });
};

export const usePutPersonMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (person: PersonDto) =>
      apiRequest2<void>({
        action: `Admin/Persons/${person.id}`,
        method: 'PUT',
        allowAnonymous: false,
        body: JSON.stringify(person),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: personKeys.all,
      });
    },
  });
};

export const useDeletePersonMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) =>
      apiRequest2<void>({
        action: `Admin/Persons/${id}`,
        method: 'DELETE',
        allowAnonymous: false,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: personKeys.all,
      });
    },
  });
};

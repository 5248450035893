import { Dialog, DialogActions, DialogTitle, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  getDataAtrributesFromApiAsync,
  getDataAttributeGroupsFromApiAsync,
  postDataAttributeToApiAsync,
} from '../../../api/admin';
import { DefaultButton, DefaultTextButton, FormInputWrapper, ListDialogContent } from '../../../layout';
import { CategoryDto } from '../../../shared/types/admin/category';
import {
  DataAttributeDto,
  DataAttributeGroupDto,
  DataAttributeTemplateDto,
} from '../../../shared/types/admin/data-attribute';
import { EditDataAttributeTemplates } from './EditAttributeTemplates';

type EditCategoryDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (category: CategoryDto | null) => void;
  category: CategoryDto | null;
};

export const EditCategoryDialog = ({ isOpen, onClose, category, onSave }: EditCategoryDialogProps) => {
  const [name, setName] = useState('');
  const [nameInputRef, setNameInputRef] = useState<HTMLInputElement | null>(null);
  const [dataAttributes, setDataAttributes] = useState<DataAttributeDto[]>([]);
  const [dataAttributeGroups, setDataAttributeGroups] = useState<DataAttributeGroupDto[]>([]);
  const [dataAttributeTemplatesInCategory, setDataAttributeTemplatesInCategory] = useState<DataAttributeTemplateDto[]>(
    []
  );

  useEffect(() => {
    if (nameInputRef) {
      nameInputRef.focus();
    }
  }, [nameInputRef]);

  useEffect(() => {
    if (!category || !isOpen) {
      return;
    }

    setName(category.name);
    setDataAttributeTemplatesInCategory([...category.dataAttributeTemplates]);

    const fetchData = async () => {
      const dataAttributes = await getDataAtrributesFromApiAsync();
      if (!dataAttributes.data) {
        return;
      }

      const sortedDataAttributes = dataAttributes.data.sort((a, b) => a.order - b.order);
      setDataAttributes(sortedDataAttributes);

      const dataAttributeGroups = await getDataAttributeGroupsFromApiAsync();
      if (!dataAttributeGroups.data) {
        return;
      }
      const sortedDataAttributeGroups = dataAttributeGroups.data.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
      setDataAttributeGroups(sortedDataAttributeGroups);
    };

    fetchData();
  }, [category, isOpen]);

  const onSaveClick = () => {
    console.log(dataAttributeTemplatesInCategory);

    const updatedCategory: CategoryDto = {
      id: category?.id || 0,
      parentId: category?.parentId || null,
      name,
      dataAttributeTemplates: dataAttributeTemplatesInCategory,
    };

    onSave(updatedCategory);
  };

  const onSaveNewDataAttribute = async (dataAttribute: DataAttributeDto) => {
    await postDataAttributeToApiAsync(dataAttribute);

    const dataAttributes = await getDataAtrributesFromApiAsync();
    if (!dataAttributes.data) {
      return;
    }

    const sortedDataAttributes = dataAttributes.data.sort((a, b) => a.order - b.order);
    setDataAttributes(sortedDataAttributes);
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={onClose}>
        <DialogTitle>Redigera kategori</DialogTitle>
        <ListDialogContent>
          <FormInputWrapper>
            <FormControl fullWidth margin="dense">
              <InputLabel>Namn</InputLabel>
              <OutlinedInput
                value={name}
                label="Name"
                onChange={(e) => setName(e.target.value)}
                autoFocus
                inputRef={(ref) => setNameInputRef(ref)}
              />
            </FormControl>
          </FormInputWrapper>
          <EditDataAttributeTemplates
            categoryId={category?.id || 0}
            dataAttributes={dataAttributes}
            dataAttributeGroups={dataAttributeGroups}
            dataAttributeTemplatesInCategory={dataAttributeTemplatesInCategory}
            setDataAttributeTemplatesInCategory={setDataAttributeTemplatesInCategory}
            onSaveNewDataAttribute={onSaveNewDataAttribute}
          />
        </ListDialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton onClick={onSaveClick}>Spara</DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

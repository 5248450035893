import styled from '@emotion/styled';
import { CircularProgress, Container } from '@mui/material';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

export const LoadingPage = () => {
  return (
    <Container>
      <Wrapper>
        <CircularProgress />
      </Wrapper>
    </Container>
  );
};

import { paths } from '../../navigation/paths';
import { RegistrationDto } from '../types/registration';
import { RegistrationListItemDto } from './../types/registration';
import { toSlug } from './slugify';

export const getRegistrationUrl = (registration: RegistrationDto | RegistrationListItemDto | undefined) => {
  if (!registration) {
    return '';
  }

  if (!registration.category || !registration.name) {
    return '';
  }

  const category = toSlug(registration.category);
  const name = toSlug(registration.name);

  return `${paths.registrations.path}/${category}/${name}/${registration.id}`;
};

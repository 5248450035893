import { CircularProgress, FormControl, Grid, MenuItem, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CompanyDto } from '../../shared/types/owner';
import { useEffect } from 'react';
import { getInsurers } from '../../api/protected/insurers';
import { FormInputWrapper } from '../../layout';

type SelectInsurerProps = {
  onInsurerChange: (insurer: CompanyDto | null) => void;
  onInsuranceIdChange: (insuranceId: string) => void;
  selectedInsurerId?: number;
  insuranceId: string;
};

export const SelectInsurer = ({
  onInsurerChange,
  onInsuranceIdChange,
  selectedInsurerId,
  insuranceId,
}: SelectInsurerProps) => {
  const insurersQuery = useQuery({
    queryKey: ['insurers'],
    queryFn: () => getInsurers(),
  });

  useEffect(() => {
    if (insurersQuery.error) {
      console.error(insurersQuery.error);
    }
  }, [insurersQuery.error]);

  const onSelectInsurerChange = (insurerId: number) => {
    if (insurerId === 0) {
      onInsurerChange(null);
      onInsuranceIdChange('');
      return;
    }
    const insurer = insurersQuery.data?.find((insurer) => insurer.id === insurerId);
    if (!insurer) return;
    onInsurerChange(insurer);
    onInsuranceIdChange('');
  };

  if (insurersQuery.data)
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <FormInputWrapper title="Försäkring">
            <FormControl fullWidth>
              <TextField
                select
                onChange={(e) => onSelectInsurerChange(e.target.value as unknown as number)}
                value={selectedInsurerId || '0'}
                label="Försäkringsbolag"
                size="small"
                fullWidth
              >
                <MenuItem key={0} value={0}>
                  Ingen försäkring
                </MenuItem>
                {insurersQuery.data.map((insurer, i) => (
                  <MenuItem key={i} value={insurer.id}>
                    {insurer.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </FormInputWrapper>
          {selectedInsurerId && (
            <FormInputWrapper>
              <FormControl fullWidth>
                <TextField
                  onChange={(e) => onInsuranceIdChange(e.target.value)}
                  value={insuranceId}
                  label="Försäkringsnummer"
                  size="small"
                  fullWidth
                />
              </FormControl>
            </FormInputWrapper>
          )}
        </Grid>
      </Grid>
    );

  return <CircularProgress />;
};

import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormInputWrapper, FormWrapper } from '../../../../layout';
import { CompanyDto } from '../../../../shared/types/owner';
import { UserInfoDto, UserOwnerDto } from '../../../../shared/types/user';
import { UserSelect } from '../UserSelect';
import Organisationsnummer from 'organisationsnummer';

type CompanyFormProps = {
  company: CompanyDto | null;
  setCompany: (company: CompanyDto) => void;
  isEditing?: boolean;
};

export const CompanyForm = ({ company, setCompany, isEditing }: CompanyFormProps) => {
  const [organizationNumberInputRef, setOrganizationNumberInputRef] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    if (organizationNumberInputRef) {
      organizationNumberInputRef.focus();
    }
  }, [organizationNumberInputRef]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!company) {
      return;
    }
    const { name, value } = event.target;
    setCompany({ ...company, [name]: value });
  };

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!company) {
      return;
    }
    const { name } = event.target;
    setCompany({ ...company, [name]: checked });
  };

  const onIsRegistrarChange = (userId: number, isRegistrar: boolean) => {
    if (!company) {
      return;
    }
    const userOwner = company.userOwners?.find((uo) => uo.userId === userId);

    if (userOwner) {
      userOwner.isRegistrar = isRegistrar;
      setCompany({ ...company });
    }
  };

  const onIsSupervisorChange = (userId: number, isSupervisor: boolean) => {
    if (!company) {
      return;
    }
    const userOwner = company.userOwners?.find((uo) => uo.userId === userId);

    if (userOwner) {
      userOwner.isSupervisor = isSupervisor;
      setCompany({ ...company });
    }
  };

  const onAddUserOwner = (userInfo: UserInfoDto, isRegistrar: boolean, isSupervisor: boolean) => {
    if (!company?.id) {
      return;
    }

    if (!company.userOwners) {
      company.userOwners = [];
    }

    const userOwner: UserOwnerDto = {
      userId: userInfo.id,
      user: {
        id: userInfo.id,
        displayName: userInfo.displayName,
        socialSecurityNumber: userInfo.socialSecurityNumber,
        givenName: userInfo.givenName,
        familyName: userInfo.familyName,
      },
      ownerId: company.id,
      isRegistrar: isRegistrar,
      isSupervisor: isSupervisor,
    };

    const userOwners = [...company.userOwners, userOwner];

    setCompany({ ...company, userOwners });
  };

  const onDeleteUserOwner = (userId: number) => {
    if (!company) {
      return;
    }

    if (!company.userOwners) {
      return;
    }

    company.userOwners = company.userOwners.filter((uo) => uo.userId !== userId);

    setCompany({ ...company });
  };

  if (!company) {
    return null;
  }

  return (
    <>
      <FormWrapper>
        <>
          {!isEditing && (
            <FormInputWrapper>
              <TextField
                size="small"
                label="Organisationsnummer"
                name="organizationNumber"
                value={company.organizationNumber || ''}
                onChange={onInputChange}
                variant="outlined"
                required
                fullWidth
                autoFocus
                inputRef={(ref) => setOrganizationNumberInputRef(ref)}
                error={company.organizationNumber ? !Organisationsnummer.valid(company.organizationNumber) : false}
              />
            </FormInputWrapper>
          )}
          <FormInputWrapper>
            <TextField
              size="small"
              label="Företagets namn"
              name="name"
              value={company.name || ''}
              onChange={onInputChange}
              variant="outlined"
              required
              fullWidth
            />
          </FormInputWrapper>
          {/* <FormInputWrapper>
            <TextField
              size="small"
              label="LegalGroupCode"
              name="legalGroupCode"
              value={company.legalGroupCode || ''}
              onChange={onInputChange}
              variant="outlined"
              required
              fullWidth
            />
          </FormInputWrapper>
          <FormInputWrapper>
            <TextField
              size="small"
              label="LegalGroupText"
              name="legalGroupText"
              value={company.legalGroupText || ''}
              onChange={onInputChange}
              variant="outlined"
              required
              fullWidth
            />
          </FormInputWrapper> */}
        </>

        <FormInputWrapper>
          <TextField
            size="small"
            label="Telefonnummer"
            name="phoneNumber"
            value={company.phoneNumber || ''}
            onChange={onInputChange}
            variant="outlined"
            fullWidth
          />
        </FormInputWrapper>

        <FormInputWrapper>
          <TextField
            size="small"
            label="C/o adress"
            name="coAddress"
            value={company.coAddress || ''}
            onChange={onInputChange}
            variant="outlined"
            fullWidth
          />
        </FormInputWrapper>
        <FormInputWrapper>
          <TextField
            size="small"
            label="Gatuadress"
            name="address"
            value={company.address || ''}
            onChange={onInputChange}
            variant="outlined"
            fullWidth
          />
        </FormInputWrapper>
        <FormInputWrapper>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                size="small"
                label="Postnummer"
                name="zipCode"
                value={company.zipCode || ''}
                onChange={onInputChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                size="small"
                label="Ort"
                name="city"
                value={company.city || ''}
                onChange={onInputChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </FormInputWrapper>

        <FormInputWrapper>
          <FormControlLabel
            control={<Checkbox checked={company.isLessor} onChange={onCheckboxChange} name="isLessor" size="small" />}
            label="Leasingbolag"
          />
          <FormControlLabel
            control={<Checkbox checked={company.isInsurer} onChange={onCheckboxChange} name="isInsurer" size="small" />}
            label="Försäkringsbolag"
          />
          <FormControlLabel
            control={
              <Checkbox checked={company.isFinancier} onChange={onCheckboxChange} name="isFinancier" size="small" />
            }
            label="Finansiär"
          />
        </FormInputWrapper>

        {company.id && (
          <Typography variant="subtitle1" gutterBottom>
            Användare
            <UserSelect
              ownerId={company.id}
              userOwners={company.userOwners}
              onAddUserOwner={onAddUserOwner}
              onDeleteUserOwner={onDeleteUserOwner}
              onIsRegistrarUpdate={onIsRegistrarChange}
              onIsSupervisorUpdate={onIsSupervisorChange}
            />
          </Typography>
        )}
      </FormWrapper>
    </>
  );
};

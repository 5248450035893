import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DataGridWrapper } from '../../../layout';
import { UserDto } from '../../../shared/types/user';
import { getFormatedSocialSecurityNumberNumber } from '../../../shared/utils/format-pid';

type UserListProps = {
  users: UserDto[] | undefined;
  onEditUser: (user: UserDto) => void;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  {
    field: 'fullName',
    valueGetter: (params: GridValueGetterParams<string, UserDto>) =>
      `${params.row.givenName || ''} ${params.row.familyName || ''}`,
    headerName: 'Namn',
    width: 130,
  },
  {
    field: 'displayName',
    headerName: 'Visningsnamn',
    width: 130,
  },

  {
    field: 'socialSecurityNumber',
    valueGetter: (params: GridValueGetterParams<string, UserDto>) =>
      getFormatedSocialSecurityNumberNumber(params.row.socialSecurityNumber),
    headerName: 'Personnummer',
    width: 130,
  },
]; /* 

const DataGridWrapper = styled.div`
  height: 400px;
  width: 100%;
`; */

export const UserList = ({ users, onEditUser }: UserListProps) => {
  /* const onEditClick = (user: UserDto) => {
    onEditUser(user);
  }; */

  return (
    <>
      <DataGridWrapper>
        <DataGrid
          onRowClick={(e) => onEditUser(e.row)}
          rows={users ?? []}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
        />
      </DataGridWrapper>
      {/*  <Divider />
      <DefaultCard>
        <ListHeader>
          <ListHeaderText>Id</ListHeaderText>
          <ListHeaderText>Användarnamn</ListHeaderText>

          <ListHeaderText>Namn</ListHeaderText>
          <ListHeaderText>Personnummer</ListHeaderText>
          <ListHeaderText>Telefon</ListHeaderText>
          <ListHeaderText>E-post</ListHeaderText>

          <ListItemActions></ListItemActions>
        </ListHeader>
        {users?.map((user) => {
          return (
            <ListItem noPointer key={user.id}>
              <ListItemText>{user.id}</ListItemText>
              <ListItemText>{user.username}</ListItemText>
              <ListItemText>{user.displayName}</ListItemText>
              <ListItemText>{getFormatedSocialSecurityNumberNumber(user.socialSecurityNumber)}</ListItemText>
              <ListItemText>{user.mobilePhone}</ListItemText>
              <ListItemText>{user.email}</ListItemText>
              <ListItemActions>
                <IconButton onClick={() => onEditClick(user)}>
                  <Edit size={20} />
                </IconButton>
              </ListItemActions>
            </ListItem>
          );
        })}
      </DefaultCard> */}
    </>
  );
};

import { TextField } from '@mui/material';
import { PersonDto } from '../../../shared/types/owner';
import { FormInputWrapper, FormWrapper } from '../../../layout';

type PersonFormProps = {
  person: PersonDto;
  onChange: (name: string, value: string) => void;
};

export const PersonForm = ({ person, onChange }: PersonFormProps) => {
  return (
    <FormWrapper>
      <FormInputWrapper>
        <TextField
          size="small"
          label="Förnamn"
          name="givenName"
          defaultValue={person.givenName || ''}
          onChange={(event) => onChange(event.target.name, event.target.value)}
          variant="outlined"
          required
          fullWidth
          error={person?.givenName === ''}
          helperText={person?.givenName === '' ? 'Förnamn är obligatoriskt' : ''}
        />
      </FormInputWrapper>

      <FormInputWrapper>
        <TextField
          size="small"
          label="Efternamn"
          name="familyName"
          defaultValue={person?.familyName || ''}
          onChange={(event) => onChange(event.target.name, event.target.value)}
          variant="outlined"
          fullWidth
        />
      </FormInputWrapper>

      <FormInputWrapper>
        <TextField
          size="small"
          label="E-post"
          name="email"
          defaultValue={person?.email || ''}
          onChange={(event) => onChange(event.target.name, event.target.value)}
          variant="outlined"
          fullWidth
        />
      </FormInputWrapper>

      <FormInputWrapper>
        <TextField
          size="small"
          label="Mobiltelefon"
          name="mobilePhone"
          defaultValue={person?.mobilePhone || ''}
          onChange={(event) => onChange(event.target.name, event.target.value)}
          variant="outlined"
          fullWidth
        />
      </FormInputWrapper>

      {/* <FormInputWrapper>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Postnummer"
              name="zipCode"
              defaultValue={person?.zipCode || ''}
              onChange={(event) => onChange(event.target.name, event.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              label="Ort"
              name="city"
              defaultValue={person?.city || ''}
              onChange={(event) => onChange(event.target.name, event.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </FormInputWrapper> */}
    </FormWrapper>
  );
};

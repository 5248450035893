import styled from '@emotion/styled';
import { Box, LinearProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  addRegistrationChildrenAsync,
  addRegistrationCreatedByOwnerAsync,
  getOwnerRegistrationDetailsFromApiAsync,
  removeRegistrationParentAsync,
  updateRegistrationCreatedByOwnerAsync,
} from '../../../api/protected/registrations';
import { useUser } from '../../../context/UserProvider';
import { DefaultCard, DefaultOutlinedButton, Divider, RouterTextLink } from '../../../layout';
import { paths } from '../../../navigation/paths';
import { PostRegistrationDto } from '../../../shared/types/registration';
import { SavingDialog } from '../../dialogs/SavingDialog';
import { DataAttributeGroups } from '../../registrations/DataAttributeGroups';
import { EditRegistrationDialog } from '../../registrations/EditRegistrationDialog';
import { NewChildRegistrationDialog } from '../../registrations/NewChildRegistrationDialog';
import { SelectChildRegistrationsDialog } from '../SelectChildRegistrationsDialog';
import { RegistrationDetailsHeader } from './RegistrationDetailsHeader';
import { RegistrationDetailsImages } from './RegistrationDetailsImages';

type RegistrationDetailsProps = {
  registrationId: number;
};

const Wrapper = styled.div`
  @media (min-width: 600px) {
    padding: 20px;
  }
`;

const Header = styled.div`
  @media (min-width: 600px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
`;

const DataAttributeGroupsWrapper = styled.div``;

export const RegistrationDetails = ({ registrationId }: RegistrationDetailsProps) => {
  //const [registrationDetails, setRegistrationDetails] = useState<RegistrationDetailsDto>();
  // const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isTheftReportDialogOpen, setIsTheftReportDialogOpen] = useState(false);
  const [isSelectChildRegistrationsDialogOpen, setIsSelectChildRegistrationsDialogOpen] = useState(false);

  const [isNewChildRegistrationDialogOpen, setIsNewChildRegistrationDialogOpen] = useState(false);
  const [isEditNotSubmittedRegistrationDialogOpen, setIsEditNotSubmittedRegistrationDialogOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const menuOpen = Boolean(anchorEl);

  const { selectedOwner } = useUser();

  const queryClient = useQueryClient();

  const registrationDetailsQuery = useQuery({
    queryKey: ['registrationDetails', registrationId],
    queryFn: () => getOwnerRegistrationDetailsFromApiAsync(selectedOwner?.ownerId ?? 0, registrationId),
    enabled: !!registrationId,
  });

  useEffect(() => {
    if (registrationDetailsQuery.error) {
      const message = (registrationDetailsQuery.error as { message: string }).message;

      if (message) {
        setErrorMessage(message);
      }
    }
  }, [registrationDetailsQuery.error]);

  const { mutate: addRegistration, isPending: isSaving } = useMutation({
    mutationFn: (registration: PostRegistrationDto) => addRegistrationCreatedByOwnerAsync(registration),
    onSuccess: () => {
      // updateRegistrations();
      queryClient.invalidateQueries({ queryKey: ['registrationDetails'] });
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  const { mutate: updateRegistration, isPending: isUpdating } = useMutation({
    mutationFn: (registration: PostRegistrationDto) => updateRegistrationCreatedByOwnerAsync(registration),
    onSuccess: () => {
      // updateRegistrations();
      queryClient.invalidateQueries({ queryKey: ['registrationDetails'] });
    },
    onError: (error) => {
      const e = error as typeof error;

      console.log(e);

      //console.log('error', error as { message: string });
    },
  });

  /* const onMoreButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    console.log('onMoreButtonClick');
    setAnchorEl(e.currentTarget);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onNewTheftReportClick = () => {
    console.log('onNewTheftReportClick');
  }; */

  const onAddNewChildRegistrationClick = () => {
    setIsNewChildRegistrationDialogOpen(true);
  };

  const onSelectChildRegistrationsDialogClose = () => {
    setIsSelectChildRegistrationsDialogOpen(false);
  };

  const onSaveChildRegistrations = async (registrationIds: number[]) => {
    await addRegistrationChildrenAsync(registrationId, registrationIds);

    // fetchRegistrationDetails();
    // updateRegistrations();
  };

  const onRemoveChildRegistration = async (registrationId: number) => {
    await removeRegistrationParentAsync(registrationId);

    // fetchRegistrationDetails();
    // updateRegistrations();
  };

  const onNewChildRegistrationDialogClose = () => {
    setIsNewChildRegistrationDialogOpen(false);
    // setChildRegistrationToEdit(null);
  };

  const onChildCreated = (childRegistration: PostRegistrationDto) => {
    const ownerId = selectedOwner?.ownerId;

    if (!ownerId) {
      return;
    }

    const newChildRegistration: PostRegistrationDto = {
      ...childRegistration,
      ownerId,
      parentId: registrationId,
    };

    //console.log('newChildRegistration', newChildRegistration);

    addRegistration(newChildRegistration);

    onNewChildRegistrationDialogClose();
  };

  const onEditRegistration = () => {
    setIsEditNotSubmittedRegistrationDialogOpen(true);
  };

  const onSaveEditedRegistration = async (registration: PostRegistrationDto | null) => {
    if (!registration) {
      return;
    }
    updateRegistration(registration);
    setIsEditNotSubmittedRegistrationDialogOpen(false);
  };

  if (registrationDetailsQuery.isPending) {
    return (
      <Wrapper>
        <LinearProgress />
      </Wrapper>
    );
  }

  if (!registrationDetailsQuery.data) {
    return (
      <Wrapper>
        <Typography variant="h5" gutterBottom>
          Registreringen hittades inte
        </Typography>
        {errorMessage && <Typography variant="body1">{errorMessage}</Typography>}
      </Wrapper>
    );
  }

  return (
    <>
      <SavingDialog open={isSaving || isUpdating} />
      <EditRegistrationDialog
        registrationId={registrationDetailsQuery.data?.id ?? null}
        onClose={() => setIsEditNotSubmittedRegistrationDialogOpen(false)}
        isOpen={isEditNotSubmittedRegistrationDialogOpen}
        onSave={onSaveEditedRegistration}
      />

      <NewChildRegistrationDialog
        isOpen={isNewChildRegistrationDialogOpen}
        onClose={onNewChildRegistrationDialogClose}
        onChildCreated={onChildCreated}
        registrationToEdit={null}
      />

      <DefaultCard>
        <Header>
          {registrationDetailsQuery.data?.parentId ? (
            <>
              <Box>
                <Typography variant="h5" gutterBottom>
                  Kopplad registrering
                </Typography>
                <Typography variant="body1">
                  Objektet i den här registreringen är kopplat till ett huvudobjekt.
                </Typography>
                <RouterTextLink
                  to={paths.myRegistrationsChangeOfOwnershipRegistrationsDetails.path.replace(
                    ':id',
                    registrationDetailsQuery.data.parentId.toString()
                  )}
                >
                  Visa huvudregistrering
                </RouterTextLink>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Header>

        {registrationDetailsQuery.data && (
          <RegistrationDetailsHeader
            onEditRegistration={onEditRegistration}
            registrationDetails={registrationDetailsQuery.data}
          />
        )}

        <RegistrationDetailsImages images={registrationDetailsQuery.data?.images} />
        <DataAttributeGroupsWrapper>
          <DataAttributeGroups dataAttributeGroups={registrationDetailsQuery.data?.dataAttributeGroups} />
        </DataAttributeGroupsWrapper>
      </DefaultCard>

      {!registrationDetailsQuery.data?.parentId && (
        <>
          <Divider />

          <SelectChildRegistrationsDialog
            isOpen={isSelectChildRegistrationsDialogOpen}
            onClose={onSelectChildRegistrationsDialogClose}
            currentRegistrationId={registrationId}
            onSave={onSaveChildRegistrations}
          />
          <DefaultCard>
            <Header>
              <Typography variant="h6">Kopplade objekt</Typography>
              {!registrationDetailsQuery.data?.isOwnerChanging && (
                <HeaderActions>
                  <DefaultOutlinedButton size="small" onClick={() => setIsSelectChildRegistrationsDialogOpen(true)}>
                    Koppla befintlig
                  </DefaultOutlinedButton>
                  <DefaultOutlinedButton size="small" onClick={onAddNewChildRegistrationClick}>
                    Skapa ny
                  </DefaultOutlinedButton>
                </HeaderActions>
              )}
            </Header>
            <Divider />
            {registrationDetailsQuery.data?.children?.length === 0 && (
              <Typography variant="body1">Det finns inga kopplade registreringar</Typography>
            )}
            {registrationDetailsQuery.data?.children?.map((childRegistrationDetails) => (
              <Box key={childRegistrationDetails.id}>
                <RegistrationDetailsHeader
                  registrationDetails={childRegistrationDetails}
                  onRemoveChildRegistration={onRemoveChildRegistration}
                />
                <RegistrationDetailsImages images={childRegistrationDetails?.images} />
                <DataAttributeGroupsWrapper>
                  <DataAttributeGroups dataAttributeGroups={childRegistrationDetails?.dataAttributeGroups} />
                </DataAttributeGroupsWrapper>
                <Divider />
              </Box>
            ))}
          </DefaultCard>
        </>
      )}
    </>
  );
};

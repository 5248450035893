import { useQuery } from '@tanstack/react-query';
import { CategoryDto } from '../../shared/types/protected/category';
import { apiRequest2 } from './../api-request';

const categoryKeys = {
  all: ['categories'],
  lists: () => [...categoryKeys.all, 'list'],
};

export const useCategoriesQuery = (enabled: boolean) =>
  useQuery({
    queryKey: categoryKeys.lists(),
    queryFn: async () => {
      const response = await apiRequest2<CategoryDto[]>({
        action: `Protected/Categories`,
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data.sort((a, b) => a.name.localeCompare(b.name));
    },
    refetchOnWindowFocus: true,
    enabled: enabled,
  });

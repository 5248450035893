import { Dialog, DialogActions, DialogTitle, FormControl, Grid, InputLabel, OutlinedInput } from '@mui/material';
import { useEffect, useState } from 'react';
import { DefaultButton, DefaultTextButton, ListDialogContent } from '../../../layout';
import { DataAttributeGroupDto } from '../../../shared/types/admin/data-attribute';

type EditDataAttributeDialogGroupProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (dataAttributeGroup: DataAttributeGroupDto) => void;
  onDelete: (dataAttributeGroup: DataAttributeGroupDto) => void;
  dataAttributeGroup: DataAttributeGroupDto | null;
  dataAttributeGroupCanBeDeleted: boolean;
};

export const EditDataAttributeGroupDialog = ({
  isOpen,
  onClose,
  onSave,
  onDelete,
  dataAttributeGroup,
  dataAttributeGroupCanBeDeleted,
}: EditDataAttributeDialogGroupProps) => {
  const [name, setName] = useState('');
  const [nameInputRef, setNameInputRef] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    if (nameInputRef) {
      nameInputRef.focus();
    }
  }, [nameInputRef]);

  useEffect(() => {
    if (dataAttributeGroup) {
      setName(dataAttributeGroup.name);
    }
  }, [dataAttributeGroup]);

  const onSaveButtonClick = () => {
    if (!dataAttributeGroup) {
      return;
    }
    onSave({
      ...dataAttributeGroup,
      name,
    });
  };

  const onDeleteButtonClick = () => {
    if (!dataAttributeGroup) {
      return;
    }
    onDelete(dataAttributeGroup);
  };

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onCloseHandler}>
        <DialogTitle>Redigera grupp</DialogTitle>

        <ListDialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Namn</InputLabel>
                <OutlinedInput
                  value={name}
                  label="Name"
                  onChange={(e) => setName(e.target.value)}
                  autoFocus
                  inputRef={(ref) => setNameInputRef(ref)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </ListDialogContent>

        <DialogActions>
          {dataAttributeGroupCanBeDeleted && (
            <DefaultTextButton onClick={onDeleteButtonClick} color="warning">
              Radera gruppen
            </DefaultTextButton>
          )}

          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton onClick={onSaveButtonClick}>Spara</DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { ChangeCircle } from '@mui/icons-material';
import { Menu } from '@mui/material';
import { useState } from 'react';
import { DefaultButton, TextLink } from '../../layout';
import { SelectUserOwner } from '../header/SelectUserOwner';

type SelectUserCompanyProps = {
  textButton?: string;
};

export const SelectUserCompany = ({ textButton }: SelectUserCompanyProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onUserClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {textButton ? (
        <TextLink onClick={onUserClick}>{textButton}</TextLink>
      ) : (
        <DefaultButton onClick={onUserClick} size="small">
          <ChangeCircle /> Ändra
        </DefaultButton>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        disableScrollLock
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <SelectUserOwner hideBackButton onClose={onClose} />
      </Menu>
    </>
  );
};

import { red } from '@mui/material/colors';
import { svSE as coreSvSE } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { svSE } from '@mui/x-data-grid';
import { colors } from './static/colors';

// A custom theme for this app
export const theme = createTheme(
  {
    typography: {
      allVariants: {
        fontFamily: '"Open Sans", sans-serif',
      },
    },
    palette: {
      primary: {
        main: colors.blue,
      },
      secondary: {
        main: colors.orange,
      },
      error: {
        main: red.A400,
      },
    },
  },
  svSE,
  coreSvSE
);

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DefaultButton } from '../../layout';

type ErrorDialogProps = {
  onClose: () => void;
  isOpen: boolean;
  message: string | null;
};

export const ErrorDialog = ({ isOpen, onClose, message }: ErrorDialogProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Något gick tyvärr fel</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <DefaultButton onClick={onClose} color="primary" autoFocus>
          Ok
        </DefaultButton>
      </DialogActions>
    </Dialog>
  );
};

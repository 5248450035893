import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { useUser } from '../../../../context/UserProvider';
import { DefaultButton, DefaultTextButton, ListDialogContent } from '../../../../layout';
import { CompanyDto } from '../../../../shared/types/owner';
import { CompanyForm } from './CompanyForm';

type AddCompanyDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (company: CompanyDto | null) => void;
};

export const AddCompanyDialog = ({ isOpen, onClose, onSave }: AddCompanyDialogProps) => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [company, setCompany] = useState<CompanyDto>({
    userOwners: [],
    isLessor: false,
    isFinancier: false,
    isInsurer: false,
  });

  const { userProfile } = useUser();

  useEffect(() => {
    if (userProfile && isOpen) {
      setCompany({
        userOwners: [],
        isLessor: false,
        isFinancier: false,
        isInsurer: false,
      });
    }
  }, [userProfile, isOpen]);

  const requiredFields = [company.organizationNumber, company.name, company.userOwners];

  const validate = () => {
    const errors = requiredFields.filter((field) => !field);
    setIsValid(errors.length === 0);
  };

  useEffect(() => {
    validate();
  }, [company]);

  const onSaveButtonClick = () => {
    if (!isValid) {
      return;
    }
    onSave(company);
  };
  return (
    <>
      <Dialog open={isOpen} maxWidth="sm" onClose={onClose} fullWidth={true}>
        <DialogTitle>Nytt företag</DialogTitle>
        <ListDialogContent>
          <CompanyForm company={company} setCompany={setCompany} />
        </ListDialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton disabled={!isValid} onClick={onSaveButtonClick}>
            Lägg till företag
          </DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

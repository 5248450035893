import { Dialog, DialogActions, DialogTitle, FormControl, Grid, InputLabel, OutlinedInput } from '@mui/material';
import { useEffect, useState } from 'react';
import { DefaultButton, DefaultTextButton, ListDialogContent } from '../../../layout';

type AddDataAttributeDialogGroupProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (name: string) => void;
};

export const AddDataAttributeGroupDialog = ({ isOpen, onClose, onSave }: AddDataAttributeDialogGroupProps) => {
  const [name, setName] = useState('');
  const [nameInputRef, setNameInputRef] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    if (nameInputRef) {
      nameInputRef.focus();
    }
  }, [nameInputRef]);

  useEffect(() => {
    if (isOpen) {
      resetInputs();
    }
  }, [isOpen]);

  const onSaveButtonClick = () => {
    onSave(name);
  };

  const resetInputs = () => {
    setName('');
  };

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onCloseHandler}>
        <DialogTitle>Lägg till ny grupp</DialogTitle>
        <ListDialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Namn</InputLabel>
                <OutlinedInput
                  value={name}
                  label="Name"
                  onChange={(e) => setName(e.target.value)}
                  autoFocus
                  inputRef={(ref) => setNameInputRef(ref)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </ListDialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton onClick={onSaveButtonClick}>Lägg till</DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { useQuery } from '@tanstack/react-query';
import { DataAttributeGroupDto } from '../../shared/types/protected/data-attribute';
import { apiRequest2 } from './../api-request';

const dataAttributeGroupsKeys = {
  all: ['DataAttributeGroups'],
  lists: () => [...dataAttributeGroupsKeys.all, 'list'],
};

export const useDataAttributeGroupsQuery = (enabled: boolean) =>
  useQuery({
    queryKey: dataAttributeGroupsKeys.lists(),
    queryFn: async () => {
      const response = await apiRequest2<DataAttributeGroupDto[]>({
        action: `Protected/DataAttributeGroups`,
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data;
    },
    refetchOnWindowFocus: true,
    enabled: enabled,
  });

import {} from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import Personnummer from 'personnummer';
import { useEffect, useState } from 'react';
import { Roles } from '../../../auth/roles';
import { Divider, FormInputWrapper, FormWrapper } from '../../../layout';
import { UserDto } from '../../../shared/types/user';

type UserFormProps = {
  user: UserDto | null;
  setUser: (user: UserDto) => void;
  isEditing?: boolean;
};

export const UserForm = ({ user, setUser, isEditing }: UserFormProps) => {
  const [socialSecurityNumberInputRef, setSocialSecurityNumberInputRef] = useState<HTMLInputElement | null>(null);
  const [socialSecurityNumberInput, setSocialSecurityNumberInput] = useState<string>('');

  useEffect(() => {
    if (socialSecurityNumberInputRef) {
      socialSecurityNumberInputRef.focus();
    }
  }, [socialSecurityNumberInputRef]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!user) {
      return;
    }
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  // Should we get this from server to make sure they use the same regex?
  const passwordRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');

  const onSocialSecurityNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSocialSecurityNumberInput(value);

    if (!Personnummer.valid(value)) {
      setUser({ ...user, socialSecurityNumber: '' });
      return;
    }

    const shortFormat = Personnummer.parse(value).format();
    setSocialSecurityNumberInput(shortFormat);

    const longFormat = Personnummer.parse(value).format(true);
    if (longFormat !== user?.socialSecurityNumber) {
      setUser({ ...user, socialSecurityNumber: longFormat });
    }
  };

  const onRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (checked) {
      setUser({ ...user, roles: [...(user?.roles || []), name] });
      return;
    }

    setUser({ ...user, roles: user?.roles?.filter((role) => role !== name) });
  };

  const isRoleSelected = (role: string) => {
    return user?.roles?.includes(role) || false;
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <FormWrapper>
        <>
          <FormInputWrapper>
            <TextField
              size="small"
              label="Personnummer"
              name="socialSecurityNumber"
              value={socialSecurityNumberInput || user.socialSecurityNumber || ''}
              onChange={onSocialSecurityNumberChange}
              variant="outlined"
              disabled={isEditing}
              fullWidth
              autoFocus
              inputRef={(ref) => setSocialSecurityNumberInputRef(ref)}
              helperText={
                !user.socialSecurityNumber && socialSecurityNumberInput.length > 10
                  ? 'Ange ett giltigt personnummer'
                  : ''
              }
            />
          </FormInputWrapper>

          <FormInputWrapper>
            <TextField
              size="small"
              label="Användarnamn"
              name="username"
              value={user.username || ''}
              onChange={onInputChange}
              variant="outlined"
              type="text"
              fullWidth
            />
          </FormInputWrapper>

          <FormInputWrapper>
            <TextField
              size="small"
              label="Nytt lösenord"
              name="password"
              value={user.password || ''}
              onChange={onInputChange}
              variant="outlined"
              type={user.password ? 'password' : 'text'}
              fullWidth
              error={user.password ? !passwordRegex.test(user.password) : false}
              helperText={
                user.password && !passwordRegex.test(user.password)
                  ? 'Lösenordet måste innehålla minst 8 tecken, varav minst en stor bokstav, en siffra och ett specialtecken'
                  : ''
              }
            />
          </FormInputWrapper>

          <Divider />
          <FormInputWrapper>
            <TextField
              size="small"
              label="Förnamn"
              name="givenName"
              value={user.givenName || ''}
              onChange={onInputChange}
              variant="outlined"
              fullWidth
            />
          </FormInputWrapper>

          <FormInputWrapper>
            <TextField
              size="small"
              label="Efternamn"
              name="familyName"
              value={user.familyName || ''}
              onChange={onInputChange}
              variant="outlined"
              fullWidth
            />
          </FormInputWrapper>

          <FormInputWrapper>
            <TextField
              size="small"
              label="Visningsnamn"
              name="displayName"
              value={user.displayName || ''}
              onChange={onInputChange}
              variant="outlined"
              fullWidth
            />
          </FormInputWrapper>

          <FormInputWrapper>
            <TextField
              size="small"
              label="E-post"
              name="email"
              value={user.email || ''}
              onChange={onInputChange}
              variant="outlined"
              fullWidth
            />
          </FormInputWrapper>

          <FormInputWrapper>
            <TextField
              size="small"
              label="Telefon"
              name="mobilePhone"
              value={user.mobilePhone || ''}
              onChange={onInputChange}
              variant="outlined"
              fullWidth
            />
          </FormInputWrapper>

          <Divider />
          <Typography variant="h6">Roller</Typography>

          <Box>
            <FormControlLabel
              control={<Checkbox name={Roles.Owner} checked={isRoleSelected(Roles.Owner)} onChange={onRoleChange} />}
              label="
              Ägare, kräver att användaren har ett validerat personnummer"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox name={Roles.Government} checked={isRoleSelected(Roles.Government)} onChange={onRoleChange} />
              }
              label="
              Polis eller annan myndighet"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={<Checkbox name={Roles.Admin} checked={isRoleSelected(Roles.Admin)} onChange={onRoleChange} />}
              label="Administratör"
            />
          </Box>
        </>
      </FormWrapper>
    </>
  );
};

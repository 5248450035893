import styled from '@emotion/styled';
import { Container, Divider as MuiDivider, Typography } from '@mui/material';
import { PageContent } from '../layout';

const Divider = styled(MuiDivider)`
  margin: 20px 0;
`;

export const Status404 = () => {
  return (
    <Container>
      <PageContent>
        <Typography variant="h4">404 - Sidan hittades inte</Typography>
        <Divider />
        <Typography variant="h5">
          Sidan du försökte nå kunde tyvärr inte hittas. Det kan bero på att sidan har flyttats, tagits bort eller att
          du kanske har angett fel adress.
        </Typography>
      </PageContent>
    </Container>
  );
};

import { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp, Edit } from 'react-feather';
import { DefaultCard, ListHeader, ListHeaderText, ListItem, ListItemText } from '../../layout';
import { RegistrationListItemDto } from '../../shared/types/registration';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';

type RegistrationListProps = {
  registrations: RegistrationListItemDto[];
  onDeleteRegistration: (registration: RegistrationListItemDto) => void;
  onEditRegistration: (registrationId: RegistrationListItemDto) => void;
};

type RegistrationListHeaderProps = {
  sortBy: (key: string, desc: boolean) => void;
  label: string;
  sortKey: string;
  isActive: boolean;
};

const RegistrationListLabel = ({ sortBy, label, sortKey, isActive }: RegistrationListHeaderProps) => {
  const [desc, setDesc] = useState(true);
  const onClick = (key: string) => {
    setDesc(!desc);
    sortBy(key, desc);
  };
  return (
    <>
      <ListHeaderText onClick={() => onClick(sortKey)}>
        {isActive && (desc ? <ChevronDown size={20} /> : <ChevronUp size={20} />)} {label}
      </ListHeaderText>
    </>
  );
};

export const RegistrationList = ({
  registrations,
  onDeleteRegistration,
  onEditRegistration,
}: RegistrationListProps) => {
  const [sortedRegistrations, setSortedRegistrations] = useState<RegistrationListItemDto[] | undefined>(registrations);
  const [currentSortKey, setCurrentSortKey] = useState<string>('');

  useEffect(() => {
    sortBy('name', false);
  }, [registrations]);

  const sortBy = (key: string, desc: boolean) => {
    const sortedRegistrations = [...registrations].sort((a, b) => {
      const aValue = (Object.values(a)[Object.keys(a).indexOf(key ?? '')] as string) ?? '';
      const bValue = (Object.values(b)[Object.keys(b).indexOf(key ?? '')] as string) ?? '';
      return desc ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
    });
    setCurrentSortKey(key);
    setSortedRegistrations([...sortedRegistrations]);
  };

  if (!registrations || registrations.length === 0) {
    return <>Hittar inga registreringar</>;
  }

  return (
    <DefaultCard>
      <ListHeader>
        <RegistrationListLabel sortBy={sortBy} label="Namn" sortKey="name" isActive={currentSortKey === 'name'} />
        <RegistrationListLabel
          sortBy={sortBy}
          label="CK nummer"
          sortKey="ckNumber"
          isActive={currentSortKey === 'ckNumber'}
        />
        <RegistrationListLabel
          sortBy={sortBy}
          label="Nuvarande ägare"
          sortKey="currentOwner"
          isActive={currentSortKey === 'currentOwner'}
        />
        <RegistrationListLabel
          sortBy={sortBy}
          label="Blivande ägare"
          sortKey="nextOwner"
          isActive={currentSortKey === 'nextOwner'}
        />
        <RegistrationListLabel
          sortBy={sortBy}
          label="Kategori"
          sortKey="category"
          isActive={currentSortKey === 'category'}
        />
        <RegistrationListLabel
          sortBy={sortBy}
          label="Skapad"
          sortKey="created"
          isActive={currentSortKey === 'created'}
        />
        <ListHeaderText></ListHeaderText>
      </ListHeader>
      {sortedRegistrations &&
        sortedRegistrations.map((registration) => {
          return (
            <ListItem key={registration.id}>
              <ListItemText>{registration.name}</ListItemText>
              <ListItemText>{registration.ckNumber}</ListItemText>
              <ListItemText>{registration.currentOwner}</ListItemText>
              <ListItemText>{registration.nextOwner}</ListItemText>
              <ListItemText>{registration.category}</ListItemText>
              <ListItemText>{new Date(registration.created).toLocaleString('sv-se')}</ListItemText>
              <ListItemText>
                <IconButton aria-label="edit" onClick={() => onEditRegistration(registration)}>
                  <Edit />
                </IconButton>
                <IconButton aria-label="delete" onClick={() => onDeleteRegistration(registration)}>
                  <Delete />
                </IconButton>
              </ListItemText>
            </ListItem>
          );
        })}
    </DefaultCard>
  );
};

export const isValidCkNumber = (ckNumber: string | undefined) => {
  if (!ckNumber) {
    return false;
  }

  return /^[A-Z]{3}\d{4}$/.test(ckNumber);
};

export const getCkNumberFromUrl = (url: string) => {
  const ckNumber = url.split('/').pop();
  if (!ckNumber) {
    throw new Error('Invalid url');
  }

  if (!isValidCkNumber(ckNumber)) {
    throw new Error('Invalid ckNumber');
  }

  return ckNumber;
};

import { useQuery } from '@tanstack/react-query';
import { RoleDto, UserDto, UserInfoDto } from '../../shared/types/user';
import { apiRequest2 } from './../api-request';

const userKeys = {
  all: ['users'],
  getUsersNotAllowedToAccessOwner: (ownerId: number, filter: string, size: number) => [
    ...userKeys.all,
    'GetUsersNotAllowedToAccessOwner',
    ownerId,
    filter,
    size,
  ],
  getUsersNotAllowedToAccessOwnerCount: (ownerId: number, filter: string) => [
    ...userKeys.all,
    'GetUsersNotAllowedToAccessOwnerCount',
    ownerId,
    filter,
  ],
};

export const useGetUsersNotAllowedToAccessOwnerQuery = (ownerId: number, filter: string, size: number) =>
  useQuery({
    queryKey: userKeys.getUsersNotAllowedToAccessOwner(ownerId, filter, size),
    queryFn: async () => {
      const response = await apiRequest2<GetUsersNotAllowedToAccessOwnerResponse>({
        action: `Admin/Users/GetUsersNotAllowedToAccessOwner`,
        method: 'GET',
        allowAnonymous: false,
        params: {
          ownerId,
          filter,
          size,
        },
      });

      return response.data;
    },
  });

export type GetUsersNotAllowedToAccessOwnerResponse = {
  total: number;
  users: UserInfoDto[];
};

export async function getUsersAsync() {
  const response = await apiRequest2<UserDto[]>({
    action: `Admin/Users`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response.data;
}

export const putUserAsync = async (user: UserDto) => {
  const response = await apiRequest2<null, UserDto>({
    action: `Admin/Users/${user.id}`,
    method: 'PUT',
    allowAnonymous: false,
    body: user,
  });

  return response.data;
};

export const postUserAsync = async (user: UserDto) => {
  const response = await apiRequest2<null, UserDto>({
    action: `Admin/Users`,
    method: 'POST',
    allowAnonymous: false,
    body: user,
  });

  return response.data;
};

export const getUserRolesAsync = async () => {
  const response = await apiRequest2<RoleDto[]>({
    action: `Admin/Roles`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response.data;
};

import { SearchResult } from '../../shared/types/search';
import { apiRequest2 } from '../api-request';

export async function getSearchResultFromApiAsync(query: string): Promise<SearchResult> {
  if (query === '') {
    return {
      totalAmount: 0,
      registrations: [],
    };
  }

  const response = await apiRequest2<SearchResult>({
    action: `Public/Search?term=${query}`,
    method: 'GET',
    allowAnonymous: true,
  });

  return response.data;
}

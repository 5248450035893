import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { getFortnoxAuthenticateUrl, getFortnoxStatus, postFortnoxAuthenticate } from '../../../api/admin';
import { DefaultExternalLinkButton, DefaultCard, PageTitle } from '../../../layout';
import { paths } from '../../../navigation/paths';
import { FortnoxAuthenticateRequest } from '../../../shared/types/fortnox';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const RedirectUrl = () => {
  return window.location.origin + paths.fortnox.path;
};

export const FortnoxStatus = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryClient = useQueryClient();

  const { data: fortnoxAuthenticateUrl } = useQuery({
    queryKey: ['FortnoxAuthenticateUrl'],
    queryFn: () => getFortnoxAuthenticateUrl(),
    refetchOnWindowFocus: false,
  });

  const { data: fortnoxStatus } = useQuery({
    queryKey: ['FortnoxStatus'],
    queryFn: () => getFortnoxStatus(),
    refetchOnWindowFocus: false,
    refetchInterval: 10000,
  });

  const { mutate: sendAuthenticateRequest } = useMutation({
    mutationFn: postFortnoxAuthenticate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['FortnoxStatus'] });
    },
  });

  useEffect(() => {
    if (searchParams.has('code')) {
      const authenticateRequest: FortnoxAuthenticateRequest = {
        code: searchParams.get('code'),
        state: searchParams.get('state'),
        redirectUrl: RedirectUrl(),
      };

      sendAuthenticateRequest(authenticateRequest);

      searchParams.delete('code');
      searchParams.delete('state');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const authUrl = fortnoxAuthenticateUrl + RedirectUrl();

  return (
    <>
      <PageTitle>Fortnox Status</PageTitle>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item md={4}>
          <DefaultCard>
            <Typography variant="h6">Authenticering</Typography>
            <Typography>Koppla chassikollen till fortnox genom att authenticera sajten.</Typography>
            <Typography>User: admin@1365943</Typography>
            <Typography>Pass: Multibolaget123</Typography>
            <DefaultExternalLinkButton href={authUrl}>Authenticate</DefaultExternalLinkButton>
          </DefaultCard>
        </Grid>
        <Grid item md={4}>
          <DefaultCard>
            <Typography variant="h6">Anslutning</Typography>
            {fortnoxStatus?.active ? (
              <>
                <Typography>Aktiv</Typography>
                <Typography>Senast uppdaterad : {Date().toLocaleString()}</Typography>
              </>
            ) : (
              <>
                <Typography>Ej Aktiv</Typography>
                <Typography>ErrorMessage : {fortnoxStatus?.errorMessage ?? '???'}</Typography>
              </>
            )}
          </DefaultCard>
        </Grid>
        <Grid item md={4}>
          <DefaultCard>
            <Typography variant="h6">Företagsinformation</Typography>
            <Typography>Namn : {fortnoxStatus?.companyInformation?.companyName ?? '???'}</Typography>
            <Typography>Org : {fortnoxStatus?.companyInformation?.organizationNumber ?? '???'}</Typography>
            <Typography>Databasnr : {fortnoxStatus?.companyInformation?.databaseNumber ?? '???'}</Typography>
            <Divider />
            <Typography>Adress : {fortnoxStatus?.companyInformation?.address ?? '???'}</Typography>
            <Typography>Postnr : {fortnoxStatus?.companyInformation?.zipCode ?? '???'}</Typography>
            <Typography>Stad : {fortnoxStatus?.companyInformation?.city ?? '???'}</Typography>
            <Typography>Landskod : {fortnoxStatus?.companyInformation?.countryCode ?? '???'}</Typography>
            <Divider />
            <Typography>Besöksadress : {fortnoxStatus?.companyInformation?.visitAddress ?? '???'}</Typography>
            <Typography>Besökspostnr : {fortnoxStatus?.companyInformation?.visitZipCode ?? '???'}</Typography>
            <Typography>Besöksstad : {fortnoxStatus?.companyInformation?.visitCity ?? '???'}</Typography>
            <Typography>Besökslandskod : {fortnoxStatus?.companyInformation?.visitCountryCode ?? '???'}</Typography>
          </DefaultCard>
        </Grid>
        <Grid item md={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Artikelnr</StyledTableCell>
                  <StyledTableCell>Beskrivning</StyledTableCell>
                  <StyledTableCell>Normalpris</StyledTableCell>
                  <StyledTableCell>Enhet</StyledTableCell>
                  <StyledTableCell>Moms</StyledTableCell>
                  <StyledTableCell>Webartikel</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fortnoxStatus?.articles?.map((article, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell>{article.articleNumber}</StyledTableCell>
                    <StyledTableCell>{article.description}</StyledTableCell>
                    <StyledTableCell>{article.salesPrice}</StyledTableCell>
                    <StyledTableCell>{article.unit}</StyledTableCell>
                    <StyledTableCell>{article.vat}</StyledTableCell>
                    <StyledTableCell>{article.webshopArticle ? 'Ja' : 'Nej'}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

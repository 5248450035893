import styled from '@emotion/styled';
import { Grid, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useUser } from '../../context/UserProvider';
import { ListCaption, StolenListInfo } from '../../layout';
import { RegistrationDto } from '../../shared/types/registration';
import { localDateTime } from '../../shared/utils/date';
import { colors } from '../../static/colors';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LockIcon from '@mui/icons-material/Lock';
import CarRentalIcon from '@mui/icons-material/CarRental';

type UserRegistrationsProps = {
  registrations: RegistrationDto[] | null | undefined;
  searchWords?: string[];
  detailsPath: string;
};

const RegistrationListHeader = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: grid;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 10px;
    font-size: 0.8rem;
    grid-template-columns: 3fr 100px 2.5fr 120px 90px;
  }
`;

const RegistrationRow = styled.div`
  display: grid;

  border-bottom: 1px solid #eee;
  cursor: pointer;
  position: relative;

  align-items: center;
  padding: 10px;
  font-size: 0.96rem;

  color: #000;

  &:hover {
    background-color: ${colors.hoverGray};
  }

  @media (min-width: 600px) {
    padding: 20px 10px 20px 20px;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 3fr 100px 2.5fr 120px 90px;
  }
`;

const RegistrationRowColumn = styled.div<{ width?: number; minWidth?: number }>`
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start; */
  //width: ${(props) => (props.width ? `${props.width}%` : 'auto')};
  //  width: ${(props) => (props.minWidth ? `${props.minWidth}px` : 'auto')};
  @media (min-width: 1200px) {
    &:last-child {
      text-align: right;
    }
  }
`;
type StatusMessageProps = {
  registration: RegistrationDto;
};
const StatusMessage = ({ registration }: StatusMessageProps) => {
  const { selectedOwner } = useUser();

  return (
    <>
      {registration.currentOwnerFinancingStatus?.financier && (
        <Tooltip title={'Finansierad av ' + registration.currentOwnerFinancingStatus.financier.name}>
          <AccountBalanceIcon />
        </Tooltip>
      )}

      {registration.currentOwnerLeaseStatus?.financier &&
        registration.currentOwnerLeaseStatus.financier.id === selectedOwner?.ownerId && (
          <Tooltip title={'Leasad till ' + registration.currentOwnerLeaseStatus.holder.fullName}>
            <CarRentalIcon />
          </Tooltip>
        )}

      {registration.currentOwnerLeaseStatus?.financier &&
        registration.currentOwnerLeaseStatus.holder.id === selectedOwner?.ownerId && (
          <Tooltip title={'Leasad från ' + registration.currentOwnerLeaseStatus.financier.name}>
            <LockIcon />
          </Tooltip>
        )}

      {registration.theftReports.find((t) => t.active) && (
        <ListCaption>
          <StolenListInfo>ANMÄLD SOM STULEN</StolenListInfo>
        </ListCaption>
      )}

      {registration.isOwnerChanging && (
        <>
          <ListCaption>
            Ägarbyte pågår{registration.nextOwner?.id === selectedOwner?.ownerId && <>, väntar på ditt godkännande</>}
            <Grid container>
              {registration.nextOwner?.id !== selectedOwner?.ownerId && (
                <>
                  <Grid item xs={10}>
                    Godkänd av blivande ägare:
                  </Grid>
                  <Grid item xs={1}>
                    {registration.nextOwnerConfirmedAt ? 'Ja' : 'Nej'}
                  </Grid>
                </>
              )}

              {registration.currentOwnerFinancingStatus != null && (
                <>
                  <Grid item xs={10}>
                    Löst från nuvarande finansiär:
                  </Grid>
                  <Grid item xs={1}>
                    {registration.currentOwnerFinancingStatus?.financingResolvedAt ? 'Ja' : 'Nej'}
                  </Grid>
                </>
              )}
            </Grid>
          </ListCaption>
        </>
      )}

      {registration.nextOwnerConfirmedAt && !registration.isOwnerChanging && (
        <ListCaption>
          <Grid container>
            <Grid item xs={10}>
              Godkänd: {localDateTime(registration.nextOwnerConfirmedAt)}
            </Grid>
          </Grid>
        </ListCaption>
      )}
    </>
  );
};

export const UserRegistrationsList = ({ registrations, detailsPath }: UserRegistrationsProps) => {
  /* const { selectedOwner } = useUser();
   */
  if (!registrations) {
    return null;
  }

  return (
    <>
      <RegistrationListHeader>
        <RegistrationRowColumn>Namn</RegistrationRowColumn>
        <RegistrationRowColumn>CK-nummer</RegistrationRowColumn>
        <RegistrationRowColumn>Status</RegistrationRowColumn>
        <RegistrationRowColumn>Kopplingar</RegistrationRowColumn>
        <RegistrationRowColumn>Registrerad</RegistrationRowColumn>
      </RegistrationListHeader>
      {registrations.map((registration, i) => {
        const path = detailsPath.replace(':id', registration.id.toString());

        return (
          <Link to={path} key={i}>
            <RegistrationRow>
              <RegistrationRowColumn>
                <ListCaption>{registration.category}</ListCaption>
                {registration.name}
              </RegistrationRowColumn>

              <RegistrationRowColumn>{registration.ckNumber ?? '-'}</RegistrationRowColumn>
              <RegistrationRowColumn>
                <StatusMessage registration={registration} />
              </RegistrationRowColumn>
              <RegistrationRowColumn>
                {registration.parentId && (
                  <ListCaption>
                    Kopplad med {registrations.find((r) => r.id === registration.parentId)?.name}{' '}
                  </ListCaption>
                )}

                {registration.childrenIds && registration.childrenIds.length === 0 && !registration.parentId && '-'}
                {registration.childrenIds && registration.childrenIds.length > 0 && (
                  <ListCaption>
                    {registration.childrenIds.length} {registration.childrenIds.length < 2 ? 'koppling' : 'kopplade'}
                  </ListCaption>
                )}
              </RegistrationRowColumn>

              <RegistrationRowColumn>{dayjs(registration.created).format('L')}</RegistrationRowColumn>
            </RegistrationRow>
          </Link>
        );
      })}
    </>
  );
};

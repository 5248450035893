import { DataAttributeValueDto } from './admin/data-attribute';
import { OwnerFinancingStatusDto, OwnerLeaseStatusDto } from './financing';
import { ImageDto } from './image';
import { OwnerDto } from './owner';
import { TheftReportDto } from './theft-report';

export type RegistrationAttribute = {
  name: string;
  type: string;
  isPartOfId: boolean;
  isRequired: boolean;
  isScannable: boolean;
  value: string;
};

export type RegistrationListCategory = {
  name: string;
  registrations: RegistrationDto[];
};

export type RegistrationDto = {
  id: number;
  name: string;
  ckNumber: string | null;
  created: string;
  category: string;
  categoryId: number;
  attributes: DataAttributeValueDto[] | null;
  parentId: number | null;
  childrenIds: number[] | null;
  images: ImageDto[];
  theftReports: TheftReportDto[];
  currentOwner: OwnerDto | null;
  currentOwnerFinancingStatus: OwnerFinancingStatusDto | null;
  currentOwnerLeaseStatus: OwnerLeaseStatusDto | null;
  nextOwner: OwnerDto | null;
  nextOwnerFinancingStatus: OwnerFinancingStatusDto | null;
  nextOwnerLeaseStatus: OwnerLeaseStatusDto | null;
  currentOwnerConfirmedAt: string | null;
  nextOwnerConfirmedAt: string | null;
  isOwnerChanging: boolean;
  ownerRecords: OwnerRecordDto[];
};

export type OwnerRecordDto = {
  id: number;
  type: OwnerRecordTypeDto;
  created: string;
  confirmed: string;
  ownerId: number;
  ownerType: OwnerTypeDto;
  ownerPid: string;
  ownerFullName: string;
  financingStatus: OwnerRecordFinancingStatusDto | null;
  leaseStatus: OwnerRecordLeaseStatusDto | null;
};

export type OwnerRecordFinancingStatusDto = {
  id: number;
  financierId: number;
  financierName: string;
  externalId: string | null;
  resolvedAt: string | null;
};

export type OwnerRecordLeaseStatusDto = {
  id: number;
  financierId: number;
  financierName: string;
  externalId: string | null;
  resolvedAt: string | null;
};

export enum OwnerRecordTypeDto {
  Change = 2,
  Genesis = 3,
}

export enum OwnerTypeDto {
  Person = 1,
  Company = 2,
  Financier = 3,
}

export type RegistrationDetailsDto = {
  id: number;
  name: string;
  ckNumber: string | null;
  created: string;
  category: string;
  dataAttributeGroups: RegistrationDetailsDataAttributeGroupDto[];
  parentId: number | null;
  children: RegistrationDetailsDto[];
  images: string[];
  theftReports: TheftReportDto[] | null;
  currentOwner: OwnerDto | null;
  currentOwnerFinancingStatus: OwnerFinancingStatusDto | null;
  currentOwnerLeaseStatus: OwnerLeaseStatusDto | null;
  nextOwner: OwnerDto | null;
  nextOwnerFinancingStatus: OwnerFinancingStatusDto | null;
  nextOwnerLeaseStatus: OwnerLeaseStatusDto | null;
  currentOwnerConfirmedAt: string | null;
  nextOwnerConfirmedAt: string | null;
  isOwnerChanging: boolean;
  currentLeaseHolder: OwnerDto | null;
};

export type RegistrationDetailsDataAttributeGroupDto = {
  name: string;
  order: number;
  dataAttributes: DataAttributeValueDto[];
};

export type PostRegistrationDto = {
  id?: number;
  ckNumber?: string;
  categoryId: number;
  ownerId?: number;
  parentId?: number;
  attributes: DataAttributeValueDto[];
  images?: ImageDto[];
  financierId?: number;
  externalId?: string;
  children?: PostRegistrationDto[];
  financingType?: FinancingTypeDto;
  insurerId?: number;
  insuranceId?: string;
};

export enum FinancingTypeDto {
  Financed = 1,
  CashPurchase = 2,
  Lease = 3,
}

export type RegistrationListItemDto = {
  id: number;
  name: string;
  ckNumber: string | null;
  created: string;
  category: string;
  childrenIds: number[] | null;
  hasTheftReports: boolean;
  parentId: number | null;
  currentOwner: string;
  nextOwner: string;
  isOwnerChanging: boolean;
  isConfirmed: boolean;
  isFinancingResolved: boolean;
};

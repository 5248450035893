import { useEffect } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import { colors } from '../../static/colors';
import { Typography } from '@mui/material';

ReactGA.gtag('consent', 'default', {
  ad_storage: 'denied',
  ad_user_data: 'denied',
  ad_personalization: 'denied',
  analytics_storage: 'denied',
});

ReactGA.initialize('G-9GTKLGZCJN');

function handleAcceptCookie() {
  ReactGA.gtag('consent', 'update', {
    ad_storage: 'granted',
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    analytics_storage: 'granted',
  });
}

export const AnalyticsWithConsent = () => {
  useEffect(() => {
    const isConsent = getCookieConsentValue('cookieConsent');
    if (isConsent === 'true') {
      handleAcceptCookie();
    }
  }, []);

  return (
    <CookieConsent
      debug={false}
      location="top"
      buttonText="OK"
      cookieName="cookieConsent"
      style={{ background: colors.lightGray, color: colors.blue }}
      buttonStyle={{ background: colors.orange, color: 'white', fontSize: '13px', borderRadius: '20px' }}
      expires={150}
      // enableDeclineButton
      // declineButtonText="Neka"
      // declineButtonStyle={{ color: '#4e503b', fontSize: '13px' }}
      onAccept={handleAcceptCookie}
      // onDecline={() => {
      //   ReactGA.gtag('consent', 'update', {
      //     analytics_storage: 'denied',
      //   });
      // }}
    >
      <Typography variant="h5">Vi använder cookies</Typography>
      <Typography variant="body1">
        Vi och våra samarbetspartner använder tekniska lösningar, bland annat kakor, för att inhämta information om dig
        för olika syften som inloggning och besöksstatistik.
      </Typography>
      <Typography variant="body1">Genom att klicka ”OK” ger du ditt samtycke till detta.</Typography>
    </CookieConsent>
  );
};

import styled from '@emotion/styled';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider as MuiDivider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import Organisationsnummer from 'organisationsnummer';
import Personnummer from 'personnummer';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCompanyByOrganizationNumberFromApiAsync, getPersonBysocialSecurityNumberFromApiAsync } from '../../api';
import { DefaultButton, DefaultTextButton, FormInputWrapper } from '../../layout';
import { OwnerDto, OwnerTypeDto } from '../../shared/types/owner';
import { getFormatedOrganizationNumber, getFormatedSocialSecurityNumberNumber } from '../../shared/utils/format-pid';

type SelectOwnerDialogProps = {
  onSelectOwner: (owner: OwnerDto) => void;
  isOpen: boolean;
  onClose: () => void;
};

const Divider = styled(MuiDivider)`
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const SelectOwnerDialog = ({ onSelectOwner, isOpen, onClose }: SelectOwnerDialogProps) => {
  const [ownerOrganizationNumberInput, setOwnerOrganizationNumberInput] = useState('');
  const [ownerOrganizationNumber, setOwnerOrganizationNumber] = useState('');
  const [ownerSocialSecurityNumber, setOwnerSocialSecurityNumber] = useState('');
  const [ownerSocialSecurityNumberInput, setOwnerSocialSecurityNumberInput] = useState('');
  const [ownerSearchError, setOwnerSearchError] = useState<string | null>('');

  useEffect(() => {
    if (isOpen) {
      setOwnerOrganizationNumberInput('');
      setOwnerOrganizationNumber('');
      setOwnerSocialSecurityNumber('');
      setOwnerSocialSecurityNumberInput('');
      setOwnerSearchError(null);
    }
  }, [isOpen]);

  const updateOrganizationNumberInput = (organizationNumber: string) => {
    const formatedOrganizationNumber = Organisationsnummer.valid(organizationNumber)
      ? Organisationsnummer.parse(organizationNumber).format(true)
      : organizationNumber;
    setOwnerOrganizationNumberInput(formatedOrganizationNumber);
  };

  const onOwnerOrganizationNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const organizationNumber = event.target.value;

    updateOrganizationNumberInput(organizationNumber);

    setOwnerSearchError(null);

    if (organizationNumber.length < 10) {
      return;
    }

    if (!Organisationsnummer.valid(organizationNumber)) {
      setOwnerSearchError('Ogiltigt organisationsnummer');
      return;
    }

    searchCompany(organizationNumber);
  };

  const searchCompany = async (organizationNumber: string) => {
    setOwnerSearchError(null);

    const formatedOrganizationNumber = Organisationsnummer.parse(organizationNumber).format(false);

    setOwnerOrganizationNumber(formatedOrganizationNumber);

    setOwnerOrganizationNumberInput(formatedOrganizationNumber);
  };

  const onOwnerSocialSecurityNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const socialSecurityNumber = event.target.value;
    updateSocialSecurityNumberInput(socialSecurityNumber);

    setOwnerSearchError(null);

    if (socialSecurityNumber.length < 10) {
      return;
    }

    if (!Personnummer.valid(socialSecurityNumber)) {
      setOwnerSearchError('Ogiltigt personnummer');
      return;
    }

    searchPerson(socialSecurityNumber);
  };

  const updateSocialSecurityNumberInput = (socialSecurityNumber: string) => {
    const formatedSocialSecurityNumber = Personnummer.valid(socialSecurityNumber)
      ? Personnummer.parse(socialSecurityNumber).format()
      : socialSecurityNumber;
    setOwnerSocialSecurityNumberInput(formatedSocialSecurityNumber);
  };

  const searchPerson = async (socialSecurityNumber: string) => {
    const formatedSocialSecurityNumber = getFormatedSocialSecurityNumberNumber(socialSecurityNumber);

    setOwnerSearchError(null);

    setOwnerSocialSecurityNumber(formatedSocialSecurityNumber);
  };

  const ownerCompanyQuery = useQuery({
    queryKey: ['ownerCompany', ownerOrganizationNumber],
    queryFn: () => getCompanyByOrganizationNumberFromApiAsync(ownerOrganizationNumber),
    enabled: !!ownerOrganizationNumber,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (ownerCompanyQuery.error) {
      console.log('error', ownerCompanyQuery.error);
      setOwnerSearchError('Företaget hittades inte');
    }
  }, [ownerCompanyQuery.error]);

  const ownerPersonQuery = useQuery({
    queryKey: ['ownerPerson', ownerSocialSecurityNumber],
    queryFn: () => getPersonBysocialSecurityNumberFromApiAsync(ownerSocialSecurityNumber),
    enabled: !!ownerSocialSecurityNumber,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (ownerPersonQuery.error) {
      console.log('error', ownerPersonQuery.error);
      setOwnerSearchError('Personen hittades inte');
    }
  }, [ownerPersonQuery.error]);

  const [ownerType, setOwnerType] = useState(OwnerTypeDto.Company);

  const onOwnerTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //setBuyerCompany(null);
    setOwnerOrganizationNumber('');
    setOwnerOrganizationNumberInput('');
    //setBuyerPerson(null);
    setOwnerSocialSecurityNumber('');
    setOwnerSocialSecurityNumberInput('');
    setOwnerType(parseInt(event.target.value) as OwnerTypeDto);
  };

  const onSelectOwnerClick = () => {
    const newOwnerId = ownerType === OwnerTypeDto.Company ? ownerCompanyQuery.data?.id : ownerPersonQuery.data?.id;

    if (!newOwnerId) {
      return;
    }

    const owner: OwnerDto = {
      id: newOwnerId,
      isPerson: ownerType === OwnerTypeDto.Person,
      fullName:
        ownerType === OwnerTypeDto.Person
          ? ownerPersonQuery.data?.givenName + ' ' + ownerPersonQuery.data?.familyName ?? ''
          : ownerCompanyQuery.data?.name ?? '',
      pid:
        ownerType === OwnerTypeDto.Person
          ? ownerPersonQuery.data?.socialSecurityNumber ?? ''
          : ownerCompanyQuery.data?.organizationNumber ?? '',
    };

    onSelectOwner(owner);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Välj ägare</DialogTitle>
      <DialogContent>
        <FormInputWrapper>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={ownerType}
              onChange={onOwnerTypeChange}
            >
              <FormControlLabel value={OwnerTypeDto.Company} control={<Radio />} label="Företag" />
              <FormControlLabel value={OwnerTypeDto.Person} control={<Radio />} label="Privatperson" />
            </RadioGroup>
          </FormControl>
        </FormInputWrapper>

        {ownerType === OwnerTypeDto.Company && (
          <FormInputWrapper>
            <TextField
              onChange={onOwnerOrganizationNumberChange}
              value={ownerOrganizationNumberInput}
              label="Organisationsnummer"
              size="small"
              fullWidth
            />
          </FormInputWrapper>
        )}
        {ownerType === OwnerTypeDto.Person && (
          <FormInputWrapper>
            <TextField
              onChange={onOwnerSocialSecurityNumberChange}
              value={ownerSocialSecurityNumberInput}
              label="Personnummer"
              size="small"
              fullWidth
            />
          </FormInputWrapper>
        )}

        <Divider />

        {ownerSearchError && (
          <FormInputWrapper>
            <Typography variant="body2" gutterBottom>
              {ownerSearchError}
            </Typography>
          </FormInputWrapper>
        )}
        {ownerPersonQuery.data && (
          <>
            <Typography variant="caption" gutterBottom>
              Privatperson
            </Typography>
            <Typography variant="h6" gutterBottom>
              {ownerPersonQuery.data.givenName} {ownerPersonQuery.data.familyName}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {getFormatedSocialSecurityNumberNumber(ownerPersonQuery.data.socialSecurityNumber)}
            </Typography>
          </>
        )}
        {ownerCompanyQuery.data && (
          <>
            <Typography variant="caption" gutterBottom>
              Företag/Organisation
            </Typography>
            <Typography variant="h6" gutterBottom>
              {ownerCompanyQuery.data.name}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {getFormatedOrganizationNumber(ownerCompanyQuery.data.organizationNumber)}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
        <DefaultButton onClick={onSelectOwnerClick} disabled={!ownerCompanyQuery.data && !ownerPersonQuery.data}>
          Välj
        </DefaultButton>
      </DialogActions>
    </Dialog>
  );
};

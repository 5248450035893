import { Alert, Card, CardContent, Collapse, IconButton, LinearProgress, TextField, Typography } from '@mui/material';
import { DefaultButton, DefaultCard } from '../../layout';
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useUser } from '../../context/UserProvider';
import { ConfirmDialog } from '../dialogs/ConfirmDialog';
import styled from '@emotion/styled';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useDebounce } from '@uidotdev/usehooks';
import { InsuranceDto } from '../../shared/types/insurance';
import { useResolveInsuranceMutation, useInsurancesByInsurerQuery } from '../../api/protected/insurances';

const Header = styled.span`
  font-weight: 700;
`;

export const InsuranceList = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [insuranceToResolve, setInsuranceToResolve] = useState<InsuranceDto>();
  const [errorText, setErrorText] = useState<string>();
  const [expanded, setExpanded] = useState<number | false>(false);

  const { selectedOwner, userProfile } = useUser();
  const insurerId = selectedOwner?.ownerId ?? 0;

  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const insurancesByInsurerQuery = useInsurancesByInsurerQuery(insurerId, debouncedSearchTerm);

  const resolveInsuranceMutation = useResolveInsuranceMutation();

  // Clear error text when insuranceToResolve changes
  useEffect(() => {
    setErrorText(undefined);
  }, [insuranceToResolve]);

  const onSearch = (searchQuery: string) => {
    setSearchTerm(searchQuery);
  };

  function onResolveInsuranceConfirm(): void {
    if (insuranceToResolve) {
      resolveInsuranceMutation.mutate(insuranceToResolve.id, {
        onSuccess: () => setInsuranceToResolve(undefined),
        onError: () => setErrorText('Kan inte utföra åtgärden just nu. Försök igen senare.'),
      });
    }
  }

  return (
    <>
      <ConfirmDialog
        isOpen={!!insuranceToResolve}
        onConfirm={onResolveInsuranceConfirm}
        onCancel={() => setInsuranceToResolve(undefined)}
        title="Är du säker?"
        message={`Vill du verkligen avsluta försäkringen med kontraktsnummer "${insuranceToResolve?.externalId}"`}
        errorText={errorText}
      />

      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={6}>
          {/* <FinancingOwnersSelect value={ownerId} onChange={(ownerId) => setOwnerId(ownerId)} /> */}
          {/* <Typography variant="h4">FinancierID: {financierId}</Typography> */}
        </Grid>
        <Grid item xs={12} sm={6} textAlign={'end'}>
          <TextField
            label="Sök"
            value={searchTerm}
            onChange={(e) => onSearch(e.target.value)}
            autoComplete="off"
            variant="outlined"
            type="search"
          />
        </Grid>
      </Grid>
      {insurancesByInsurerQuery.data ? (
        <>
          {insurancesByInsurerQuery.data.length > 0 ? (
            <>
              {insurancesByInsurerQuery.data.map((insurance) => (
                <DefaultCard key={insurance.id} style={{ marginBottom: 10, paddingTop: 20, paddingBottom: 15 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <Typography variant="body1">
                        <Header>Kontraktsnummer</Header> {insurance.externalId ?? 'Okänt'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Typography variant="body1">
                        <Header>Försäkringstagare</Header> {insurance.holder.fullName ?? 'Okänt'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2} textAlign={'end'}>
                      {!insurance.resolved && (
                        <DefaultButton
                          disabled={
                            insurance.resolved !== null ||
                            resolveInsuranceMutation.isPending ||
                            insurance.insurer.userOwners?.find(
                              (userOwner) => userOwner.userId === userProfile?.id && userOwner.isSupervisor
                            ) === undefined
                          }
                          onClick={() => setInsuranceToResolve(insurance)}
                          size="small"
                        >
                          Avsluta
                        </DefaultButton>
                      )}
                    </Grid>

                    {insurance.resolved ? (
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          Försäkringen avslutades {new Date(insurance.resolved).toLocaleDateString()}.
                        </Typography>
                      </Grid>
                    ) : insurance.registrations.length > 0 ? (
                      <>
                        <Grid item xs={12} sm={10}>
                          <Typography variant="body1">
                            <Header>{insurance.registrations.length} objekt ingår i försäkringen</Header>
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={2} textAlign={'end'}>
                          <IconButton
                            onClick={() => setExpanded(expanded === insurance.id ? false : insurance.id)}
                            size="small"
                          >
                            {expanded === insurance.id ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                        </Grid>

                        <Grid item xs={12}>
                          {insurance.registrations.map((registration, index) => {
                            return (
                              <Collapse in={expanded === insurance.id} key={index}>
                                <Card key={index} style={{ marginBottom: 10 }}>
                                  <CardContent>
                                    <Grid container spacing={2} marginBottom={1}>
                                      <Grid item xs={12} sm={6}>
                                        <Typography variant="body1">
                                          <Header>Namn</Header>
                                        </Typography>
                                        <Typography variant="body1">
                                          {registration.name || registration.category || 'Saknar namn'}
                                        </Typography>
                                        <Typography variant="body1">
                                          <Header>Ck Nummer</Header>
                                        </Typography>
                                        <Typography variant="body1">
                                          {registration.ckNumber || 'Saknar Ck nummer'}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography variant="body1">
                                          <Header>Nuvarande ägare</Header>
                                        </Typography>
                                        <Typography variant="body1">{registration.currentOwner?.fullName}</Typography>
                                        {registration.currentOwnerConfirmedAt && (
                                          <>
                                            <Typography variant="body1">
                                              <Header>Sedan</Header>
                                            </Typography>
                                            <Typography variant="body1">
                                              {new Date(registration.currentOwnerConfirmedAt).toLocaleDateString()}
                                            </Typography>
                                          </>
                                        )}
                                        {registration.currentOwnerLeaseStatus?.externalId !== insurance.externalId && (
                                          <Alert severity="warning" variant="outlined" style={{ marginTop: 10 }}>
                                            Försäkringen gjordes för en tidigare ägare
                                            {' som heter ' +
                                              registration.ownerRecords.find(
                                                (ownerRecord) => ownerRecord.leaseStatus?.id === insurance.id
                                              )?.ownerFullName}
                                            .
                                            {registration.currentOwnerLeaseStatus?.financier?.name &&
                                              ' Den nuvarande ägaren försäkras av ' +
                                                registration.currentOwnerLeaseStatus?.financier?.name}
                                          </Alert>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Collapse>
                            );
                          })}
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12}>
                        <Alert severity="warning" variant="outlined">
                          Försäkringen har inte längre några objekt kopplade till sig.
                        </Alert>
                      </Grid>
                    )}
                  </Grid>
                </DefaultCard>
              ))}
            </>
          ) : (
            <Typography variant="h6">Inga försäkringar hittades</Typography>
          )}
        </>
      ) : (
        insurancesByInsurerQuery.isPending && <LinearProgress />
      )}
      {insurancesByInsurerQuery.isError && (
        <>
          <Typography variant="h4">Ett fel har inträffat</Typography>;
          {insurancesByInsurerQuery.error instanceof Error && (
            <Typography variant="h6">
              {insurancesByInsurerQuery.error.message} : {JSON.stringify(insurancesByInsurerQuery.error)}
            </Typography>
          )}
        </>
      )}
    </>
  );
};

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { DefaultButton, DefaultTextButton, ListItem } from '../../layout';
import { TheftReportDto } from '../../shared/types/theft-report';

type TheftReportDialogProps = {
  theftReports: TheftReportDto[];
  open: boolean;
  onClose: () => void;
  onSubmit: (theftReports: TheftReportDto[]) => void;
};

export const TheftReportDialog = ({ theftReports, open, onClose, onSubmit }: TheftReportDialogProps) => {
  const [activeTheftReportIds, setActiveTheftReportIds] = useState<number[]>([]);

  useEffect(() => {
    setActiveTheftReportIds(theftReports.filter((t) => t.active).map((t) => t.id));
  }, [theftReports]);

  const onSubmitButtonClick = () => {
    const updatedTheftReports = theftReports.map((theftReport) => ({
      ...theftReport,
      active: activeTheftReportIds.includes(theftReport.id),
    }));

    onSubmit(updatedTheftReports);
  };

  const toogleThefReportActive = (index: number) => {
    if (activeTheftReportIds.includes(index)) {
      setActiveTheftReportIds(activeTheftReportIds.filter((id) => id !== index));
    } else {
      setActiveTheftReportIds([...activeTheftReportIds, index]);
    }
  };

  const isActive = (index: number) => activeTheftReportIds.includes(index);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Stöldanmälningar</DialogTitle>
        <DialogContent>
          {theftReports.map((theftReport) => {
            return (
              <ListItem noPointer disabled={!isActive(theftReport.id)} key={theftReport.id}>
                <Box>
                  <Typography variant="caption">{theftReport.created}</Typography>
                  <Typography variant="h6" gutterBottom>
                    {theftReport.description}
                  </Typography>
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Switch
                        onClick={() => toogleThefReportActive(theftReport.id)}
                        checked={isActive(theftReport.id)}
                      />
                    }
                    label="Aktiv"
                  />
                </Box>
              </ListItem>
            );
          })}
        </DialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton onClick={onSubmitButtonClick}>Spara</DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { DefaultButton, DefaultTextButton, ListDialogContent } from '../../../../layout';
import { PersonDto } from '../../../../shared/types/owner';
import { PersonForm } from './PersonForm';

type EditPersonDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (person: PersonDto | null) => void;
  personToUpdate: PersonDto | null;
};

export const EditPersonDialog = ({ isOpen, onClose, onSave, personToUpdate }: EditPersonDialogProps) => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [person, setPerson] = useState<PersonDto>({} as PersonDto);

  useEffect(() => {
    if (personToUpdate) {
      setPerson({ ...personToUpdate });
      setName(`${personToUpdate.givenName} ${personToUpdate.familyName}}`);
    }
  }, [personToUpdate]);

  const requiredFields = [person.socialSecurityNumber, person.givenName, person.familyName, person.userOwners];

  const validate = () => {
    const errors = requiredFields.filter((field) => !field);
    setIsValid(errors.length === 0);
  };

  useEffect(() => {
    validate();
  }, [person]);

  const onSaveButtonClick = () => {
    if (!isValid) {
      return;
    }
    onSave(person);
  };
  return (
    <>
      <Dialog open={isOpen} maxWidth="sm" onClose={onClose} fullWidth={true}>
        <DialogTitle>Redigera {name}</DialogTitle>
        <ListDialogContent>
          <PersonForm isEditing person={person} setPerson={setPerson} />
        </ListDialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton disabled={!isValid} onClick={onSaveButtonClick}>
            Spara
          </DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

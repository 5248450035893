import styled from '@emotion/styled';
import { Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { DefaultCard, TextLink } from '../../layout';
import { DataAttributeTypes } from '../../shared/types/protected/data-attribute';
import { RegistrationDetailsDataAttributeGroupDto } from '../../shared/types/registration';

type DataAttributeGroupsProps = {
  dataAttributeGroups: RegistrationDetailsDataAttributeGroupDto[] | null | undefined;
};

const GroupCard = styled.div``;

const Attribute = styled.div`
  font-size: 0.875rem;
  margin-bottom: 10px;
  max-width: 300px;
`;

const AttributeName = styled.div`
  font-weight: 700;
`;

const AttributeValue = styled.div``;

const AttributeGroupsWrapper = styled.div`
  //max-width: 600px;
  margin-bottom: 20px;
`;

const GroupTitle = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;
const Attributes = styled.div`
  padding: 10px 20px;
  // margin-top: 10px;
`;

const ExpandActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 10px;
  gap: 10px;

  @media print {
    display: none;
  }
`;

export const DataAttributeGroups = ({ dataAttributeGroups }: DataAttributeGroupsProps) => {
  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);

  useEffect(() => {
    if (dataAttributeGroups) {
      const initialExpandedGroups = dataAttributeGroups[0]?.name ? [dataAttributeGroups[0].name] : [];
      setExpandedGroups(initialExpandedGroups);
    }
  }, [dataAttributeGroups]);

  const showAllGroups = () => {
    if (dataAttributeGroups) {
      setExpandedGroups(dataAttributeGroups.map((g) => g.name));
    }
  };

  const hideAllGroups = () => {
    setExpandedGroups([]);
  };

  const toggleGroup = (groupName: string) => {
    if (expandedGroups.includes(groupName)) {
      setExpandedGroups(expandedGroups.filter((g) => g !== groupName));
    } else {
      setExpandedGroups([...expandedGroups, groupName]);
    }
  };

  const isGropExpanded = (groupName: string) => {
    return expandedGroups.includes(groupName);
  };

  if (!dataAttributeGroups) {
    return null;
  }

  return (
    <>
      <AttributeGroupsWrapper>
        <ExpandActions>
          <TextLink onClick={showAllGroups}>Visa alla uppgifter</TextLink>
          <TextLink onClick={hideAllGroups}>Dölj alla uppgifter</TextLink>
        </ExpandActions>

        <DefaultCard noPadding>
          <Grid container>
            {dataAttributeGroups.map((attributeGroup, i) => (
              <Grid item xs={12} key={i}>
                <GroupCard>
                  <GroupTitle onClick={() => toggleGroup(attributeGroup.name)}>
                    <Typography variant="h6">{attributeGroup.name}</Typography>{' '}
                    {isGropExpanded(attributeGroup.name) ? <ChevronUp /> : <ChevronDown />}
                  </GroupTitle>
                  {isGropExpanded(attributeGroup.name) && (
                    <Attributes>
                      <Grid container>
                        {attributeGroup.dataAttributes.map((attribute, i) => (
                          <Grid item xs={12} sm={6} lg={3} key={i}>
                            <Attribute>
                              <AttributeName>{attribute.name}</AttributeName>
                              {attribute.type === DataAttributeTypes.BOOLEAN && (
                                <AttributeValue>{attribute.value ? 'Ja' : 'Nej'}</AttributeValue>
                              )}

                              {(attribute.type === DataAttributeTypes.INT_32 ||
                                attribute.type === DataAttributeTypes.STRING) && (
                                <AttributeValue>{attribute.value}</AttributeValue>
                              )}
                            </Attribute>
                          </Grid>
                        ))}
                      </Grid>
                    </Attributes>
                  )}
                </GroupCard>
                {dataAttributeGroups.length - 1 !== i && <Divider />}
              </Grid>
            ))}
          </Grid>
        </DefaultCard>
      </AttributeGroupsWrapper>
    </>
  );
};

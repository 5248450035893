import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRefreshedToken, useUserProfileMutation, useUserProfileQuery } from '../api/auth/auth';
import {
  getTokenFromStorage,
  getUser,
  removeCurrentUserFromLocalStorage,
  saveCurrentUserInLocalStorage,
} from '../auth/userStorage';
import { paths } from '../navigation/paths';
import { LoadingPage } from '../pages/Loading';
import { getStoredUserSettings, saveUserSettings } from '../settings';
import { ProfileUserOwnerDto, UserCredentialsDto, UserProfileDto } from '../shared/types/user';
import { UserContext, UserContextType } from './UserContext';
import { Roles } from '../auth/roles';

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = React.useState<UserCredentialsDto | null>(null);
  const [selectedOwner, setSelectedOwner] = React.useState<ProfileUserOwnerDto | null>(null);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const refreshTokenTimeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const userProfileQuery = useUserProfileQuery(user?.username ?? null);

  useEffect(() => {
    if (userProfileQuery.data) {
      onUserProfileUpdated(userProfileQuery.data);
      setIsLoading(false);
    } else {
      if (!isLoading) {
        signOut();
      }
    }
  }, [userProfileQuery.data]);

  const userProfileMutation = useUserProfileMutation(user?.username ?? null);

  useEffect(() => {
    checkIfUserIsAuthenticated();
  }, []);

  useEffect(() => {
    if (user) {
      saveCurrentUserInLocalStorage(user);
    }
  }, [user]);

  const checkIfUserIsAuthenticated = async () => {
    const storedUser = getUser();

    if (!storedUser) {
      setIsLoading(false);
      removeCurrentUserFromLocalStorage();
      setIsAuthenticated(false);
      if (refreshTokenTimeoutId.current) {
        clearTimeout(refreshTokenTimeoutId.current);
      }
      signOut();
      return;
    }

    const currentToken = getTokenFromStorage();

    if (!currentToken) {
      return null;
    }

    try {
      const newToken = await getRefreshedToken();

      if (!newToken) {
        signOut();
      }

      if (storedUser && newToken?.token) {
        storedUser.token = newToken.token;
        await updateUser(storedUser, newToken.expiresInSeconds);
      }
    } catch (error) {
      console.log(error);
      signOut();
    }
  };

  const startRefreshTokenTimeout = (expiresInSeconds: number) => {
    if (refreshTokenTimeoutId.current) {
      clearTimeout(refreshTokenTimeoutId.current);
    }

    refreshTokenTimeoutId.current = setTimeout(async () => {
      try {
        checkIfUserIsAuthenticated();
      } catch (error) {
        console.log(error);
      }
    }, (expiresInSeconds - 30) * 1000);
  };

  const updateUser = async (userCredentials: UserCredentialsDto, tokenExpiresInSeconds: number) => {
    startRefreshTokenTimeout(tokenExpiresInSeconds);
    saveCurrentUserInLocalStorage(userCredentials);

    setUser(userCredentials);
    setIsAuthenticated(true);
  };

  const onUserProfileUpdated = (userProfile: UserProfileDto) => {
    // Get stored user settings
    const userSettings = getStoredUserSettings(userProfile);

    // If ownerId is stored, use that, otherwise use the ownerId from the user profile
    const ownerId = userSettings?.selectedOwnerId || userProfile.ownerId;

    // Get the userOwner from the userProfile
    const userOwner = userProfile.userOwners.find((o) => o.ownerId === ownerId);

    // Update the selected owner
    setSelectedOwner(userOwner || null);
  };

  const signOut = () => {
    setUser(null);
    removeCurrentUserFromLocalStorage();
    setIsAuthenticated(false);
    if (refreshTokenTimeoutId.current) {
      clearTimeout(refreshTokenTimeoutId.current);
    }
    navigate('/');
  };

  const updateSelectedOwner = (owner?: ProfileUserOwnerDto) => {
    if (!userProfileQuery.data || !owner) {
      return;
    }

    setSelectedOwner(owner);
    saveSelectedOwnerSettings(owner.ownerId);

    // If the user is on the myOwners page, don't navigate
    if (location.pathname === paths.myOwners.path) return;

    navigate(paths.myRegistrations.path);
  };

  const saveSelectedOwnerSettings = (ownerId: number) => {
    if (!userProfileQuery.data) {
      return;
    }

    const userSettings = getStoredUserSettings(userProfileQuery.data);

    if (!userSettings) {
      return;
    }

    const selectedOwnerId = ownerId;

    const updatedUserSettings = { ...userSettings, selectedOwnerId };

    saveUserSettings(userProfileQuery.data.id, updatedUserSettings);
  };

  const isRegistrar =
    (userProfileQuery.data &&
      selectedOwner &&
      (userProfileQuery.data?.roles.includes(Roles.Admin) ||
        userProfileQuery.data.userOwners.some(
          (userOwner) => userOwner.ownerId === selectedOwner.ownerId && userOwner.isRegistrar
        ))) ??
    false;

  const updateProfile = (userProfile: UserProfileDto) => {
    userProfileMutation.mutate(userProfile);
  };

  const value: UserContextType = {
    user,
    userProfile: userProfileQuery.data,
    selectedOwner,
    updateSelectedOwner,
    updateUser,
    isAuthenticated,
    signOut,
    isRegistrar,
    updateProfile,
  };

  return <UserContext.Provider value={value}>{isLoading ? <LoadingPage /> : children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = React.useContext(UserContext) as UserContextType;
  if (context === undefined) throw new Error('useUser must be used within UserProvider');
  return context;
};

import { Alert, Card, CardContent, Collapse, IconButton, LinearProgress, TextField, Typography } from '@mui/material';
import { DefaultButton, DefaultCard } from '../../layout';
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useUser } from '../../context/UserProvider';
import { ConfirmDialog } from '../dialogs/ConfirmDialog';
import styled from '@emotion/styled';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useDebounce } from '@uidotdev/usehooks';
import { LeaseDto } from '../../shared/types/lease';
import { useExpireLeaseMutation, useLeasesByLessorQuery } from '../../api/protected/leases';

const Header = styled.span`
  font-weight: 700;
`;

export const LeaseList = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [leaseToExpire, setLeaseToExpire] = useState<LeaseDto>();
  const [errorText, setErrorText] = useState<string>();
  const [expanded, setExpanded] = useState<number | false>(false);

  const { selectedOwner, userProfile } = useUser();
  const lessorId = selectedOwner?.ownerId ?? 0;

  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const leasesByLessorQuery = useLeasesByLessorQuery(lessorId, debouncedSearchTerm);

  const expireLeaseMutation = useExpireLeaseMutation();

  // Clear error text when financingToResolve changes
  useEffect(() => {
    setErrorText(undefined);
  }, [leaseToExpire]);

  const onSearch = (searchQuery: string) => {
    setSearchTerm(searchQuery);
  };

  function onExpireLeaseConfirm(): void {
    if (leaseToExpire) {
      expireLeaseMutation.mutate(leaseToExpire.id, {
        onSuccess: () => setLeaseToExpire(undefined),
        onError: () => setErrorText('Kan inte utföra åtgärden just nu. Försök igen senare.'),
      });
    }
  }

  return (
    <>
      <ConfirmDialog
        isOpen={!!leaseToExpire}
        onConfirm={onExpireLeaseConfirm}
        onCancel={() => setLeaseToExpire(undefined)}
        title="Är du säker?"
        message={`Vill du verkligen avsluta leasingen med kontraktsnummer "${leaseToExpire?.externalId}"`}
        errorText={errorText}
      />

      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={6}>
          {/* <FinancingOwnersSelect value={ownerId} onChange={(ownerId) => setOwnerId(ownerId)} /> */}
          {/* <Typography variant="h4">FinancierID: {financierId}</Typography> */}
        </Grid>
        <Grid item xs={12} sm={6} textAlign={'end'}>
          <TextField
            label="Sök"
            value={searchTerm}
            onChange={(e) => onSearch(e.target.value)}
            autoComplete="off"
            variant="outlined"
            type="search"
          />
        </Grid>
      </Grid>
      {leasesByLessorQuery.data ? (
        <>
          {leasesByLessorQuery.data.length > 0 ? (
            <>
              {leasesByLessorQuery.data.map((lease) => (
                <DefaultCard key={lease.id} style={{ marginBottom: 10, paddingTop: 20, paddingBottom: 15 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <Typography variant="body1">
                        <Header>Kontraktsnummer</Header> {lease.externalId ?? 'Okänt'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Typography variant="body1">
                        <Header>Brukare</Header> {lease.holder.fullName ?? 'Okänt'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2} textAlign={'end'}>
                      {!lease.expires && (
                        <DefaultButton
                          disabled={
                            lease.expires !== null ||
                            expireLeaseMutation.isPending ||
                            lease.leaser.userOwners?.find(
                              (userOwner) => userOwner.userId === userProfile?.id && userOwner.isSupervisor
                            ) === undefined
                          }
                          onClick={() => setLeaseToExpire(lease)}
                          size="small"
                        >
                          Avsluta
                        </DefaultButton>
                      )}
                    </Grid>

                    {lease.expires ? (
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          Leasingen avslutades {new Date(lease.expires).toLocaleDateString()}.
                        </Typography>
                      </Grid>
                    ) : lease.registrations.length > 0 ? (
                      <>
                        <Grid item xs={12} sm={10}>
                          <Typography variant="body1">
                            <Header>{lease.registrations.length} objekt ingår i leasingen</Header>
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={2} textAlign={'end'}>
                          <IconButton
                            onClick={() => setExpanded(expanded === lease.id ? false : lease.id)}
                            size="small"
                          >
                            {expanded === lease.id ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                        </Grid>

                        <Grid item xs={12}>
                          {lease.registrations.map((registration, index) => {
                            return (
                              <Collapse in={expanded === lease.id} key={index}>
                                <Card key={index} style={{ marginBottom: 10 }}>
                                  <CardContent>
                                    <Grid container spacing={2} marginBottom={1}>
                                      <Grid item xs={12} sm={6}>
                                        <Typography variant="body1">
                                          <Header>Namn</Header>
                                        </Typography>
                                        <Typography variant="body1">
                                          {registration.name || registration.category || 'Saknar namn'}
                                        </Typography>
                                        <Typography variant="body1">
                                          <Header>Ck Nummer</Header>
                                        </Typography>
                                        <Typography variant="body1">
                                          {registration.ckNumber || 'Saknar Ck nummer'}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography variant="body1">
                                          <Header>Nuvarande ägare</Header>
                                        </Typography>
                                        <Typography variant="body1">{registration.currentOwner?.fullName}</Typography>
                                        {registration.currentOwnerConfirmedAt && (
                                          <>
                                            <Typography variant="body1">
                                              <Header>Sedan</Header>
                                            </Typography>
                                            <Typography variant="body1">
                                              {new Date(registration.currentOwnerConfirmedAt).toLocaleDateString()}
                                            </Typography>
                                          </>
                                        )}
                                        {registration.currentOwnerLeaseStatus?.externalId !== lease.externalId && (
                                          <Alert severity="warning" variant="outlined" style={{ marginTop: 10 }}>
                                            Leasingen gjordes för en tidigare ägare
                                            {' som heter ' +
                                              registration.ownerRecords.find(
                                                (ownerRecord) => ownerRecord.leaseStatus?.id === lease.id
                                              )?.ownerFullName}
                                            .
                                            {registration.currentOwnerLeaseStatus?.financier?.name &&
                                              ' Den nuvarande ägaren leasas av ' +
                                                registration.currentOwnerLeaseStatus?.financier?.name}
                                          </Alert>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Collapse>
                            );
                          })}
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12}>
                        <Alert severity="warning" variant="outlined">
                          Leasingen har inte längre några objekt kopplade till sig.
                        </Alert>
                      </Grid>
                    )}
                  </Grid>
                </DefaultCard>
              ))}
            </>
          ) : (
            <Typography variant="h6">Inga leasingar hittades</Typography>
          )}
        </>
      ) : (
        leasesByLessorQuery.isPending && <LinearProgress />
      )}
      {leasesByLessorQuery.isError && (
        <>
          <Typography variant="h4">Ett fel har inträffat</Typography>;
          {leasesByLessorQuery.error instanceof Error && (
            <Typography variant="h6">
              {leasesByLessorQuery.error.message} : {JSON.stringify(leasesByLessorQuery.error)}
            </Typography>
          )}
        </>
      )}
    </>
  );
};

import { CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { BankIdAuthResponse, startBankIdCollectPolling, stopBankIdCollectPolling } from '../../auth/bankid';
import { useUser } from '../../context/UserProvider';
import { DefaultButton } from '../../layout';
import { UserCredentialsDto } from '../../shared/types/user';
import ReactGA from 'react-ga4';

type BankIdProps = {
  isActive: boolean;
  onBackClick: () => void;
  authResponse: BankIdAuthResponse | null;
};

export const BankIdSameDevice = ({ onBackClick, isActive }: BankIdProps) => {
  const [error, setError] = useState('');

  useEffect(() => {
    if (isActive) {
      startBankId();
      return;
    }
    stopBankIdCollectPolling();
  }, [isActive]);

  const { updateUser } = useUser();

  const startBankId = async () => {
    setError('');

    try {
      const collectResponse = await startBankIdCollectPolling();

      if (collectResponse.givenName && collectResponse.token) {
        const user: UserCredentialsDto = {
          username: collectResponse.givenName,
          token: collectResponse.token,
        };

        updateUser(user, collectResponse.tokenExpiresInSeconds || 600);
        ReactGA.event('login', { method: 'BankIdSameDevice' });
      }
    } catch (error) {
      alert('error: ' + JSON.stringify(error));
      console.log(error);
    }
  };

  if (!isActive) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CircularProgress />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{'Väntar på bankId-appen...'}</Typography>
      </Grid>
      <Grid item xs={12}>
        {error && <Typography variant="body1">{error}</Typography>}
      </Grid>

      <Grid item xs={12}>
        <DefaultButton onClick={() => onBackClick()}>Tillbaka</DefaultButton>
      </Grid>
    </Grid>
  );
};

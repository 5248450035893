import { UserCredentialsDto } from '../shared/types/user';

export const getTokenFromStorage = (): string => {
  const user = getUser();
  return user?.token || '';
};

export const getUser = (): UserCredentialsDto | null => {
  const user = localStorage.getItem('current-user');
  return user ? JSON.parse(user) : null;
};

export const saveCurrentUserInLocalStorage = (user: UserCredentialsDto) => {
  localStorage.setItem('current-user', JSON.stringify(user));
};

export const removeCurrentUserFromLocalStorage = () => {
  localStorage.removeItem('current-user');
};

export const iSignIn = () => {
  const user = localStorage.getItem('current-user');
  return user !== null;
};

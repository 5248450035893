import styled from '@emotion/styled';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider as MuiDivider,
  FormControl,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import Personnummer from 'personnummer';
import { useEffect, useState } from 'react';
import { getUserBysocialSecurityNumberFromApiAsync } from '../../api';
import { DefaultButton, DefaultCard, DefaultTextButton } from '../../layout';
import { UserInfoDto, UserOwnerDto } from '../../shared/types/user';

type AddUserOwnerDialogProps = {
  userOwners: UserOwnerDto[];
  isOpen: boolean;
  onClose: () => void;
  onAddUserOwner: (userUnfo: UserInfoDto, isSupervisor: boolean) => void;
};

const Divider = styled(MuiDivider)`
  margin: 16px 0;
`;

export const AddUserOwnerDialog = ({ userOwners, isOpen, onClose, onAddUserOwner }: AddUserOwnerDialogProps) => {
  const [socialSecurityNumberInput, setSocialSecurityNumberInput] = useState<string>('');
  const [socialSecurityNumber, setSocialSecurityNumber] = useState<string>('');
  const [socialSecurityNumberInputRef, setSocialSecurityNumberInputRef] = useState<HTMLInputElement | null>(null);
  const [userInfo, setUserInfo] = useState<UserInfoDto | null>(null);
  const [userOwnerIsSupervisor, setUserOwnerIsSupervisor] = useState<boolean>(false);
  const [isExistingUserOwner, setIsExistingUserOwner] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  useEffect(() => {
    if (socialSecurityNumberInputRef) {
      socialSecurityNumberInputRef.focus();
    }
  }, [socialSecurityNumberInputRef]);

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);

  const onSaveButtonClick = () => {
    if (!userInfo) {
      return;
    }
    onAddUserOwner(userInfo, userOwnerIsSupervisor);
    onClose();
  };

  const reset = () => {
    setSocialSecurityNumberInput('');
    setSocialSecurityNumber('');
    setUserInfo(null);
    setIsNotFound(false);
    setIsExistingUserOwner(false);
    if (socialSecurityNumberInputRef) {
      socialSecurityNumberInputRef.focus();
    }
  };

  const updateUserOwner = async (socialSecurityNumber: string) => {
    const isAdded = userOwners.some((userOwner) => userOwner.user?.socialSecurityNumber === socialSecurityNumber);
    if (isAdded) {
      setIsExistingUserOwner(true);
      return;
    }

    const response = await getUserBysocialSecurityNumberFromApiAsync(socialSecurityNumber);

    if (response.status === 404) {
      setIsExistingUserOwner(false);
      setIsNotFound(true);
    }

    const userInfo = response.data;

    if (!userInfo) {
      setUserInfo({
        id: 0,
        givenName: '',
        familyName: '',
        displayName: '',
        socialSecurityNumber,
      });
      return;
    }

    const existingUser = userOwners.find((u) => u.userId === userInfo.id);

    if (existingUser) {
      setIsExistingUserOwner(true);
      return;
    }

    setUserInfo(userInfo);
  };

  const onSocialSecurityNumberChange = (value: string) => {
    setSocialSecurityNumberInput(value);

    if (!Personnummer.valid(value)) {
      setSocialSecurityNumber('');
      setUserInfo(null);
      setIsExistingUserOwner(false);
      setIsNotFound(false);
      return;
    }

    const shortFormat = Personnummer.parse(value).format();
    setSocialSecurityNumberInput(shortFormat);

    const longFormat = Personnummer.parse(value).format(true);
    if (longFormat !== socialSecurityNumber) {
      setSocialSecurityNumber(longFormat);
      updateUserOwner(longFormat);
    }
  };

  const onGivenNameChange = (value: string) => {
    if (!userInfo) {
      return;
    }
    setUserInfo({
      ...userInfo,
      givenName: value,
    });
  };

  const onFamilyNameChange = (value: string) => {
    if (!userInfo) {
      return;
    }
    setUserInfo({
      ...userInfo,
      familyName: value,
    });
  };

  return (
    <>
      <Dialog open={isOpen} maxWidth="xs" onClose={onClose} fullWidth={true}>
        <DialogTitle>Lägg till ny användare</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <TextField
              size="small"
              value={socialSecurityNumberInput}
              label="Personnummer (ÅÅMMDD-XXXX)"
              onChange={(e) => onSocialSecurityNumberChange(e.target.value)}
              autoFocus
              required
              inputRef={(ref) => setSocialSecurityNumberInputRef(ref)}
              helperText={
                !socialSecurityNumber && socialSecurityNumberInput.length > 10 ? 'Ange ett giltigt personnummer' : ''
              }
            />
          </FormControl>

          {isExistingUserOwner && (
            <>
              <Divider />

              <DefaultCard>
                <Typography variant="body2" color="textSecondary">
                  Användare är redan kopplad till företaget
                </Typography>
                <Typography variant="body1">{userInfo?.displayName}</Typography>
                {userOwnerIsSupervisor && (
                  <Typography variant="body2" color="textSecondary">
                    Handläggare
                  </Typography>
                )}
                <Divider />
                <DefaultButton onClick={reset}>Testa ett annat personnummer</DefaultButton>
              </DefaultCard>
            </>
          )}

          {isNotFound && (
            <>
              <Divider />

              <DefaultCard>
                <Typography variant="body1" gutterBottom>
                  Personnummret finns tyvärr inte registrerat hos Chassikollen.
                </Typography>
                <Typography variant="body2">
                  Det går ändå bra att lägga till personen som då kommer vara kopplad till företaget när den loggar in
                  första gången.
                </Typography>
              </DefaultCard>
            </>
          )}

          {!isExistingUserOwner && userInfo && (
            <>
              <Divider />

              <DefaultCard>
                {isNotFound ? (
                  <>
                    <FormControl fullWidth margin="dense">
                      <TextField
                        size="small"
                        value={userInfo?.givenName}
                        label="Förnamn"
                        onChange={(e) => onGivenNameChange(e.target.value)}
                        required
                      />
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                      <TextField
                        size="small"
                        value={userInfo?.familyName}
                        label="Efternamn"
                        onChange={(e) => onFamilyNameChange(e.target.value)}
                        required
                      />
                    </FormControl>
                  </>
                ) : (
                  <>
                    <Typography variant="h6" gutterBottom>
                      {userInfo.displayName}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {/* Personnummer.parse(userOwner?.user?.socialSecurityNumber || '').format() */}
                    </Typography>
                  </>
                )}

                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userOwnerIsSupervisor}
                        onChange={(e) => setUserOwnerIsSupervisor(e.target.checked)}
                      />
                    }
                    label="Handläggare"
                  />
                </FormControl>
              </DefaultCard>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>

          <DefaultButton disabled={!userInfo || isExistingUserOwner} onClick={onSaveButtonClick}>
            Lägg till användare
          </DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

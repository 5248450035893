import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { useEffect, useRef } from 'react';

const CircleDiagramWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  gap: 40px;
`;

const ColorCircle = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
  margin-top: 10px;
`;

const LabelValue = styled.div`
  font-size: 0.8rem;
  color: #666;
  white-space: nowrap;
  margin-top: 2px;
`;

type ChartItem = {
  value: number;
  label: string;
};

const colors = [
  '#3366cc',
  '#dc3912',
  '#ff9900',
  '#109618',
  '#990099',
  '#0099c6',
  '#dd4477',
  '#66aa00',
  '#b82e2e',
  '#316395',
  '#3366cc',
  '#994499',
  '#22aa99',
  '#aaaa11',
  '#6633cc',
  '#e67300',
  '#8b0707',
  '#651067',
  '#329262',
  '#5574a6',
  '#3b3eac',
  '#b77322',
  '#16d620',
  '#b91383',
  '#f4359e',
  '#9c5935',
  '#a9c413',
  '#2a778d',
  '#668d1c',
  '#bea413',
  '#0c5922',
  '#743411',
];

type CircleDiagramProps = {
  radius: number;
  data: ChartItem[];
};

export const CircleDiagram = ({ data, radius }: CircleDiagramProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    canvas.width = radius * 2;
    canvas.height = radius * 2;
    canvas.style.width = `${radius}px`;
    canvas.style.height = `${radius}px`;
    const ctx = canvasRef.current.getContext('2d');

    if (!ctx) {
      return;
    }

    createPieChart(ctx, data, radius);
  }, [data]);

  const createSlice = (ctx: CanvasRenderingContext2D, start: number, end: number, radius: number, color: string) => {
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.arc(0, 0, radius, start, end);
    ctx.fillStyle = color;
    ctx.fill();
  };

  const createMiddleCircle = (ctx: CanvasRenderingContext2D, radius: number) => {
    ctx.beginPath();
    ctx.arc(0, 0, radius * 0.5, 0, 2 * Math.PI);
    ctx.fillStyle = '#fff';
    ctx.fill();
  };

  const createPieChart = (ctx: CanvasRenderingContext2D, items: ChartItem[], radius: number) => {
    ctx.translate(radius, radius);
    ctx.rotate(-0.5 * Math.PI);
    const total = items.reduce((acc, item) => acc + item.value, 0);
    let start = 0;
    let end = 0;
    items.forEach((item, index) => {
      start = end;
      end = start + (item.value / total) * 2 * Math.PI;
      createSlice(ctx, start, end, radius, colors[index]);
    });

    createMiddleCircle(ctx, radius);
  };

  return (
    <>
      <CircleDiagramWrapper>
        <canvas ref={canvasRef} />
        <Box>
          {data.map((item, index) => (
            <Label key={index}>
              <ColorCircle color={colors[index]} />
              <LabelValue>
                {item.label} ({item.value})
              </LabelValue>
            </Label>
          ))}
        </Box>
      </CircleDiagramWrapper>
    </>
  );
};

import styled from '@emotion/styled';
import { Alert, TextField, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { postLocalSignIn } from '../../api';
import { useUser } from '../../context/UserProvider';
import { DefaultButton } from '../../layout';
import { LocalCredentialsDto, LocalResultDto } from '../../shared/types/auth';
import ReactGA from 'react-ga4';

const LocalSignInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  gap: 20px;
`;

const SignInButton = styled(DefaultButton)`
  width: 100%;
  height: 50px;
  font-size: 18px;
`;

export const LocalSignIn = () => {
  const usernameInput = useRef<HTMLInputElement>();
  const passwordInput = useRef<HTMLInputElement>();

  const [error, setError] = useState<string | null>(null);

  const { updateUser } = useUser();

  const { mutate: signInUser, reset } = useMutation({
    mutationFn: (credentials: LocalCredentialsDto) => postLocalSignIn(credentials),
    onSuccess: (data) => onSignInUserSuccess(data),
    onError: (error) => {
      console.log('error', error);
      setError('Nätverksfel, försök igen senare');
      reset();
    },
  });

  const onSignInUserSuccess = (response: LocalResultDto) => {
    if (response?.status === 'ERROR') {
      setError('Fel användarnamn eller lösenord');
      return;
    }

    if (response?.token && response.status === 'OK') {
      updateUser({ username: response.givenName, token: response.token }, response.tokenExpiresInSeconds || 600);
      ReactGA.event('login', { method: 'LocalSignIn' });
    }
  };

  const onSubmit = async () => {
    if (!usernameInput.current || !passwordInput.current) {
      return;
    }

    signInUser({
      username: usernameInput.current.value,
      password: passwordInput.current.value,
    });

    setError(null);
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <>
      <LocalSignInWrapper>
        <Typography variant="subtitle1" textAlign="left">
          Logga in med användarnamn och lösenord
        </Typography>

        {error && <Alert severity="error">{error}</Alert>}

        <TextField inputRef={usernameInput} fullWidth label="Användarnamn" onKeyDown={onKeyDown} />
        <TextField inputRef={passwordInput} fullWidth type="password" label="Lösenord" onKeyDown={onKeyDown} />

        <SignInButton onClick={onSubmit}>Logga in</SignInButton>
      </LocalSignInWrapper>
    </>
  );
};

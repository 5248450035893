import { Container, Typography } from '@mui/material';
import { DefaultLinkButton, Divider, PageContent, PageText } from '../../layout';
import { paths } from '../../navigation/paths';

export const ChangeOfOwnershipCompletePage = () => {
  return (
    <>
      <Container>
        <PageContent>
          <PageText>
            <Typography variant="h5" gutterBottom>
              Ditt ägarbyte behandlas
            </Typography>

            <Typography variant="h3" gutterBottom>
              Vad händer nu?
            </Typography>

            <Typography variant="body1" gutterBottom>
              Innan ägarbytet går igenom så görs en kontroll hos eventuell nuvarande och blivande
              leasinggivare/kreditgivare.
            </Typography>
            <Typography variant="body2">
              Blivande ägare ska också godkänna ägarbytet innan ägarbytet registreras i Chassikollen.
            </Typography>
            <Divider />
            <DefaultLinkButton to={paths.myRegistrations.path}>Tillbaka till mina registreringar</DefaultLinkButton>
          </PageText>
        </PageContent>
      </Container>
    </>
  );
};

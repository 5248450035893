import styled from '@emotion/styled';
import {
  Box,
  Container,
  Grid,
  IconButton,
  ListItem,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import Personnummer from 'personnummer';
import { useEffect, useRef, useState } from 'react';
import { Image, Trash2 } from 'react-feather';
import { useConfig } from '../../context/ConfigProvider';
import { useUser } from '../../context/UserProvider';
import {
  DefaultButton,
  DefaultCard,
  DefaultTextButton,
  Divider,
  FileInput,
  StepperNavigation,
  SubContainer,
  TextLink,
  UploadButton,
} from '../../layout';
import { getDataAttributeValuesFromTemplate } from '../../shared/converters/get-data-attribute-values-from-template';
import { CategoryDto } from '../../shared/types/protected/category';
import { DataAttributeTypes, DataAttributeValueDto } from '../../shared/types/protected/data-attribute';
import { CompanyDto } from '../../shared/types/owner';
import { TransactionType } from '../../shared/types/financing';
import { ImageDto } from '../../shared/types/image';
import { OwnerDto } from '../../shared/types/owner';
import { FinancingTypeDto, PostRegistrationDto, RegistrationDto } from '../../shared/types/registration';
import { toBase64String } from '../../shared/utils/base64';
import { localDateTime } from '../../shared/utils/date';
import { getFormatedOrganizationNumber, getFormatedSocialSecurityNumberNumber } from '../../shared/utils/format-pid';
import { resizeImage } from '../../shared/utils/resize-image';
import { SelectFinancier } from '../financings/SelectFinancier';
import { SelectUserCompany } from '../my-registrations/SelectUserCompany';
import { ImagesToUploadPreview } from './ImagesToUploadPreview';
import { NewChildRegistrationDialog } from './NewChildRegistrationDialog';
import { RegistrationForm } from './RegistrationForm';
import { SelectCategoryTree } from './SelectCategoryTree';
import { SelectOwnerDialog } from './SelectOwnerDialog';
import { useCategoriesQuery } from '../../api/protected/categories';
import { useDataAttributeGroupsQuery } from '../../api/protected/data-attribute';
import { SelectInsurer } from '../insurances/SelectInsurer';

type NewRegistrationFormProps = {
  source: RegistrationDto | null;
  onSave: (registration: PostRegistrationDto) => void;
  isAdmin?: boolean;
};

const SelectUserCompanyWrapper = styled.div`
  margin: 10px 0;
`;
const SelectCategoryTreeWrapper = styled.div`
  min-height: 200px;
`;

const Attribute = styled.div`
  font-size: 0.875rem;
  margin-bottom: 10px;
  max-width: 300px;
`;

const AttributeName = styled.div`
  font-weight: 700;
`;
const AttributeValue = styled.div``;

const StepperWrapper = styled.div`
  margin-bottom: 100px;
`;

const DataAttributeGroupsWrapper = styled.div`
  margin-top: 20px;
`;

function getDataAttributeValues(source: RegistrationDto | null, category: CategoryDto | null) {
  const dataAttributeValues = (source?.attributes || [])
    .filter((dataAttributeValue) => !dataAttributeValue.isUnique)
    .concat(getDataAttributeValuesFromTemplate(category?.dataAttributeTemplates || []))
    .filter(
      (dataAttributeValue, index, array) =>
        array.findIndex((item) => item.dataAttributeId === dataAttributeValue.dataAttributeId) === index
    );

  dataAttributeValues.sort((a, b) => (a.dataAttributeOrder || 0) - (b.dataAttributeOrder || 0));

  return dataAttributeValues;
}

export const NewRegistrationForm = ({ source, onSave, isAdmin = false }: NewRegistrationFormProps) => {
  const [selectedCategory, setSelectedCategory] = useState<CategoryDto | null>(null);
  const [ckNumber, setCkNumber] = useState('');
  const [selectedImages, setSelectedImages] = useState<ImageDto[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [dataAttributeValues, setDataAttributeValues] = useState<DataAttributeValueDto[]>([]);
  const [isAllRequiredDataAttributeValuesSet, setIsAllRequiredDataAttributeValuesSet] = useState(false);
  const [dataAttributeIdsWithErrors, setDataAttributeIdsWithErrors] = useState<number[]>([]);

  const [financier, setFinancier] = useState<CompanyDto | null>(null);
  const [isFinancierValid, setIsFinancierValid] = useState(false);
  const [externalId, setExternalId] = useState('');
  const [transactionType, setTransactionType] = useState(TransactionType.None);

  const [insurer, setInsurer] = useState<CompanyDto | null>(null);
  const [insuranceId, setInsuranceId] = useState('');
  const [isInsurerValid, setIsIsinsurerValid] = useState(false);

  const [IsNewChildRegistrationDialogOpen, setIsNewChildRegistrationDialogOpen] = useState(false);

  const [childRegistrations, setChildRegistrations] = useState<PostRegistrationDto[]>([]);
  const [childRegistrationToEdit, setChildRegistrationToEdit] = useState<PostRegistrationDto | null>(null);
  const [childRegistrationToEditIndex, setChildRegistrationToEditIndex] = useState<number | null>(null);

  const [owner, setOwner] = useState<OwnerDto | null>(null);
  const [isSelectOwnerDialogOpen, setIsSelectOwnerDialogOpen] = useState(false);

  const { financedTransactionTypes } = useConfig();

  const { userProfile, selectedOwner } = useUser();

  const categories = useCategoriesQuery(true);
  const { data: dataAttributeGroups } = useDataAttributeGroupsQuery(true);

  useEffect(() => {
    const emptyRequiredAttributes = dataAttributeValues.filter((a) => a.isRequired && !a.value);
    setIsAllRequiredDataAttributeValuesSet(emptyRequiredAttributes.length === 0);
  }, [dataAttributeValues]);

  useEffect(() => {
    if (transactionType === TransactionType.None) {
      setIsFinancierValid(false);
      return;
    }

    if (transactionType === TransactionType.Financed && (!financier?.id || externalId === '')) {
      setIsFinancierValid(false);
      return;
    }

    if (transactionType === TransactionType.Lease && (!financier?.id || externalId === '')) {
      setIsFinancierValid(false);
      return;
    }

    setIsFinancierValid(true);
  }, [financier, transactionType, financedTransactionTypes, externalId]);

  useEffect(() => {
    if (insurer && insuranceId === '') {
      setIsIsinsurerValid(false);
      return;
    }

    setIsIsinsurerValid(true);
  }, [insurer, insuranceId]);

  //  Prepopulate registration data if source is set
  useEffect(() => {
    if (source) {
      const category = categories.data?.find((c) => c.id === source.categoryId) || null;

      setSelectedCategory(category);

      const dataAttributeValues = getDataAttributeValues(source, category);
      setDataAttributeValues(dataAttributeValues);

      onStepNavigationClick(2);
      //setActiveStep(2);
    }
  }, [source]);

  const onSelectCategory = (category: CategoryDto | null) => {
    if (!category) {
      return;
    }

    setSelectedCategory(category);

    const dataAttributeValues = getDataAttributeValues(source, category);
    setDataAttributeValues(dataAttributeValues);

    onStepNavigationClick(2);
  };
  const onSelectImagesChange = async (images: FileList | null) => {
    if (!images) {
      return;
    }
    const newImages: ImageDto[] = await Promise.all(
      Array.from(images).map(async (image) => {
        const resizedImage = await resizeImage(image, 1200, 1200);
        return { filename: image.name, contentType: image.type, base64String: await toBase64String(resizedImage) };
      })
    );

    setSelectedImages([...selectedImages, ...newImages]);
  };

  const onDeleteImage = (image: ImageDto) => {
    if (!selectedImages) {
      return;
    }
    const newImages = selectedImages.filter((i) => i.filename !== image.filename);
    setSelectedImages(newImages);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const getFinancingType = (transactionType: TransactionType): FinancingTypeDto => {
    switch (transactionType) {
      case TransactionType.CashPurchase:
        return FinancingTypeDto.CashPurchase;
      case TransactionType.Financed:
        return FinancingTypeDto.Financed;
      case TransactionType.Lease:
        return FinancingTypeDto.Lease;
      default:
        throw new Error('Invalid TransactionType : ' + transactionType);
    }
  };

  const onSubmitRegistration = async () => {
    const ownerId = selectedOwner?.ownerId;

    if (!ownerId) {
      return;
    }

    const financingType = getFinancingType(transactionType);

    const newRegistration: PostRegistrationDto = {
      categoryId: selectedCategory?.id || 0,
      ownerId,
      attributes: dataAttributeValues,
      images: selectedImages,
      financierId: financier?.id,
      externalId,
      children: childRegistrations,
      ckNumber,
      financingType,
      insurerId: insurer?.id,
      insuranceId,
    };

    onSave(newRegistration);
  };

  const onRegistrationFormNextStep = () => {
    setDataAttributeIdsWithErrors([]);
    if (!isAllRequiredDataAttributeValuesSet) {
      setDataAttributeIdsWithErrors(
        dataAttributeValues.filter((a) => a.isRequired && !a.value).map((a) => a.dataAttributeId)
      );
      return;
    }
    onStepNavigationClick(3);
  };

  const onAddNewChildRegistrationClick = () => {
    setIsNewChildRegistrationDialogOpen(true);
  };

  const onChildCreated = (child: PostRegistrationDto) => {
    if (childRegistrationToEdit && childRegistrationToEditIndex !== null) {
      const newChildRegistrations = [...childRegistrations];
      newChildRegistrations[childRegistrationToEditIndex] = child;
      setChildRegistrations(newChildRegistrations);
      setChildRegistrationToEdit(null);
      setChildRegistrationToEditIndex(null);
      setIsNewChildRegistrationDialogOpen(false);
      return;
    }

    setChildRegistrations([...childRegistrations, child]);
    setIsNewChildRegistrationDialogOpen(false);
  };

  const onEditChildRegistrationClick = (childRegistration: PostRegistrationDto, index: number) => {
    setChildRegistrationToEdit(childRegistration);
    setChildRegistrationToEditIndex(index);
    setIsNewChildRegistrationDialogOpen(true);
  };

  const onNewChildRegistrationDialogClose = () => {
    setIsNewChildRegistrationDialogOpen(false);
    setChildRegistrationToEdit(null);
  };

  const onDeleteChildRegistrationClick = (index: number, evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.stopPropagation();

    const newChildRegistrations = [...childRegistrations];
    newChildRegistrations.splice(index, 1);
    setChildRegistrations(newChildRegistrations);
  };

  const onStepNavigationClick = (step: number) => {
    setActiveStep(step);
  };

  const onSelectOwnerClick = () => {
    setIsSelectOwnerDialogOpen(true);
  };

  const onSelectOwnerChange = (owner: OwnerDto | null) => {
    setOwner(owner);
    setIsSelectOwnerDialogOpen(false);
  };

  return (
    <>
      <SelectOwnerDialog
        isOpen={isSelectOwnerDialogOpen}
        onClose={() => setIsSelectOwnerDialogOpen(false)}
        onSelectOwner={onSelectOwnerChange}
      />
      <NewChildRegistrationDialog
        isOpen={IsNewChildRegistrationDialogOpen}
        onClose={onNewChildRegistrationDialogClose}
        onChildCreated={onChildCreated}
        registrationToEdit={childRegistrationToEdit}
      />
      <Container ref={containerRef} maxWidth={false}>
        <SubContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <StepperWrapper>
                <Stepper activeStep={activeStep} orientation="vertical">
                  <Step id="step-0">
                    <StepLabel>
                      Ägare{activeStep !== 0 && `: ${isAdmin ? owner?.fullName : selectedOwner?.fullName}`}
                    </StepLabel>
                    <StepContent>
                      <DefaultCard>
                        {isAdmin && (
                          <>
                            <Typography variant="subtitle1" gutterBottom>
                              Ägare
                            </Typography>
                            <Typography variant="h6">{owner?.fullName}</Typography>
                            <Typography variant="caption">
                              {owner?.isPerson
                                ? getFormatedSocialSecurityNumberNumber(owner?.pid)
                                : getFormatedOrganizationNumber(owner?.pid)}
                            </Typography>
                            <SelectUserCompanyWrapper>
                              {owner ? (
                                <>
                                  <TextLink onClick={() => onSelectOwnerClick()}> Ändra ägare</TextLink>
                                </>
                              ) : (
                                <DefaultButton onClick={() => onSelectOwnerClick()}> Välj ägare</DefaultButton>
                              )}
                            </SelectUserCompanyWrapper>
                          </>
                        )}

                        {!isAdmin && (
                          <>
                            <Typography variant="subtitle1" gutterBottom>
                              Du företräder
                            </Typography>
                            <Typography variant="h6">{selectedOwner?.fullName}</Typography>
                            <Typography variant="caption">
                              {selectedOwner?.pid ||
                                Personnummer.parse(userProfile?.socialSecurityNumber || '').format()}
                            </Typography>
                            <SelectUserCompanyWrapper>
                              <SelectUserCompany textButton="Ändra vem du företräder" />
                            </SelectUserCompanyWrapper>
                          </>
                        )}
                      </DefaultCard>
                      <StepperNavigation>
                        <DefaultButton onClick={() => onStepNavigationClick(1)}>Nästa</DefaultButton>
                      </StepperNavigation>
                    </StepContent>
                  </Step>

                  <Step id="step-1">
                    <StepLabel>Kategori{selectedCategory && `: ${selectedCategory.name}`}</StepLabel>

                    <StepContent TransitionProps={{ unmountOnExit: false }}>
                      <DefaultCard>
                        <SelectCategoryTreeWrapper>
                          <SelectCategoryTree selectedCategory={selectedCategory} onSelectCategory={onSelectCategory} />
                        </SelectCategoryTreeWrapper>
                      </DefaultCard>
                      <StepperNavigation>
                        <DefaultTextButton onClick={() => onStepNavigationClick(0)}>Föregående</DefaultTextButton>
                        <DefaultButton disabled={!selectedCategory} onClick={() => onStepNavigationClick(2)}>
                          Nästa
                        </DefaultButton>
                      </StepperNavigation>
                    </StepContent>
                  </Step>

                  <Step id="step-2">
                    <StepLabel>Uppgifter</StepLabel>
                    <StepContent>
                      <DefaultCard>
                        <RegistrationForm
                          dataAttributeValues={dataAttributeValues}
                          setDataAttributeValues={setDataAttributeValues}
                          dataAttributeIdsWithErrors={dataAttributeIdsWithErrors}
                          ckNumber={ckNumber}
                          setCkNumber={setCkNumber}
                        />
                      </DefaultCard>
                      <StepperNavigation>
                        <DefaultTextButton onClick={() => onStepNavigationClick(1)}>Föregående</DefaultTextButton>
                        <DefaultButton onClick={() => onRegistrationFormNextStep()}>Nästa</DefaultButton>
                      </StepperNavigation>
                    </StepContent>
                  </Step>

                  <Step id="step-3">
                    <StepLabel>Bilder</StepLabel>
                    <StepContent>
                      <DefaultCard>
                        <ImagesToUploadPreview selectedImages={selectedImages} onDeleteImage={onDeleteImage} />

                        <Box>
                          <UploadButton htmlFor="images-upload">
                            <Image color="rgba(0, 0, 0, 0.6)" size={20} /> Ladda upp bilder...
                          </UploadButton>
                          <FileInput
                            type="file"
                            id="images-upload"
                            name="registrationImages"
                            multiple
                            onChange={(event) => onSelectImagesChange(event.target.files)}
                            accept="image/*"
                          />
                        </Box>
                        <Divider />
                        <Typography variant="caption" color="textSecondary">
                          Det går att ladda upp bilder i formaten jpg, jpeg, png och gif. Max tre bilder per
                          registrering.
                        </Typography>
                      </DefaultCard>

                      <StepperNavigation>
                        <DefaultTextButton onClick={() => onStepNavigationClick(2)}>Föregående</DefaultTextButton>
                        <DefaultButton onClick={() => onStepNavigationClick(4)}>Nästa</DefaultButton>
                      </StepperNavigation>
                    </StepContent>
                  </Step>

                  <Step id="step-4">
                    <StepLabel>Kopplingar</StepLabel>
                    <StepContent>
                      <DefaultCard>
                        <Typography variant="h6" gutterBottom>
                          Kopplade objekt
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Du kan koppla andra objekt till denna registrering. Det kan t.ex. vara en skopa eller andra
                          tillbehör som hör ihop med huvudobjektet.
                        </Typography>
                        <Divider />
                        {childRegistrations.length > 0 ? (
                          <Box>
                            {childRegistrations.map((childRegistration, i) => (
                              <ListItem key={i} onClick={() => onEditChildRegistrationClick(childRegistration, i)}>
                                <Box>
                                  {categories.data?.find((c) => c.id === childRegistration.categoryId)?.name}:{' '}
                                  {childRegistration.attributes
                                    .filter((a) => a.isPartOfName)
                                    .map((a) => a.value)
                                    .join(' ')}
                                </Box>
                                <Box>
                                  <IconButton onClick={(e) => onDeleteChildRegistrationClick(i, e)}>
                                    <Trash2 size={20} />
                                  </IconButton>
                                </Box>
                              </ListItem>
                            ))}
                          </Box>
                        ) : (
                          <Typography variant="body1" color="textSecondary">
                            Det finns inga kopplade objekt.
                          </Typography>
                        )}
                        <Divider />
                        <DefaultButton onClick={() => onAddNewChildRegistrationClick()}>
                          Lägg till nytt kopplat objekt
                        </DefaultButton>
                      </DefaultCard>

                      <StepperNavigation>
                        <DefaultTextButton onClick={() => onStepNavigationClick(3)}>Föregående</DefaultTextButton>
                        <DefaultButton onClick={() => onStepNavigationClick(5)}>Nästa</DefaultButton>
                      </StepperNavigation>
                    </StepContent>
                  </Step>

                  <Step id="step-5">
                    <StepLabel>Finansiering</StepLabel>
                    <StepContent>
                      <DefaultCard>
                        <Typography variant="h6" gutterBottom>
                          Finansiering
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          Ange om registreringen är finansierad och om så är fallet, ange vem som finansierar.
                        </Typography>
                        <Divider />
                        <SelectFinancier
                          onFinancierChange={(value) => setFinancier(value)}
                          onTransactionTypeChange={(transactionType) => setTransactionType(transactionType)}
                          onExternalIdChange={(externalId) => setExternalId(externalId)}
                          selectedFinancierId={financier?.id}
                          transactionType={transactionType}
                          externalId={externalId}
                        />
                      </DefaultCard>
                      <StepperNavigation>
                        <DefaultTextButton onClick={() => onStepNavigationClick(4)}>Föregående</DefaultTextButton>
                        <DefaultButton onClick={() => onStepNavigationClick(6)} disabled={!isFinancierValid}>
                          Nästa
                        </DefaultButton>
                      </StepperNavigation>
                    </StepContent>
                  </Step>

                  <Step id="step-6">
                    <StepLabel>Försäkring</StepLabel>
                    <StepContent>
                      <DefaultCard>
                        <Typography variant="h6" gutterBottom>
                          Försäkring
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          Ange om registreringen är försäkrad och om så är fallet, ange av vem.
                        </Typography>
                        <Divider />
                        <SelectInsurer
                          onInsurerChange={(value) => setInsurer(value)}
                          onInsuranceIdChange={(insuranceId) => setInsuranceId(insuranceId)}
                          selectedInsurerId={insurer?.id}
                          insuranceId={insuranceId}
                        />
                      </DefaultCard>
                      <StepperNavigation>
                        <DefaultTextButton onClick={() => onStepNavigationClick(5)}>Föregående</DefaultTextButton>
                        <DefaultButton onClick={() => onStepNavigationClick(7)} disabled={!isInsurerValid}>
                          Nästa
                        </DefaultButton>
                      </StepperNavigation>
                    </StepContent>
                  </Step>

                  <Step id="step-7">
                    <StepLabel>Sammanställning</StepLabel>
                    <StepContent TransitionProps={{ unmountOnExit: false }}>
                      <DefaultCard>
                        <Typography variant="h6" gutterBottom>
                          {transactionType === TransactionType.Lease ? 'Brukare' : 'Ägare'}
                        </Typography>
                        <Typography variant="subtitle1">
                          {selectedOwner?.fullName || `${userProfile?.givenName} ${userProfile?.familyName}`}
                        </Typography>
                        <Typography variant="subtitle1">
                          {selectedOwner?.pid || Personnummer.parse(userProfile?.socialSecurityNumber || '').format()}
                        </Typography>

                        <Divider />

                        <Typography variant="h6" gutterBottom>
                          Kategori
                        </Typography>
                        <Typography variant="subtitle1">{selectedCategory?.name}</Typography>

                        <Divider />

                        <Typography variant="h6" gutterBottom>
                          CK-nummer
                        </Typography>
                        <Typography variant="subtitle1">{ckNumber}</Typography>

                        <Divider />

                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            {dataAttributeGroups?.map((dataAttributeGroup, i) => {
                              const dataAttributesInGroup = dataAttributeValues.filter(
                                (d) => d.dataAttributeGroupId === dataAttributeGroup.id
                              );

                              if (dataAttributesInGroup.length === 0) {
                                return null;
                              }
                              return (
                                <Box key={i}>
                                  <Typography variant="h6" gutterBottom>
                                    {dataAttributeGroup.name}
                                  </Typography>

                                  <DataAttributeGroupsWrapper>
                                    <Grid container spacing={1}>
                                      {dataAttributeValues
                                        .filter((d) => d.dataAttributeGroupId === dataAttributeGroup.id)
                                        .map((attribute, i) => (
                                          <Grid xs={12} md={6} item key={i}>
                                            <Attribute key={i}>
                                              <AttributeName>{attribute.name}</AttributeName>
                                              {(attribute.type === DataAttributeTypes.STRING ||
                                                attribute.type === DataAttributeTypes.INT_32) && (
                                                <AttributeValue>{attribute.value || '-'}</AttributeValue>
                                              )}
                                              {attribute.type === DataAttributeTypes.BOOLEAN && (
                                                <AttributeValue>{attribute.value ? 'Ja' : 'Nej'}</AttributeValue>
                                              )}
                                              {attribute.type === DataAttributeTypes.DATE_TIME && (
                                                <AttributeValue>
                                                  {attribute.value ? localDateTime(attribute.value as string) : '-'}
                                                </AttributeValue>
                                              )}
                                            </Attribute>
                                          </Grid>
                                        ))}
                                    </Grid>
                                  </DataAttributeGroupsWrapper>
                                  <Divider />
                                </Box>
                              );
                            })}
                          </Grid>
                        </Grid>

                        <Typography variant="h6" gutterBottom>
                          Bilder
                        </Typography>
                        {selectedImages.length === 0 && <Typography variant="subtitle1">Inga bilder valda</Typography>}
                        <ImagesToUploadPreview selectedImages={selectedImages} small />

                        <Divider />

                        <Typography variant="h6" gutterBottom>
                          Kopplade objekt
                        </Typography>
                        {childRegistrations.length === 0 && (
                          <Typography variant="subtitle1">Inga kopplade objekt valda</Typography>
                        )}
                        <DataAttributeGroupsWrapper>
                          <Grid container spacing={1}>
                            {childRegistrations.length > 0 && (
                              <>
                                {childRegistrations.map((childRegistration, i) => (
                                  <Grid xs={12} md={6} item key={i}>
                                    <Attribute key={i}>
                                      <AttributeName>
                                        {childRegistration.attributes
                                          .filter((a) => a.isPartOfName)
                                          .map((a) => a.value)
                                          .join(' ')}
                                      </AttributeName>
                                      <AttributeValue>
                                        {categories.data?.find((c) => c.id === childRegistration.categoryId)?.name}
                                      </AttributeValue>
                                    </Attribute>
                                  </Grid>
                                ))}
                              </>
                            )}
                          </Grid>
                        </DataAttributeGroupsWrapper>

                        <Divider />

                        <Typography variant="h6" gutterBottom>
                          Finansiering
                        </Typography>

                        <Typography variant="subtitle1">
                          {transactionType === TransactionType.Lease && 'Leasing'}
                          {transactionType === TransactionType.Financed && 'Finansierad'}
                          {transactionType === TransactionType.CashPurchase && 'Kontantköp'}
                        </Typography>

                        {transactionType === TransactionType.Financed && (
                          <DataAttributeGroupsWrapper>
                            <Grid container spacing={1}>
                              <Grid xs={12} md={6} item>
                                <Attribute>
                                  <AttributeName>Kreditgivare</AttributeName>
                                  <AttributeValue>{financier?.name}</AttributeValue>
                                </Attribute>
                              </Grid>
                              <Grid xs={12} md={6} item>
                                <Attribute>
                                  <AttributeName>Kontraktsnummer</AttributeName>
                                  <AttributeValue>{externalId}</AttributeValue>
                                </Attribute>
                              </Grid>
                            </Grid>
                          </DataAttributeGroupsWrapper>
                        )}
                        {transactionType === TransactionType.Lease && (
                          <DataAttributeGroupsWrapper>
                            <Grid container spacing={1}>
                              <Grid xs={12} md={6} item>
                                <Attribute>
                                  <AttributeName>Leasegivaren</AttributeName>
                                  <AttributeValue>{financier?.name}</AttributeValue>
                                </Attribute>
                              </Grid>
                              <Grid xs={12} md={6} item>
                                <Attribute>
                                  <AttributeName>Leasenummer</AttributeName>
                                  <AttributeValue>{externalId}</AttributeValue>
                                </Attribute>
                              </Grid>
                            </Grid>
                          </DataAttributeGroupsWrapper>
                        )}

                        <Divider />

                        <Typography variant="h6" gutterBottom>
                          Försäkring
                        </Typography>
                        {insurer ? (
                          <DataAttributeGroupsWrapper>
                            <Grid container spacing={1}>
                              <Grid xs={12} md={6} item>
                                <Attribute>
                                  <AttributeName>Försäkringsbolag</AttributeName>
                                  <AttributeValue>{insurer.name}</AttributeValue>
                                </Attribute>
                              </Grid>
                              <Grid xs={12} md={6} item>
                                <Attribute>
                                  <AttributeName>Försäkringsnummer</AttributeName>
                                  <AttributeValue>{insuranceId}</AttributeValue>
                                </Attribute>
                              </Grid>
                            </Grid>
                          </DataAttributeGroupsWrapper>
                        ) : (
                          <Typography variant="subtitle1">Ingen försäkring</Typography>
                        )}
                      </DefaultCard>
                      <StepperNavigation>
                        <DefaultTextButton onClick={() => onStepNavigationClick(6)}>Föregående</DefaultTextButton>
                        <DefaultButton onClick={() => onSubmitRegistration()}>Skapa registrering</DefaultButton>
                      </StepperNavigation>
                    </StepContent>
                  </Step>
                </Stepper>
              </StepperWrapper>
            </Grid>
          </Grid>
        </SubContainer>
      </Container>
    </>
  );
};

import { IconButton } from '@mui/material';
import { Edit, Trash2 } from 'react-feather';
import { DefaultCard, ListHeader, ListHeaderText, ListItem, ListItemActions, ListItemText } from '../../../../layout';
import { PersonDto } from '../../../../shared/types/owner';

type PersonListProps = {
  persons: PersonDto[] | undefined;
  onDeletePerson: (person: PersonDto) => void;
  onEditPerson: (person: PersonDto) => void;
};

export const PersonList = ({ persons: companies, onEditPerson, onDeletePerson }: PersonListProps) => {
  const onEditClick = (person: PersonDto) => {
    onEditPerson(person);
  };

  const onDeleteClick = (person: PersonDto) => {
    onDeletePerson(person);
  };

  return (
    <DefaultCard>
      <ListHeader>
        <ListHeaderText>Namn</ListHeaderText>
        <ListHeaderText>Pnr.</ListHeaderText>
        <ListItemActions></ListItemActions>
      </ListHeader>
      {companies &&
        companies.map((person) => {
          return (
            <ListItem noPointer key={person.id}>
              <ListItemText>
                {person.givenName} {person.familyName}
              </ListItemText>
              <ListItemText>{person.socialSecurityNumber}</ListItemText>
              <ListItemActions>
                <IconButton onClick={() => onEditClick(person)}>
                  <Edit size={20} />
                </IconButton>
                <IconButton onClick={() => onDeleteClick(person)}>
                  <Trash2 size={20} />
                </IconButton>
              </ListItemActions>
            </ListItem>
          );
        })}
    </DefaultCard>
  );
};

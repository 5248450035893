import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  ListItem,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import { useState } from 'react';
import { DefaultTextButton } from '../../../layout';
import { UserInfoDto } from '../../../shared/types/user';
import { useGetUsersNotAllowedToAccessOwnerQuery } from '../../../api/admin';
import { stringAvatar } from '../../../shared/utils/avatar';

type AddUserOwnerDialogProps = {
  ownerId: number;
  isOpen: boolean;
  onClose: () => void;
  onAddUserOwner: (userUnfo: UserInfoDto, isRegistrar: boolean, isSupervisor: boolean) => void;
};

export const AddUserOwnerDialog = ({ ownerId, isOpen, onClose, onAddUserOwner }: AddUserOwnerDialogProps) => {
  const [filter, setFilter] = useState('');

  const getUsersNotAllowedToAccessOwnerQuery = useGetUsersNotAllowedToAccessOwnerQuery(ownerId, filter, 8);

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="xs"
        onClose={onClose}
        fullWidth={true}
        sx={{
          '& .MuiDialog-scrollPaper': {
            alignItems: 'flex-start',
          },
          '& .MuiPaper-paperScrollBody': {
            verticalAlign: 'top',
          },
        }}
      >
        <DialogTitle>Lägg till ny användare</DialogTitle>
        <DialogContent>
          <FormControl margin="dense">
            <TextField
              size="small"
              label="Filter"
              type="search"
              autoFocus
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </FormControl>
          {getUsersNotAllowedToAccessOwnerQuery.isLoading && <Typography>Loading...</Typography>}
          {getUsersNotAllowedToAccessOwnerQuery.data && (
            <>
              <List
                dense={true}
                sx={{
                  border: 1,
                  borderColor: 'divider',
                  borderStyle: 'solid',
                  borderRadius: 1,
                  mb: 2,
                }}
              >
                {getUsersNotAllowedToAccessOwnerQuery.data.users.map((user) => (
                  <ListItem key={user.id}>
                    <ListItemAvatar>
                      <Avatar {...stringAvatar(user.displayName)} />
                    </ListItemAvatar>
                    <ListItemButton
                      onClick={() => {
                        onAddUserOwner(user, false, false);
                        onClose();
                      }}
                    >
                      <ListItemText primary={user.displayName} secondary={user.socialSecurityNumber} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <Typography>{getUsersNotAllowedToAccessOwnerQuery.data.total} användare hittades</Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CompanyDto } from '../../shared/types/owner';
import { apiRequest2 } from '../api-request';

const companyKeys = {
  all: ['companies'],
  list: (
    filter: string,
    page: number,
    rowsPerPage: number,
    isFinancier: boolean,
    isLessor: boolean,
    isInsurer: boolean
  ) => [...companyKeys.all, 'list', filter, page, rowsPerPage, isFinancier, isLessor, isInsurer],
};

export const useGetCompaniesQuery = (
  filter: string,
  page: number,
  rowsPerPage: number,
  isFinancier: boolean,
  isLessor: boolean,
  isInsurer: boolean
) =>
  useQuery({
    queryKey: companyKeys.list(filter, page, rowsPerPage, isFinancier, isLessor, isInsurer),
    queryFn: async () => {
      const response = await apiRequest2<GetCompaniesResponse>({
        action: `Admin/Companies`,
        method: 'GET',
        allowAnonymous: false,
        params: {
          filter,
          page,
          rowsPerPage,
          isFinancier,
          isLessor,
          isInsurer,
        },
      });

      return response.data;
    },
  });

export type GetCompaniesResponse = {
  total: number;
  companies: CompanyDto[];
};

export const usePostCompanyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (company: CompanyDto) =>
      apiRequest2<void>({
        action: `Admin/Companies`,
        method: 'POST',
        allowAnonymous: false,
        body: JSON.stringify(company),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: companyKeys.all,
      });
    },
  });
};

export const usePutCompanyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (company: CompanyDto) =>
      apiRequest2<void>({
        action: `Admin/Companies/${company.id}`,
        method: 'PUT',
        allowAnonymous: false,
        body: JSON.stringify(company),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: companyKeys.all,
      });
    },
  });
};

export const useDeleteCompanyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) =>
      apiRequest2<void>({
        action: `Admin/Companies/${id}`,
        method: 'DELETE',
        allowAnonymous: false,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: companyKeys.all,
      });
    },
  });
};

export const useUploadInsuranceCompaniesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData: FormData) =>
      apiRequest2<void>({
        action: `Admin/Companies/UploadInsuranceCompanies`,
        method: 'POST',
        allowAnonymous: false,
        body: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: '*/*',
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: companyKeys.all,
      });
    },
  });
};

import styled from '@emotion/styled';
import { Container, Grid, Typography } from '@mui/material';
//import appPromoImg from '../assets/images/app-promo.png';
//import appStore from '../assets/images/app-store.png';
//import googlePlay from '../assets/images/google-play.png';
import { TextCarousel } from '../components/home/TextCarousel';
import { SearchRegistration } from '../components/search/SearchRegistration';
import { useConfig } from '../context/ConfigProvider';
//import { Divider } from '../layout';
import { colors } from '../static/colors';
const HomeHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${colors.blue};

  padding: 0 20px;
  padding-top: 20px;
`;

const SearchWrapper = styled.div`
  margin-top: 40px;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 120px;
  width: 100%;
`;

const InfoWrapper = styled(Container)`
  margin-bottom: 60px;
  padding: 0 20px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding-top: 60px;
`;

/* const AppPromoImg = styled.img`
  width: 100%;
  max-width: 400px;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const Promo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 40px;
  position: relative;
`;

const DownloadApp = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;

  img {
    width: 200px;
  }
`;

const PromoText = styled.div`
  max-width: 700px;
`; */

const MainContent = styled.div`
  background: linear-gradient(180deg, rgba(21, 48, 120, 1) 0%, rgba(21, 48, 120, 0) 40%);
`;

/* const Clock = styled.div`
  font-size: 12px;
  color: #fff;
  position: absolute;
  top: 85px;
  left: 75px;
  background-color: ${colors.blue};
  padding: 5px;
`; */

export const Home = () => {
  const { promoTexts } = useConfig();

  /* const [currentTime, setCurrentTime] = useState<string>('');

  const updateClock = () => {
    const date = new Date();
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

    setCurrentTime(`${hours}:${minutes}`);
  };

  useEffect(() => {
    updateClock();
    const interval = setInterval(() => {
      updateClock();
    }, 1000);

    return () => clearInterval(interval);
  }, []); */

  return (
    <>
      <HomeHeaderWrapper>
        <TextCarousel texts={promoTexts} />
        {/*  <HomeTitle>Tydlig ägarhistorik från start ger enklare spårning av stöldgods</HomeTitle> */}
        <SearchWrapper>
          <SearchRegistration />
        </SearchWrapper>
      </HomeHeaderWrapper>
      <MainContent>
        <InfoWrapper>
          {/* <Promo>
            <AppPromoImg src={appPromoImg} alt="app-promo" />
            <PromoText>
              <Typography variant="h2" gutterBottom>
                Ladda ner vår app
              </Typography>
              <Typography variant="h6" gutterBottom>
                Objekt registrerade hos Chassikollen får ett unikt nummer och en unik QR-kod som du enkelt skannar med
                din mobiltelefon – och får en fullständig ägarbild i realtid!
              </Typography>

              <Divider />

              <Typography variant="body1" gutterBottom>
                Ladda ner Chassikollens app redan i dag.
              </Typography>

              <DownloadApp>
                <img src={appStore} alt="App Store" />
                <img src={googlePlay} alt="Google Play" />
              </DownloadApp>
            </PromoText>
          </Promo>
          <Divider /> */}
          <Info>
            <Grid container spacing={5}>
              <Grid item xs={12} md={4}>
                <Typography variant="h4" gutterBottom>
                  Bli avtalskund
                </Typography>
                <p>
                  Som företag, försäkringsbolag, bank eller myndighet får ni tillgång till all data och avancerad
                  sökning i Chassikollens register.
                </p>
                <p>
                  Se information om leasingavtal, ägarföljd, miljöklassing, kopplade objekt och mycket mer. Dessutom får
                  ni en lägre registreringskostnad för egna objekt.
                </p>
                <p>Tillverkare eller importör? Kontakta Chassikollen för offert.</p>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h4" gutterBottom>
                  Sök i realtid
                </Typography>

                <p>
                  Du kan enkelt söka efter ett objekt med Chassikollens sökfunktion{/* , via webben eller i vår app */}.
                </p>
                <p>Sök på chassi- eller serienummer, registreringsnummer eller skanna QR-märkningen. Helt gratis!</p>
                <p>Avancerad sökning ingår i Chassikollen Avtalskund.</p>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography variant="h4" gutterBottom>
                  Håll ordning
                </Typography>
                <p>
                  Registrera dina ägodelar på Chassikollen och få en uppdaterad och tydlig överblick över dina maskiner,
                  redskap och övriga prylar.
                </p>
                <p>
                  När det är dags att sälja så kan både du och köparen känna er trygga med en säker transaktion med
                  ägarbyte registrerat med BankID.
                </p>
              </Grid>
            </Grid>
          </Info>
        </InfoWrapper>
      </MainContent>
    </>
  );
};

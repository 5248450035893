import { CompanyDto } from '../../shared/types/owner';
import { apiRequest2 } from '../api-request';

export const getInsurers = async () => {
  const response = await apiRequest2<CompanyDto[]>({
    action: `Protected/Insurers`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response.data;
};

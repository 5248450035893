import styled from '@emotion/styled';
import { Button, DialogContent, Divider as MuiDivider } from '@mui/material';
import { Link } from 'react-router-dom';
import { colors } from '../static/colors';

export const HiddenOnMobile = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

export const Divider = styled(MuiDivider)`
  margin: 20px 0;
`;

export const PageTitle = styled.h1`
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 20px;
`;
export const PageContent = styled.div`
  padding-top: 40px;
  width: 100%;

  @media (min-width: 960px) {
    padding: 40px;
  }

  padding-bottom: 64px;
`;

export const SubContainer = styled.div`
  max-width: 960px;
`;

export const UserPage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

export const PageWrapper = styled.div`
  padding: 20px;
`;

export const PageText = styled.div<{ alignLeft?: boolean }>`
  max-width: 640px;
  margin: ${(props) => (props.alignLeft ? '0' : '0 auto')};
`;

export const DefaultCard = styled.div<{ noPadding?: boolean; selected?: boolean }>`
  background-color: white;
  border-radius: 12px;
  border: 1px solid ${colors.lightGray};
  padding: 10px 15px;
  position: relative;
  ${(props) => props.noPadding && `padding: 0;`}
  ${(props) => props.selected && `box-shadow: 1px 1px 5px grey;`}
  @media (min-width: 600px) {
    padding: 40px 30px;
    ${(props) => props.noPadding && `padding: 0;`}
  }
`;

export const DefaultCardTopActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  position: absolute;
  top: 35px;
  right: 35px;
  z-index: 1;
`;

export const DefaultButton = styled(Button)`
  background-color: ${colors.orange};
  color: #fff;
  text-transform: none;
  border-radius: 20px;
  padding: 6px 20px;
  letter-spacing: 0.01rem;
  white-space: nowrap;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background-color: ${colors.orange};
    color: #fff;
  }

  &:disabled {
    background-color: ${colors.lightGray};
    color: #fff;
  }

  @media print {
    display: none;
  }
`;

export const DefaultExternalLinkButton = styled.a`
  background-color: ${colors.orange};
  color: #fff !important;
  text-transform: none;
  border-radius: 20px;
  padding: 6px 20px;
  letter-spacing: 0.01rem;
  white-space: nowrap;
  font-size: 14px;
  line-height: 24.5px;
  display: inline-block;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background-color: ${colors.orange};
    color: #fff;
  }

  &:disabled {
    background-color: ${colors.lightGray};
    color: #fff;
  }
`;

export const DefaultLinkButton = styled(Link)`
  background-color: ${colors.orange};
  color: #fff !important;
  text-transform: none;
  border-radius: 20px;
  padding: 6px 20px;
  letter-spacing: 0.01rem;
  white-space: nowrap;
  font-size: 14px;
  line-height: 24.5px;
  display: inline-block;

  svg {
    margin-right: 5px;
  }

  &:hover {
    background-color: ${colors.orange};
    color: #fff;
  }

  &:disabled {
    background-color: ${colors.lightGray};
    color: #fff;
  }
`;

export const DefaultTextButton = styled(Button)<{
  color?: string;
}>`
  background-color: transparent;
  text-transform: none;
  padding: 6px 20px;
  letter-spacing: 0.01rem;
  border-radius: 20px;
  color: ${(props) => props.color || '#333'};

  svg {
    margin-right: 5px;
  }
` as typeof Button;

export const DefaultOutlinedButton = styled(Button)<{ size?: 'small' | 'medium' | 'large' }>`
  background-color: #fff;
  text-transform: none;
  padding: 6px 20px;
  letter-spacing: 0.01rem;
  border: 1px solid #333;
  border-radius: 20px;
  color: #333;
  white-space: nowrap;

  &:hover {
    background-color: #fff;
    color: #333;
  }
  &:active {
    background-color: #fff;
    color: #333;
  }

  svg {
    margin-right: 5px;
  }

  ${(props) =>
    props.size === 'small' &&
    `
    padding: 3px 10px;
    font-size: 0.78rem;
    height: 28px;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 3px;
  `}
`;
export const DefaultOutlinedLinkButton = styled(Link)<{ size?: 'small' | 'medium' | 'large' }>`
  background-color: #fff;
  text-transform: none;
  padding: 6px 20px;
  letter-spacing: 0.01rem;
  border: 1px solid #333;
  border-radius: 20px;
  color: #333 !important;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:hover {
    background-color: #fff;
    color: #333;
  }
  &:active {
    background-color: #fff;
    color: #333;
  }

  svg {
    margin-right: 5px;
  }

  ${(props) =>
    props.size === 'small' &&
    `
    padding: 3px 10px;
    font-size: 0.78rem;
    height: 28px;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 3px;
  `}
`;

export const SmallOutlinedButton = styled(Button)`
  background-color: transparent;
  text-transform: none;
  padding: 3px 10px;
  letter-spacing: 0.01rem;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 20px;
  color: #333;
  font-size: 0.8rem;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }
`;

export const UploadButton = styled.label`
  display: flex;
  align-items: center;
  background-color: transparent;
  text-transform: none;
  padding: 6px 20px;
  letter-spacing: 0.01rem;
  border-radius: 20px;
  color: ${(props) => props.color || '#333'};
  cursor: pointer;
  font-size: 14px;

  svg {
    margin-right: 5px;
  }
`;

export const ButtonImgIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 10px;
`;

export const DataGridWrapper = styled.div`
  height: calc(100vh - 230px);
  width: 100%;
  background-color: white;

  & .MuiDataGrid-row {
    cursor: pointer;
  }
`;

export const DataGridHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  align-items: center;
`;

export const DataGridHeaderActions = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const DataGridFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const ListDialogContent = styled(DialogContent)`
  @media (min-width: 600px) {
    min-width: 500px;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-width: 300px;
  min-height: 300px;
`;

export const FilterInput = styled.input`
  display: none;
  @media (min-width: 600px) {
    display: block;
    width: 200px;
    margin-right: 5px;
    border: 1px solid rgba(224, 224, 224, 1);
    padding: 5px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
  }
`;

export const ListTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 10px;
`;

export const ListCaption = styled.div`
  font-size: 0.85rem;
  color: #666;
  margin-top: 5px;
`;

export const ListItem = styled.div<{ noPointer?: boolean; active?: boolean; dense?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  min-height: 45px;
  user-select: none;

  &:hover {
    border-color: rgb(0, 0, 0);
  }

  ${(props) => {
    if (!props.noPointer) {
      return `cursor: pointer;`;
    }
  }}
  ${(props) => props.active && `background-color: rgba(0, 0, 0, 0.02); border-color: rgb(0, 0, 0);`}
  ${(props) => props.disabled && `color: rgba(0,0,0,0.5); border-color: rgba(0, 0, 0, 0.5);`}
  ${(props) => props.dense && `min-height: 30px; padding: 0 5px; font-size: 0.85rem;`}
`;
export const ListHeaderText = styled(HiddenOnMobile)`
  width: 150px;
  font-size: 0.75rem;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: start;
  justify-content: start;
`;
export const ListHeaderIcon = styled(HiddenOnMobile)`
  width: 100px;
  font-size: 0.75rem;
  font-weight: 700;
  text-align: center;
`;

export const ListItemText = styled(HiddenOnMobile)`
  width: 150px;
  font-size: 0.875rem;
`;
export const ListItemIcon = styled(HiddenOnMobile)`
  width: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ListItemActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 140px;
`;

export const FormWrapper = styled.div`
  margin-top: 20px;
`;

export const FormInputWrapper = styled.div`
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const RouterTextLink = styled(Link)`
  color: ${colors.orange};
  text-decoration: none;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`;

export const TextLink = styled.div`
  color: ${colors.orange};
  text-decoration: none;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`;

export const FileInput = styled.input`
  display: none;
`;

export const RegistrationList = styled.div`
  margin: 8px 0;
  margin-bottom: 20px;
`;

export const RegistrationListItem = styled(Link)`
  color: ${colors.gray} !important;
  padding: 5px;

  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.lightGray};

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  &:hover {
    background-color: ${colors.hoverGray};
  }
`;

export const RegistrationListInfo = styled.div`
  font-size: 0.8rem;
  color: #666;
  white-space: nowrap;
  margin-top: -4px;
  margin-bottom: 10px;
`;

export const MenuSectionTitle = styled.div`
  font-size: 0.8rem;
  font-weight: 700;
  margin-bottom: 8px;
  margin-left: 16px;
  margin-top: 16px;
`;

export const StepperNavigation = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

export const StolenInfo = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  background-color: red;
  padding: 20px;
  color: white;
  margin: 20px;
  text-align: center;
  border-radius: 5px;
`;

export const StolenListInfo = styled.div`
  font-size: 0.8rem;
  color: red;
  white-space: nowrap;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;
`;

import { Alert, Box, Grid, Typography } from '@mui/material';
import { DefaultCard, DefaultLinkButton, ListCaption, RegistrationList, RegistrationListItem } from '../../layout';
import { paths } from '../../navigation/paths';
import {
  useChangeOfOwnershipRegistrationsByOwnerQuery,
  useConfirmedRegistrationsByOwnerQuery,
} from '../../api/protected/registrations';

export const StatusList = () => {
  const changeOfOwnershipRegistrations = useChangeOfOwnershipRegistrationsByOwnerQuery();
  const confirmedRegistrations = useConfirmedRegistrationsByOwnerQuery();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <DefaultCard>
          <Typography variant="h6">Senast godkända registreringar</Typography>

          {confirmedRegistrations.data && (
            <>
              <RegistrationList>
                {confirmedRegistrations.data.length === 0 && (
                  <Typography variant="body1">Inga godkända registreringar</Typography>
                )}

                {confirmedRegistrations.data
                  .filter((r) => !r.parentId)
                  .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                  .slice(0, 5)
                  .map((registration) => (
                    <Box key={registration.id}>
                      <RegistrationListItem
                        to={paths.myRegistrationsRegistrationDetails.path.replace(':id', registration.id.toString())}
                      >
                        <Box>
                          <ListCaption>{registration.category}</ListCaption>
                          <Typography variant="subtitle1">
                            {registration.ckNumber != null ? registration.ckNumber + ' ' : ''}
                            {registration.name}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption">
                            {new Date(registration.created).toLocaleDateString('sv')}
                          </Typography>
                        </Box>
                      </RegistrationListItem>
                    </Box>
                  ))}
              </RegistrationList>

              <DefaultLinkButton to={`${paths.myRegistrationsRegistrations.path}`}>
                Visa alla registreringar
              </DefaultLinkButton>
            </>
          )}
          {confirmedRegistrations.isPending && <Alert severity="warning">Laddar...</Alert>}
          {confirmedRegistrations.isError && (
            <Alert severity="error">Ett fel uppstod vid hämtning av registreringar</Alert>
          )}
        </DefaultCard>
      </Grid>

      <Grid item xs={12} lg={6}>
        <DefaultCard>
          <Typography variant="h6">Pågående ägarbyten</Typography>

          {changeOfOwnershipRegistrations.data ? (
            <>
              <RegistrationList>
                {changeOfOwnershipRegistrations.data.length === 0 && (
                  <Typography variant="body1">Inga pågående ägarbyten</Typography>
                )}

                {changeOfOwnershipRegistrations.data
                  .filter((r) => !r.parentId)
                  .map((registration) => (
                    <Box key={registration.id}>
                      <RegistrationListItem
                        to={paths.myRegistrationsRegistrationDetails.path.replace(':id', registration.id.toString())}
                      >
                        <Box>
                          <ListCaption>{registration.category}</ListCaption>
                          <Typography variant="subtitle1">
                            {registration.ckNumber != null ? registration.ckNumber + ' ' : ''}
                            {registration.name}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption">
                            {new Date(registration.created).toLocaleDateString('sv')}
                          </Typography>
                        </Box>
                      </RegistrationListItem>
                    </Box>
                  ))}
              </RegistrationList>
            </>
          ) : (
            changeOfOwnershipRegistrations.isPending && <Alert severity="info">Laddar...</Alert>
          )}
          {changeOfOwnershipRegistrations.isError && (
            <Alert severity="error">Ett fel uppstod vid hämtning av ägarbyten</Alert>
          )}
        </DefaultCard>
      </Grid>
    </Grid>
  );
};

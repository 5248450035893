import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../context/UserProvider';

export const SignOutPage = () => {
  const { signOut } = useUser();
  useEffect(() => {
    signOut();
  }, [signOut]);
  return <Navigate to="/" />;
};

import { Container } from '@mui/material';
import { PageContent, PageTitle } from '../layout';
import { BreadCrumbs } from '../navigation/BreadCrumbs';
import { FinancingList } from '../components/financings/FinancingList';

export const FinancingsPage = () => {
  return (
    <Container>
      <BreadCrumbs />
      <PageContent>
        <PageTitle>Finansieringar</PageTitle>
        <FinancingList />
      </PageContent>
    </Container>
  );
};

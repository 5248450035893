import { DataAttributeTemplateDto, DataAttributeValueDto } from '../types/protected/data-attribute';

export const getDataAttributeValuesFromTemplate = (templates: DataAttributeTemplateDto[]): DataAttributeValueDto[] => {
  const dataAttributeValues = templates.map((template) => {
    return {
      dataAttributeId: template.dataAttributeId,
      name: template.dataAttributeName ?? '',
      type: template.type,
      isRequired: template.isRequired,
      value: '',
      isPartOfId: template.isPartOfId,
      isPartOfName: template.isPartOfName,
      isScannable: template.isScannable,
      isPublic: template.isPublic,
      isUnique: template.isUnique,
      dataAttributeOrder: template.dataAttributeOrder,
      dataAttributeGroupId: template.dataAttributeGroupId,
    };
  });

  dataAttributeValues.sort((a, b) => (a.dataAttributeOrder || 0) - (b.dataAttributeOrder || 0));

  return dataAttributeValues;
};

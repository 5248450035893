import styled from '@emotion/styled';
import { Alert, Box, Checkbox, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';
import { useState } from 'react';
import { DefaultButton, DefaultTextButton, ListDialogContent, ListItem } from '../../layout';
import { RegistrationDto } from '../../shared/types/registration';
import { groupBy } from '../../shared/utils/groupBy';
import { useConfirmedRegistrationsByOwnerQuery } from '../../api/protected/registrations';

const RegistrationName = styled.div`
  font-size: 0.8rem;
`;

const RegistrationInfo = styled.div`
  text-align: right;
  font-size: 0.7rem;
`;

type SelectChildRegistrationsDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (registrationIds: number[]) => void;
  currentRegistrationId: number | null;
};

export const SelectChildRegistrationsDialog = ({
  isOpen,
  onClose,
  currentRegistrationId,
  onSave,
}: SelectChildRegistrationsDialogProps) => {
  const confirmedRegistrations = useConfirmedRegistrationsByOwnerQuery();
  const [selectedRegistrationIds, setSelectedRegistrationIds] = useState<number[]>([]);

  const isSelected = (id: number) => {
    return selectedRegistrationIds.includes(id);
  };

  const onRegistrationListItemClick = (id: number) => {
    const selectedIds = isSelected(id)
      ? selectedRegistrationIds.filter((selectedId) => selectedId !== id)
      : [...selectedRegistrationIds, id];

    setSelectedRegistrationIds(selectedIds);
  };

  const hasChildren = (id: number) => {
    return confirmedRegistrations.data?.some((r) => r.parentId === id);
  };

  const isSelectable = (registration: RegistrationDto) => {
    if (registration.isOwnerChanging) {
      return false;
    }

    if (hasChildren(registration.id)) {
      return false;
    }

    if (registration.parentId) {
      return false;
    }

    if (registration.id === currentRegistrationId) {
      return false;
    }

    return true;
  };

  const onSaveClick = () => {
    onClose();
    onSave(selectedRegistrationIds);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Välj registreringar som ska kopplas</DialogTitle>
        <ListDialogContent>
          <>
            {confirmedRegistrations.data ? (
              <>
                {confirmedRegistrations.data.filter((r) => isSelectable(r)).length > 1 ? (
                  <>
                    {Object.entries(
                      groupBy(
                        confirmedRegistrations.data.filter((r) => isSelectable(r)),
                        (registration) => registration.category
                      )
                    )
                      .sort(([key1], [key2]) => key1.localeCompare(key2))
                      .map(([key, value]) => {
                        return (
                          <Box key={key}>
                            <Typography variant="caption" gutterBottom>
                              {key} {value.length} st
                            </Typography>
                            {value
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((registration, i) => (
                                <ListItem
                                  active={isSelected(registration.id)}
                                  key={i}
                                  onClick={() => onRegistrationListItemClick(registration.id)}
                                >
                                  <Box>
                                    <RegistrationName>
                                      <Checkbox size="small" disableRipple checked={isSelected(registration.id)} />
                                      {registration.name.substring(0, 40)}
                                    </RegistrationName>
                                  </Box>
                                  <RegistrationInfo>
                                    <Box>{registration.ckNumber ?? '-------'}</Box>
                                    <Box>{new Date(registration.created).toLocaleDateString('sv')}</Box>
                                  </RegistrationInfo>
                                </ListItem>
                              ))}
                          </Box>
                        );
                      })}
                  </>
                ) : (
                  <Alert severity="warning">Det finns inga registreringar att välja, skapa en ny i stället.</Alert>
                )}
              </>
            ) : (
              confirmedRegistrations.isPending && <Alert severity="warning">Laddar...</Alert>
            )}
            {confirmedRegistrations.isError && (
              <Alert severity="error">Ett fel uppstod vid hämtning av registreringar</Alert>
            )}
          </>
        </ListDialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton disabled={selectedRegistrationIds.length === 0} onClick={onSaveClick}>
            Koppla valda registreringar
          </DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

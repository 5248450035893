import styled from '@emotion/styled';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Lock, MoreVertical, Copy } from 'react-feather';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { addTheftReportAsync, postConfirmOwnershipToApiAsync, updateTheftReportsAsync } from '../../../api';
import { useUser } from '../../../context/UserProvider';
import {
  DefaultButton,
  DefaultCard,
  DefaultOutlinedButton,
  DefaultOutlinedLinkButton,
  Divider,
  ListCaption,
} from '../../../layout';
import { paths } from '../../../navigation/paths';
import { RegistrationDetailsDto } from '../../../shared/types/registration';
import { TheftReportDto } from '../../../shared/types/theft-report';
import { getFormatedOrganizationNumber, getFormatedSocialSecurityNumberNumber } from '../../../shared/utils/format-pid';
import { colors } from '../../../static/colors';
import { ConfirmDialog } from '../../dialogs/ConfirmDialog';
import { NewTheftReportDialog } from '../NewTheftReportDialog';
import { TheftReportDialog } from '../TheftReportDialog';
import { useConfirmedRegistrationsByOwnerQuery } from '../../../api/protected/registrations';

type RegistrationDetailsHeaderProps = {
  registrationDetails?: RegistrationDetailsDto;
  onRemoveChildRegistration?: (childRegistrationId: number) => void;
  onEditRegistration?: (registrationId: number) => void;
};

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  @media print {
    display: none;
  }
`;

const HeaderActionButtons = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
  }
`;

const StolenAlert = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: ${colors.red};
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
  padding: 10px;
  border-radius: 5px;
  padding: 20px;
`;

const OwnerChangingInfo = styled(DefaultCard)`
  margin-top: 20px;
`;

export const RegistrationDetailsHeader = ({
  registrationDetails,
  onRemoveChildRegistration,
  onEditRegistration,
}: RegistrationDetailsHeaderProps) => {
  const [isNewTheftReportDialogOpen, setIsNewTheftReportDialogOpen] = useState(false);
  const [isTheftReportDialogOpen, setIsTheftReportDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const { selectedOwner, isRegistrar } = useUser();
  const confirmedRegistrations = useConfirmedRegistrationsByOwnerQuery();

  const queryClient = useQueryClient();

  const { mutate: submitNewTheftReport } = useMutation({
    mutationFn: (theftReport: TheftReportDto) => addTheftReportAsync(theftReport),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['registrationDetails'] });
      //updateRegistrations();
    },
  });

  const { mutate: updateTheftReports } = useMutation({
    mutationFn: (theftReports: TheftReportDto[]) => updateTheftReportsAsync(theftReports),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['registrationDetails'] });
      //updateRegistrations();
    },
  });

  const navigate = useNavigate();

  const onMoreButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onNewTheftReportClick = () => {
    onMenuClose();
    setIsNewTheftReportDialogOpen(true);
  };

  const onTheftReportDialogClose = () => {
    setIsTheftReportDialogOpen(false);
    setIsNewTheftReportDialogOpen(false);
  };

  const onSubmitTheftReport = (theftReport: TheftReportDto) => {
    submitNewTheftReport(theftReport);
    onTheftReportDialogClose();
  };

  const onUpdateTheftReports = () => {
    setIsTheftReportDialogOpen(true);
    onMenuClose();
  };

  const onSubmitUpdatedTheftReports = (theftReports: TheftReportDto[]) => {
    updateTheftReports(theftReports);
    onTheftReportDialogClose();
  };

  const getParentRegistration = () => {
    if (!registrationDetails?.parentId) {
      return null;
    }

    return confirmedRegistrations.data?.find((r) => r.id === registrationDetails?.parentId);
  };

  const onConfirmOwnershipClick = async () => {
    if (!registrationDetails?.id) {
      return;
    }
    setIsConfirmDialogOpen(true);
  };

  const confirmOwnership = async () => {
    if (!registrationDetails?.id) {
      return;
    }
    const response = await postConfirmOwnershipToApiAsync(registrationDetails.id);
    if (response.status === 204) {
      //updateRegistrations();
    }
    setIsConfirmDialogOpen(false);
    navigate(paths.myRegistrationsChangeOfOwnershipConfirmed.path);
  };

  const onEditRegistrationClick = () => {
    onMenuClose();
    if (onEditRegistration) {
      onEditRegistration(registrationDetails?.id || 0);
    }
  };

  const onPrintClick = () => {
    onMenuClose();
    window.print();
  };

  if (!registrationDetails) {
    return null;
  }

  return (
    <>
      <NewTheftReportDialog
        registrationDetails={registrationDetails}
        open={isNewTheftReportDialogOpen}
        onClose={onTheftReportDialogClose}
        onSubmit={onSubmitTheftReport}
      />

      <TheftReportDialog
        theftReports={registrationDetails?.theftReports || []}
        onClose={onTheftReportDialogClose}
        open={isTheftReportDialogOpen}
        onSubmit={onSubmitUpdatedTheftReports}
      />

      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onConfirm={() => confirmOwnership()}
        title="Godkänn ägarbyte"
        message="Är du säker på att du vill godkänna ägarskapet?"
        onCancel={() => setIsConfirmDialogOpen(false)}
        ok="Godkänn"
      />

      <Header>
        <Box>
          <ListCaption>{registrationDetails?.category}</ListCaption>
          <Typography variant="h5">{registrationDetails?.name}</Typography>
          {registrationDetails?.currentOwnerConfirmedAt &&
            (registrationDetails?.ckNumber ? (
              <Typography variant="subtitle1">CK-nummer: {registrationDetails?.ckNumber}</Typography>
            ) : (
              <Typography variant="h6" color="red">
                CK-nummer saknas
              </Typography>
            ))}

          <Typography variant="body1">Registrerad: {dayjs(registrationDetails?.created).format('L')}</Typography>

          {!registrationDetails?.parentId && (
            <>
              {!registrationDetails.currentOwner && registrationDetails?.nextOwnerFinancingStatus?.financier?.name && (
                <>
                  <Typography variant="body1">
                    Finansiering: {registrationDetails?.nextOwnerFinancingStatus?.financier?.name}
                  </Typography>
                  <Typography variant="body1">
                    Avtalsnummer: {registrationDetails?.nextOwnerFinancingStatus?.externalId}
                  </Typography>
                </>
              )}

              {registrationDetails.currentOwner && registrationDetails?.currentOwnerFinancingStatus?.financier?.name && (
                <>
                  <Typography variant="body1">
                    Finansiering: {registrationDetails?.currentOwnerFinancingStatus?.financier?.name}
                  </Typography>
                  <Typography variant="body1">
                    Avtalsnummer: {registrationDetails?.currentOwnerFinancingStatus?.externalId}
                  </Typography>
                </>
              )}

              {!registrationDetails.currentOwner && registrationDetails?.nextOwnerLeaseStatus?.financier?.name && (
                <>
                  <Typography variant="body1">
                    Leasegivare: {registrationDetails?.nextOwnerLeaseStatus?.financier?.name}
                  </Typography>
                  <Typography variant="body1">
                    Avtalsnummer: {registrationDetails?.nextOwnerLeaseStatus?.externalId}
                  </Typography>
                  <Typography variant="body1">
                    Brukare: {registrationDetails?.nextOwnerLeaseStatus?.holder?.fullName}
                  </Typography>
                </>
              )}

              {registrationDetails.currentOwner && registrationDetails?.currentOwnerLeaseStatus?.financier?.name && (
                <>
                  <Typography variant="body1">
                    Leasegivare: {registrationDetails?.currentOwnerLeaseStatus?.financier?.name}
                  </Typography>
                  <Typography variant="body1">
                    Avtalsnummer: {registrationDetails?.currentOwnerLeaseStatus?.externalId}
                  </Typography>
                  <Typography variant="body1">
                    Brukare: {registrationDetails?.currentOwnerLeaseStatus?.holder?.fullName}
                  </Typography>
                </>
              )}
            </>
          )}

          {registrationDetails.theftReports && registrationDetails.theftReports.find((t) => t.active) && (
            <StolenAlert>
              ANMÄLD SOM STULEN!
              <DefaultOutlinedButton onClick={() => onUpdateTheftReports()}>Visa detaljer</DefaultOutlinedButton>
            </StolenAlert>
          )}

          {registrationDetails?.parentId && (
            <Typography variant="caption">
              Huvudregistrering: {getParentRegistration()?.name ?? 'Ingen koppling'}
            </Typography>
          )}

          {registrationDetails?.isOwnerChanging && (
            <OwnerChangingInfo>
              <Typography variant="h6">Ägarbyte pågår</Typography>
              <Typography variant="caption">Blivande ägare</Typography>
              <Typography variant="subtitle1"> {registrationDetails.nextOwner?.fullName}</Typography>
              <Typography variant="subtitle2">
                {!registrationDetails.nextOwner?.isPerson
                  ? getFormatedOrganizationNumber(registrationDetails.nextOwner?.pid)
                  : getFormatedSocialSecurityNumberNumber(registrationDetails.nextOwner?.pid)}
              </Typography>

              {registrationDetails?.nextOwner?.id !== selectedOwner?.ownerId && (
                <>
                  <Divider />
                  Väntar på godkännande av den blivande ägaren
                </>
              )}
              {registrationDetails?.nextOwner?.id === selectedOwner?.ownerId && (
                <>
                  <Divider />
                  <DefaultButton size="large" onClick={onConfirmOwnershipClick}>
                    Godkänn ägarbyte
                  </DefaultButton>
                </>
              )}
            </OwnerChangingInfo>
          )}
        </Box>
        <HeaderActions>
          <HeaderActionButtons>
            {registrationDetails.theftReports && !registrationDetails.theftReports.find((t) => t.active) && (
              <DefaultOutlinedButton onClick={() => onNewTheftReportClick()} size="small">
                <Lock />
                Anmäl som stulen
              </DefaultOutlinedButton>
            )}
            {!registrationDetails?.isOwnerChanging && registrationDetails.currentOwner?.id == selectedOwner?.ownerId && (
              <DefaultOutlinedLinkButton
                size="small"
                to={paths.myRegistrationsChangeOfOwnership.path.replace(':id', registrationDetails.id.toString())}
              >
                Ansök om ägarbyte
              </DefaultOutlinedLinkButton>
            )}
            {isRegistrar && (
              <DefaultOutlinedLinkButton
                size="small"
                to={paths.myRegistrationsCopyRegistration.path.replace(':id', registrationDetails.id.toString())}
              >
                <Copy />
                Kopiera
              </DefaultOutlinedLinkButton>
            )}
          </HeaderActionButtons>

          <IconButton onClick={(e) => onMoreButtonClick(e)}>
            <MoreVertical />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={onMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => onUpdateTheftReports()}>Stöldanmälningar</MenuItem>
            {onEditRegistration && <MenuItem onClick={() => onEditRegistrationClick()}>Redigera</MenuItem>}
            <MenuItem onClick={() => onPrintClick()}>Skriv ut</MenuItem>

            {onRemoveChildRegistration && !registrationDetails.isOwnerChanging && (
              <MenuItem onClick={() => onRemoveChildRegistration(registrationDetails.id)}>Ta bort koppling</MenuItem>
            )}
          </Menu>
        </HeaderActions>
      </Header>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { TransactionType } from '../shared/types/change-of-ownership';
import { SelectableStatistcsAttribute } from '../shared/types/statistics';
import { ConfigContext, ConfigContextType } from './ConfigContext';

type ConfigProviderProps = {
  children: React.ReactNode;
};

export const ConfigProvider = ({ children }: ConfigProviderProps) => {
  const [promoTexts, setPromoTexts] = useState<string[]>([]);
  const [selectableStatistcsAttributes, setSelectableStatistcsAttributes] = useState<SelectableStatistcsAttribute[]>(
    []
  );
  const [transactionTypes, setTransactionTypes] = useState<TransactionType[]>([]);
  const [financedTransactionTypes, setFinancedTransactionTypes] = useState<string[]>([]);

  useEffect(() => {
    const fetchConfig = async () => {
      const response = await fetch('/config.json');
      const config = await response.json();
      setPromoTexts(config.promoTexts);
      setSelectableStatistcsAttributes(config.selectableStatistcsAttributes);
      setTransactionTypes(config.transactionTypes);
      setFinancedTransactionTypes(config.financedTransactionTypes);
    };
    fetchConfig();
  }, []);

  const value: ConfigContextType = {
    promoTexts,
    selectableStatistcsAttributes,
    transactionTypes,
    financedTransactionTypes,
  };

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => {
  const context = React.useContext(ConfigContext) as ConfigContextType;
  if (context === undefined) throw new Error('useConfig must be used within ConfigProvider');
  return context;
};

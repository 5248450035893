import styled from '@emotion/styled';
import { Container, Grid, Typography } from '@mui/material';
import { SignInHandler } from '../components/sign-in/SignInHandler';
import { DefaultCard, PageContent } from '../layout';

const LoginCard = styled(DefaultCard)`
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
`;

export const SignInPage = () => {
  return (
    <Container>
      <PageContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LoginCard>
              <Typography variant="h4">Logga in</Typography>

              <SignInHandler />
            </LoginCard>
          </Grid>
          <Grid item xs={12}>
            {/* <AppPromoCard>Ladda ner appen...</AppPromoCard> */}
          </Grid>
        </Grid>
      </PageContent>
    </Container>
  );
};

import { RegistrationDetailsDto } from '../types/registration';

export const getSortedDetails = (details: RegistrationDetailsDto | null) => {
  if (!details) {
    return null;
  }
  const sortedDetails = details.dataAttributeGroups
    .map((group) => {
      const sortedAttributes = group.dataAttributes.sort(
        (a, b) => (a.dataAttributeOrder ?? 0) - (b.dataAttributeOrder ?? 0)
      );
      return { ...group, dataAttributes: sortedAttributes };
    })
    .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

  return { ...details, dataAttributeGroups: sortedDetails };
};

import { Alert, Container, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useAddRegistrationCreatedByOwnerMutation,
  useConfirmedRegistrationsByOwnerQuery,
} from '../../api/protected/registrations';
import { SavingDialog } from '../../components/dialogs/SavingDialog';
import { NewRegistrationForm } from '../../components/registrations/NewRegistrationForm';
import { PageContent, PageTitle } from '../../layout';
import { BreadCrumbs } from '../../navigation/BreadCrumbs';
import { paths } from '../../navigation/paths';
import { PostRegistrationDto } from '../../shared/types/registration';

export const NewRegistrationByOwnerPage = () => {
  const { id } = useParams();
  const confirmedRegistrations = useConfirmedRegistrationsByOwnerQuery();
  const navigate = useNavigate();
  const addRegistrationCreatedByOwnerMutation = useAddRegistrationCreatedByOwnerMutation();

  function saveRegistration(registration: PostRegistrationDto) {
    addRegistrationCreatedByOwnerMutation.mutate(registration, {
      onSuccess: () => {
        navigate(paths.myRegistrationsRegistrationComplete.path);
      },
    });
  }

  return (
    <>
      <SavingDialog open={addRegistrationCreatedByOwnerMutation.isPending} />
      <Container maxWidth={false}>
        <BreadCrumbs />
        <PageContent>
          <PageTitle>Ny registrering</PageTitle>
          <Typography variant="body1" gutterBottom>
            Det är enkelt att registrera ett objekt i Chassikollen. Följ anvisningarna steg för steg.
          </Typography>
          {confirmedRegistrations.data ? (
            <NewRegistrationForm
              source={confirmedRegistrations.data?.find((r) => r.id === Number(id)) ?? null}
              onSave={saveRegistration}
            />
          ) : (
            confirmedRegistrations.isPending && <Alert severity="warning">Laddar...</Alert>
          )}
          {confirmedRegistrations.isError && (
            <Alert severity="error">Ett fel uppstod vid hämtning av registreringar</Alert>
          )}
        </PageContent>
      </Container>
    </>
  );
};

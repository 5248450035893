import styled from '@emotion/styled';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Image } from 'react-feather';
import { useCategoriesQuery } from '../../api';
import { DefaultButton, DefaultCard, DefaultTextButton, Divider, FileInput, UploadButton } from '../../layout';
import { getDataAttributeValuesFromTemplate } from '../../shared/converters/get-data-attribute-values-from-template';
import { CategoryDto } from '../../shared/types/protected/category';
import { DataAttributeValueDto } from '../../shared/types/protected/data-attribute';
import { ImageDto } from '../../shared/types/image';
import { PostRegistrationDto } from '../../shared/types/registration';
import { toBase64String } from '../../shared/utils/base64';
import { resizeImage } from '../../shared/utils/resize-image';
import { ImagesToUploadPreview } from './ImagesToUploadPreview';
import { RegistrationForm } from './RegistrationForm';
import { SelectCategoryTree } from './SelectCategoryTree';
import { useUser } from '../../context/UserProvider';

type NewChildRegistrationDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onChildCreated: (registration: PostRegistrationDto) => void;
  registrationToEdit: PostRegistrationDto | null;
};

const StepperWrapper = styled.div`
  width: 100%;
`;

const SelectCategoryTreeWrapper = styled.div`
  min-height: 200px;
`;

export const NewChildRegistrationDialog = ({
  isOpen,
  onClose,
  onChildCreated,
  registrationToEdit,
}: NewChildRegistrationDialogProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<CategoryDto | null>(null);
  const [dataAttributeValues, setDataAttributeValues] = useState<DataAttributeValueDto[]>([]);
  const [dataAttributeIdsWithErrors, setDataAttributeIdsWithErrors] = useState<number[]>([]);
  const [isAllRequiredDataAttributeValuesSet, setIsAllRequiredDataAttributeValuesSet] = useState(false);
  const [selectedImages, setSelectedImages] = useState<ImageDto[]>([]);
  const [ckNumber, setCkNumber] = useState('');

  const { selectedOwner } = useUser();

  const { data: categories } = useCategoriesQuery(true);

  useEffect(() => {
    if (!isOpen) {
      setActiveStep(0);
      setSelectedCategory(null);
      setDataAttributeValues([]);
      setDataAttributeIdsWithErrors([]);
      setIsAllRequiredDataAttributeValuesSet(false);
      setSelectedImages([]);
      setCkNumber('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (!registrationToEdit) {
      return;
    }
    const category = categories?.find((c) => c.id === registrationToEdit.categoryId);

    if (!category) {
      return;
    }
    setSelectedCategory(category);
    setDataAttributeValues(registrationToEdit.attributes);
    setSelectedImages(registrationToEdit.images || []);
  }, [registrationToEdit, categories]);

  useEffect(() => {
    const emptyRequiredAttributes = dataAttributeValues.filter((a) => a.isRequired && !a.value);
    setIsAllRequiredDataAttributeValuesSet(emptyRequiredAttributes.length === 0);
  }, [dataAttributeValues]);

  const onSelectCategory = (category: CategoryDto | null) => {
    if (!category) {
      return;
    }

    setSelectedCategory(category);
    setDataAttributeValues(getDataAttributeValuesFromTemplate(category.dataAttributeTemplates));
    setActiveStep(1);
  };

  const onSelectImagesChange = async (images: FileList | null) => {
    if (!images) {
      return;
    }
    const newImages: ImageDto[] = await Promise.all(
      Array.from(images).map(async (image) => {
        const resizedImage = await resizeImage(image, 1200, 1200);
        return { filename: image.name, contentType: image.type, base64String: await toBase64String(resizedImage) };
      })
    );

    setSelectedImages([...selectedImages, ...newImages]);
  };

  const onDeleteImage = (image: ImageDto) => {
    if (!selectedImages) {
      return;
    }
    const newImages = selectedImages.filter((i) => i.filename !== image.filename);
    setSelectedImages(newImages);
  };

  const onAddChildClick = async () => {
    const ownerId = selectedOwner?.ownerId;

    if (!ownerId) {
      return;
    }

    const registration: PostRegistrationDto = {
      categoryId: selectedCategory?.id || 0,
      ownerId,
      attributes: dataAttributeValues,
      images: selectedImages,
      ckNumber,
    };
    onChildCreated(registration);
  };

  const onBackClick = () => {
    if (activeStep === 0) {
      return;
    }
    setActiveStep(activeStep - 1);
  };

  const onNextClick = () => {
    if (activeStep === 2) {
      return;
    }
    setActiveStep(activeStep + 1);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Nytt kopplat objekt</DialogTitle>
        <DialogContent>
          <StepperWrapper>
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step>
                <StepLabel>Kategori{selectedCategory && `: ${selectedCategory.name}`}</StepLabel>

                <StepContent TransitionProps={{ unmountOnExit: false }}>
                  <DefaultCard>
                    <SelectCategoryTreeWrapper>
                      <SelectCategoryTree selectedCategory={selectedCategory} onSelectCategory={onSelectCategory} />
                    </SelectCategoryTreeWrapper>
                  </DefaultCard>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Uppgifter</StepLabel>
                <StepContent>
                  <DefaultCard>
                    <RegistrationForm
                      dataAttributeValues={dataAttributeValues}
                      setDataAttributeValues={setDataAttributeValues}
                      dataAttributeIdsWithErrors={dataAttributeIdsWithErrors}
                      ckNumber={ckNumber}
                      setCkNumber={setCkNumber}
                    />
                  </DefaultCard>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Bilder</StepLabel>
                <StepContent>
                  <DefaultCard>
                    <ImagesToUploadPreview selectedImages={selectedImages} onDeleteImage={onDeleteImage} />
                    <Box>
                      <UploadButton htmlFor="images-upload">
                        <Image color="rgba(0, 0, 0, 0.6)" size={20} /> Ladda upp bilder...
                      </UploadButton>
                      <FileInput
                        type="file"
                        id="images-upload"
                        name="registrationImages"
                        multiple
                        onChange={(event) => onSelectImagesChange(event.target.files)}
                        accept="image/*"
                      />
                    </Box>
                    <Divider />
                    <Typography variant="caption" color="textSecondary">
                      Det går att ladda upp bilder i formaten jpg, jpeg, png och gif. Max tre bilder per registrering.
                    </Typography>
                  </DefaultCard>
                </StepContent>
              </Step>
            </Stepper>
          </StepperWrapper>
        </DialogContent>
        <DialogActions>
          {activeStep === 0 && <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>}
          {activeStep !== 0 && <DefaultTextButton onClick={() => onBackClick()}>Föregående</DefaultTextButton>}
          {activeStep !== 2 && (
            <DefaultButton
              onClick={() => onNextClick()}
              disabled={activeStep == 1 && !isAllRequiredDataAttributeValuesSet}
            >
              Nästa
            </DefaultButton>
          )}
          {activeStep == 2 && <DefaultButton onClick={() => onAddChildClick()}>Lägg till objekt</DefaultButton>}
        </DialogActions>
      </Dialog>
    </>
  );
};

import styled from '@emotion/styled';
import { MyRegistrationsMenu } from '../../components/my-registrations/MyRegistrationsMenu';
import { HiddenOnMobile } from '../../layout';
import { paths } from '../../navigation/paths';
import { LoadingPage } from '../Loading';
import { ChangeOfOwnerRegistrationsPage } from './ChangeOfOwnerRegistrations';
import { ChangeOfOwnershipPage } from './ChangeOfOwnership';
import { ConfirmedRegistrationsPage } from './ConfirmedRegistrations';
import { NewRegistrationByOwnerPage } from './NewRegistrationByOwnerPage';
import { OwnerOverviewPage } from './OwnerOverview';
import { OwnerRegistrationDetailsPage } from './OwnerRegistrationDetails';
import { RegistrationCompletePage } from './RegistrationComplete';
import { StatusPage } from './Status';
import { useConfirmedRegistrationsByOwnerQuery } from '../../api/protected/registrations';

type MyRegistrationsPageProps = {
  subPagePath: string;
};

const Wrapper = styled.div``;

const Content = styled.div`
  padding: 10px;

  @media (min-width: 600px) {
    flex: 1;
    padding: 20px;
    margin-left: 300px;
    min-height: calc(100vh - 60px);
  }
`;

const MobileMenu = styled.div`
  display: none;
`;

const SubPage = ({ subPagePath }: MyRegistrationsPageProps) => {
  const pages = [
    {
      path: paths.myRegistrations.path,
      component: <OwnerOverviewPage />,
    },
    {
      path: paths.myRegistrationsStatus.path,
      component: <StatusPage />,
    },

    {
      path: paths.myRegistrationsRegistrations.path,
      component: <ConfirmedRegistrationsPage />,
    },
    {
      path: paths.myRegistrationsRegistrationDetails.path,
      component: <OwnerRegistrationDetailsPage />,
    },
    {
      path: paths.myRegistrationsNewRegistration.path,
      component: <NewRegistrationByOwnerPage />,
    },
    {
      path: paths.myRegistrationsCopyRegistration.path,
      component: <NewRegistrationByOwnerPage />,
    },
    {
      path: paths.myRegistrationsRegistrationComplete.path,
      component: <RegistrationCompletePage />,
    },
    {
      path: paths.myRegistrationsChangeOfOwnershipRegistrations.path,
      component: <ChangeOfOwnerRegistrationsPage />,
    },
    {
      path: paths.myRegistrationsChangeOfOwnership.path,
      component: <ChangeOfOwnershipPage />,
    },
    {
      path: paths.myRegistrationsChangeOfOwnershipRegistrationsDetails.path,
      component: <OwnerRegistrationDetailsPage />,
    },
  ];

  const page = pages.find((p) => p.path === subPagePath);

  return page?.component || <OwnerOverviewPage />;
};

const PageContent = ({ subPagePath }: MyRegistrationsPageProps) => {
  const { isLoading } = useConfirmedRegistrationsByOwnerQuery();

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Wrapper>
      <MobileMenu>
        <MyRegistrationsMenu />
      </MobileMenu>
      <HiddenOnMobile>
        <MyRegistrationsMenu />
      </HiddenOnMobile>

      <Content>
        <SubPage subPagePath={subPagePath} />
      </Content>
    </Wrapper>
  );
};

export const MyRegistrationsPage = ({ subPagePath }: MyRegistrationsPageProps) => {
  return <PageContent subPagePath={subPagePath} />;
};

import { ImageDto } from '../types/image';

export const toBase64String = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;
      const base64String = result.replace('data:', '').replace(/^.+,/, '');
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const imageDtoToDataURI = (image: ImageDto): string => {
  return `data:${image.contentType};base64,${image.base64String}`;
};

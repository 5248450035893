/* eslint-disable prettier/prettier */
import styled from '@emotion/styled';
import { Container, Grid, LinearProgress, Typography } from '@mui/material';
import { SearchResultListItem } from '../components/search/SearchResultListItem';
import { useUser } from '../context/UserProvider';
import { useSearchResult } from '../hooks/search-hooks';
import { PageContent } from '../layout';
import { BreadCrumbs } from '../navigation/BreadCrumbs';
import { groupBy } from '../shared/utils/groupBy';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRegistrationUrl } from '../shared/utils/registration-url';

const SearchInfo = styled.div`
  margin-bottom: 20px;
`;

export const SearchResultPage = () => {
  const { searchResult, isSearching, searchError } = useSearchResult();

  const { isAuthenticated } = useUser();

  const navigate = useNavigate();

  // If there is only one result, move to details page
  useEffect(() => {
    if (searchResult && searchResult.totalAmount === 1 && searchResult.registrations.length === 1) {
      const url = getRegistrationUrl(searchResult.registrations[0]);
      navigate(url, { replace: true });
      return;
    }
  }, [searchResult]);

  return (
    <>
      <Container>
        <BreadCrumbs />
        <PageContent>
          {/* <SearchBar>
            <SearchRegistration />
          </SearchBar> */}

          {isSearching && <LinearProgress />}

          {/*searchError && <SearchInfo>Något gick tyvärr fel, vänligen försök igen.</SearchInfo>*/}

          {!isSearching && !searchError && (
            <>
              <SearchInfo>Din sökning gav {searchResult?.totalAmount} resultat</SearchInfo>
              {isAuthenticated ? (
                <Grid container spacing={2}>
                  {searchResult?.registrations &&
                    searchResult.registrations.map((registration) => {
                      return (
                        <Grid item xs={12} key={registration.id}>
                          <SearchResultListItem registration={registration} />
                        </Grid>
                      );
                    })}
                </Grid>
              ) : (
                // <PublicSearchResultListItem registration={registration} />
                <Grid container spacing={2}>
                  {searchResult?.registrations &&
                    Object.entries(groupBy(searchResult.registrations, (registration) => registration.category))
                      .sort(([, value1], [, value2]) => value2.length - value1.length)
                      .slice(0, 10)
                      .map(([key, value]) => {
                        return (
                          <Grid item xs={12} key={key}>
                            <Typography variant="h6">
                              {key} : {value.length} st
                            </Typography>
                          </Grid>
                        );
                      })}
                  <Grid item xs={12} key={0}>
                    <Typography variant="h6">...</Typography>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </PageContent>
      </Container>
    </>
  );
};

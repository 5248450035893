import { UserInfoDto } from '../../shared/types/user';
import { apiRequest } from '../api-request';

export async function getUserBysocialSecurityNumberFromApiAsync(socialSecurityNumber: string) {
  const response = await apiRequest<UserInfoDto>({
    action: `Protected/User/BySocialSecurityNumber/${socialSecurityNumber}`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response;
}

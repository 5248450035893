import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CompanyDto, OwnerListItemDto, PersonDto } from '../../shared/types/owner';
import { apiRequest2 } from './../api-request';

const ownerKeys = {
  all: ['owners'],
  list: () => [...ownerKeys.all, 'list'],
  persons: () => [...ownerKeys.all, 'persons'],
  personById: (ownerId: number | null) => [...ownerKeys.persons(), 'byId', ownerId],
  companies: () => [...ownerKeys.all, 'companies'],
  companyList: () => [...ownerKeys.companies(), 'list'],
  companyById: (ownerId: number | null) => [...ownerKeys.companies(), 'byId', ownerId],
  financiers: () => [...ownerKeys.all, 'financiers'],
  financierById: (ownerId: number | null) => [...ownerKeys.financiers(), 'byId', ownerId],
};

export const useOwnerListQuery = () =>
  useQuery({
    queryKey: ownerKeys.list(),
    queryFn: async () => {
      const response = await apiRequest2<OwnerListItemDto[]>({
        action: `Protected/Owner`,
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data;
    },
  });

export const useCompanyByIdQuery = (companyId: number | null) =>
  useQuery({
    queryKey: ownerKeys.companyById(companyId),
    queryFn: async () => {
      const response = await apiRequest2<CompanyDto>({
        action: `Protected/Companies/${companyId}`,
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data;
    },
    enabled: companyId !== null,
  });

export const usePutCompanyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (company: CompanyDto) =>
      apiRequest2<void>({
        action: `Protected/Companies/${company.id}`,
        method: 'PUT',
        allowAnonymous: false,
        body: JSON.stringify(company),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ownerKeys.all,
      });
    },
  });
};

export const usePersonByIdQuery = (personId: number | null) =>
  useQuery({
    queryKey: ownerKeys.personById(personId),
    queryFn: async () => {
      const response = await apiRequest2<PersonDto>({
        action: `Protected/Persons/${personId}`,
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data;
    },
    enabled: personId !== null,
  });

export const usePutPersonMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (person: PersonDto) =>
      apiRequest2<void>({
        action: `Protected/Persons/${person.id}`,
        method: 'PUT',
        allowAnonymous: false,
        body: JSON.stringify(person),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ownerKeys.all,
      });
    },
  });
};

export const useFinancierByIdQuery = (financierId: number | null) =>
  useQuery({
    queryKey: ownerKeys.financierById(financierId),
    queryFn: async () => {
      const response = await apiRequest2<CompanyDto>({
        action: `Protected/Financiers/${financierId}`,
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data;
    },
    enabled: financierId !== null,
  });

export const usePutFinancierMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (financier: CompanyDto) =>
      apiRequest2<void>({
        action: `Protected/Financiers/${financier.id}`,
        method: 'PUT',
        allowAnonymous: false,
        body: JSON.stringify(financier),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ownerKeys.all,
      });
    },
  });
};

export async function getPersonBysocialSecurityNumberFromApiAsync(socialSecurityNumber: string) {
  const response = await apiRequest2<PersonDto>({
    action: `Protected/Persons/BySocialSecurityNumber/${socialSecurityNumber}`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response.data;
}

export async function getCompanyByOrganizationNumberFromApiAsync(organizationNumber: string) {
  const response = await apiRequest2<CompanyDto>({
    action: `Protected/Companies/ByOrganizationNumber/${organizationNumber}`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response.data;
}

import { createContext } from 'react';
import { UserCredentialsDto } from '../shared/types/user';
import { ProfileUserOwnerDto, UserProfileDto } from './../shared/types/user';

export type UserContextType = {
  user: UserCredentialsDto | null;
  userProfile?: UserProfileDto;
  selectedOwner: ProfileUserOwnerDto | null;
  updateSelectedOwner: (owner?: ProfileUserOwnerDto) => void;
  updateUser: (user: UserCredentialsDto, tokenExpiresInSeconds: number) => void;
  signOut: () => void;
  isAuthenticated: boolean;
  isRegistrar: boolean;
  updateProfile: (userProfile: UserProfileDto) => void;
};

export const UserContext = createContext<UserContextType | null>(null);

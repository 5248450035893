import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import fbImg from '../../assets/images/fb.png';
import InstagramImg from '../../assets/images/instagram.png';
import linkedInImg from '../../assets/images/linkedin.png';
import footerLogo from '../../assets/images/text-logo.png';
import { colors } from '../../static/colors';
import { paths } from '../../navigation/paths';

const FooterWrapper = styled.footer`
  background-color: ${colors.blue};
  color: #fff;
  padding: 20px;
  padding-top: 30px;
  min-height: 300px;
  position: relative;
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 20px 0;
  color: #fff;

  p {
    color: #fff;
  }
`;

const FooterLogo = styled.img`
  width: auto;
  height: 20px;
  @media (min-width: 600px) {
    height: 25px;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  @media print {
    display: none;
  }
`;

const SocialMediaImg = styled.img`
  width: auto;
  height: 30px;
  margin-right: 10px;
`;

const FooterTel = styled.a`
  color: #fff;
  text-decoration: none;
`;

const FooterMail = styled.a`
  color: #fff;
  text-decoration: none;
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  @media print {
    display: none;
  }
`;
const FooterLink = styled(Link)`
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  margin-bottom: 10px;
`;

const CopyRight = styled.div`
  text-align: left;
  padding: 20px 0;
`;

export const Footer = () => {
  return (
    <FooterWrapper>
      <FooterLogo src={footerLogo} alt="Chassikollen" />
      <FooterContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2">Chassikollen AB</Typography>
            <Typography variant="body2">Möllersvärdsgatan 5</Typography>
            <Typography variant="body2">754 50 Uppsala</Typography>

            <Typography variant="body2">
              <FooterTel href="tel:0102045070">010-204 50 70</FooterTel>
            </Typography>
            <Typography variant="body2">
              <FooterMail href="mailto:info@chassikollen.se">info@chassikollen.se</FooterMail>
            </Typography>

            <SocialMedia>
              <a
                href="https://www.facebook.com/chassikollen"
                target="_blank"
                rel="noopener noreferrer"
                title="Facebook"
              >
                <SocialMediaImg src={fbImg} alt="Facebook" />
              </a>
              <a
                href="https://www.instagram.com/chassikollen/"
                target="_blank"
                rel="noopener noreferrer"
                title="Instagram"
              >
                <SocialMediaImg src={InstagramImg} alt="Instagram" />
              </a>
              <a
                href="https://www.linkedin.com/company/chassikollen"
                target="_blank"
                rel="noopener noreferrer"
                title="LinkedIn"
              >
                <SocialMediaImg src={linkedInImg} alt="LinkedIn" />
              </a>
            </SocialMedia>
          </Grid>

          <Grid item xs={12} md={3}>
            <FooterLinks>
              <FooterLink to={paths.about.path}>{paths.about.name}</FooterLink>
              <FooterLink to={paths.contact.path}>{paths.contact.name}</FooterLink>
              <FooterLink to={paths.privatePolicy.path}>{paths.privatePolicy.name}</FooterLink>
            </FooterLinks>
          </Grid>

          <Grid item xs={12} md={3}>
            <FooterLinks></FooterLinks>
          </Grid>
        </Grid>
      </FooterContent>
      <CopyRight>
        <Typography variant="body2">© {new Date().getFullYear()} Chassikollen AB</Typography>
      </CopyRight>
    </FooterWrapper>
  );
};

export const resizeImage = (image: File, maxWidth: number, maxHeight: number) => {
  return new Promise<File>((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;
      img.onload = () => {
        console.log('Image loaded', img.width, img.height);
        let newWidth = maxWidth;
        let newHeight = (img.height * newWidth) / img.width;

        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = (img.width * newHeight) / img.height;
        }

        const elem = document.createElement('canvas');
        elem.width = newWidth;
        elem.height = newHeight;
        const ctx = elem.getContext('2d');
        ctx?.drawImage(img, 0, 0, newWidth, newHeight);
        ctx?.canvas.toBlob(
          (blob) => {
            const filename = image.name.replace(/.(jpg|jpeg|png|gif)$/i, '.jpg');
            const file = new File([blob as Blob], filename, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            });
            resolve(file);
          },
          'image/jpeg',
          1
        );
      };
    };
    reader.readAsDataURL(image);
  });
};

import { UserProfileDto, UserSettings } from './../shared/types/user';

const getAllStoredUserSettings = (): UserSettings[] => {
  const userSettings = localStorage.getItem('user-settings');
  return userSettings ? JSON.parse(userSettings) : [];
};

export const getStoredUserSettings = (userProfile: UserProfileDto) => {
  if (!userProfile.id) {
    return;
  }

  const userSettings = getAllStoredUserSettings();
  return (
    userSettings.find((userSetting) => userSetting.userId === userProfile.id) || {
      selectedOwnerId: userProfile.ownerId,
      userId: userProfile.id,
    }
  );
};

export const saveUserSettings = (userId: number, userSettings: UserSettings) => {
  const allUserSettings = getAllStoredUserSettings();

  const userSettingsIndex = allUserSettings.findIndex((userSettings) => userSettings.userId === userId);

  if (userSettingsIndex === -1) {
    allUserSettings.push(userSettings);
  } else {
    allUserSettings[userSettingsIndex] = userSettings;
  }

  localStorage.setItem('user-settings', JSON.stringify(allUserSettings));
};

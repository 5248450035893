import { FinancingTypeDto } from './registration';
import { UserOwnerDto } from './user';

export enum OwnerTypeDto {
  Unknown = 0,
  Person = 1,
  Company = 2,
}

export type OwnerListItemDto = {
  id: number;
  pid: string;
  fullname: string;
  type: OwnerTypeDto;
  isSupervisor: boolean;
};

export type OwnerDto = {
  id: number;
  isPerson: boolean;
  pid: string;
  fullName: string;
};

export type OwnerChangeDto = {
  registrationId: number;
  newOwnerId: number;
  financierId?: number;
  externalId?: string;
  financingType?: FinancingTypeDto;
};

export type PersonDto = {
  id?: number;
  givenName?: string;
  familyName?: string;
  socialSecurityNumber?: string;
  email?: string;
  mobilePhone?: string;
  customerNumber?: string;
  userOwners?: UserOwnerDto[];
};

export type CompanyDto = {
  id?: number;
  name?: string;
  organizationNumber?: string;
  legalGroupCode?: string;
  legalGroupText?: string;
  address?: string;
  coAddress?: string;
  phoneNumber?: string;
  city?: string;
  zipCode?: string;
  customerNumber?: string;
  userOwners?: UserOwnerDto[];
  instituteId?: string;
  lei?: string;
  bankType?: string;
  isLessor: boolean;
  isInsurer: boolean;
  isFinancier: boolean;
};

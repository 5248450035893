import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getFinanciers } from '../../api';
import { FormInputWrapper } from '../../layout';
import { CompanyDto } from '../../shared/types/owner';
import { TransactionType } from '../../shared/types/financing';
import { useEffect } from 'react';

type SelectFinancierProps = {
  onFinancierChange: (finacier: CompanyDto | null) => void;
  onTransactionTypeChange: (transactionType: TransactionType) => void;
  onExternalIdChange: (externalId: string) => void;
  selectedFinancierId?: number;
  transactionType: TransactionType;
  externalId: string;
};

export const SelectFinancier = ({
  onFinancierChange,
  onTransactionTypeChange,
  onExternalIdChange,
  selectedFinancierId,
  transactionType,
  externalId,
}: SelectFinancierProps) => {
  const financiersQuery = useQuery({
    queryKey: ['financiers'],
    queryFn: () => getFinanciers(),
    refetchOnMount: false,
  });

  useEffect(() => {
    if (financiersQuery.error) {
      console.error(financiersQuery.error);
    }
  }, [financiersQuery.error]);

  const onSelectTransactionTypeChange = (transactionType: TransactionType) => {
    onTransactionTypeChange(transactionType);
    onFinancierChange(null);
    onExternalIdChange('');
  };

  const onSelectFinancierChange = (financierId: number) => {
    const financier = financiersQuery.data?.find((financier) => financier.id === financierId);
    if (!financier) return;
    onFinancierChange(financier);
  };

  if (financiersQuery.data)
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={transactionType === TransactionType.Lease}
                onChange={(e) =>
                  onSelectTransactionTypeChange(e.target.checked ? TransactionType.Lease : TransactionType.None)
                }
              />
            }
            label="Leasing"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={transactionType === TransactionType.Financed}
                onChange={(e) =>
                  onSelectTransactionTypeChange(e.target.checked ? TransactionType.Financed : TransactionType.None)
                }
              />
            }
            label="Finansierad"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={transactionType === TransactionType.CashPurchase}
                onChange={(e) =>
                  onSelectTransactionTypeChange(e.target.checked ? TransactionType.CashPurchase : TransactionType.None)
                }
              />
            }
            label="Kontantköp"
          />
        </Grid>

        {transactionType === TransactionType.Financed && (
          <>
            <Grid item xs={12} md={6}>
              <FormInputWrapper>
                <FormControl size="small" fullWidth>
                  <InputLabel id="transaction-type-select-label">Kreditgivare</InputLabel>
                  <Select
                    labelId="transaction-finance-select-label"
                    id="transaction-finance-select"
                    label="Kreditgivare"
                    value={selectedFinancierId || ''}
                    onChange={(e) => onSelectFinancierChange(e.target.value as number)}
                    fullWidth
                  >
                    {financiersQuery.data.map((financier, i) => (
                      <MenuItem key={i} value={financier.id}>
                        {financier.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormInputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputWrapper>
                <TextField
                  onChange={(e) => onExternalIdChange(e.target.value)}
                  value={externalId}
                  label="Kontraktsnummer"
                  size="small"
                  fullWidth
                />
              </FormInputWrapper>
            </Grid>
          </>
        )}

        {transactionType === TransactionType.Lease && (
          <>
            <Grid item xs={12} md={6}>
              <FormInputWrapper>
                <FormControl size="small" fullWidth>
                  <InputLabel id="transaction-type-select-label">Leasegivare</InputLabel>
                  <Select
                    labelId="transaction-finance-select-label"
                    id="transaction-finance-select"
                    label="Leasegivare"
                    value={selectedFinancierId || ''}
                    onChange={(e) => onSelectFinancierChange(e.target.value as number)}
                    fullWidth
                  >
                    {financiersQuery.data.map((financier, i) => (
                      <MenuItem key={i} value={financier.id}>
                        {financier.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormInputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInputWrapper>
                <TextField
                  onChange={(e) => onExternalIdChange(e.target.value)}
                  value={externalId}
                  label="Leasenummer"
                  size="small"
                  fullWidth
                />
              </FormInputWrapper>
            </Grid>
          </>
        )}
      </Grid>
    );

  return <CircularProgress />;
};

import styled from '@emotion/styled';
import { Box, IconButton, Tooltip } from '@mui/material';
import { ChevronDown, ChevronUp, Edit, Edit3, Plus, Trash2 } from 'react-feather';
import { DefaultCard, DefaultTextButton, ListItem, ListItemActions, ListItemText, ListTitle } from '../../../layout';
import { DataAttributeDto, DataAttributeGroupDto } from '../../../shared/types/admin/data-attribute';

type DataAttributeListProps = {
  dataAttributes: DataAttributeDto[];
  onEditDataAttribute: (dataAttribute: DataAttributeDto) => void;
  onDeleteDataAttribute: (dataAttribute: DataAttributeDto) => void;
  onAddDataAttribute: (groupId: number) => void;
  onEditDataAttributeGroup?: (dataAttributeGroup: DataAttributeGroupDto) => void;
  onGroupOrderChange: (groupId: number, direction: 'up' | 'down') => void;
  onDataAttributeOrderChange: (dataAttributeId: number, direction: 'up' | 'down') => void;
  group: DataAttributeGroupDto;
  isTopGroup?: boolean;
  isBottomGroup?: boolean;
};

const ListItemTitle = styled.div`
  display: flex;
  align-items: center;
`;

const DataAttributeSettings = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
  align-items: center;
`;

const DataAttributeSetting = styled.div`
  font-size: 0.65rem;
  padding: 1px 5px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const DataAttributeList = ({
  dataAttributes,
  onEditDataAttribute,
  onDeleteDataAttribute,
  onAddDataAttribute,
  onEditDataAttributeGroup,
  group,
  onGroupOrderChange,
  onDataAttributeOrderChange,
  isTopGroup,
  isBottomGroup,
}: DataAttributeListProps) => {
  const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement>, dataAttribute: DataAttributeDto) => {
    event.stopPropagation();
    event.preventDefault();
    onDeleteDataAttribute(dataAttribute);
  };
  const onEditClick = (event: React.MouseEvent<HTMLButtonElement>, dataAttribute: DataAttributeDto) => {
    event.stopPropagation();
    event.preventDefault();
    onEditDataAttribute(dataAttribute);
  };

  const onDataAttributeOrderClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    dataAttributeId: number,
    direction: 'up' | 'down'
  ) => {
    event.stopPropagation();
    event.preventDefault();

    onDataAttributeOrderChange(dataAttributeId, direction);
  };

  return (
    <DefaultCard>
      <ListTitle>
        <Box>
          {group?.name}
          {onEditDataAttributeGroup && (
            <IconButton onClick={() => onEditDataAttributeGroup(group)}>
              <Edit3 size={20} />
            </IconButton>
          )}
        </Box>
        <Box>
          <IconButton disabled={isTopGroup} onClick={() => onGroupOrderChange(group.id, 'up')}>
            <ChevronUp size={20} />
          </IconButton>

          <IconButton disabled={isBottomGroup} onClick={() => onGroupOrderChange(group.id, 'down')}>
            <ChevronDown size={20} />
          </IconButton>
          <DefaultTextButton onClick={() => onAddDataAttribute(group.id)}>
            <Plus size={20} /> Nytt attribut
          </DefaultTextButton>
        </Box>
      </ListTitle>

      {dataAttributes.map((dataAttribute, i) => {
        return (
          <ListItem key={i} noPointer>
            <ListItemTitle>
              <ListItemText>{dataAttribute.name}</ListItemText>
              <DataAttributeSettings>
                {dataAttribute.isPartOfId && <DataAttributeSetting>Del av id</DataAttributeSetting>}
                {dataAttribute.isPartOfName && <DataAttributeSetting>Del av namn</DataAttributeSetting>}
                {dataAttribute.isRequired && <DataAttributeSetting>Obligatorisk</DataAttributeSetting>}
                {dataAttribute.isScannable && <DataAttributeSetting>Kan scannas</DataAttributeSetting>}
                {dataAttribute.isPublic && <DataAttributeSetting>Publik</DataAttributeSetting>}
                {dataAttribute.isUnique && <DataAttributeSetting>Unikt</DataAttributeSetting>}
              </DataAttributeSettings>
            </ListItemTitle>
            <ListItemActions>
              <IconButton disabled={i === 0} onClick={(e) => onDataAttributeOrderClick(e, dataAttribute.id, 'up')}>
                <ChevronUp size={20} />
              </IconButton>
              <IconButton
                disabled={i === dataAttributes.length - 1}
                onClick={(e) => onDataAttributeOrderClick(e, dataAttribute.id, 'down')}
              >
                <ChevronDown size={20} />
              </IconButton>
              <IconButton onClick={(event) => onEditClick(event, dataAttribute)}>
                <Edit size={20} />
              </IconButton>
              {dataAttribute.isUsedInTemplate ? (
                <Tooltip title="Kan inte raderas då den används i en kategori">
                  <IconButton>
                    <Trash2 color="rgba(0,0,0,.26)" size={20} />
                  </IconButton>
                </Tooltip>
              ) : (
                <IconButton onClick={(event) => onDeleteClick(event, dataAttribute)}>
                  <Trash2 size={20} />
                </IconButton>
              )}
            </ListItemActions>
          </ListItem>
        );
      })}
    </DefaultCard>
  );
};

import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { DefaultButton, DefaultTextButton, ListDialogContent } from '../../../layout';
import { UserDto } from '../../../shared/types/user';
import { UserForm } from './UserForm';

type NewUserDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (person: UserDto | null) => void;
};

export const NewUserDialog = ({ isOpen, onClose, onSave }: NewUserDialogProps) => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [user, setUser] = useState<UserDto>({} as UserDto);

  const requiredFields: string[] = [];

  const validate = () => {
    const errors = requiredFields.filter((field) => !field);
    setIsValid(errors.length === 0);
  };

  useEffect(() => {
    validate();
  }, [user]);

  const onSaveButtonClick = () => {
    if (!isValid) {
      return;
    }
    onSave(user);
  };
  return (
    <>
      <Dialog open={isOpen} maxWidth="sm" onClose={onClose} fullWidth={true}>
        <DialogTitle>Ny användare</DialogTitle>
        <ListDialogContent>
          <UserForm user={user} setUser={setUser} />
        </ListDialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton disabled={!isValid} onClick={onSaveButtonClick}>
            Lägg till användare
          </DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { Container, Typography } from '@mui/material';
import { DefaultLinkButton, Divider, PageContent, PageText } from '../../layout';
import { paths } from '../../navigation/paths';

export const ConfirmOfOwnershipCompletePage = () => {
  return (
    <>
      <Container>
        <PageContent>
          <PageText>
            <Typography variant="h5" gutterBottom>
              Ägarbytet är godkänt
            </Typography>

            <Typography variant="h3" gutterBottom>
              Vad händer nu?
            </Typography>

            <Typography variant="body1" gutterBottom>
              Det registretade objektet är nu överfört till dig. Du kan nu använda det som du vill.
            </Typography>
            <Typography variant="body2">
              Om du har några frågor är du välkommen att kontakta oss på{' '}
              <a href="mailto:info@chassikollen.se">info@chassikollen.se</a>
            </Typography>
            <Divider />
            <DefaultLinkButton to={paths.myRegistrations.path}>
              Tillbaka till {paths.myRegistrations.name}
            </DefaultLinkButton>
          </PageText>
        </PageContent>
      </Container>
    </>
  );
};

import { Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';
import { DefaultCard, FormInputWrapper, FormWrapper, ListTitle } from '../../layout';
import { DataAttributeTypes, DataAttributeValueDto } from '../../shared/types/protected/data-attribute';
import { useDataAttributeGroupsQuery } from '../../api/protected/data-attribute';
import QRCode from 'react-qr-code';

type RegistrationFormProps = {
  dataAttributeValues: DataAttributeValueDto[];
  setDataAttributeValues: (dataAttributeValues: DataAttributeValueDto[]) => void;
  ckNumber?: string;
  setCkNumber?: (ckNumber: string) => void;
  dataAttributeIdsWithErrors?: number[];
};

export const RegistrationForm = ({
  dataAttributeValues,
  setDataAttributeValues,
  ckNumber,
  setCkNumber,
  dataAttributeIdsWithErrors,
}: RegistrationFormProps) => {
  const updateDataAttributeValue = (id: number | undefined, value: string | number | boolean | null) => {
    const dataAttributeValue = dataAttributeValues.find((d) => d.dataAttributeId === id);
    if (dataAttributeValue) {
      dataAttributeValue.value = value;
      setDataAttributeValues([...dataAttributeValues]);
    }
  };

  const dataAttributeGroups = useDataAttributeGroupsQuery(true);

  return (
    <>
      <FormWrapper>
        <Grid container spacing={2}>
          {setCkNumber && (
            <>
              <Grid item xs={6}>
                <FormInputWrapper>
                  <TextField
                    label="CK-nummer"
                    value={ckNumber}
                    onChange={(e) => setCkNumber(e.target.value.toUpperCase())}
                    variant="outlined"
                    fullWidth
                    required
                    //style={{ textTransform: 'uppercase' }}
                  />
                </FormInputWrapper>
              </Grid>
              {ckNumber && ckNumber.length === 7 && (
                <Grid item xs={6}>
                  <QRCode value={'https://www.chassikollen.se/regnr/' + ckNumber} />
                </Grid>
              )}
            </>
          )}

          {dataAttributeGroups.data &&
            dataAttributeGroups.data.map((dataAttributeGroup, i) => {
              const dataAttributesInGroup = dataAttributeValues.filter(
                (d) => d.dataAttributeGroupId === dataAttributeGroup.id
              );

              if (dataAttributesInGroup.length === 0) {
                return null;
              }
              return (
                <Grid item xs={12} key={i}>
                  <DefaultCard>
                    <ListTitle>{dataAttributeGroup.name}</ListTitle>
                    {dataAttributeValues
                      .filter((d) => d.dataAttributeGroupId === dataAttributeGroup.id)
                      .map((dataAttributeValue, j) => {
                        const isError = dataAttributeIdsWithErrors?.includes(dataAttributeValue.dataAttributeId);
                        return (
                          <FormInputWrapper key={j}>
                            {dataAttributeValue.type === DataAttributeTypes.STRING && (
                              <>
                                <TextField
                                  size="small"
                                  value={dataAttributeValue.value}
                                  onChange={(e) =>
                                    updateDataAttributeValue(dataAttributeValue.dataAttributeId, e.target.value)
                                  }
                                  label={dataAttributeValue.name}
                                  variant="outlined"
                                  required={dataAttributeValue.isRequired}
                                  fullWidth
                                  error={isError}
                                  helperText={isError && 'Fältet är obligatoriskt'}
                                />
                              </>
                            )}

                            {dataAttributeValue.type === DataAttributeTypes.INT_32 && (
                              <>
                                <TextField
                                  size="small"
                                  value={dataAttributeValue.value}
                                  onChange={(e) =>
                                    updateDataAttributeValue(dataAttributeValue.dataAttributeId, e.target.value)
                                  }
                                  label={dataAttributeValue.name}
                                  variant="outlined"
                                  required={dataAttributeValue.isRequired}
                                  type="number"
                                  fullWidth
                                  error={isError}
                                  helperText={isError && 'Fältet är obligatoriskt'}
                                />
                              </>
                            )}

                            {dataAttributeValue.type === DataAttributeTypes.BOOLEAN && (
                              <>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={(e) =>
                                          updateDataAttributeValue(
                                            dataAttributeValue.dataAttributeId,
                                            e.target.checked ? '1' : '0'
                                          )
                                        }
                                        checked={dataAttributeValue.value === '1' ? true : false}
                                      />
                                    }
                                    label={dataAttributeValue.name}
                                  />
                                </FormGroup>
                              </>
                            )}
                          </FormInputWrapper>
                        );
                      })}
                  </DefaultCard>
                </Grid>
              );
            })}
        </Grid>
      </FormWrapper>
    </>
  );
};

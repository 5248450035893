import { Dialog, DialogActions, DialogTitle, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  getDataAtrributesFromApiAsync,
  getDataAttributeGroupsFromApiAsync,
  postDataAttributeToApiAsync,
} from '../../../api/admin';
import { DefaultButton, DefaultTextButton, FormInputWrapper, ListDialogContent } from '../../../layout';
import { CategoryDto } from '../../../shared/types/admin/category';
import {
  DataAttributeDto,
  DataAttributeGroupDto,
  DataAttributeTemplateDto,
} from '../../../shared/types/admin/data-attribute';
import { EditDataAttributeTemplates } from './EditAttributeTemplates';

type AddCategoryDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (category: CategoryDto | null) => void;
  parentCategoryId: number | null;
  parentCategoryName: string | null;
};

export const AddCategoryDialog = ({
  isOpen,
  onClose,
  onSave,
  parentCategoryId,
  parentCategoryName,
}: AddCategoryDialogProps) => {
  const [name, setName] = useState('');
  const [nameInputRef, setNameInputRef] = useState<HTMLInputElement | null>(null);
  const [dataAttributes, setDataAttributes] = useState<DataAttributeDto[]>([]);
  const [dataAttributeGroups, setDataAttributeGroups] = useState<DataAttributeGroupDto[]>([]);
  const [dataAttributeTemplatesInCategory, setDataAttributeTemplatesInCategory] = useState<DataAttributeTemplateDto[]>(
    []
  );

  useEffect(() => {
    if (nameInputRef) {
      nameInputRef.focus();
    }
  }, [nameInputRef]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setDataAttributeTemplatesInCategory([]);
    setName('');

    const fetchData = async () => {
      const dataAttributes = await getDataAtrributesFromApiAsync();
      if (!dataAttributes.data) {
        return;
      }

      const sortedDataAttributes = dataAttributes.data.sort((a, b) => a.order - b.order);
      setDataAttributes(sortedDataAttributes);

      const dataAttributeGroups = await getDataAttributeGroupsFromApiAsync();
      if (!dataAttributeGroups.data) {
        return;
      }
      const sortedDataAttributeGroups = dataAttributeGroups.data.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
      setDataAttributeGroups(sortedDataAttributeGroups);
    };

    fetchData();
  }, [isOpen]);

  const onSaveClick = () => {
    const newCategory: CategoryDto = {
      id: 0,
      parentId: parentCategoryId || null,
      name,
      dataAttributeTemplates: dataAttributeTemplatesInCategory,
    };

    onSave(newCategory);
  };

  const onSaveNewDataAttribute = async (newDataAttribute: DataAttributeDto) => {
    await postDataAttributeToApiAsync(newDataAttribute);

    const dataAttributes = await getDataAtrributesFromApiAsync();
    if (!dataAttributes.data) {
      return;
    }

    const sortedDataAttributes = dataAttributes.data.sort((a, b) => a.order - b.order);
    setDataAttributes(sortedDataAttributes);
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={onClose}>
        <DialogTitle>
          {parentCategoryName && `Skapa en ny underkategori till ${parentCategoryName}`}
          {!parentCategoryName && `Skapa en ny huvudkategori`}
        </DialogTitle>
        <ListDialogContent>
          <FormInputWrapper>
            <FormControl fullWidth margin="dense">
              <InputLabel>Namn</InputLabel>
              <OutlinedInput
                value={name}
                label="Name"
                onChange={(e) => setName(e.target.value)}
                autoFocus
                inputRef={(ref) => setNameInputRef(ref)}
              />
            </FormControl>
          </FormInputWrapper>
          <EditDataAttributeTemplates
            categoryId={0}
            dataAttributes={dataAttributes}
            dataAttributeGroups={dataAttributeGroups}
            dataAttributeTemplatesInCategory={dataAttributeTemplatesInCategory}
            setDataAttributeTemplatesInCategory={setDataAttributeTemplatesInCategory}
            onSaveNewDataAttribute={onSaveNewDataAttribute}
          />
        </ListDialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton onClick={onSaveClick}>Spara</DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useState } from 'react';
import { useUser } from '../../context/UserProvider';
import { DefaultButton, DefaultTextButton } from '../../layout';
import { RegistrationDetailsDto } from '../../shared/types/registration';
import { TheftReportDto } from '../../shared/types/theft-report';

type NewTheftReportDialogProps = {
  registrationDetails?: RegistrationDetailsDto | null;
  open: boolean;
  onClose: () => void;
  onSubmit: (theftReport: TheftReportDto) => void;
};

export const NewTheftReportDialog = ({ registrationDetails, open, onClose, onSubmit }: NewTheftReportDialogProps) => {
  const { userProfile } = useUser();
  const [description, setDescription] = useState<string>('');

  const onSubmitButtonClick = () => {
    if (registrationDetails) {
      onSubmit({
        id: 0,
        registrationId: registrationDetails.id,
        description,
        rapporteurId: userProfile?.id || 0,
      });
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Stöldanmälan av {registrationDetails?.name}</DialogTitle>
        <DialogContent>
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="dense"
            rows={3}
            multiline
            fullWidth
            label="En kort beskrivning av stölden"
          />
        </DialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton disabled={description === ''} onClick={onSubmitButtonClick}>
            Anmäl
          </DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

import styled from '@emotion/styled';
import { KeyboardEvent } from 'react';

import { useRef, useState } from 'react';
import { Search, X } from 'react-feather';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from '../../navigation/paths';
import { QrScannerButton } from '../qr-scanner/QrScannerButton';

const InputWrapper = styled.div<{ dense?: boolean }>`
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 8px;
  width: 100%;
  max-width: 100%;
  align-items: center;
  background-color: #fff;

  ${({ dense }) =>
    dense &&
    `
    margin-bottom: 0;
  `}
`;

const Input = styled.input<{ dense?: boolean }>`
  border: none;
  outline: none;
  flex: 1;
  font-size: 16px;
  padding: 8px;
  width: 100%;

  ${({ dense }) =>
    dense &&
    `
    font-size: 14px;
    padding: 0 8px;
  `}
`;

type SearchRegistrationProps = {
  dense?: boolean;
};

export const SearchRegistration = ({ dense }: SearchRegistrationProps) => {
  const [searchParams] = useSearchParams();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchQuery, setSearchQuery] = useState(searchParams.get('q') || '');

  const navigate = useNavigate();

  const resetSearchQuery = () => {
    setSearchQuery('');
    searchInputRef.current?.focus();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      submitSearchQuery();
    }
  };

  const submitSearchQuery = async () => {
    if (searchQuery) {
      const trimmedValue = searchQuery.trim();
      navigate(`${paths.search.path}?q=${trimmedValue}`);
    }
  };

  const scanComplete = (value: string) => {
    setSearchQuery(value);
    if (value) {
      const trimmedValue = value.trim();
      navigate(`${paths.search.path}?q=${trimmedValue}`);
    }
  };

  return (
    <InputWrapper dense={dense}>
      <Search />
      <Input
        ref={searchInputRef}
        autoFocus
        value={searchQuery}
        onKeyDown={handleKeyDown}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Sök chassinummer, reg nr, med mera"
        dense={dense}
      />
      <QrScannerButton onResult={(value) => scanComplete(value)} />
      {searchQuery && <X onClick={resetSearchQuery} />}
    </InputWrapper>
  );
};

import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiRequest2 } from '../api-request';
import { InsuranceDto } from '../../shared/types/insurance';

const insuranceKeys = {
  all: ['insurances'],
  byInsurer: (insurerId: number, searchTerm: string | undefined) => [
    ...insuranceKeys.all,
    'byInsurer',
    insurerId,
    searchTerm,
  ],
  count: (insurerId: number) => [...insuranceKeys.all, 'count', insurerId],
};

export const useInsurancesByInsurerQuery = (insurerId: number, searchTerm: string) =>
  useQuery({
    queryKey: insuranceKeys.byInsurer(insurerId, searchTerm),
    queryFn: async () => {
      const response = await apiRequest2<InsuranceDto[]>({
        action: `Protected/Insurances/Insurer/${insurerId}`,
        method: 'GET',
        allowAnonymous: false,
        params: { searchTerm: searchTerm },
      });

      return response.data;
    },
    placeholderData: keepPreviousData,
  });

export const useInsuranceCountQuery = (insurerId: number) =>
  useQuery({
    queryKey: insuranceKeys.count(insurerId),
    queryFn: async () => {
      const response = await apiRequest2<number>({
        action: `Protected/Insurances/Count/Insurer/` + insurerId,
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data;
    },
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60 * 10,
    gcTime: 1000 * 60 * 10,
  });

export const useResolveInsuranceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (insuranceId: number) =>
      apiRequest2<void>({
        action: `Protected/Insurances/Resolve/${insuranceId}`,
        method: 'PUT',
        allowAnonymous: false,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: insuranceKeys.all,
      });
    },
  });
};

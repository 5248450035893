import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiRequest2 } from '../api-request';
import { FinancingActionType, FinancingListItemDto, FinancingUpdateDto } from './../../shared/types/financing';

const getActionRequiredText = (type: FinancingActionType) => {
  switch (type) {
    case FinancingActionType.Resolve:
      return 'Väntar på att lösas';
  }
};

const isInFilter = (financing: FinancingListItemDto, filter: string) => {
  if (!filter) {
    return true;
  }

  const filterLower = filter.toLowerCase();
  const financier = financing.financier.toLowerCase();
  const externalId = financing.externalId.toLowerCase();
  const registrations = financing.registrations
    .map((r) => r)
    .join(',')
    .toLowerCase();

  return financier.includes(filterLower) || externalId.includes(filterLower) || registrations.includes(filterLower);
};

const getConvertedFinancings = (financings: FinancingListItemDto[]) => {
  return (
    financings
      //.filter((financing) => financing.registrations.length > 0 && financing.actionRequired !== FinancingActionType.None)
      .map((financing) => {
        return {
          ...financing,
          registrationsText: financing.registrations.map((r) => r.name).join(', '),
          actionRequiredText: getActionRequiredText(financing.actionRequired),
        };
      })
  );
};

export const useFinancingsQuery = (filter: string, actionRequired: boolean) => {
  return useQuery({
    queryKey: ['financings', actionRequired, filter],
    queryFn: async () => {
      const response = await apiRequest2<FinancingListItemDto[]>({
        action: `Admin/Financings`,
        method: 'GET',
        allowAnonymous: false,
        params: { actionRequired: actionRequired },
      });

      return response.data;
    },
    select: (data: FinancingListItemDto[]) => {
      const financings = getConvertedFinancings(data);
      if (!filter || filter.length === 0) {
        return financings;
      }
      return financings.filter((financing) => isInFilter(financing, filter));
    },
  });
};

export const useUpdateFinancingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (financingUpdate: FinancingUpdateDto) => {
      const response = await apiRequest2<void, FinancingUpdateDto>({
        action: `Admin/Financings/${financingUpdate.id}`,
        method: 'PUT',
        body: financingUpdate,
        allowAnonymous: false,
      });

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['financings'] });
    },
    onError: (error) => {
      console.log('error', error);
    },
  });
};

import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { useUser } from '../../../../context/UserProvider';
import { DefaultButton, DefaultTextButton, ListDialogContent } from '../../../../layout';
import { PersonDto } from '../../../../shared/types/owner';
import { PersonForm } from './PersonForm';

type AddPersonDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (person: PersonDto | null) => void;
};

export const AddPersonDialog = ({ isOpen, onClose, onSave }: AddPersonDialogProps) => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [person, setPerson] = useState<PersonDto>({} as PersonDto);

  const { userProfile } = useUser();

  useEffect(() => {
    if (userProfile && isOpen) {
      setPerson({
        userOwners: [],
      });
    }
  }, [userProfile, isOpen]);

  const requiredFields = [person.socialSecurityNumber, person.givenName, person.familyName, person.userOwners];

  const validate = () => {
    const errors = requiredFields.filter((field) => !field);
    setIsValid(errors.length === 0);
  };

  useEffect(() => {
    validate();
  }, [person]);

  const onSaveButtonClick = () => {
    if (!isValid) {
      return;
    }
    onSave(person);
  };
  return (
    <>
      <Dialog open={isOpen} maxWidth="sm" onClose={onClose} fullWidth={true}>
        <DialogTitle>Ny person</DialogTitle>
        <ListDialogContent>
          <PersonForm person={person} setPerson={setPerson} />
        </ListDialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton disabled={!isValid} onClick={onSaveButtonClick}>
            Lägg till person
          </DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getSortedDetails } from '../../shared/sort/sort-registration-details';
import { PostRegistrationDto, RegistrationDetailsDto, RegistrationDto } from '../../shared/types/registration';
import { apiRequest } from '../api-request';
import { apiRequest2 } from './../api-request';
import { useUser } from '../../context/UserProvider';

const registrationKeys = {
  all: ['registrations'],
  confirmedRegistrationsByOwner: (ownerId: number) => [...registrationKeys.all, 'ConfirmedRegistrations', ownerId],
  changeOfOwnershipRegistrationsByOwner: (ownerId: number) => [
    ...registrationKeys.all,
    'ChangeOfOwnershipRegistrations',
    ownerId,
  ],
  detail: (registrationId: number | null) => [...registrationKeys.all, 'detail', registrationId],
};

export const useChangeOfOwnershipRegistrationsByOwnerQuery = () => {
  const { selectedOwner } = useUser();

  return useQuery({
    queryKey: registrationKeys.changeOfOwnershipRegistrationsByOwner(selectedOwner?.ownerId ?? 0),
    queryFn: async () => {
      if (!selectedOwner?.ownerId) return Promise.resolve([]);

      const response = await apiRequest2<RegistrationDto[]>({
        action: `Protected/Owner/${selectedOwner?.ownerId ?? 0}/ChangeOfOwnershipRegistrations`,
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data;
    },
  });
};

export const useConfirmedRegistrationsByOwnerQuery = () => {
  const { selectedOwner } = useUser();

  return useQuery({
    queryKey: registrationKeys.confirmedRegistrationsByOwner(selectedOwner?.ownerId ?? 0),
    queryFn: async () => {
      if (!selectedOwner?.ownerId) return Promise.resolve([]);

      const response = await apiRequest2<RegistrationDto[]>({
        action: `Protected/Owner/${selectedOwner?.ownerId ?? 0}/ConfirmedRegistrations`,
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data;
    },
  });
};

export const useAddRegistrationCreatedByOwnerMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (registration: PostRegistrationDto) => addRegistrationCreatedByOwnerAsync(registration),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: registrationKeys.all });
    },
    onError: (error) => {
      console.log('error', error);
    },
  });
};

export const addRegistrationChildrenAsync = async (registrationId: number, childIds: number[]) => {
  const response = await apiRequest<void>({
    action: `Protected/Registrations/AddChildren/${registrationId}`,
    method: 'PUT',
    body: JSON.stringify(childIds),
    allowAnonymous: false,
  });

  return response;
};

export const removeRegistrationParentAsync = async (registrationId: number) => {
  const response = await apiRequest<void>({
    action: `Protected/Registrations/RemoveParent/${registrationId}`,
    method: 'PUT',
    allowAnonymous: false,
  });

  return response;
};

export const useProtectedRegistrationDetailsQuery = (registrationDetailsId: number | null) =>
  useQuery({
    queryKey: ['registrationDetails', registrationDetailsId],
    queryFn: async () => {
      const response = await apiRequest<RegistrationDetailsDto>({
        action: `Protected/RegistrationDetails/${registrationDetailsId}`,
        method: 'GET',
        allowAnonymous: false,
      });

      return getSortedDetails(response.data);
    },
    enabled: !!registrationDetailsId,
  });

export const getRegistrationsByOwnerIdFromApiAsync = async (ownerId: number) => {
  const response = await apiRequest2<RegistrationDto[]>({
    action: `Protected/Owner/${ownerId}/Registrations`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response.data;
};

export const getOwnerRegistrationDetailsFromApiAsync = async (ownerId: number, id: number) => {
  const response = await apiRequest2<RegistrationDetailsDto>({
    action: `Protected/Owner/${ownerId}/RegistrationDetails/${id}`,
    method: 'GET',
    allowAnonymous: false,
  });

  return getSortedDetails(response.data);
};

export const addRegistrationCreatedByOwnerAsync = async (registration: PostRegistrationDto) => {
  const response = await apiRequest2<RegistrationDto, PostRegistrationDto>({
    action: 'Protected/Registrations',
    method: 'POST',
    body: registration,
    allowAnonymous: false,
  });

  return response;
};

export const useRegistrationQuery = (registrationId: number | null) =>
  useQuery({
    queryKey: registrationKeys.detail(registrationId),
    queryFn: async () => {
      const response = await apiRequest2<RegistrationDto>({
        action: `Protected/Registrations/${registrationId}`,
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data;
    },
    enabled: !!registrationId,
  });

export const updateRegistrationCreatedByOwnerAsync = async (registration: PostRegistrationDto) => {
  const response = await apiRequest2<void, PostRegistrationDto>({
    action: `Protected/Registrations/${registration.id}`,
    method: 'PUT',
    body: registration,
    allowAnonymous: false,
  });

  return response.data;
};

import { TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormInputWrapper, FormWrapper } from '../../../../layout';
import { PersonDto } from '../../../../shared/types/owner';
import { UserInfoDto, UserOwnerDto } from '../../../../shared/types/user';
import { UserSelect } from '../UserSelect';
import Personnummer from 'personnummer';

type PersonFormProps = {
  person: PersonDto | null;
  setPerson: (person: PersonDto) => void;
  isEditing?: boolean;
};

export const PersonForm = ({ person, setPerson, isEditing }: PersonFormProps) => {
  const [socialSecurityNumberInputRef, setSocialSecurityNumberInputRef] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    if (socialSecurityNumberInputRef) {
      socialSecurityNumberInputRef.focus();
    }
  }, [socialSecurityNumberInputRef]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!person) {
      return;
    }
    const { name, value } = event.target;
    setPerson({ ...person, [name]: value });
  };

  const onIsRegistrarChange = (userId: number, isRegistrar: boolean) => {
    if (!person) {
      return;
    }
    const userOwner = person.userOwners?.find((uo) => uo.userId === userId);

    if (userOwner) {
      userOwner.isRegistrar = isRegistrar;
      setPerson({ ...person });
    }
  };

  const onIsSupervisorChange = (userId: number, isSupervisor: boolean) => {
    if (!person) {
      return;
    }
    const userOwner = person.userOwners?.find((uo) => uo.userId === userId);

    if (userOwner) {
      userOwner.isSupervisor = isSupervisor;
      setPerson({ ...person });
    }
  };

  const onAddUserOwner = (userInfo: UserInfoDto, isRegistrar: boolean, isSupervisor: boolean) => {
    if (!person?.id) {
      return;
    }

    if (!person.userOwners) {
      person.userOwners = [];
    }

    const userOwner: UserOwnerDto = {
      userId: userInfo.id,
      user: {
        id: userInfo.id,
        displayName: userInfo.displayName,
        socialSecurityNumber: userInfo.socialSecurityNumber,
        givenName: userInfo.givenName,
        familyName: userInfo.familyName,
      },
      ownerId: person.id,
      isRegistrar: isRegistrar,
      isSupervisor: isSupervisor,
    };

    const userOwners = [...person.userOwners, userOwner];

    setPerson({ ...person, userOwners });
  };

  const onDeleteUserOwner = (userId: number) => {
    if (!person) {
      return;
    }

    if (!person.userOwners) {
      return;
    }

    person.userOwners = person.userOwners.filter((uo) => uo.userId !== userId);

    setPerson({ ...person });
  };

  if (!person) {
    return null;
  }

  return (
    <>
      <FormWrapper>
        <>
          {!isEditing && (
            <FormInputWrapper>
              <TextField
                size="small"
                label="Personnummer"
                name="socialSecurityNumber"
                value={person.socialSecurityNumber || ''}
                onChange={onInputChange}
                variant="outlined"
                required
                fullWidth
                autoFocus
                inputRef={(ref) => setSocialSecurityNumberInputRef(ref)}
                error={person.socialSecurityNumber ? !Personnummer.valid(person.socialSecurityNumber) : false}
              />
            </FormInputWrapper>
          )}

          <FormInputWrapper>
            <TextField
              size="small"
              label="Förnamn"
              name="givenName"
              value={person.givenName || ''}
              onChange={onInputChange}
              variant="outlined"
              required
              fullWidth
            />
          </FormInputWrapper>

          <FormInputWrapper>
            <TextField
              size="small"
              label="Efternamn"
              name="familyName"
              value={person.familyName || ''}
              onChange={onInputChange}
              variant="outlined"
              required
              fullWidth
            />
          </FormInputWrapper>
          {/* <FormInputWrapper>
            <TextField
              size="small"
              label="LegalGroupCode"
              name="legalGroupCode"
              value={person.legalGroupCode || ''}
              onChange={onInputChange}
              variant="outlined"
              required
              fullWidth
            />
          </FormInputWrapper>
          <FormInputWrapper>
            <TextField
              size="small"
              label="LegalGroupText"
              name="legalGroupText"
              value={person.legalGroupText || ''}
              onChange={onInputChange}
              variant="outlined"
              required
              fullWidth
            />
          </FormInputWrapper> */}
        </>

        <FormInputWrapper>
          <TextField
            size="small"
            label="E-post"
            name="email"
            value={person.email || ''}
            onChange={onInputChange}
            variant="outlined"
            fullWidth
          />
        </FormInputWrapper>

        <FormInputWrapper>
          <TextField
            size="small"
            label="Telefon"
            name="mobilePhone"
            value={person.mobilePhone || ''}
            onChange={onInputChange}
            variant="outlined"
            fullWidth
          />
        </FormInputWrapper>

        {person.id && (
          <Typography variant="subtitle1" gutterBottom>
            Användare
            <UserSelect
              ownerId={person.id}
              userOwners={person.userOwners}
              onAddUserOwner={onAddUserOwner}
              onDeleteUserOwner={onDeleteUserOwner}
              onIsRegistrarUpdate={onIsRegistrarChange}
              onIsSupervisorUpdate={onIsSupervisorChange}
            />
          </Typography>
        )}
      </FormWrapper>
    </>
  );
};

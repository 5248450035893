import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { getSearchResultFromApiAsync } from '../api';
import ReactGA from 'react-ga4';

export const useSearchResult = () => {
  const [searchParams] = useSearchParams();
  const { cknumber } = useParams();

  const term = searchParams.get('q') || cknumber || '';

  const {
    data: searchResult,
    isLoading: isSearching,
    error: searchError,
  } = useQuery({
    queryKey: ['search-result', term],
    queryFn: () => {
      const response = getSearchResultFromApiAsync(term);

      ReactGA.event('search', { search_term: term });

      return response;
    },
  });

  return {
    searchResult,
    isSearching,
    searchError,
  };
};

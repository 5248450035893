import styled from '@emotion/styled';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { DefaultCard, StolenListInfo } from '../../layout';
import { RegistrationDto } from '../../shared/types/registration';
import { getRegistrationUrl } from '../../shared/utils/registration-url';
import { colors } from '../../static/colors';

type SearchResultListItemProps = {
  registration: RegistrationDto;
};

const Wrapper = styled(DefaultCard)`
  padding: 10px;
  color: #000;
`;

const Title = styled.div`
  font-size: 1.1rem;
  text-decoration: none;
  font-weight: 700;
  margin-bottom: 10px;
`;
const Category = styled.div`
  font-size: 0.9rem;
  text-decoration: none;
  font-weight: 500;
  margin-bottom: 0px;
`;

const SubTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${colors.gray};
  font-size: 0.875rem;
  padding-bottom: 10px;
`;

const Created = styled.div`
  font-size: 0.7rem;
  text-decoration: none;
  color: ${colors.gray};
  font-weight: 500;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const SearchResultListItem = ({ registration }: SearchResultListItemProps) => {
  const url = getRegistrationUrl(registration);
  return (
    <Link to={url}>
      <Wrapper>
        {registration.theftReports.find((t) => t.active) && <StolenListInfo>ANMÄLD SOM STULEN</StolenListInfo>}

        <Header>
          <Box>
            <Category>{registration.category}</Category>
            <Title>{registration.name}</Title>
          </Box>
          <Box>
            <Created>Skapad {dayjs(registration.created).fromNow()}</Created>
          </Box>
        </Header>
        <SubTitle>CK-nummer: {registration.ckNumber}</SubTitle>
      </Wrapper>
    </Link>
  );
};

import { Navigate, PathRouteProps, Route } from 'react-router-dom';
import { Roles } from '../auth/roles';
import { useUser } from '../context/UserProvider';
import { AdminPage } from '../pages/Admin';
import { paths } from './paths';

type AdminRouteProps = {
  isAdmin: boolean;
  outlet: JSX.Element;
};

const AdminRoute = ({ isAdmin, outlet }: AdminRouteProps) => {
  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  return outlet;
};

const adminSubPages: PathRouteProps[] = [
  {
    path: paths.editDataAttributes.path,
    element: <AdminPage subPagePath={paths.editDataAttributes.path} />,
  },
  {
    path: paths.editCategories.path,
    element: <AdminPage subPagePath={paths.editCategories.path} />,
  },
  {
    path: paths.editCompanies.path,
    element: <AdminPage subPagePath={paths.editCompanies.path} />,
  },
  {
    path: paths.editRegistrations.path,
    element: <AdminPage subPagePath={paths.editRegistrations.path} />,
  },
  {
    path: paths.newRegistration.path,
    element: <AdminPage subPagePath={paths.newRegistration.path} />,
  },
  {
    path: paths.editPersons.path,
    element: <AdminPage subPagePath={paths.editPersons.path} />,
  },
  {
    path: paths.editOwners.path,
    element: <AdminPage subPagePath={paths.editOwners.path} />,
  },
  {
    path: paths.editUsers.path,
    element: <AdminPage subPagePath={paths.editUsers.path} />,
  },
  {
    path: paths.editOwnership.path,
    element: <AdminPage subPagePath={paths.editOwnership.path} />,
  },
  {
    path: paths.editFinancions.path,
    element: <AdminPage subPagePath={paths.editFinancions.path} />,
  },
  {
    path: paths.fortnox.path,
    element: <AdminPage subPagePath={paths.fortnox.path} />,
  },
];

export const AdminRoutes = () => {
  const { isAuthenticated, userProfile } = useUser();

  return (
    <>
      <Route
        path={paths.admin.path}
        element={
          <AdminRoute
            isAdmin={(isAuthenticated && userProfile?.roles.includes(Roles.Admin)) ?? false}
            outlet={<AdminPage subPagePath={paths.editRegistrations.path} />}
          />
        }
      />
      {adminSubPages.map((page, index) => (
        <Route
          key={index}
          path={page.path}
          element={
            <AdminRoute
              isAdmin={(isAuthenticated && userProfile?.roles.includes(Roles.Admin)) ?? false}
              outlet={page.element as JSX.Element}
            />
          }
        />
      ))}
    </>
  );
};

import { CategoryDto } from '../../shared/types/admin/category';
import { apiRequest } from '../api-request';

export async function getCategoriesFromApiAsync() {
  const response = await apiRequest<CategoryDto[]>({
    action: `Admin/Categories`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response;
}

export async function getCategoryFromApiAsync(categoryId: number) {
  const response = await apiRequest<CategoryDto>({
    action: `Admin/Categories/${categoryId}`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response;
}
export async function updateCategoryToApiAsync(category: CategoryDto) {
  const response = await apiRequest<CategoryDto>({
    action: `Admin/Categories/${category.id}`,
    method: 'PUT',
    body: JSON.stringify(category),
    allowAnonymous: false,
  });

  return response;
}

export async function postCategoryToApiAsync(category: CategoryDto) {
  const response = await apiRequest<CategoryDto>({
    action: `Admin/Categories`,
    method: 'POST',
    body: JSON.stringify(category),
    allowAnonymous: false,
  });

  return response;
}

export async function putCategoryApiAsync(categoryId: number, category: CategoryDto) {
  const response = await apiRequest<CategoryDto>({
    action: `Admin/Categories/${categoryId}`,
    method: 'PUT',
    body: JSON.stringify(category),
    allowAnonymous: false,
  });

  return response;
}

export async function deleteCategoryApiAsync(categoryId: number) {
  await apiRequest({
    action: `Admin/Categories/${categoryId}`,
    method: 'DELETE',
    allowAnonymous: false,
  });
}

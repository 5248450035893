import { Avatar, Box, CardActionArea, Container, Grid, IconButton, Typography } from '@mui/material';
import { PageWrapper, PageContent, PageTitle, DefaultCard } from '../../layout';
import { BreadCrumbs } from '../../navigation/BreadCrumbs';
import { useOwnerListQuery } from '../../api';
import { stringAvatar } from '../../shared/utils/avatar';
import { Edit } from 'react-feather';
import { OwnerListItemDto, OwnerTypeDto } from '../../shared/types/owner';
import { useState } from 'react';
import { CompanyEditDialog } from './companies/CompanyEditDialog';
import { useUser } from '../../context/UserProvider';
import { PersonEditDialog } from './persons/PersonEditDialog';

export const MyOwnersPage = () => {
  const [personIdToEdit, setPersonIdToEdit] = useState<number | null>(null);
  const [companyIdToEdit, setCompanyIdToEdit] = useState<number | null>(null);

  const ownerListQuery = useOwnerListQuery();

  function onEditOwnerClick(owner: OwnerListItemDto): void {
    switch (owner.type) {
      case OwnerTypeDto.Person:
        setPersonIdToEdit(owner.id);
        break;
      case OwnerTypeDto.Company:
        setCompanyIdToEdit(owner.id);
        break;
      default:
        //TODO: implement
        break;
    }
  }

  const { selectedOwner, updateSelectedOwner, userProfile } = useUser();

  const updateSelectedUserOwnerClick = async (ownerId: number | undefined) => {
    const userOwner = userProfile?.userOwners.find((userOwner) => userOwner.ownerId === ownerId);

    if (!userOwner) {
      return;
    }

    updateSelectedOwner(userOwner);
  };

  return (
    <>
      {personIdToEdit && <PersonEditDialog personId={personIdToEdit} onClose={() => setPersonIdToEdit(null)} />}
      {companyIdToEdit && <CompanyEditDialog companyId={companyIdToEdit} onClose={() => setCompanyIdToEdit(null)} />}

      <Container>
        <PageWrapper>
          <BreadCrumbs />
          <PageContent>
            <PageTitle>Mina ägare</PageTitle>
            <Typography variant="body1" gutterBottom>
              Här kan du se vilka ägare du kan företräda. Om du inte har behörighet att företräda en ägare kan du be
              någon som har det att lägga till dig. För företag får du automatiskt behörighet om du är firmatecknare
              eller styrelseledamot.
            </Typography>
            {ownerListQuery.data && (
              <Grid container spacing={2} marginTop={1}>
                {ownerListQuery.data?.map((owner) => (
                  <Grid key={owner.id} item xs={12} lg={6}>
                    <DefaultCard style={{ height: '100%' }} selected={selectedOwner?.ownerId === owner.id}>
                      <Grid container spacing={2}>
                        <Grid item xs="auto">
                          <CardActionArea onClick={() => updateSelectedUserOwnerClick(owner.id)}>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                              <Avatar {...stringAvatar(owner.fullname)} />
                            </Box>
                          </CardActionArea>
                        </Grid>
                        <Grid item xs>
                          <CardActionArea onClick={() => updateSelectedUserOwnerClick(owner.id)}>
                            <Typography variant="h6">
                              {owner.id === userProfile?.ownerId ? 'Dig själv' : owner?.fullname}
                            </Typography>
                          </CardActionArea>
                        </Grid>
                        {owner.isSupervisor && (
                          <Grid item xs="auto">
                            <Box display="flex" justifyContent="flex-end">
                              <IconButton size="small" onClick={() => onEditOwnerClick(owner)}>
                                <Edit />
                              </IconButton>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </DefaultCard>
                  </Grid>
                ))}
              </Grid>
            )}
          </PageContent>
        </PageWrapper>
      </Container>
    </>
  );
};

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { MouseEvent, useEffect, useState } from 'react';
import { ExternalLink } from 'react-feather';
import { Link } from 'react-router-dom';
import {
  DefaultButton,
  DefaultCard,
  Divider,
  ListHeader,
  ListHeaderText,
  ListItem,
  ListItemActions,
  ListItemText,
} from '../../../layout';
import { FinancingActionType, FinancingListItemDto } from '../../../shared/types/financing';
import { getRegistrationUrl } from '../../../shared/utils/registration-url';

type FinancingListProps = {
  financings: FinancingListItemDto[];
  onResolve: (financingId: number) => void;
  onEdit: (financingId: number) => void;
};

const getFinancingsGroupedByRegistration = (financings: FinancingListItemDto[]) => {
  const groupedFinancings: FinancingListItemDto[][] = [];

  financings.forEach((financing) => {
    const mainRegistrationId = financing.registrations.find((r) => r.parentId === null)?.id;

    const existingGroup = groupedFinancings.find(
      (group) => group[0].registrations.find((r) => r.parentId === null)?.id === mainRegistrationId
    );

    if (existingGroup) {
      existingGroup.push(financing);
    } else {
      groupedFinancings.push([financing]);
    }
  });

  return groupedFinancings;
};

export const FinancingList = ({ financings, onResolve, onEdit }: FinancingListProps) => {
  const [groupedFinancings, setGroupedFinancings] = useState<FinancingListItemDto[][]>([financings]);

  useEffect(() => {
    const groupedFinancings = getFinancingsGroupedByRegistration(financings);
    setGroupedFinancings(groupedFinancings);
  }, [financings]);

  const onResolveClick = (event: MouseEvent<HTMLButtonElement>, financingId: number) => {
    event.stopPropagation();
    onResolve(financingId);
  };

  if (!groupedFinancings.length) {
    return <Typography variant="h6">Inga registreringar behöver åtgärd just nu. </Typography>;
  }

  return (
    <>
      {groupedFinancings.map((group, i) => {
        const mainRegistration = group[0]?.registrations.find((r) => r.parentId === null);
        const registrationUrl = getRegistrationUrl(mainRegistration);

        return (
          <Box key={i}>
            <DefaultCard>
              <Typography variant="subtitle1">{mainRegistration?.category}</Typography>
              <Typography variant="h6" gutterBottom>
                {mainRegistration?.name}{' '}
                <Link target="_blank" to={registrationUrl}>
                  <ExternalLink size={20} />
                </Link>
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="caption">Underregistreringar</Typography>
                  <Typography variant="subtitle2">
                    {group[0]?.registrations
                      .filter((r) => r.parentId !== null)
                      ?.map((r) => r.name)
                      .join(', ') || 'Inga underregistreringar'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">Nuvarande ägare</Typography>
                  <Typography variant="subtitle2">{group[0]?.registrations[0]?.currentOwner}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">Blivande ägare</Typography>
                  <Typography variant="subtitle2">{group[0]?.registrations[0]?.nextOwner}</Typography>
                </Grid>
              </Grid>

              <Divider />

              <ListHeader>
                {/* <ListHeaderLabel
                  sortBy={function (key: string, desc: boolean): void {
                    throw new Error('Function not implemented.');
                  }}
                  label={'Kreditgivare'}
                  sortKey={''}
                  isActive={false}
                ></ListHeaderLabel>
                <ListHeaderLabel
                  sortBy={function (key: string, desc: boolean): void {
                    throw new Error('Function not implemented.');
                  }}
                  label={'Kontraktsnummer'}
                  sortKey={''}
                  isActive={false}
                ></ListHeaderLabel>
                <ListHeaderLabel
                  sortBy={function (key: string, desc: boolean): void {
                    throw new Error('Function not implemented.');
                  }}
                  label={'Status'}
                  sortKey={''}
                  isActive={false}
                ></ListHeaderLabel> */}

                <ListHeaderText>Kreditgivare</ListHeaderText>
                <ListHeaderText>Låntagare</ListHeaderText>
                <ListHeaderText>Kontraktsnummer</ListHeaderText>
                <ListHeaderText>Status</ListHeaderText>
                <ListItemActions></ListItemActions>
              </ListHeader>

              {group.map((financing) => {
                return (
                  <ListItem key={financing.id} onClick={() => onEdit(financing.id)}>
                    <ListItemText>{financing.financier}</ListItemText>
                    <ListItemText>{financing.borrower}</ListItemText>
                    <ListItemText>{financing.externalId}</ListItemText>
                    <ListItemText>{financing.actionRequiredText}</ListItemText>
                    <ListItemActions>
                      {financing.actionRequired === FinancingActionType.Resolve && (
                        <DefaultButton onClick={(e) => onResolveClick(e, financing.id)} size="small">
                          Lös
                        </DefaultButton>
                      )}
                    </ListItemActions>
                  </ListItem>
                );
              })}
            </DefaultCard>
            <Divider />
          </Box>
        );
      })}

      {/* <DefaultCard>
        <ListHeader>
          <ListHeaderLabel
            sortBy={sortBy}
            label="Kreditgivare"
            sortKey="financier"
            isActive={currentSortKey === 'financier'}
          />
          <ListHeaderLabel
            sortBy={sortBy}
            label="Kontraktsnummer"
            sortKey="externalId"
            isActive={currentSortKey === 'externalId'}
          />

          <ListHeaderLabel
            sortBy={sortBy}
            label="Registreringar"
            sortKey="registrationsText"
            isActive={currentSortKey === 'registrationsText'}
          />
          <ListHeaderLabel
            sortBy={sortBy}
            label="Status"
            sortKey="actionRequiredText"
            isActive={currentSortKey === 'actionRequiredText'}
          />
          <ListHeaderText></ListHeaderText>
        </ListHeader>
        {sortedFinancings.map((financing) => {
          return (
            <ListItem key={financing.id} onClick={() => onEditClick(financing)}>
              <ListItemText>
                {financing.financier} {financing.actionRequired === FinancingActionType.Confirm && 'blivande'}
              </ListItemText>
              <ListItemText>{financing.externalId}</ListItemText>
              <ListItemText>{financing.registrationsText}</ListItemText>
              <ListItemText>{financing.actionRequiredText}</ListItemText>
              <ListItemActions>
                {financing.actionRequired === FinancingActionType.Confirm && <DefaultButton>Godkänn</DefaultButton>}
                {financing.actionRequired === FinancingActionType.Resolve && <DefaultButton>Lös</DefaultButton>}
              </ListItemActions>
            </ListItem>
          );
        })}
      </DefaultCard> */}
    </>
  );
};

import { FortnoxAuthenticateRequest, FortnoxAuthenticateResponse, FortnoxStatus } from '../../shared/types/fortnox';
import { apiRequest } from '../api-request';
import { apiRequest2 } from './../api-request';

export async function getFortnoxAuthenticateUrl() {
  const response = await apiRequest2<string>({
    action: `Admin/Fortnox/GetAuthenticateUrl`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response.data;
}

export async function getFortnoxStatus() {
  const response = await apiRequest2<FortnoxStatus>({
    action: `Admin/Fortnox/GetStatus`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response.data;
}

export async function postFortnoxAuthenticate(authenticateRequest: FortnoxAuthenticateRequest) {
  const response = await apiRequest<FortnoxAuthenticateResponse>({
    action: `Admin/Fortnox/Authenticate`,
    method: 'POST',
    body: JSON.stringify(authenticateRequest),
    allowAnonymous: false,
  });

  return response;
}

import { Grid } from '@mui/material';
import { useState } from 'react';
import { useFinancingsQuery, useUpdateFinancingMutation } from '../../../api/admin/financings';
import { DataGridHeader, PageTitle } from '../../../layout';
import { FinancingUpdateDto } from '../../../shared/types/financing';
import { EditFinancingDialog } from './EditFinancingDialog';
import { FinancingList } from './FinancingList';

export const EditFinancions = () => {
  const { data: financings, isLoading } = useFinancingsQuery('', false);
  const [financingToEdit, setFinancingToEdit] = useState<number | null>(null);

  const { mutate: updateFinancing } = useUpdateFinancingMutation();

  const onResolve = (financingId: number) => {
    updateFinancing({
      id: financingId,
      resolvedAt: new Date().toDateString(),
    });

    console.log('resolve', financingId);
  };

  const onEdit = (financingId: number) => {
    setFinancingToEdit(financingId);
  };

  const onSave = (financingUpdate: FinancingUpdateDto) => {
    console.log('financingUpdate', financingUpdate);
    updateFinancing(financingUpdate);
    setFinancingToEdit(null);
  };

  return (
    <>
      <EditFinancingDialog
        isOpen={!!financingToEdit}
        onClose={() => setFinancingToEdit(null)}
        financingId={financingToEdit}
        onSave={onSave}
      />

      <PageTitle>Finansieringar och ägarbyten</PageTitle>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataGridHeader></DataGridHeader>
          {financings && !isLoading && (
            <>
              <FinancingList financings={financings} onResolve={onResolve} onEdit={onEdit} />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

import { TheftReportDto } from '../../shared/types/theft-report';
import { apiRequest2 } from '../api-request';

export async function addTheftReportAsync(theftReport: TheftReportDto) {
  const response = await apiRequest2<TheftReportDto>({
    action: `Protected/TheftReports`,
    method: 'POST',
    body: JSON.stringify(theftReport),
    allowAnonymous: false,
  });

  return response.data;
}
export async function updateTheftReportsAsync(theftReports: TheftReportDto[]) {
  const response = await apiRequest2<null, TheftReportDto[]>({
    action: `Protected/TheftReports`,
    method: 'PUT',
    body: theftReports,
    allowAnonymous: false,
  });

  return response.data;
}

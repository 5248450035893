import styled from '@emotion/styled';
import { Container, Divider as MuiDivider, Typography } from '@mui/material';
import { SignInHandler } from '../components/sign-in/SignInHandler';
import { PageContent } from '../layout';

const SignInHandlerWrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
`;

const Divider = styled(MuiDivider)`
  margin: 20px 0;
`;

export const Status401 = () => {
  return (
    <Container>
      <PageContent>
        <SignInHandlerWrapper>
          <Typography variant="h4">401</Typography>
          <Divider />
          <Typography variant="h5">Sidan kräver att du loggar in</Typography>

          <SignInHandler />
        </SignInHandlerWrapper>
      </PageContent>
    </Container>
  );
};

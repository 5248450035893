import styled from '@emotion/styled';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Header } from './components/header/Header';
import { Footer } from './components/main/Footer';
import { ConfigProvider } from './context/ConfigProvider';
import { UserProvider } from './context/UserProvider';
import { Navigation } from './navigation/Navigation';
import { colors } from './static/colors';
import { theme } from './theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

dayjs.extend(relativeTime);
dayjs.locale('sv');
const MainContent = styled.main`
  min-width: 300px;
  min-height: calc(100vh - 410px);

  @media (min-width: 600px) {
    padding-top: 60px;
  }

  a {
    text-decoration: none;
    color: ${colors.orange};
  }
`;

const queryClient = new QueryClient();

export const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Header />
              <MainContent>
                <Navigation />
              </MainContent>
              {!pathname.startsWith('/admin') && <Footer />}
            </LocalizationProvider>
          </UserProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
};

import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { DefaultButton, DefaultTextButton, ListDialogContent } from '../../../layout';
import { UserDto } from '../../../shared/types/user';
import { getFormatedSocialSecurityNumberNumber } from '../../../shared/utils/format-pid';
import { UserForm } from './UserForm';

type EditUserDialogProps = {
  isOpen: boolean;
  userToUpdate?: UserDto | null;
  onClose: () => void;
  onSave: (person: UserDto | null) => void;
};

export const EditUserDialog = ({ isOpen, onClose, onSave, userToUpdate }: EditUserDialogProps) => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [user, setUser] = useState<UserDto>({} as UserDto);
  const [name, setName] = useState('');

  useEffect(() => {
    if (userToUpdate) {
      setUser({ ...userToUpdate });
      setName(
        `${userToUpdate.givenName} ${userToUpdate.familyName} - ${getFormatedSocialSecurityNumberNumber(
          userToUpdate.socialSecurityNumber
        )}`
      );
    }
  }, [userToUpdate]);

  const requiredFields = [user.socialSecurityNumber];

  const validate = () => {
    const errors = requiredFields.filter((field) => !field);
    setIsValid(errors.length === 0);
  };

  useEffect(() => {
    validate();
  }, [user]);

  const onSaveButtonClick = () => {
    if (!isValid) {
      return;
    }
    onSave(user);
  };
  return (
    <>
      <Dialog open={isOpen} maxWidth="sm" onClose={onClose} fullWidth={true}>
        <DialogTitle>Redigera {name}</DialogTitle>
        <ListDialogContent>
          <UserForm isEditing user={user} setUser={setUser} />
        </ListDialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton disabled={!isValid} onClick={onSaveButtonClick}>
            Spara
          </DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

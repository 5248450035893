import styled from '@emotion/styled';
import { Grid, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { AlertTriangle } from 'react-feather';
import bankIDImg from '../../assets/images/bankid.png';
import { BankIdAuthResponse, getBankIdAuth, openBankIdApp } from '../../auth/bankid';
import { ButtonImgIcon, DefaultButton } from '../../layout';
import { BankIdMobileDevice } from './BankIdMobileDevice';
import { BankIdSameDevice } from './BankIdSameDevice';
import { LocalSignIn } from './LocalSignIn';

const SignInHandlerWrapper = styled.div`
  padding: 30px;
`;

const BankIdButton = styled(DefaultButton)`
  width: 200px;
  height: 50px;
  font-size: 18px;
`;

const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
`;

export const SignInHandler = () => {
  const [isMobileDeviceActive, setIsMobileDeviceActive] = useState(false);
  const [isSameDeviceActive, setIsSameDeviceActive] = useState(false);
  const [authResponse, setAuthResponse] = useState<BankIdAuthResponse | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [error, setError] = useState('');

  const fetchBankIdAuth = async (isSameDevice?: boolean) => {
    const authResponse = await getBankIdAuth();

    if (!authResponse) {
      setError('Kunde inte starta BankID');
      return;
    }

    setAuthResponse(authResponse);
    if (isSameDevice) {
      openBankIdApp(authResponse.autoStartToken);
    }
  };

  useEffect(() => {
    return () => {
      setAuthResponse(null);
      setIsMobileDeviceActive(false);
      setIsSameDeviceActive(false);
    };
  }, []);

  const onBankIdClick = () => {
    fetchBankIdAuth();
    setIsMobileDeviceActive(true);
  };

  /* const onSameDeviceClick = async () => {
    await fetchBankIdAuth(true);
    setIsSameDeviceActive(true);
  }; */

  const onBackClick = () => {
    setIsMobileDeviceActive(false);
    setIsSameDeviceActive(false);
    setAuthResponse(null);
    setError('');
  };

  const onTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const onError = (error: string) => {
    setIsMobileDeviceActive(false);
    setIsSameDeviceActive(false);
    setAuthResponse(null);
    setError(error);
  };

  return (
    <SignInHandlerWrapper>
      {!isMobileDeviceActive && !isSameDeviceActive && (
        <>
          <Tabs value={activeTab} onChange={onTabsChange} centered>
            <Tab label="Lokalt" />
            <Tab label="BankID" />
          </Tabs>

          {activeTab === 0 && (
            <>
              <LocalSignIn />
            </>
          )}

          {activeTab === 1 && (
            <TabContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <BankIdButton onClick={onBankIdClick}>
                    <ButtonImgIcon src={bankIDImg} alt="BankID" />
                    Logga in
                  </BankIdButton>
                </Grid>
                {/* <Grid item xs={12}>
                  <DefaultOutlinedButton onClick={onSameDeviceClick}>
                    Öppna BankID på den här enheten
                  </DefaultOutlinedButton>
                </Grid> */}
                {error && (
                  <Grid item xs={12}>
                    <AlertTriangle />
                    <p> {error}</p>
                  </Grid>
                )}
              </Grid>
            </TabContent>
          )}
        </>
      )}

      <BankIdMobileDevice
        onBackClick={onBackClick}
        onError={onError}
        isActive={isMobileDeviceActive}
        authResponse={authResponse}
      />
      <BankIdSameDevice authResponse={authResponse} onBackClick={onBackClick} isActive={isSameDeviceActive} />
    </SignInHandlerWrapper>
  );
};

import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DefaultButton, DefaultTextButton } from '../../layout';

type ConfirmDialogProps = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  title: string;
  message: string;
  ok?: string;
  errorText?: string;
};

export const ConfirmDialog = ({ isOpen, onConfirm, onCancel, title, message, ok, errorText }: ConfirmDialogProps) => {
  const handleClose = () => {
    onCancel ? onCancel() : onConfirm();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      {errorText && (
        <>
          <DialogContent>
            <Alert severity="error">{errorText}</Alert>
          </DialogContent>
        </>
      )}
      <DialogActions>
        {onCancel && (
          <DefaultTextButton onClick={onCancel} color="primary">
            Avbryt
          </DefaultTextButton>
        )}
        <DefaultButton onClick={onConfirm} color="primary" autoFocus>
          {ok || 'Ok'}
        </DefaultButton>
      </DialogActions>
    </Dialog>
  );
};

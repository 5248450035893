import styled from '@emotion/styled';
import { Container, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useProtectedRegistrationDetailsQuery } from '../api/protected/registrations';
import { DataAttributeGroups } from '../components/registrations/DataAttributeGroups';
import { useUser } from '../context/UserProvider';
import { Divider, PageContent, StolenInfo } from '../layout';
import { BreadCrumbs } from '../navigation/BreadCrumbs';
import { paths } from '../navigation/paths';
import { usePublicRegistrationDetailsQuery } from '../api/public/registrations';

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const ImageWrapper = styled.div`
  //height: 300px;
  background-color: #eee;
  img {
    max-height: 200px;
    display: block;
  }
`;

const MainRegistration = styled.div`
  margin-bottom: 50px;
`;
const ChildRegistration = styled.div`
  margin-bottom: 50px;
`;

const DataAttributeGroupsWrapper = styled.div`
  margin-bottom: 20px;
`;

export const RegistrationDetails = () => {
  const { id } = useParams();
  const { isAuthenticated, selectedOwner } = useUser();

  const { data: registrationDetails, isLoading } =
    isAuthenticated && selectedOwner
      ? useProtectedRegistrationDetailsQuery(parseInt(id as string))
      : usePublicRegistrationDetailsQuery(parseInt(id as string));

  if (!registrationDetails) {
    return null;
  }

  return (
    <>
      <Container>
        <BreadCrumbs />
        <PageContent>
          {!isLoading && (
            <>
              {registrationDetails.theftReports?.find((t) => t.active) && <StolenInfo>ANMÄLD SOM STULEN</StolenInfo>}

              <MainRegistration>
                <Typography gutterBottom variant="h5">
                  {registrationDetails?.category} - {registrationDetails?.name}
                </Typography>

                <Typography variant="subtitle1">CK-nummer: {registrationDetails?.ckNumber}</Typography>

                {registrationDetails?.currentOwner && (
                  <Typography variant="subtitle1">
                    Ägare: {registrationDetails?.currentOwner?.fullName} - {registrationDetails?.currentOwner.pid}
                  </Typography>
                )}

                {registrationDetails?.currentLeaseHolder?.fullName && (
                  <Typography variant="subtitle1">
                    Brukare: {registrationDetails?.currentLeaseHolder?.fullName}
                  </Typography>
                )}

                <Typography variant="subtitle1">
                  <>Registrerad: {new Date(registrationDetails?.created ?? '').toLocaleDateString('sv')}</>
                </Typography>

                {registrationDetails?.currentOwnerFinancingStatus?.financier?.name && (
                  <Typography variant="subtitle1">
                    Finansiering: {registrationDetails?.currentOwnerFinancingStatus?.financier?.name}
                  </Typography>
                )}

                {registrationDetails?.parentId && (
                  <Typography variant="subtitle1">
                    <>
                      Objektet är kopplat till ett annat objekt,{' '}
                      <Link
                        to={paths.registrationDetailsParent.path.replace(
                          ':id',
                          registrationDetails.parentId.toString()
                        )}
                      >
                        {registrationDetails.parentId}
                      </Link>
                    </>
                  </Typography>
                )}

                {registrationDetails?.images?.length > 0 && (
                  <ImagesWrapper>
                    {registrationDetails?.images?.map((image, i) => (
                      <ImageWrapper key={i}>
                        <img src={image} alt="Bild" />
                      </ImageWrapper>
                    ))}
                  </ImagesWrapper>
                )}
                <DataAttributeGroupsWrapper>
                  <DataAttributeGroups dataAttributeGroups={registrationDetails?.dataAttributeGroups} />
                </DataAttributeGroupsWrapper>
              </MainRegistration>

              <Typography variant="subtitle1">Kopplade objekt</Typography>
              <Divider />

              {registrationDetails?.children?.length > 0 && (
                <>
                  {registrationDetails?.children?.map((child) => (
                    <ChildRegistration key={child.id}>
                      <Typography variant="h5" gutterBottom>
                        {child?.category} - {child.name}
                      </Typography>
                      <Typography variant="subtitle1">CK-nummer: {child?.ckNumber}</Typography>

                      <DataAttributeGroupsWrapper>
                        <DataAttributeGroups dataAttributeGroups={child?.dataAttributeGroups} />
                      </DataAttributeGroupsWrapper>
                    </ChildRegistration>
                  ))}
                </>
              )}
            </>
          )}
        </PageContent>
      </Container>
    </>
  );
};

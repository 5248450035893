import styled from '@emotion/styled';
import { CircularProgress, Dialog, DialogContent as MuiDialogContent, Typography } from '@mui/material';
type SavingDialogProps = {
  open: boolean;
};
const DialogContent = styled(MuiDialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 10px;
`;

export const SavingDialog = ({ open }: SavingDialogProps) => {
  return (
    <Dialog open={open}>
      <DialogContent>
        <Typography variant="h6">Ett ögonblick...</Typography>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

import { getMobileOS } from '../shared/utils/browser';

export type BankIdCollectResponse = {
  error?: string;
  status?: string;
  hintCode?: string;
  token?: string;
  tokenExpiresInSeconds?: number;
  givenName?: string;
  personalIdentityNumber?: string;
  surname?: string;
};

export type BankIdAuthResponse = {
  error?: string;
  autoStartToken?: string;
  orderRef?: string;
  qrData?: string;
  protectedQrStartSecret?: string;
  qrStartToken?: string;
  qrStartTime?: string;
};

export type BankIdQrDataRequestDto = {
  qrStartToken: string;
  protectedQrStartSecret: string;
  qrStartTime: string;
};

export type QrData = {
  error?: string;
  qrData?: string;
};

export type RefreshedTokenResonse = {
  token: string;
  tokenExpiresInSeconds: number;
};

const BANK_ID_API_BASE = process.env.REACT_APP_BANK_ID_API_BASE;

const statusMessage = {
  pending: 'Väntar på BankID.',
  cancelled: 'Inloggning avbruten. Försök igen.',
  alreadyInProgress: 'En identifiering eller underskrift för det här personnumret är redan påbörjad. Försök igen.',
  internalError: 'Internt tekniskt fel. Försök igen.',
  outstandingTransaction: 'Försöker starta BankID-appen.',
  responseStatus401: 'Åtkomst nekad, logga in igen för att fortsätta.',
  responseStatus500: 'Något gick fel, innehållet kunde inte laddas.',
  networkRequestFailed: 'Något gick fel, kontrollera att mobildata är aktiverat och att du har en dataanslutning.',
};

const bankIdAuthResponseData: BankIdAuthResponse = {};

const getRedirectUrl = () => {
  const mobileOS = getMobileOS();

  if (!mobileOS) {
    return '';
  }
  if (navigator.userAgent.match('CriOS')) {
    return 'googlechrome://' + encodeURIComponent(window.location.href);
  }

  if (navigator.userAgent.match('FxiOS')) {
    return 'firefox://open-url?url=' + encodeURIComponent(window.location.href);
  }
  const randomHash = Math.random().toString(36).substring(2, 15);
  return encodeURIComponent(`${window.location.href}#${randomHash}`);
};

export const openBankIdApp = async (autoStartToken: string | undefined) => {
  const redirect = getRedirectUrl();

  const url = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=${redirect}`;

  window.location.href = url;
};

export const getBankIdAuth = async (): Promise<BankIdAuthResponse> => {
  const url = `${BANK_ID_API_BASE}Auth`;

  try {
    const response = await fetch(url, {
      method: 'POST',
    });

    if (response.status === 401) {
      return { error: statusMessage.responseStatus401 };
    }

    if (response.status === 500) {
      return { error: statusMessage.responseStatus500 };
    }

    const json = await response.json();

    bankIdAuthResponseData.autoStartToken = json.autoStartToken;
    bankIdAuthResponseData.orderRef = json.orderRef;
    bankIdAuthResponseData.qrData = json.qrData;
    bankIdAuthResponseData.protectedQrStartSecret = json.protectedQrStartSecret;
    bankIdAuthResponseData.qrStartToken = json.qrStartToken;
    bankIdAuthResponseData.qrStartTime = json.qrStartTime;

    if (bankIdAuthResponseData.autoStartToken && bankIdAuthResponseData.orderRef && bankIdAuthResponseData.qrData) {
      return bankIdAuthResponseData;
    }

    return { error: statusMessage.internalError };
  } catch (error) {
    return { error: statusMessage.networkRequestFailed };
  }
};

export const getBankIdQrData = async (): Promise<QrData> => {
  const url = `${BANK_ID_API_BASE}QRData`;

  if (
    !bankIdAuthResponseData.qrStartToken ||
    !bankIdAuthResponseData.protectedQrStartSecret ||
    !bankIdAuthResponseData.qrStartTime
  ) {
    return { error: statusMessage.internalError };
  }

  try {
    const data: BankIdQrDataRequestDto = {
      qrStartToken: bankIdAuthResponseData.qrStartToken,
      protectedQrStartSecret: bankIdAuthResponseData.protectedQrStartSecret,
      qrStartTime: bankIdAuthResponseData.qrStartTime,
    };
    const headers: Headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const body = JSON.stringify(data);
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body,
    });

    console.log('status', response.status);

    if (response.status === 401) {
      return { error: statusMessage.responseStatus401 };
    }

    if (response.status === 406) {
      return { error: statusMessage.cancelled };
    }

    if (response.status === 500) {
      return { error: statusMessage.responseStatus500 };
    }

    const json = await response.json();

    if (json?.qrData) {
      return {
        qrData: json.qrData,
      };
    }

    return { error: statusMessage.internalError };
  } catch (error) {
    return { error: statusMessage.networkRequestFailed };
  }
};

export const getBankIdCollectResponse = async (): Promise<BankIdCollectResponse> => {
  const url = `${BANK_ID_API_BASE}Collect?orderRef=${bankIdAuthResponseData.orderRef}`;

  console.log(url);

  try {
    const response = await fetch(url, {
      method: 'POST',
    });

    console.log(response.status);

    if (response.status === 401) {
      return { error: statusMessage.responseStatus401 };
    }
    if (response.status === 406) {
      return { error: statusMessage.cancelled };
    }

    if (response.status === 500) {
      return { error: statusMessage.responseStatus500 };
    }

    const json = await response.json();

    if (json?.status === 'complete' && json?.token) {
      return {
        token: json.token,
        givenName: json.givenName,
        personalIdentityNumber: json.personalIdentityNumber,
      };
    }

    if (json?.token) {
      return { token: json.token };
    }

    if (json?.status || json?.errorCode === 2) {
      return {
        status: json.status,
      };
    }

    return { error: statusMessage.internalError };
  } catch (error) {
    return { error: statusMessage.networkRequestFailed };
  }
};

type QrCodePollingProps = {
  onUpdate: (qrData: string) => void;
  onError: (error: string) => void;
};

let isPollingQrData = false;
let pollingQrDataInterval: NodeJS.Timeout | undefined;

export const startQrCodePolling = async ({ onUpdate, onError }: QrCodePollingProps) => {
  if (isPollingQrData) {
    return;
  }

  isPollingQrData = true;

  const poll = async () => {
    if (!isPollingQrData) {
      return;
    }

    const response = await getBankIdQrData();

    if (response.qrData) {
      onUpdate(response.qrData);
    }
    if (response.error) {
      onError(response.error);
      return;
    }
    pollingQrDataInterval = setTimeout(poll, 1000);
  };

  poll();
};

export const stopQrCodePolling = () => {
  isPollingQrData = false;
  if (pollingQrDataInterval) {
    clearTimeout(pollingQrDataInterval);
  }
};

let isPollingBankIdCollect = false;
let pollingBankIdCollectInterval: NodeJS.Timeout | undefined;

export const startBankIdCollectPolling = async () => {
  return new Promise<BankIdCollectResponse>((resolve, reject) => {
    isPollingBankIdCollect = true;

    const poll = async () => {
      if (!isPollingBankIdCollect) {
        return;
      }

      const response = await getBankIdCollectResponse();

      if (response?.error) {
        isPollingBankIdCollect = false;
        reject(response);
        return;
      }

      if (response.token && response.givenName) {
        isPollingBankIdCollect = false;
        resolve(response);
        return;
      }

      pollingQrDataInterval = setTimeout(poll, 2000);
    };

    poll();
  });
};

export const stopBankIdCollectPolling = () => {
  isPollingBankIdCollect = false;
  if (pollingBankIdCollectInterval) {
    clearTimeout(pollingBankIdCollectInterval);
  }
};

/* export const getRefreshToken = async () => {
  const user = getUser();

  if (!user) return;

  try {
    const response = await apiRequest<RefreshedTokenResonse>({
      action: `${BANK_ID_API_BASE}/Refresh`,
      method: 'POST',
      allowAnonymous: false,
    });

    if (response.data?.token) {
      return response.data.token;
    }
  } catch (error) {
    console.log(error);
  }

  return false;
}; */

import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import bankIDImg from '../../assets/images/bankid-black.png';
import {
  BankIdAuthResponse,
  openBankIdApp,
  startBankIdCollectPolling,
  startQrCodePolling,
  stopBankIdCollectPolling,
  stopQrCodePolling,
} from '../../auth/bankid';
import { useUser } from '../../context/UserProvider';
import { DefaultButton, DefaultOutlinedButton } from '../../layout';
import { UserCredentialsDto } from '../../shared/types/user';
import { BankIdQRCode } from './BankIdQRCode';
import ReactGA from 'react-ga4';

type BankIdProps = {
  isActive: boolean;
  onBackClick: () => void;
  authResponse: BankIdAuthResponse | null;
  onError: (error: string) => void;
};

const QRCodeWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const BankIdInstructions = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  // text-align: left;
  line-height: 1.2rem;
  font-size: 0.75rem;
`;

export const BankIdMobileDevice = ({ onBackClick, isActive, authResponse, onError }: BankIdProps) => {
  const [qrData, setqrData] = useState('');
  const [autoStartToken, setAutoStartToken] = useState('');

  useEffect(() => {
    return () => {
      stopQrCodePolling();
      stopBankIdCollectPolling();
    };
  }, []);

  useEffect(() => {
    if (isActive && authResponse) {
      startBankId();
      return;
    }
    stopQrCodePolling();
    stopBankIdCollectPolling();
  }, [isActive, authResponse]);

  const { updateUser } = useUser();

  const startBankId = async () => {
    stopQrCodePolling();

    if (!authResponse) {
      return;
    }

    if (authResponse.autoStartToken) {
      setAutoStartToken(authResponse.autoStartToken);
    }

    if (authResponse.qrData && authResponse.orderRef) {
      setqrData(authResponse.qrData);
      startQrCodePolling({
        onUpdate: (qrData) => setqrData(qrData),
        onError: (error) => onError(error),
      });

      try {
        console.log('startBankIdCollectPolling');
        const collectResponse = await startBankIdCollectPolling();

        if (collectResponse.givenName && collectResponse.token) {
          const user: UserCredentialsDto = {
            username: collectResponse.givenName,
            token: collectResponse.token,
          };

          updateUser(user, collectResponse.tokenExpiresInSeconds || 600);
          ReactGA.event('login', { method: 'BankIdMobileDevice' });
        }
      } catch (error) {
        console.log(error);
      }

      stopQrCodePolling();
    }

    if (authResponse.error) {
      onError(authResponse.error);
    }
  };

  const onSameDeviceClick = async () => {
    try {
      stopQrCodePolling();
      await openBankIdApp(autoStartToken);
    } catch (error) {
      console.log(error);
    }
  };

  if (!isActive) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <img width={150} src={bankIDImg} alt="BankID" />
      </Grid>
      <Grid item xs={12}>
        {qrData && (
          <>
            <QRCodeWrapper>
              <BankIdQRCode qrData={qrData} />
            </QRCodeWrapper>
            <BankIdInstructions>
              <div>1. Öppna appen för BankID.</div>
              <div>2. Tryck på QR-symbolen i appen för BankID.</div>
              <div>3. Rikta kameran mot QR-koden.</div>
              <div>4. Följ instruktionerna i appen för att logga in.</div>
            </BankIdInstructions>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <DefaultOutlinedButton onClick={onSameDeviceClick}>Öppna BankID på den här enheten</DefaultOutlinedButton>
      </Grid>
      <Grid item xs={12}>
        <DefaultButton onClick={() => onBackClick()}>Avbryt</DefaultButton>
      </Grid>
    </Grid>
  );
};

import Organisationsnummer from 'organisationsnummer';
import Personnummer from 'personnummer';

export const getFormatedSocialSecurityNumberNumber = (socialSecurityNumber: string | undefined | null) => {
  if (!socialSecurityNumber) {
    return '';
  }
  try {
    const formatedSocialSecurityNumber = Personnummer.parse(socialSecurityNumber).format();
    return formatedSocialSecurityNumber;
  } catch (error) {
    return 'ogiltigt personnummer';
  }
};

export const getFormatedOrganizationNumber = (orgnr: string | undefined | null) => {
  if (!orgnr) {
    return '';
  }
  try {
    const formatedOrganizationNumber = Organisationsnummer.parse(orgnr).format(true);
    return formatedOrganizationNumber;
  } catch (error) {
    return 'ogiltigt organisationsnummer';
  }
};

const publicPaths = {
  home: {
    path: '/',
    name: 'Hem',
  },
  search: {
    path: '/sokresultat',
    name: 'Sökresultat',
  },
  about: {
    path: '/om-chassikollen',
    name: 'Om Chassikollen',
  },
  contact: {
    path: '/kontakt',
    name: 'Kontakt',
  },
  privatePolicy: {
    path: '/anvandarvillkor',
    name: 'Användarvillkor',
  },
  registrations: {
    path: '/registreringar',
    name: 'Detaljer',
  },
  registrationDetails: {
    path: '/registreringar/:category/:name/:id',
    name: 'Detaljer',
  },
  registrationDetailsParent: {
    path: '/registreringar/detaljer/:id',
    name: 'Detaljer',
  },
  registrationDetailsFromQr: {
    path: '/regnr/:cknumber',
    name: 'Detaljer',
  },
};

const authPaths = {
  signIn: {
    path: '/logga-in',
    name: 'Logga in',
  },
  signOut: {
    path: '/logga-ut',
    name: 'Logga ut',
  },
};

const protectedPaths = {
  myProfile: {
    path: '/min-profil',
    name: 'Min profil',
  },
  myRegistrations: {
    path: '/mina-registreringar',
    name: 'Översikt',
  },
  myRegistrationsNewRegistration: {
    path: '/mina-registreringar/ny-registrering',
    name: 'Ny registrering',
  },
  myRegistrationsCopyRegistration: {
    path: '/mina-registreringar/kopiera-registrering/:id',
    name: 'Kopiera registrering',
  },
  myRegistrationsNewChildRegistration: {
    path: '/mina-registreringar/ny-registrering/:parentId',
    name: 'Ny kopplad registrering',
  },
  myRegistrationsChangeOfOwnershipBase: {
    path: '/mina-registreringar/agarbyte',
    name: 'Ägarbyte',
  },
  myRegistrationsChangeOfOwnership: {
    path: '/mina-registreringar/agarbyte/:id',
    name: 'Ägarbyte',
  },
  myRegistrationsChangeOfOwnershipComplete: {
    path: '/mina-registreringar/agarbyte-klart',
    name: 'Ägarbyte klart',
  },
  myRegistrationsChangeOfOwnershipConfirmed: {
    path: '/mina-registreringar/agarbyte-godkant',
    name: 'Ägarbyte godkännt',
  },
  myRegistrationsChangeOfOwnershipRegistrations: {
    path: '/mina-registreringar/pagaende-agarbyten',
    name: 'Pågående ägarbyten',
  },
  myRegistrationsChangeOfOwnershipRegistrationsDetails: {
    path: '/mina-registreringar/pagaende-agarbyten/:id',
    name: 'Detaljer',
  },
  myRegistrationsRegistrationComplete: {
    path: '/mina-registreringar/registrering-klar',
    name: 'Registrering klar',
  },
  myRegistrationsRegistrations: {
    path: '/mina-registreringar/registreringar',
    name: 'Registreringar',
  },
  myRegistrationsRegistrationDetails: {
    path: '/mina-registreringar/registreringar/:id',
    name: 'Detaljer',
  },
  myRegistrationsStatus: {
    path: '/mina-registreringar/pagaende-arenden',
    name: 'Pågående ärenden',
  },
  financings: {
    path: '/finansieringar',
    name: 'Finansieringar',
  },
  leases: {
    path: '/leasingar',
    name: 'Leasingar',
  },
  insurances: {
    path: '/forsakringar',
    name: 'Försäkringar',
  },
  myOwners: {
    path: '/mina-agare',
    name: 'Mina ägare',
  },
};

const adminPaths = {
  admin: {
    path: '/admin',
    name: 'Admin',
  },
  editDataAttributes: {
    path: '/admin/attribut',
    name: 'Redigera attribut',
  },
  editCategories: {
    path: '/admin/kategorier',
    name: 'Redigera kategorier',
  },
  editCompanies: {
    path: '/admin/foretag',
    name: 'Redigera företag',
  },
  editRegistrations: {
    path: '/admin/registreringar',
    name: 'Redigera registreringar',
  },
  newRegistration: {
    path: '/admin/ny-registrering',
    name: 'Ny registrering',
  },
  editPersons: {
    path: '/admin/personer',
    name: 'Redigera personer',
  },
  editOwners: {
    path: '/admin/agare',
    name: 'Redigera ägare',
  },
  editUsers: {
    path: '/admin/anvandare',
    name: 'Redigera användare',
  },
  editOwnership: {
    path: '/admin/agarbyte',
    name: 'Redigera ägarbyten',
  },
  editFinancions: {
    path: '/admin/finansieringar',
    name: 'Redigera finansieringar',
  },
  fortnox: {
    path: '/admin/fortnox',
    name: 'Fortnox',
  },
};

export const paths = {
  ...publicPaths,
  ...authPaths,
  ...protectedPaths,
  ...adminPaths,
};

import axios, { AxiosError, AxiosResponse } from 'axios';
import { getTokenFromStorage } from '../auth/userStorage';

export interface ApiRequestOptions<B = unknown> {
  action: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: B;
  allowAnonymous?: boolean;
  params?: unknown;
  headers?: { [key: string]: string };
}

const API_BASE = process.env.REACT_APP_API_BASE;

export interface ApiRequestResponse<R> {
  error: string | null;
  status: number;
  data: R | null;
}

export interface ApiError {
  code: number;
  message: string;
}

// TODO: Migrate all api requests to this function, remove th old one and rename this one to apiRequest

export const apiRequest2 = async <T, B = unknown>({
  action,
  method,
  body,
  allowAnonymous,
  params,
  headers,
}: ApiRequestOptions<B>) => {
  const url = `${API_BASE}${action}`;

  // TODO: Use httponly cookie instead

  if (!headers) {
    headers = { 'Content-Type': 'application/json' };
  }
  // const headers: { [key: string]: string } = {
  //   'Content-Type': 'application/json',
  // };

  if (!allowAnonymous) {
    const token = getTokenFromStorage();
    //axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    headers['Authorization'] = `Bearer ${token}`;
  }

  try {
    return await axios.request<T, AxiosResponse<T, B>, B>({
      method,
      url,
      data: body,
      headers: headers,
      params: params,
    });
  } catch (error) {
    console.error(error);

    const { response, message } = error as AxiosError;

    if (response) {
      throw response.data;
    }

    if (message === 'Network Error') {
      throw { code: 0, message: 'Något gick fel, kontrollera din internetuppkoppling' };
    }

    throw error;
  }
};

export const apiRequest = async <T>({
  action,
  method,
  body,
  allowAnonymous,
}: ApiRequestOptions<string>): Promise<ApiRequestResponse<T>> => {
  const url = `${API_BASE}${action}`;

  const headers: Headers = new Headers();
  headers.append('Content-Type', 'application/json');

  if (!allowAnonymous) {
    const token = getTokenFromStorage();
    headers.append('Authorization', `Bearer ${token}`);
  }

  try {
    const response = await fetch(url, {
      method,
      headers,
      body,
    });

    if (response.status === 500) {
      return { error: 'Något gick tyvärr fel', data: null, status: response.status };
    }

    if (response.status === 400) {
      return { error: 'Något gick tyvärr fel', data: null, status: response.status };
    }

    if (response.status === 401) {
      //  document.location.href = '/logga-in';
      return { error: 'Du har inte behörighet att visa innehållet', data: null, status: response.status };
    }
    if (response.status === 404) {
      return { error: 'Posten finns inte', data: null, status: response.status };
    }

    if (response.status === 409) {
      return { error: 'Posten finns redan i databasen', data: null, status: response.status };
    }

    if (response.headers.get('Content-Type') === 'text/plain; charset=utf-8') {
      const text = await response.text();
      return { error: text, data: null, status: response.status };
    }

    if (response.status === 204) {
      return { error: null, data: null, status: response.status };
    }

    const json = (await response.json()) as T;
    return { error: null, data: json, status: response.status };
  } catch (error) {
    console.log(error);
  }

  return { error: 'Något gick tyvärr fel', data: null, status: 500 };
};

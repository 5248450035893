import styled from '@emotion/styled';
import { NodeModel, useDragOver } from '@minoru/react-dnd-treeview';
import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { ChevronDown, ChevronRight, Edit, Plus, Trash2 } from 'react-feather';
import { ListItem } from '../../../layout';
import { CategoryDto } from '../../../shared/types/admin/category';

type TreeNodeWrapperProps = {
  depth: number;
};

type TreeNodeProps = {
  node: NodeModel<CategoryDto>;
  depth: number;
  isOpen: boolean;
  hasChild: boolean;
  onToggle: (id: NodeModel['id']) => void;
  onAddChildCategory: (category: CategoryDto) => void;
  onEditCategory: (category: CategoryDto) => void;
  onDeleteCategory: (id: string) => void;
};

const TreeNodeWrapper = styled.div<TreeNodeWrapperProps>`
  margin-left: ${(props) => props.depth * 20}px;
  font-size: ${(props) => (props.depth < 3 ? 20 - props.depth * 2 : 14)}px;
`;

const TreeNodeIcon = styled.div`
  margin-right: 2px;

  display: flex;
  align-items: center;
`;

const DataAttributeList = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
  align-items: center;
`;

const DataAttribute = styled.div`
  font-size: 0.65rem;
  padding: 1px 5px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
`;

const TreeNodeTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  white-space: nowrap;
`;

const TreeNodeActions = styled.div`
  display: flex;
  align-items: center;
`;

export const CategoryTreeNode = ({
  node,
  depth,
  isOpen,
  onToggle,
  hasChild,
  onAddChildCategory,
  onEditCategory,
  onDeleteCategory,
}: TreeNodeProps) => {
  const { id, text, data } = node;
  const dataAttributes = data?.dataAttributeTemplates || [];

  dataAttributes.sort((a, b) => (a.dataAttributeOrder || 0) - (b.dataAttributeOrder || 0));

  const onAddCategoryClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onAddChildCategory(data as CategoryDto);
  };

  const onEditCategoryClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onEditCategory(data as CategoryDto);
  };

  const onDeleteCategoryClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onDeleteCategory(id as string);
  };

  const handleToggle = () => {
    onToggle(node.id);
  };

  const dragOverProps = useDragOver(id, isOpen, onToggle);

  return (
    <TreeNodeWrapper {...dragOverProps} depth={depth}>
      <ListItem onClick={handleToggle}>
        <TreeNodeTitle>
          {hasChild && <TreeNodeIcon>{isOpen ? <ChevronDown size={20} /> : <ChevronRight size={20} />}</TreeNodeIcon>}
          <Box>{text}</Box>
          <DataAttributeList>
            {dataAttributes.map((dataAttribute, i) => {
              return <DataAttribute key={i}>{dataAttribute.dataAttributeName} </DataAttribute>;
            })}
          </DataAttributeList>
        </TreeNodeTitle>

        <TreeNodeActions>
          <Tooltip title="Lägg till underkategori">
            <IconButton onClick={onAddCategoryClick}>
              <Plus size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Redigera kategori">
            <IconButton onClick={onEditCategoryClick}>
              <Edit size={20} />
            </IconButton>
          </Tooltip>
          {!hasChild && (
            <Tooltip title="Ta bort kategori">
              <IconButton onClick={onDeleteCategoryClick}>
                <Trash2 size={20} />
              </IconButton>
            </Tooltip>
          )}
        </TreeNodeActions>
      </ListItem>
    </TreeNodeWrapper>
  );
};

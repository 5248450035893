import styled from '@emotion/styled';

type RegistrationDetailsImagesProps = {
  images?: string[];
};

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const ImageWrapper = styled.div`
  //height: 300px;
  background-color: #eee;
  img {
    max-height: 200px;
    display: block;
  }
`;

export const RegistrationDetailsImages = ({ images }: RegistrationDetailsImagesProps) => {
  return (
    <>
      <ImagesWrapper>
        {images?.map((image, i) => (
          <ImageWrapper key={i}>
            <img src={image} alt="Bild" />
          </ImageWrapper>
        ))}
      </ImagesWrapper>
    </>
  );
};

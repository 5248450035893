import { Route } from 'react-router-dom';
import { Status401 } from '../pages/Status401';
import { Status404 } from '../pages/Status404';
import { Status500 } from '../pages/Status500';

export const ErrorRoutes = () => {
  return (
    <>
      <Route path="/401" element={<Status401 />} />
      <Route path="*" element={<Status404 />} />
      <Route path="/500" element={<Status500 />} />
    </>
  );
};

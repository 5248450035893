import styled from '@emotion/styled';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Person, Business, AccountBalance } from '@mui/icons-material';
import { Fragment, useEffect, useState } from 'react';
import { Edit, Image } from 'react-feather';
import { useCategoriesQuery } from '../../api';
import { useAdminRegistrationQuery } from '../../api/admin';
import { useRegistrationQuery } from '../../api/protected/registrations';
import { Roles } from '../../auth/roles';
import { useUser } from '../../context/UserProvider';
import { DefaultButton, DefaultTextButton, Divider, FileInput, UploadButton } from '../../layout';
import { CategoryDto } from '../../shared/types/protected/category';
import { DataAttributeTemplateDto, DataAttributeValueDto } from '../../shared/types/protected/data-attribute';
import { CompanyDto } from '../../shared/types/owner';
import { TransactionType } from '../../shared/types/financing';
import { ImageDto } from '../../shared/types/image';
import { OwnerDto } from '../../shared/types/owner';
import { OwnerTypeDto, PostRegistrationDto } from '../../shared/types/registration';
import { toBase64String } from '../../shared/utils/base64';
import { isValidCkNumber } from '../../shared/utils/ck-number';
import { resizeImage } from '../../shared/utils/resize-image';
import { SelectFinancier } from '../financings/SelectFinancier';
import { ImagesToUploadPreview } from './ImagesToUploadPreview';
import { RegistrationForm } from './RegistrationForm';
import { SelectCategoryDialog } from './SelectCategoryDialog';
import { SelectOwnerDialog } from './SelectOwnerDialog';

type EditRegistrationDialogProps = {
  registrationId: number | null;
  onClose: () => void;
  isOpen: boolean;
  onSave: (registration: PostRegistrationDto | null) => void;
  isAdmin?: boolean;
};

const SelectedTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EditRegistrationDialog = ({
  registrationId,
  onClose,
  onSave,
  isOpen,
  isAdmin,
}: EditRegistrationDialogProps) => {
  const { userProfile } = useUser();

  const { data: registration, isLoading } = userProfile?.roles.includes(Roles.Admin)
    ? useAdminRegistrationQuery(registrationId)
    : useRegistrationQuery(registrationId);

  useEffect(() => {
    if (registration) {
      const owner = registration?.currentOwner ? registration.currentOwner : registration?.nextOwner ?? null;

      const leaseGiver = registration?.nextOwnerLeaseStatus?.financier
        ? registration.nextOwnerLeaseStatus.financier
        : registration?.currentOwnerLeaseStatus?.financier ?? null;

      const financier = registration?.nextOwnerFinancingStatus?.financier
        ? registration.nextOwnerFinancingStatus.financier
        : registration?.currentOwnerFinancingStatus?.financier ?? null;

      const transactionType = leaseGiver
        ? TransactionType.Lease
        : financier
        ? TransactionType.Financed
        : TransactionType.CashPurchase;

      const externalId =
        transactionType === TransactionType.Lease
          ? registration?.nextOwnerLeaseStatus?.externalId
            ? registration.nextOwnerLeaseStatus.externalId
            : registration?.currentOwnerLeaseStatus?.externalId ?? ''
          : transactionType === TransactionType.Financed
          ? registration?.nextOwnerFinancingStatus?.externalId
            ? registration.nextOwnerFinancingStatus.externalId
            : registration?.currentOwnerFinancingStatus?.externalId ?? ''
          : '';

      setSelectedCategoryId(registration?.categoryId ?? null);
      setSelectedOwner(owner);
      setSelectedImages(registration?.images ?? []);
      setCkNumber(registration?.ckNumber ?? '');
      setDataAttributeValues(registration?.attributes ?? []);
      setTransactionType(transactionType);
      setFinancier(financier);
      setExternalId(externalId);
    }
  }, [registration]);

  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(registration?.categoryId ?? null);
  const [selectedOwner, setSelectedOwner] = useState<OwnerDto | null>(registration?.currentOwner ?? null);
  const [selectedImages, setSelectedImages] = useState<ImageDto[]>([]);
  const [dataAttributeValues, setDataAttributeValues] = useState<DataAttributeValueDto[]>([]);
  const [ckNumber, setCkNumber] = useState<string>(registration?.ckNumber ?? '');
  const [isValid, setIsValid] = useState<boolean>(false);
  //const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [isSelectOwnerDialogOpen, setIsSelectOwnerDialogOpen] = useState<boolean>(false);
  const [isSelectCategoryDialogOpen, setIsSelectCategoryDialogOpen] = useState<boolean>(false);
  const [externalId, setExternalId] = useState<string>(
    registration?.nextOwnerLeaseStatus?.externalId
      ? registration?.nextOwnerLeaseStatus?.externalId
      : registration?.currentOwnerLeaseStatus?.externalId
      ? registration?.currentOwnerLeaseStatus?.externalId
      : registration?.nextOwnerFinancingStatus?.externalId
      ? registration?.nextOwnerFinancingStatus?.externalId
      : registration?.currentOwnerFinancingStatus?.externalId
      ? registration?.currentOwnerFinancingStatus?.externalId
      : ''
  );
  const [leaseGiver, setleaseGiver] = useState<CompanyDto | null>(
    registration?.nextOwnerLeaseStatus?.financier
      ? registration.nextOwnerLeaseStatus.financier
      : registration?.currentOwnerLeaseStatus?.financier
      ? registration.currentOwnerLeaseStatus?.financier
      : null
  );
  const [financier, setFinancier] = useState<CompanyDto | null>(
    registration?.nextOwnerFinancingStatus?.financier
      ? registration.nextOwnerFinancingStatus?.financier
      : registration?.currentOwnerFinancingStatus?.financier
      ? registration?.currentOwnerFinancingStatus?.financier
      : null
  );
  const [transactionType, setTransactionType] = useState<TransactionType>(
    registration?.currentOwnerLeaseStatus?.financier
      ? TransactionType.Lease
      : registration?.currentOwnerFinancingStatus?.financier
      ? TransactionType.Financed
      : TransactionType.CashPurchase
  );

  const { data: categories } = useCategoriesQuery(!!registrationId);

  useEffect(() => {
    validateRegistration();
  }, [selectedCategoryId, selectedOwner, dataAttributeValues, ckNumber]);

  const validateRegistration = () => {
    const errors: string[] = [];
    //   setValidationErrors([]);

    if (ckNumber !== '' && !isValidCkNumber(ckNumber)) {
      errors.push('Felaktigt CK-nummer');
    }

    if (!selectedCategoryId) {
      errors.push('Kategori måste väljas');
    }
    if (!selectedOwner) {
      errors.push('Ägare måste väljas');
    }
    if (!dataAttributeValues || dataAttributeValues.length === 0) {
      errors.push('Inga attribut');
    }

    const requiredAttributes = dataAttributeValues.filter((a) => a.isRequired);
    const missingRequiredAttributes = requiredAttributes.filter((a) => !a.value);
    if (missingRequiredAttributes.length > 0) {
      errors.push('Följande obligatoriska attribut saknas: ' + missingRequiredAttributes.map((a) => a.name).join(', '));
    }

    if (errors.length > 0) {
      setIsValid(false);
      return;
    }

    setIsValid(true);
  };

  const onDeleteImage = (image: ImageDto) => {
    if (!selectedImages) {
      return;
    }

    const newImages = image.id
      ? selectedImages.filter((i) => i.id !== image.id)
      : selectedImages.filter((i) => i.filename !== image.filename);
    setSelectedImages(newImages);
  };

  const onSelectImagesChange = async (images: FileList | null) => {
    if (!images) {
      return;
    }

    const newImages: ImageDto[] = await Promise.all(
      Array.from(images).map(async (image) => {
        const resizedImage = await resizeImage(image, 1200, 1200);
        console.log(resizedImage.name, resizedImage.type);
        return {
          filename: resizedImage.name,
          contentType: resizedImage.type,
          base64String: await toBase64String(resizedImage),
        };
      })
    );

    setSelectedImages([...selectedImages, ...newImages]);
  };

  const getTemplateDataAttributeValuesWithExistingValues = (
    templateAttributeValues: DataAttributeValueDto[]
  ): DataAttributeValueDto[] => {
    const updatedAttributeValues = templateAttributeValues.map((templateValue) => {
      const existingValue = dataAttributeValues.find((v) => v.dataAttributeId === templateValue.dataAttributeId);
      if (existingValue) {
        return existingValue;
      }
      return templateValue;
    });

    return updatedAttributeValues;
  };

  const updateDataAttributeValues = (category: CategoryDto) => {
    if (!category) {
      return;
    }
    const templateAttributeValues: DataAttributeValueDto[] = getDataAttributeValuesFromTemplate(
      category.dataAttributeTemplates
    );

    const templateAttributeValuesWithExistingValues =
      getTemplateDataAttributeValuesWithExistingValues(templateAttributeValues);

    const existingDataAttributeValuesNotInTemplate = dataAttributeValues.filter(
      (v) => !templateAttributeValues.find((t) => t.dataAttributeId === v.dataAttributeId) && v.value
    );

    const updatedAttributeValues = [
      ...templateAttributeValuesWithExistingValues,
      ...existingDataAttributeValuesNotInTemplate,
    ];

    updatedAttributeValues.sort((a, b) => (a.dataAttributeOrder || 0) - (b.dataAttributeOrder || 0));

    setDataAttributeValues(updatedAttributeValues);
  };

  const getDataAttributeValuesFromTemplate = (templates: DataAttributeTemplateDto[]): DataAttributeValueDto[] => {
    return templates.map((template) => {
      return {
        dataAttributeId: template.dataAttributeId,
        name: template.dataAttributeName ?? '',
        type: template.type,
        isRequired: template.isRequired,
        value: '',
        isPartOfId: template.isPartOfId,
        isPartOfName: template.isPartOfName,
        isScannable: template.isScannable,
        isPublic: template.isPublic,
        isUnique: template.isUnique,
        dataAttributeOrder: template.dataAttributeOrder,
        dataAttributeGroupId: template.dataAttributeGroupId,
      };
    });
  };

  const onSelectCategoryChange = (category: CategoryDto | null) => {
    if (!category) {
      return;
    }

    setSelectedCategoryId(category?.id ?? null);

    updateDataAttributeValues(category);
  };

  const onSaveButtonClick = () => {
    const updatedRegistration = {
      id: registration?.id,
      attributes: dataAttributeValues,
      categoryId: selectedCategoryId || null,
      ownerId: selectedOwner?.id || null,
      ckNumber: ckNumber || null,
      images: selectedImages,
      financierId: financier?.id || null,
      externalId: externalId || null,
    } as PostRegistrationDto;

    if (!isValid) {
      console.log('Invalid registration');
      return;
    }

    onSave(updatedRegistration);
  };

  return (
    <>
      <SelectOwnerDialog
        isOpen={isSelectOwnerDialogOpen}
        onSelectOwner={(owner) => setSelectedOwner(owner)}
        onClose={() => setIsSelectOwnerDialogOpen(false)}
      />

      <SelectCategoryDialog
        isOpen={isSelectCategoryDialogOpen}
        onClose={() => setIsSelectCategoryDialogOpen(false)}
        onSelectCategoryChange={(category) => onSelectCategoryChange(category)}
        selectedCategory={categories?.find((c) => c.id === selectedCategoryId) || null}
      />

      <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
        <DialogTitle>Redigera registrering</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <>
              {selectedCategoryId && (
                <>
                  <Divider />
                  <SelectedTitle>
                    <Box>
                      <Typography variant="caption">Kategori</Typography>
                      <Typography variant="h6">{categories?.find((c) => c.id === selectedCategoryId)?.name}</Typography>
                    </Box>
                    <Box>
                      <IconButton onClick={() => setIsSelectCategoryDialogOpen(true)}>
                        <Edit />
                      </IconButton>
                    </Box>
                  </SelectedTitle>
                  <Divider />
                </>
              )}

              {selectedOwner && selectedCategoryId && (
                <>
                  <SelectedTitle>
                    <Box>
                      <Typography variant="caption">Ägare</Typography>
                      <Typography variant="h6">{selectedOwner.fullName}</Typography>
                    </Box>

                    {isAdmin && (
                      <>
                        <Box>
                          <IconButton onClick={() => setIsSelectOwnerDialogOpen(true)}>
                            <Edit />
                          </IconButton>
                        </Box>
                      </>
                    )}
                  </SelectedTitle>
                  <Divider />

                  <Typography variant="caption">Ägarhistorik</Typography>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell rowSpan={2}>Typ</TableCell>
                          <TableCell>Skapad</TableCell>
                          <TableCell>Godkänd</TableCell>
                          <TableCell>Ägare</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Finansiär</TableCell>
                          <TableCell>Löst</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {registration?.ownerRecords.map((ownerRecord) => (
                          <Fragment key={ownerRecord.id}>
                            <TableRow>
                              <TableCell rowSpan={2}>
                                {ownerRecord.type == 2
                                  ? 'Ägarbyte'
                                  : ownerRecord.type == 3
                                  ? 'Nyregistrering'
                                  : 'Okänt'}
                              </TableCell>
                              <TableCell>{new Date(ownerRecord.created).toLocaleDateString('sv-se')}</TableCell>
                              <TableCell>
                                {ownerRecord.confirmed != null
                                  ? new Date(ownerRecord.confirmed).toLocaleDateString('sv-se')
                                  : '-'}
                              </TableCell>
                              <TableCell>
                                {ownerRecord.ownerType == OwnerTypeDto.Person && <Person />}
                                {ownerRecord.ownerType == OwnerTypeDto.Company && <Business />}
                                {ownerRecord.ownerType == OwnerTypeDto.Financier && <AccountBalance />}
                                {ownerRecord.ownerFullName}
                              </TableCell>
                            </TableRow>
                            <TableRow key={ownerRecord.id}>
                              <TableCell>
                                {ownerRecord.financingStatus != null ? ownerRecord.financingStatus.financierName : '-'}
                              </TableCell>
                              <TableCell>
                                {ownerRecord.financingStatus != null && ownerRecord.financingStatus.resolvedAt != null
                                  ? new Date(ownerRecord.financingStatus.resolvedAt).toLocaleDateString('sv-se')
                                  : '-'}
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Divider />
                </>
              )}

              {selectedOwner && selectedCategoryId && (
                <>
                  <SelectedTitle>
                    <Box>
                      <Typography variant="caption">Bilder</Typography>
                      {!selectedImages && <Typography variant="h6">Inga bilder</Typography>}
                    </Box>
                  </SelectedTitle>
                  <ImagesToUploadPreview selectedImages={selectedImages} onDeleteImage={onDeleteImage} />
                  <Box>
                    <UploadButton htmlFor="images-upload">
                      <Image color="rgba(0, 0, 0, 0.6)" size={20} /> Ladda upp bilder...
                    </UploadButton>
                    <FileInput
                      type="file"
                      id="images-upload"
                      name="registrationImages"
                      multiple
                      onChange={(event) => onSelectImagesChange(event.target.files)}
                      accept="image/*"
                    />
                  </Box>
                  <Divider />
                  <SelectedTitle>
                    <Box>
                      <Typography variant="caption">Finansiering</Typography>
                      <SelectFinancier
                        onFinancierChange={(value) => {
                          switch (transactionType) {
                            case TransactionType.Financed:
                              setFinancier(value);
                              break;
                            case TransactionType.Lease:
                              setleaseGiver(value);
                              break;
                          }
                        }}
                        onTransactionTypeChange={(transactionType) => setTransactionType(transactionType)}
                        onExternalIdChange={(externalId) => setExternalId(externalId)}
                        selectedFinancierId={
                          transactionType === TransactionType.Lease
                            ? leaseGiver?.id
                            : transactionType === TransactionType.Financed
                            ? financier?.id
                            : undefined
                        }
                        transactionType={transactionType}
                        externalId={externalId}
                      />
                    </Box>
                  </SelectedTitle>
                  <Divider />
                  <RegistrationForm
                    dataAttributeValues={dataAttributeValues}
                    setDataAttributeValues={setDataAttributeValues}
                    ckNumber={isAdmin ? ckNumber ?? '' : undefined}
                    setCkNumber={isAdmin ? setCkNumber : undefined}
                  />
                </>
              )}
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Box>
            <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
            <DefaultButton disabled={!isValid} onClick={onSaveButtonClick}>
              Spara registrering
            </DefaultButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

import styled from '@emotion/styled';
import { Divider as MuiDivider, Drawer, MenuItem as MuiMenuItem } from '@mui/material';
import { useState } from 'react';
import { Menu } from 'react-feather';
import { Link } from 'react-router-dom';
import { colors } from '../../static/colors';

import logo from '../../assets/images/text-logo.png';
import { MenuSectionTitle } from '../../layout';
import { paths } from '../../navigation/paths';

const MobileMenuButton = styled(Menu)`
  color: #fff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 20px;

  @media (min-width: 600px) {
    display: none;
  }
`;

const DrawerMenuWrapper = styled.div`
  width: 250px;
  height: 100%;
  background-color: ${colors.blue};
  color: #fff;
`;

const MenuItem = styled(MuiMenuItem)`
  color: #fff;
  font-size: 1.1rem;
  a {
    color: #fff;
    text-decoration: none;
  }
`;

const Logo = styled.img`
  width: 210px;
  height: auto;
  margin-top: 20px;
  margin-left: 16px;
  margin-bottom: 20px;
`;

const Divider = styled(MuiDivider)`
  background-color: rgba(255, 255, 255, 0.2);
  margin: 10px 0;
`;

type DrawerMenuProps = {
  onMenuClick: () => void;
};
const DrawerMenu = ({ onMenuClick }: DrawerMenuProps) => {
  return (
    <>
      <DrawerMenuWrapper>
        <Logo src={logo} alt="Chassikollen" />
        <Divider />
        <MenuItem>
          <Link onClick={onMenuClick} to={paths.home.path}>
            {paths.home.name}
          </Link>
        </MenuItem>
        <Divider />
        <MenuSectionTitle>Mina sidor</MenuSectionTitle>
        <MenuItem>
          <Link onClick={onMenuClick} to={paths.myRegistrations.path}>
            Översikt
          </Link>
        </MenuItem>
        <MenuItem>
          <Link onClick={onMenuClick} to={paths.myRegistrationsRegistrations.path}>
            Registreringar
          </Link>
        </MenuItem>
        <MenuItem>
          <Link onClick={onMenuClick} to={paths.myRegistrationsStatus.path}>
            {paths.myRegistrationsStatus.name}
          </Link>
        </MenuItem>
        <Divider />

        <MenuItem>
          <Link onClick={onMenuClick} to={paths.contact.path}>
            {paths.contact.name}
          </Link>
        </MenuItem>

        <MenuItem>
          <Link onClick={onMenuClick} to={paths.about.path}>
            {paths.about.name}
          </Link>
        </MenuItem>
      </DrawerMenuWrapper>
    </>
  );
};

export const MobileMenu = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onMenuClick = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <DrawerMenu onMenuClick={onMenuClick} />
      </Drawer>

      <MobileMenuButton onClick={() => setIsDrawerOpen(!isDrawerOpen)} />
    </>
  );
};

import { Container, Typography } from '@mui/material';
import { PageContent, PageText } from '../layout';

export const About = () => {
  return (
    <Container>
      <PageContent>
        <PageText>
          <Typography variant="h6">Om Chassikollen</Typography>
          <Typography variant="h5" gutterBottom>
            En ny tjänst i kampen mot stöld och bedrägeri
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Ett register med information och ägarhistorik över allt från entreprenadmaskiner, redskap, båtar och cyklar.
            Så kan man beskriva Chassikollen, ett digitalt verktyg som förenklar tillvaron för företag, polis och
            försäkringsbolag. Men som gör livet surt för tjuvar och stöldligor.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Chassikollen är en tjänst som hjälper dig som företag, förening eller annan organisation att registrera
            maskiner, redskap och andra ägodelar, och där du signerar äktheten i uppgifterna med ditt BankID. Vid
            händelse av stöld är det enklare att återfinna rätt ägare och svårare att sälja vidare stöldgodset - då
            Chassikollen fungerar som en kontrollsida och varje registrerat objekt får ett unikt nummer och en unik
            QR-kod.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Tidigare har det inte funnits något fungerande nationellt maskin- och redskapsregister i Sverige. I vårt
            grannland Norge har detta funnits sedan 2015 och marknaden har länge efterfrågat detsamma i Sverige.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Chassikollen har därför utvecklat ett registerverktyg som inte bara ger information om maskinens
            ägaruppgifter men även om storlek, vikt, motor, bränsle, försäkring, miljöklassning och ägarföljd – för att
            nämna en del av registrets innehåll.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Registrering görs av ägare och kontrolleras fysiskt av Chassikollens kontrollant. Maskinägare, tillverkare,
            maskinhandlare, försäkringsbolag, finansbolag, auktionsfirmor och Polisen är några av de som ser stor nytta
            i Chassikollens register.
          </Typography>
        </PageText>
      </PageContent>
    </Container>
  );
};

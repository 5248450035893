import { IconButton, FormControlLabel, Tooltip } from '@mui/material';
import React from 'react';
import { Trash2 } from 'react-feather';
import { DefaultOutlinedButton, ListItem, ListItemActions, ListItemText } from '../../layout';
import { UserInfoDto, UserOwnerDto } from '../../shared/types/user';
import { AddUserOwnerDialog } from './AddUserOwnerDialog';
import CheckBox from '@mui/material/Checkbox';

type UserOwnerListProps = {
  ownerId: number;
  userOwners: UserOwnerDto[] | null | undefined;
  onChange(userOwners: UserOwnerDto[]): void;
};

export const UserOwnerList = ({ ownerId, userOwners, onChange }: UserOwnerListProps) => {
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = React.useState(false);

  const onIsSupervisorClick = (userId: number | undefined, value: boolean) => {
    if (userId) {
      onChange(
        userOwners?.map((userOwner) =>
          userOwner.userId === userId && userOwner.ownerId === ownerId
            ? { ...userOwner, isSupervisor: value }
            : userOwner
        ) || []
      );
    }
  };

  const onAddUserClick = () => {
    setIsAddUserDialogOpen(true);
  };

  const onDeleteClick = (userId: number | undefined) => {
    if (userId) {
      onChange(userOwners?.filter((userOwner) => userOwner.userId !== userId || userOwner.ownerId !== ownerId) || []);
    }
  };

  if (!userOwners) {
    return null;
  }

  function onAddUserOwner(userInfo: UserInfoDto, isSupervisor: boolean): void {
    console.log('onAddUserOwner: ', userInfo, isSupervisor);
    onChange([
      ...(userOwners || []),
      { ownerId: ownerId, userId: userInfo.id, isSupervisor, user: userInfo, isRegistrar: false },
    ]);
  }

  return (
    <>
      <AddUserOwnerDialog
        userOwners={userOwners}
        isOpen={isAddUserDialogOpen}
        onClose={() => setIsAddUserDialogOpen(false)}
        onAddUserOwner={onAddUserOwner}
      />

      {userOwners.map((userOwner, i) => (
        <ListItem noPointer key={i}>
          <ListItemText>
            {userOwner.user?.givenName || 'Ny användare'} {userOwner.user?.familyName}
          </ListItemText>
          <ListItemText>
            <Tooltip title="En handläggare kan bland annat lägga till och ta bort användare...">
              <FormControlLabel
                control={
                  <CheckBox
                    checked={userOwner.isSupervisor}
                    onChange={(e) => onIsSupervisorClick(userOwner.userId, e.target.checked)}
                  />
                }
                label="Handläggare"
              />
            </Tooltip>
          </ListItemText>
          {/* <ListItemText>{userOwner.user?.socialSecurityNumber || ''}</ListItemText> */}
          <ListItemActions>
            <Tooltip title="Ta bort användare">
              <IconButton onClick={() => onDeleteClick(userOwner.userId)}>
                <Trash2 size={20} />
              </IconButton>
            </Tooltip>
          </ListItemActions>
        </ListItem>
      ))}

      <DefaultOutlinedButton size="small" onClick={onAddUserClick}>
        Lägg till ny användare
      </DefaultOutlinedButton>
    </>
  );
};

import { Route } from 'react-router-dom';
import { About } from '../pages/About';
import { Contact } from '../pages/Contact';
import { Home } from '../pages/Home';
import { PrivatePolicy } from '../pages/PrivatePolicy';
import { RegistrationDetails } from '../pages/RegistrationDetails';
import { SearchResultPage } from '../pages/SearchResult';
import { paths } from './paths';

export const PublicRoutes = () => {
  return (
    <>
      <Route path={paths.home.path} element={<Home />} />
      <Route path={paths.search.path} element={<SearchResultPage />} />
      <Route path={paths.registrationDetails.path} element={<RegistrationDetails />} />
      <Route path={paths.registrationDetailsParent.path} element={<RegistrationDetails />} />
      <Route path={paths.about.path} element={<About />} />
      <Route path={paths.contact.path} element={<Contact />} />
      <Route path={paths.privatePolicy.path} element={<PrivatePolicy />} />
      <Route path={paths.registrationDetailsFromQr.path} element={<SearchResultPage />} />
    </>
  );
};

import styled from '@emotion/styled';
import { Alert, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { CircleDiagram } from '../../components/charts/CircleDiagram';
import { useConfig } from '../../context/ConfigProvider';
import { DefaultCard } from '../../layout';
import { useConfirmedRegistrationsByOwnerQuery } from '../../api/protected/registrations';

type ChartItem = {
  value: number;
  label: string;
};

const DiagramHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StatisticCard = styled(DefaultCard)`
  font-size: 0.9rem;
`;

const getChartItems = (items: string[]): ChartItem[] => {
  const counts = items.reduce(
    (acc, value) => ({
      ...acc,
      [value]: (Object(acc)[value] || 0) + 1,
    }),
    {}
  );

  const chartItems = Object.entries(counts).map(([key, value]) => ({
    value: value as number,
    label: key,
  }));

  chartItems.sort((a, b) => b.value - a.value);

  const firstFive = chartItems.slice(0, 6);

  const rest = chartItems.slice(6);

  const restSum = rest.reduce((acc, item) => acc + item.value, 0);

  const restItem = {
    value: restSum,
    label: 'Övriga',
  };

  return [...firstFive, restItem];
};

export const Statistics = () => {
  const [categoryStats, setCategoryStats] = useState<ChartItem[]>([]);

  const confirmedRegistrations = useConfirmedRegistrationsByOwnerQuery();

  const { selectableStatistcsAttributes } = useConfig();

  useEffect(() => {
    if (!confirmedRegistrations.data) {
      return;
    }

    const categories = confirmedRegistrations.data.map((registration) => registration.category);
    setCategoryStats(getChartItems(categories));
  }, [confirmedRegistrations.data]);

  const getDiagramData = (attributeName: string) => {
    if (!confirmedRegistrations.data) {
      return [];
    }

    const attributes = confirmedRegistrations.data.flatMap((registration) =>
      registration.attributes?.filter((a) => a.name === attributeName).map((attribute) => attribute.value)
    );

    return getChartItems(attributes as string[]);
  };

  return (
    <>
      {confirmedRegistrations.data ? (
        <>
          {confirmedRegistrations.data.length > 1 ? (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <StatisticCard>
                  <DiagramHeader>
                    <Typography variant="h6">Kategorier</Typography>
                  </DiagramHeader>
                  <CircleDiagram data={categoryStats} radius={200} />
                </StatisticCard>
              </Grid>

              {selectableStatistcsAttributes.map((attribute) => {
                const diagramData = getDiagramData(attribute.name);

                if (diagramData.length < 2) {
                  return null;
                }

                return (
                  <Grid item xs={12} md={6} key={attribute.name}>
                    <StatisticCard>
                      <DiagramHeader>
                        <Typography variant="h6">{attribute.title}</Typography>
                      </DiagramHeader>
                      <CircleDiagram data={diagramData} radius={200} />
                    </StatisticCard>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Typography variant="h6">Det finns för få registreringar för att visa statistik</Typography>
          )}
        </>
      ) : (
        confirmedRegistrations.isPending && <Alert severity="warning">Laddar...</Alert>
      )}
      {confirmedRegistrations.isError && <Alert severity="error">Ett fel uppstod vid hämtning av registreringar</Alert>}
    </>
  );
};

import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiRequest2 } from '../api-request';
import { LeaseDto } from '../../shared/types/lease';

const leaseKeys = {
  all: ['leases'],
  byLessor: (lessorId: number, searchTerm: string | undefined) => [...leaseKeys.all, 'byLessor', lessorId, searchTerm],
  count: (lessorId: number) => [...leaseKeys.all, 'count', lessorId],
};

export const useLeasesByLessorQuery = (lessorId: number, searchTerm: string) =>
  useQuery({
    queryKey: leaseKeys.byLessor(lessorId, searchTerm),
    queryFn: async () => {
      const response = await apiRequest2<LeaseDto[]>({
        action: `Protected/Leasing/Lessor/${lessorId}`,
        method: 'GET',
        allowAnonymous: false,
        params: { searchTerm: searchTerm },
      });

      return response.data;
    },
    placeholderData: keepPreviousData,
  });

export const useLeasesCountQuery = (lessorId: number) =>
  useQuery({
    queryKey: leaseKeys.count(lessorId),
    queryFn: async () => {
      const response = await apiRequest2<number>({
        action: `Protected/Leasing/Count/Lessor/` + lessorId,
        method: 'GET',
        allowAnonymous: false,
      });

      return response.data;
    },
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60 * 10,
    gcTime: 1000 * 60 * 10,
  });

export const useExpireLeaseMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (leaseId: number) =>
      apiRequest2<void>({
        action: `Protected/Leasing/Expire/${leaseId}`,
        method: 'PUT',
        allowAnonymous: false,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: leaseKeys.all,
      });
    },
  });
};

import styled from '@emotion/styled';
import { ChevronRight } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';
import { paths } from './paths';

const BreadCrumbsWrapper = styled.div`
  font-size: 0.8rem;
  color: #666;
  font-weight: 500;
  padding-top: 10px;
  display: flex;

  @media print {
    display: none;
  }
`;

const LinkWrapper = styled.div<{ isLast?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: #666;
  font-weight: ${(props) => (props.isLast ? '700' : '500')};
`;

type BreadCrumbsProps = {
  customPathname?: string;
};

const replaceLastPartOfPath = (path: string) => {
  if (!path) return path;

  const lastPartOfPath = path.split('/').pop();
  if (lastPartOfPath && !isNaN(Number(lastPartOfPath))) {
    path = path.replace(lastPartOfPath, ':id');
  }
  return path;
};

export const BreadCrumbs = ({ customPathname }: BreadCrumbsProps) => {
  const { pathname } = customPathname ? { pathname: customPathname } : useLocation();

  const pathParts = pathname.split('/').map((_part, i, arr) => arr.slice(0, i + 1).join('/') || '/');
  const getPathName = (path: string) => {
    return Object.values(paths).find((p) => p.path === replaceLastPartOfPath(path))?.name;
  };

  return (
    <BreadCrumbsWrapper>
      {pathParts.map((path, index) => {
        const name = getPathName(path);
        const nextName = getPathName(pathParts[index + 1]);

        const isLast = index === pathParts.length - 1 || !nextName;

        return (
          <LinkWrapper key={index} isLast={isLast}>
            {isLast ? (
              getPathName(path)
            ) : (
              <>
                <Link to={path}>{name}</Link> <ChevronRight size={14} />
              </>
            )}
          </LinkWrapper>
        );
      })}
    </BreadCrumbsWrapper>
  );
};

import dayjs from 'dayjs';

export const localDate = (date: string | undefined | null) => {
  if (!date) {
    return '';
  }
  try {
    return dayjs(date).format('YYYY-MM-DD');
  } catch (error) {
    return 'ogiltigt datum';
  }
};

export const localDateTime = (date: string | undefined | null) => {
  if (!date) {
    return '';
  }
  try {
    return dayjs(date).format('YYYY-MM-DD HH:mm');
  } catch (error) {
    return 'ogiltigt datum';
  }
};

import { Alert, Container, Typography } from '@mui/material';
import { OwnerRegistrations } from '../../components/my-registrations/OwnerRegistrations';
import { Divider, PageContent, PageText, PageTitle } from '../../layout';
import { BreadCrumbs } from '../../navigation/BreadCrumbs';
import { useChangeOfOwnershipRegistrationsByOwnerQuery } from '../../api/protected/registrations';

export const ChangeOfOwnerRegistrationsPage = () => {
  const changeOfOwnershipRegistrations = useChangeOfOwnershipRegistrationsByOwnerQuery();
  return (
    <>
      <Container maxWidth={false}>
        <BreadCrumbs />
        <PageContent>
          <>
            {changeOfOwnershipRegistrations.data ? (
              <>
                {changeOfOwnershipRegistrations.data.length === 0 ? (
                  <PageTitle>Inga pågående ägarbyten just nu</PageTitle>
                ) : (
                  <>
                    <PageTitle>Pågående ägarbyten</PageTitle>
                    <PageText alignLeft>
                      <Typography variant="body1" gutterBottom>
                        När du säljer ett objekt så ska ett ägarbyte registreras i Chassikollen.
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Det är säljaren som initierar ägarbytet men både säljare och köpare bekräftar ägarbytet med sitt
                        personliga BankID.
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Om försäljningsobjektet är finansierat med leasing eller kreditköp måste finansiären (bank eller
                        leasinggivare) godkänna ägarbytet innan objektet i Chassikollen flyttas över till den nya
                        ägaren.
                      </Typography>
                    </PageText>

                    <Divider />
                    <OwnerRegistrations type="change-of-owner" registrations={changeOfOwnershipRegistrations.data} />
                  </>
                )}
              </>
            ) : (
              changeOfOwnershipRegistrations.isPending && <Alert severity="info">Laddar...</Alert>
            )}
            {changeOfOwnershipRegistrations.isError && (
              <Alert severity="error">Ett fel uppstod vid hämtning av ägarbyten</Alert>
            )}
          </>
        </PageContent>
      </Container>
    </>
  );
};

import { DataAttributeDto, DataAttributeGroupDto } from '../../shared/types/admin/data-attribute';
import { apiRequest } from '../api-request';

export async function getDataAtrributesFromApiAsync() {
  const response = await apiRequest<DataAttributeDto[]>({
    action: `Admin/DataAttributes`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response;
}

export async function putDataAttributeToApiAsync(dataAttribute: DataAttributeDto) {
  const response = await apiRequest<DataAttributeDto[]>({
    action: `Admin/DataAttributes/${dataAttribute.id}`,
    method: 'PUT',
    body: JSON.stringify(dataAttribute),
    allowAnonymous: false,
  });

  return response;
}

export async function postDataAttributeToApiAsync(dataAttribute: DataAttributeDto) {
  const response = await apiRequest<DataAttributeDto>({
    action: `Admin/DataAttributes`,
    method: 'POST',
    body: JSON.stringify(dataAttribute),
    allowAnonymous: false,
  });

  return response;
}

export async function deleteDataAttributeApiAsync(dataAttributeId: number) {
  await apiRequest({
    action: `Admin/DataAttributes/${dataAttributeId}`,
    method: 'DELETE',
    allowAnonymous: false,
  });
}

export async function putDataAttributesOrderToApiAsync(dataAttributes: DataAttributeDto[]) {
  const response = await apiRequest<DataAttributeDto[]>({
    action: `Admin/DataAttributes/UpdateOrder`,
    method: 'PUT',
    body: JSON.stringify(dataAttributes),
    allowAnonymous: false,
  });

  return response;
}

// TODO: Remove, obnsolete
export async function getDataAttributeGroupsFromApiAsync() {
  const response = await apiRequest<DataAttributeGroupDto[]>({
    action: `Admin/DataAttributeGroups`,
    method: 'GET',
    allowAnonymous: false,
  });

  return response;
}

export async function postDataAttributeGroupToApiAsync(group: DataAttributeGroupDto) {
  await apiRequest({
    action: `Admin/DataAttributeGroups`,
    method: 'POST',
    body: JSON.stringify(group),
    allowAnonymous: false,
  });
}
export async function putDataAttributeGroupToApiAsync(group: DataAttributeGroupDto) {
  await apiRequest({
    action: `Admin/DataAttributeGroups/${group.id}`,
    method: 'PUT',
    body: JSON.stringify(group),
    allowAnonymous: false,
  });
}

export async function putDataAttributesGroupOrderToApiAsync(groups: DataAttributeGroupDto[]) {
  await apiRequest({
    action: `Admin/DataAttributeGroups/UpdateOrder`,
    method: 'PUT',
    body: JSON.stringify(groups),
    allowAnonymous: false,
  });
}
export async function deleteDataAttributeGroupApiAsync(group: DataAttributeGroupDto) {
  await apiRequest({
    action: `Admin/DataAttributeGroups/${group.id}`,
    method: 'DELETE',
    allowAnonymous: false,
  });
}

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { DefaultButton, DefaultTextButton, ListDialogContent } from '../../../layout';
import { dataAttributeTypeList } from '../../../shared/select-items/data-attribute-select-list';
import {
  DataAttributeDto,
  DataAttributeGroupDto,
  DataAttributeTypes,
} from '../../../shared/types/admin/data-attribute';

type AddDataAttributeDialogProps = {
  isOpen: boolean;
  dataAttributeGroups: DataAttributeGroupDto[];
  selectedGroupId?: number;
  onClose: () => void;
  onSave: (dataAttribute: DataAttributeDto) => void;
};

export const AddDataAttributeDialog = ({
  isOpen,
  dataAttributeGroups,
  selectedGroupId,
  onClose,
  onSave,
}: AddDataAttributeDialogProps) => {
  const [name, setName] = useState('');
  const [isPartOfId, setIsPartOfId] = useState(false);
  const [isPartOfName, setIsPartOfName] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [isScannable, setIsScannable] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [isUnique, setIsUnique] = useState(false);
  const [type, setType] = useState(DataAttributeTypes.STRING);
  const [nameInputRef, setNameInputRef] = useState<HTMLInputElement | null>(null);
  const [groupId, setGroupId] = useState(0);

  useEffect(() => {
    if (nameInputRef) {
      nameInputRef.focus();
    }
  }, [nameInputRef]);

  useEffect(() => {
    if (isOpen) {
      resetInputs();
    }
  }, [isOpen]);

  useEffect(() => {
    console.log('selectedGroupId', selectedGroupId);
    if (!selectedGroupId) {
      return;
    }
    setGroupId(selectedGroupId);
  }, [selectedGroupId]);

  const onSaveButtonClick = () => {
    const newDataAttribute: DataAttributeDto = {
      id: 0,
      groupId,
      name,
      type,
      isPartOfId,
      isPartOfName,
      isRequired,
      isScannable,
      isPublic,
      isUnique,
      isUsedInTemplate: false,
      order: 0,
    };

    onSave(newDataAttribute);
  };

  const resetInputs = () => {
    setName('');
    setIsPartOfId(false);
    setIsPartOfName(false);
    setIsRequired(false);
    setIsScannable(false);
    setIsPublic(false);
    setType(DataAttributeTypes.STRING);
    setGroupId(0);
  };

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onCloseHandler}>
        <DialogTitle>Lägg till attribut</DialogTitle>

        <ListDialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Namn</InputLabel>
                <OutlinedInput
                  value={name}
                  label="Name"
                  onChange={(e) => setName(e.target.value)}
                  autoFocus
                  inputRef={(ref) => setNameInputRef(ref)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Typ</InputLabel>
                <Select label="Typ" value={type} onChange={(e) => setType(e.target.value as DataAttributeTypes)}>
                  {dataAttributeTypeList.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Grupp</InputLabel>
                <Select label="Grupp" value={groupId || ''} onChange={(e) => setGroupId(Number(e.target.value))}>
                  {dataAttributeGroups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={isPartOfId} onChange={(e) => setIsPartOfId(e.target.checked)} />}
              label="Del av Id"
            />
            <FormControlLabel
              control={<Checkbox checked={isPartOfName} onChange={(e) => setIsPartOfName(e.target.checked)} />}
              label="Del av namn"
            />
            <FormControlLabel
              control={<Checkbox checked={isRequired} onChange={(e) => setIsRequired(e.target.checked)} />}
              label="Obligatorisk"
            />
            <FormControlLabel
              control={<Checkbox checked={isScannable} onChange={(e) => setIsScannable(e.target.checked)} />}
              label="Kan scannas"
            />
            <FormControlLabel
              control={<Checkbox checked={isPublic} onChange={(e) => setIsPublic(e.target.checked)} />}
              label="Kan visas/sökas fast man inte är inloggad"
            />
            <FormControlLabel
              control={<Checkbox checked={isUnique} onChange={(e) => setIsUnique(e.target.checked)} />}
              label="Unikt per registrering, ska inte tas med vid kopiering"
            />
          </FormGroup>
        </ListDialogContent>

        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton onClick={onSaveButtonClick}>Lägg till</DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { Grid } from '@mui/material';
import { DataAttributeDto, DataAttributeGroupDto } from '../../../shared/types/admin/data-attribute';
import { DataAttributeList } from './DataAttributeList';

type DataAttributeGroupProps = {
  dataAttributeGroups: DataAttributeGroupDto[];
  dataAttributes: DataAttributeDto[];
  isEmptyGroupsVisible: boolean;
  onAddDataAttribute?: (groupId: number) => void;
  onEditDataAttribute: (dataAttribute: DataAttributeDto) => void;
  onDeleteDataAttribute: (dataAttribute: DataAttributeDto) => void;
  onEditDataAttributeGroup?: (dataAttributeGroup: DataAttributeGroupDto) => void;
  onGroupOrderChange: (groupId: number, direction: 'up' | 'down') => void;
  onDataAttributeOrderChange: (dataAttributeId: number, direction: 'up' | 'down') => void;
};

export const DataAttributeGroups = ({
  dataAttributeGroups,
  dataAttributes,
  isEmptyGroupsVisible,
  onEditDataAttribute,
  onDeleteDataAttribute,
  onAddDataAttribute,
  onEditDataAttributeGroup,
  onGroupOrderChange,
  onDataAttributeOrderChange,
}: DataAttributeGroupProps) => {
  const dataAttributesWithoutGroup = dataAttributes.filter((x) => !x.groupId);
  return (
    <>
      <Grid container spacing={2}>
        {dataAttributeGroups.map((dataAttributeGroup, i) => {
          const dataAttributesInGroup = dataAttributes.filter((x) => x.groupId === dataAttributeGroup.id);
          if (dataAttributesInGroup.length === 0 && !isEmptyGroupsVisible) {
            return null;
          }
          return (
            <Grid item xs={12} key={i}>
              <DataAttributeList
                key={i}
                dataAttributes={dataAttributesInGroup}
                group={dataAttributeGroup}
                onAddDataAttribute={() => onAddDataAttribute?.(dataAttributeGroup.id)}
                onEditDataAttribute={onEditDataAttribute}
                onDeleteDataAttribute={onDeleteDataAttribute}
                onEditDataAttributeGroup={onEditDataAttributeGroup}
                onGroupOrderChange={onGroupOrderChange}
                onDataAttributeOrderChange={onDataAttributeOrderChange}
                isTopGroup={i === 0}
                isBottomGroup={i === dataAttributeGroups.length - 1}
              />
            </Grid>
          );
        })}

        {dataAttributesWithoutGroup.length > 0 && (
          <Grid item xs={12}>
            <DataAttributeList
              dataAttributes={dataAttributesWithoutGroup}
              group={{ id: 0, name: 'Utan grupp' }}
              onEditDataAttribute={onEditDataAttribute}
              onDeleteDataAttribute={onDeleteDataAttribute}
              onAddDataAttribute={() => onAddDataAttribute?.(0)}
              onGroupOrderChange={onGroupOrderChange}
              onDataAttributeOrderChange={onDataAttributeOrderChange}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

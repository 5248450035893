import { Container, Typography } from '@mui/material';
import { PageContent, PageText } from '../layout';

export const PrivatePolicy = () => {
  return (
    <Container>
      <PageContent>
        <PageText>
          <Typography variant="h6">Chassikollens policy för behandling av kunders personuppgifter</Typography>
          <Typography variant="h5" gutterBottom>
            INTEGRITETSPOLICY
          </Typography>
          <Typography variant="body1" gutterBottom>
            Din integritet är viktig för oss. I vår policy för behandling av personuppgifter beskrivs bland annat vilka
            uppgifter vi samlar in, i vilket syfte de samlas in, på vilket sätt du kan ha kontroll över dina egna
            uppgifter samt hur du kan kontakta oss.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Chassikollen AB (organisationsnummer 559305–6376, Möllersvärdsgatan 5, 754 50 Uppsala) är
            personuppgiftsansvarig för behandlingen av personuppgifter på hemsidan www.chassikollen.se.
          </Typography>
          <Typography variant="h5" gutterBottom>
            VILKA PERSONUPPGIFTER SAMLAR VI IN OM DIG SOM KUND OCH VARFÖR?
          </Typography>
          <Typography variant="body1" gutterBottom>
            Här nedan beskrivs för vilka ändamål vi behandlar personuppgifter, vilka kategorier av personuppgifter som
            behandlas samt på vilken laglig grund behandlingen utförs.
          </Typography>
          <Typography variant="h5" gutterBottom>
            FÖR ATT KUNNA HANTERA BESTÄLLNINGAR/KÖP
          </Typography>
          <Typography variant="body1" gutterBottom>
            Personuppgifter behandlas för att:
          </Typography>
          <ul>
            <li>
              leverera en beställd produkt eller tjänst (inklusive avisering om leverans eller kontakter rörande
              leverans).
            </li>
            <li>kunna göra ID-kontroll.</li>
            <li>hantera eventuella betalningar (inklusive analysering av vilka betallösningar som ska erbjudas).</li>
            <li>hantera retur-, reklamation- och garantiärenden.</li>
          </ul>
          <Typography variant="body1" gutterBottom>
            De kategorier av personuppgifter som behandlas är:
          </Typography>
          <ul>
            <li>Namn</li>
            <li>Kontaktuppgifter (till exempel adress, e-post, telefonnummer).</li>
            <li>Betalinformation (till exempel transaktionsreferens, transaktionstidpunkt).</li>
            <li>Organisations-/personnummer</li>
            <li>Kundnummer</li>
            <li>Betalningshistorik</li>
            <li>Orderinformation</li>
            <li>Laglig grund: Fullgörande av köpeavtal</li>
          </ul>
          <Typography variant="body1" gutterBottom>
            Personuppgifter behandlas för att:
          </Typography>
          <ul>
            <li>ge behörighet att logga in.</li>
            <li>upprätthålla korrekta och uppdaterade uppgifter.</li>
            <li>göra det möjligt att följa köphistorik.</li>
          </ul>
          <Typography variant="body1" gutterBottom>
            De kategorier av personuppgifter som behandlas är:
          </Typography>
          <ul>
            <li>Namn</li>
            <li>Kontaktuppgifter (till exempel adress, e-post, telefonnummer).</li>
            <li>Användarnamn (e-postadress)</li>
            <li>Köphistorik</li>
            <li>Beställningshistorik</li>
            <li>Kundnummer</li>
          </ul>
          <Typography variant="body1" gutterBottom>
            Laglig grund: Registrerade kunder - berättigat intresse. Behandlingen är nödvändig för att tillgodose ditt
            och vårt intresse av att hantera och administrera ditt användarkonto.
          </Typography>
          <Typography variant="h5" gutterBottom>
            FÖR ATT KUNNA HANTERA KUNDTJÄNSTÄRENDEN
          </Typography>
          <Typography variant="body1" gutterBottom>
            Personuppgifter behandlas för att:
          </Typography>
          <ul>
            <li>
              kommunicera med kunden och besvara förfrågningar som kommer in till kundtjänst via telefon eller i
              digitala kanaler (inklusive sociala medier).
            </li>
            <li>utreda problem, klagomål- och supportärenden</li>
          </ul>
          <Typography variant="body1" gutterBottom>
            De kategorier av personuppgifter som behandlas är:
          </Typography>
          <ul>
            <li>Namn</li>
            <li>Kontaktuppgifter (till exempel adress, e-post, telefonnummer).</li>
            <li>Din korrespondens</li>
            <li>Uppgift om köptidpunkt, köpställe, felet/klagomålet på produkt.</li>
            <li>Användaruppgifter för webbkonto vid exempelvis inloggningsproblem.</li>
          </ul>
          <Typography variant="body1" gutterBottom>
            Laglig grund: Berättigat intresse. Behandlingen är nödvändig för att tillgodose ditt och vårt intresse av
            att kunna hantera kundtjänstärenden.
          </Typography>
          <Typography variant="h5" gutterBottom>
            PRENUMERATION PÅ NYHETSBREV
          </Typography>
          <Typography variant="body1" gutterBottom>
            Personuppgifter behandlas för att:
          </Typography>
          <ul>
            <li>tillhandahålla önskade nyhetsbrev.</li>
            <li>analysera data i syfte att skapa relevanta erbjudanden för dig.</li>
          </ul>
          <Typography variant="body1" gutterBottom>
            De kategorier av personuppgifter som behandlas är:
          </Typography>
          <ul>
            <li>E-postadress</li>
            <li>Namn (om detta finns att tillgå)</li>
            <li>Geografisk position (om detta finns att tillgå)</li>
            <li>Bransch (om detta finns att tillgå)</li>
            <li>Butik (om detta finns att tillgå)</li>
          </ul>
          <Typography variant="body1" gutterBottom>
            Laglig grund: Samtycke. Genom att lämna din e-postadress i avsett fält och aktivt klicka på knappen
            Prenumerera anses du ha lämnat ditt samtycke för utskick.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Du kan när som helst välja att avsluta din prenumeration, via länk i varje nyhetsbrev.
          </Typography>
          <Typography variant="h5" gutterBottom>
            FÖR ATT KUNNA FULLGÖRA RÄTTSLIGA FÖRPLIKTELSER
          </Typography>
          <Typography variant="body1" gutterBottom>
            (till exempel avseende krav i bokföringslagen, produktansvar och produktsäkerhet och skydd av
            personuppgifter i IT-system)
          </Typography>
          <Typography variant="body1" gutterBottom>
            Personuppgifter behandlas för att:
          </Typography>
          <ul>
            <li>
              kunna uppfylla rättsliga förpliktelser, enligt krav i lagar, domar eller myndighetsbeslut. Sådana krav kan
              t. ex. avse krav avseende produktansvar och produktsäkerhet såsom framtagande av kommunikation och
              information till allmänheten och kunder om produktlarm och produktåterkallelser, till exempel vid en
              defekt eller hälsovådlig vara eller i den mån det krävs av bokföringslagen eller penningtvättslagen och
              kan hänföras till en enskild individ.
            </li>
          </ul>
          <Typography variant="body1" gutterBottom>
            De kategorier av personuppgifter som kan behandlas är:
          </Typography>
          <ul>
            <li>Namn</li>
            <li>Kontaktuppgifter (till exempel adress, e-post, telefonnummer).</li>
            <li>Din korrespondens</li>
            <li>Uppgift om köptidpunkt, köpställe, felet/klagomålet på produkt.</li>
            <li>Användarnamn för webbkonto (e-postadress).</li>
            <li>Organisationsnummer/Personnummer</li>
            <li>Betalningsinformation</li>
            <li>Order- och betalningshistorik</li>
          </ul>
          <Typography variant="body1" gutterBottom>
            Laglig grund: Rättslig förpliktelse.
          </Typography>
          <Typography variant="h5" gutterBottom>
            DELNING OCH ÖVERFÖRING AV PERSONUPPGIFTER
          </Typography>
          <Typography variant="body1" gutterBottom>
            Personuppgifter kan också komma att överföras för nödvändig behandling till andra företag som Chassikollen
            samarbetar med exempelvis när det gäller marknadsföring (print och distribution, mediebyråer osv),
            distribution och transporter, betallösningar, myndighetskontakter och IT-tjänster. När dina personuppgifter
            delas med Chassikollens partners ska uppgifterna behandlas enligt Chassikollens instruktioner och för
            Chassikollens räkning, samt endast för ändamål som är förenliga med de ändamål för vilka Chassikollen har
            samlat in informationen.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Utöver detta kan Chassikollen enligt lag vara tvingad att lämna ut uppgifter till statliga myndigheter
            (exempelvis Polisen och Skatteverket). Dessutom kan Chassikollen lämna ut personuppgifter till företag som
            erbjuder betalningslösningar (till exempel betaltjänstleverantörer och banker). I dessa fall behandlar
            partners uppgifterna som självständiga personuppgiftsansvariga i enlighet med deras egna integritetspolicyer
            och hanteringsinstruktioner.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Chassikollen strävar efter att behandla personuppgifter inom EU/EES samt samarbeta med partners och
            leverantörer som behandlar personuppgifter inom EU/EES. Om det inte är möjligt, kan behandlingen av
            personuppgifter ske utanför EU/EES i land som anses ha en adekvat skyddsnivå i enlighet med EU
          </Typography>
          <Typography variant="body1" gutterBottom>
            kommissionens beslut eller genom användandet av lämpliga skyddsåtgärder exempelvis standardavtalsklausuler,
            bindande företagsinterna regler eller US Privacy Shield. Oavsett i vilket land personuppgifterna behandlas
            vidtar Chassikollen rimliga tekniska, legala och organisatoriska åtgärder för att säkerställa att
            skyddsnivån är densamma som inom EU/EES.
          </Typography>
          <Typography variant="h5" gutterBottom>
            LAGRING AV PERSONUPPGIFTER
          </Typography>
          <Typography variant="body1" gutterBottom>
            Behandlingen sker enligt gällande lagstiftning och innebär att personuppgifter inte bevaras under en längre
            tid än vad som är nödvändigt med hänsyn till ändamålen med behandlingen. Detta innebär rent praktiskt att
            uppgifter gallras bort då de inte längre är aktuella eller nödvändiga för analyser och de ändamål som de har
            samlats in för. I marknadsföringssyfte används inte information om köptransaktioner som är äldre än 3 år.
            Vid all hantering av personuppgifter iakttas dock alltid hög säkerhet och sekretess.
          </Typography>
          <Typography variant="h5" gutterBottom>
            DINA RÄTTIGHETER OCH VAL
          </Typography>
          <Typography variant="h5" gutterBottom>
            RÄTT TILL TILLGÅNG
          </Typography>
          <Typography variant="body1" gutterBottom>
            Vi vill vara öppna och transparenta med hur vi behandlar dina uppgifter och om du vill få mer kunskap om den
            personuppgiftsbehandling som görs, har du rätt att begära tillgång till dina uppgifter som av oss lämnas i
            form av ett s.k. registerutdrag (ändamål, kategorier av personuppgifter, kategorier av mottagare av
            personuppgifter, lagringsperiod eller kriterierna för hur lagringsperiod fastställs, information om varifrån
            uppgifter har samlats in). Om vi mottar en begäran om tillgång, kan vi komma att fråga om ytterligare
            uppgifter för att säkerställa vilka uppgifter du vill ta del av och att vi lämnar ut uppgifterna till rätt
            person.
          </Typography>
          <Typography variant="h5" gutterBottom>
            RÄTT TILL RÄTTELSE
          </Typography>
          <Typography variant="body1" gutterBottom>
            Du har alltid rätt att begära att dina personuppgifter rättas om uppgifterna är felaktiga. Inom ramen för
            det angivna ändamålet har du också rätt att komplettera eventuellt ofullständiga personuppgifter.
          </Typography>
          <Typography variant="h5" gutterBottom>
            RÄTT TILL RADERING
          </Typography>
          <ul>
            <li>uppgifterna inte längre är nödvändiga för de ändamål för vilka de har samlats in eller behandlats.</li>
            <li>
              du har återkallat ett samtycke som behandlingen grundar sig på och det inte finns någon annan laglig grund
              för behandlingen.
            </li>
            <li>
              du invänder mot en intresseavvägning av berättigat intresse som vi har gjort och det saknas berättigat
              intresse för Multibolaget som väger tyngre.
            </li>
            <li>du inte önskar få direktmarknadsföring i form av nyhetsbrev.</li>
            <li>personuppgifterna behandlats på ett olagligt sätt.</li>
            <li>
              personuppgifterna måste raderas för att uppfylla en rättslig förpliktelse som vi omfattas av
              personuppgifterna har samlats in från ett barn (under 13 år) som du har föräldraansvaret för, i samband
              med erbjudande av informationssamhällets tjänster, till exempel sociala medier.
            </li>
          </ul>
          <Typography variant="body1" gutterBottom>
            Det kan finnas anledning för oss att inte tillmötesgå din begäran om radering ifall det finns legala
            skyldigheter som hindrar oss från detta. Det kan vara om behandlingen behövs för att utöva vår rätt till
            yttrande- och informationsfrihet, för att uppfylla en rättslig förpliktelse som vi omfattas av eller för att
            kunna fastställa, göra gällande eller försvara rättsliga anspråk.
          </Typography>
          <Typography variant="h5" gutterBottom>
            RÄTT TILL BEGRÄNSNING
          </Typography>
          <Typography variant="body1" gutterBottom>
            Du har rätt att begära att vår behandling av dina personuppgifter begränsas.
          </Typography>
          <Typography variant="h5" gutterBottom>
            RÄTT ATT GÖRA INVÄNDNINGAR MOT VISS TYP AV BEHANDLING
          </Typography>
          <Typography variant="body1" gutterBottom>
            Berättigat intresse
          </Typography>
          <Typography variant="body1" gutterBottom>
            Du har rätt att invända mot behandlingar som stödjer sig på ett berättigat intresse som vi har om du har
            personliga skäl som rör situationen. Vi får dock fortsätta att behandla dina uppgifter, trots att du har
            motsatt dig behandlingen, om vi har tvingande berättigade skäl för behandlingen som överväger ditt
            integritetsintresse.
          </Typography>
          <Typography variant="h5" gutterBottom>
            RÄTT TILL DATAPORTABILITET
          </Typography>
          <Typography variant="body1" gutterBottom>
            Om vår rätt att behandla dina personuppgifter grundar sig på ditt lämnade samtycke eller fullgörande av
            åtaganden i ett avtal med dig har du rätt att begära att få de uppgifter som rör dig och som du har lämnat
            till oss överförda till en annan personuppgiftsansvarig (s.k. dataportering).
          </Typography>
          <Typography variant="h5" gutterBottom>
            OM COOKIES
          </Typography>
          <Typography variant="body1" gutterBottom>
            Personuppgifter kan samlas in när du använder chassikollen.se och då lagras informationen om din användning
            och vilka sidor som besöks. Det kan vara teknisk information om din enhet och internetuppkoppling såsom
            operativsystem, webbläsarversion, IP-adress, cookies och unika identifierare. Vid besök på chassikollen.se
            där våra tjänster tillhandahålls, kan olika tekniker användas för att känna igen dig i syfte att lära oss
            mer om våra användare. Detta kan ske direkt eller genom användning av teknik från tredje part. Detta kan
            vara användning av till exempel cookies.
          </Typography>
          <Typography variant="h5" gutterBottom>
            VAD ÄR EN COOKIE?
          </Typography>
          <Typography variant="body1" gutterBottom>
            Det finns två typer av cookies. Den ena typen sparar en textfil under en längre tid, men har ett
            utgångsdatum. Denna cookie syftar till att exempelvis tala om för dig vad som är nytt sedan senaste besöket.
            Den andra typen av cookie är en så kallad sessionscookie, som saknar utgångsdatum. Textfilen sparas
            temporärt så länge som du är inne och surfar på en sida, och hjälper exempelvis till med att hålla reda på
            vilket språk som du vill använda dig av. Så snart som webbläsaren stängs ner raderas också textfilen.
          </Typography>
          <Typography variant="h5" gutterBottom>
            VARFÖR ANVÄNDER VI OSS AV COOKIES?
          </Typography>
          <Typography variant="body1" gutterBottom>
            På chassikollen.se använder vi cookies för att exempelvis hålla reda på vilka objekt du har registrerat i
            ditt användarkonto. Vi använder även cookies för att få fram webbstatistik. Denna statistik behöver vi för
            att vidareutveckla chassikollen.se. Informationen är inte tillgänglig för andra parter än Chassikollen AB.
          </Typography>
          <Typography variant="body1" gutterBottom>
            För att kunna använda chassikollen.se fullt ut måste du acceptera cookies, och det gör du genom din
            webbläsares inställningar. Vill du inte acceptera cookies kan du stänga av cookies via din webbläsares
            säkerhetsinställningar. Detta innebär dock att chassikollen.se inte kommer att fungera som avsett.
          </Typography>
          <Typography variant="h5" gutterBottom>
            HANTERING AV PERSONNUMMER
          </Typography>
          <Typography variant="body1" gutterBottom>
            Vi behandlar bara ditt personnummer när det är klart motiverat med hänsyn till ändamålet, nödvändigt för
            säker identifiering eller om det finns annat beaktansvärt skäl. I de fall där det är tillräckligt, använder
            vi i stället ditt kundnummer för att minimera användningen av personnummer i så stor utsträckning som
            möjligt.
          </Typography>
          <Typography variant="h5" gutterBottom>
            KLAGOMÅL
          </Typography>
          <Typography variant="body1" gutterBottom>
            Du har rätt att inge klagomål till tillsynsmyndigheten Datainspektionen, www.datainspektionen.se.
          </Typography>
          <Typography variant="h5" gutterBottom>
            KONTAKTUPPGIFTER
          </Typography>
          <Typography variant="body1" gutterBottom>
            För ytterligare information om personuppgiftshantering eller om du har frågor, är du välkommen att kontakta
            oss på:
          </Typography>
          <Typography variant="body1" gutterBottom>
            Chassikollen AB
          </Typography>
          <Typography variant="body1" gutterBottom>
            Möllersvärdsgatan 5, 754 50 Uppsala
          </Typography>
          <Typography variant="body1" gutterBottom>
            E-postadress: info@chassikollen.se
          </Typography>
          <Typography variant="body1" gutterBottom>
            Telefonnummer: 010-204 50 70
          </Typography>
          <Typography variant="h5" gutterBottom>
            ÄNDRINGAR I POLICYN
          </Typography>
          <Typography variant="body1" gutterBottom>
            Denna personuppgiftspolicy kan komma att uppdateras för att åtgärda störningar eller för att uppfylla nya
            legala eller tekniska krav. Den senaste uppdaterade versionen av integritetspolicyn hittar du alltid på
            denna sida. Vid betydande ändringar (till exempel av ändamålen för personuppgiftsbehandling eller kategorier
            av personuppgifter) kommer du att få information om detta via e-post eller på chassikollen.se.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Senast uppdaterad 18 april 2023
          </Typography>
        </PageText>
      </PageContent>
    </Container>
  );
};

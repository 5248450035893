import { Routes, useLocation } from 'react-router-dom';
import { AdminRoutes } from './AdminRoutes';
import { AuthRoutes } from './AuthRoutes';
import { ErrorRoutes } from './ErrorRoutes';
import { ProtectedRoutes } from './ProtectedRoutes';
import { PublicRoutes } from './PublicRoutes';
import { paths } from './paths';
import { useEffect } from 'react';

const getPathName = (path: string) => {
  return Object.values(paths).find((p) => p.path === replaceLastPartOfPath(path))?.name;
};

const replaceLastPartOfPath = (path: string) => {
  if (!path) return path;

  const lastPartOfPath = path.split('/').pop();
  if (lastPartOfPath && !isNaN(Number(lastPartOfPath))) {
    path = path.replace(lastPartOfPath, ':id');
  }
  return path;
};

export const Navigation = () => {
  const { pathname } = useLocation();

  const title = [getPathName(pathname), 'Chassikollen']
    .filter((item) => item !== undefined && item !== null)
    .join(' - ');

  useEffect(() => {
    document.title = title;
  }, [pathname]);

  return (
    <Routes>
      {PublicRoutes()}
      {AdminRoutes()}
      {ProtectedRoutes()}
      {AuthRoutes()}
      {ErrorRoutes()}
    </Routes>
  );
};

import { Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { DefaultButton, DefaultTextButton, ListDialogContent } from '../../../../layout';
import { CompanyDto } from '../../../../shared/types/owner';
import { CompanyForm } from './CompanyForm';

type EditCompanyDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (company: CompanyDto | null) => void;
  companyToUpdate: CompanyDto | null;
};

export const EditCompanyDialog = ({ isOpen, onClose, onSave, companyToUpdate }: EditCompanyDialogProps) => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [company, setCompany] = useState<CompanyDto>({
    userOwners: [],
    isLessor: false,
    isFinancier: false,
    isInsurer: false,
  });

  useEffect(() => {
    if (companyToUpdate) {
      setCompany({ ...companyToUpdate });
      setName(companyToUpdate.name || '');
    }
  }, [companyToUpdate]);

  const requiredFields = [company.organizationNumber, company.name, company.userOwners];

  const validate = () => {
    const errors = requiredFields.filter((field) => !field);
    setIsValid(errors.length === 0);
  };

  useEffect(() => {
    validate();
  }, [company]);

  const onSaveButtonClick = () => {
    if (!isValid) {
      return;
    }
    onSave(company);
  };
  return (
    <>
      <Dialog open={isOpen} maxWidth="sm" onClose={onClose} fullWidth={true}>
        <DialogTitle>
          Redigera företagsuppgifter
          <Typography variant="body1">
            {name} - {company.organizationNumber}
          </Typography>
        </DialogTitle>

        <ListDialogContent>
          <CompanyForm isEditing company={company} setCompany={setCompany} />
        </ListDialogContent>
        <DialogActions>
          <DefaultTextButton onClick={onClose}>Avbryt</DefaultTextButton>
          <DefaultButton disabled={!isValid} onClick={onSaveButtonClick}>
            Spara företag
          </DefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useState } from 'react';
import { useUser } from '../../context/UserProvider';
import { DefaultButton, DefaultTextButton, FormInputWrapper, FormWrapper } from '../../layout';

type EditUserProfileDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const EditUserProfileDialog = ({ isOpen, onClose }: EditUserProfileDialogProps) => {
  const { userProfile, updateProfile } = useUser();

  const [userProfileToUpdate, setUserProfileToUpdate] = useState({ ...userProfile });

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (!userProfileToUpdate) {
      return;
    }

    setUserProfileToUpdate({ ...userProfileToUpdate, [name]: value });
  };

  function onCloseClick(): void {
    onClose();
  }

  function onSaveClick(): void {
    console.log('onSaveClick: ', userProfileToUpdate);

    if (userProfile) {
      updateProfile({ ...userProfile, ...userProfileToUpdate });
    }

    onClose();
  }

  return (
    <Dialog maxWidth="sm" fullWidth onClose={onClose} open={isOpen}>
      <DialogTitle>Redigera användarprofil</DialogTitle>
      <DialogContent>
        <FormWrapper>
          <FormInputWrapper>
            <TextField
              value={userProfileToUpdate?.displayName || ''}
              name="displayName"
              onChange={onInputChange}
              fullWidth
              label="Visningsnamn"
            />
          </FormInputWrapper>
          <FormInputWrapper>
            <TextField
              value={userProfileToUpdate?.givenName || ''}
              name="givenName"
              onChange={onInputChange}
              fullWidth
              label="Förnamn"
            />
          </FormInputWrapper>
          <FormInputWrapper>
            <TextField
              value={userProfileToUpdate?.familyName || ''}
              name="familyName"
              onChange={onInputChange}
              fullWidth
              label="Efternamn"
            />
          </FormInputWrapper>
          <FormInputWrapper>
            <TextField
              value={userProfileToUpdate?.email || ''}
              name="email"
              onChange={onInputChange}
              fullWidth
              label="E-post"
            />
          </FormInputWrapper>
          <FormInputWrapper>
            <TextField
              value={userProfileToUpdate?.mobilePhone || ''}
              name="mobilePhone"
              onChange={onInputChange}
              fullWidth
              label="Mobilnummer"
            />
          </FormInputWrapper>
        </FormWrapper>
      </DialogContent>
      <DialogActions>
        <DefaultTextButton onClick={onCloseClick}>Avbryt</DefaultTextButton>
        <DefaultButton onClick={onSaveClick} disabled={!userProfileToUpdate}>
          Spara
        </DefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export type DataAttributeDto = {
  id: number;
  name: string;
  type: number;
  typeText?: string;
  isPartOfId: boolean;
  isPartOfName: boolean;
  isRequired: boolean;
  isScannable: boolean;
  isPublic: boolean;
  isUnique: boolean;
  isUsedInTemplate: boolean;
  groupId: number;
  order: number;
};

export type DataAttributeGroupDto = {
  id: number;
  name: string;
  order?: number;
};

export type DataAttributeTemplateDto = {
  isPartOfId: boolean;
  isPartOfName: boolean;
  isRequired: boolean;
  isScannable: boolean;
  isPublic: boolean;
  isUnique: boolean;
  dataAttributeOrder: number | null;
  categoryId?: number | null;
  dataAttributeId: number;
  dataAttributeGroupId: number;
  dataAttributeName?: string;
  type: DataAttributeTypes;
};

export enum DataAttributeTypes {
  STRING = 1,
  INT_32 = 2,
  DATE_TIME = 3,
  BOOLEAN = 4,
}

export type DataAttributeValueDto = {
  type: number;
  typeText?: string;
  isPartOfId: boolean;
  isPartOfName: boolean;
  isRequired: boolean;
  isScannable: boolean;
  isPublic: boolean;
  isUnique: boolean;
  value: string | number | boolean | null;
  dataAttributeOrder: number | null;
  name: string;
  dataAttributeId: number;
  dataAttributeGroupId: number;
};

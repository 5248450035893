import { Navigate, Route } from 'react-router-dom';
import { useUser } from '../context/UserProvider';
import { FinancingsPage } from '../pages/Financings';
import { MyProfilePage } from '../pages/my-profile/MyProfilePage';
import { ChangeOfOwnershipCompletePage } from '../pages/my-registrations/ChangeOfOwnershipComplete';
import { ConfirmOfOwnershipCompletePage } from '../pages/my-registrations/ConfirmOfOwnershipComplete';
import { MyRegistrationsPage } from '../pages/my-registrations/MyRegistrationsPage';
import { paths } from './paths';
import { MyOwnersPage } from '../pages/my-owners/MyOwnersPage';
import { LeasesPage } from '../pages/Leases';
import { InsurancesPage } from '../pages/Insurances';

type ProtectedRouteProps = {
  outlet: JSX.Element;
};

export const ProtectedRoute = ({ outlet }: ProtectedRouteProps) => {
  const { isAuthenticated } = useUser();

  if (!isAuthenticated) {
    return <Navigate to="/logga-in" replace />;
  }

  // if (!userProfile?.roles.includes(Roles.Owner)) {
  //   return <Navigate to="/" replace />;
  // }

  return outlet;
};

const myRegistrationsSubPages = [
  {
    path: paths.myProfile.path,
    element: <MyProfilePage />,
  },
  {
    path: paths.myRegistrations.path,
    element: <MyRegistrationsPage subPagePath={paths.myRegistrations.path} />,
  },
  {
    path: paths.myRegistrations.path,
    element: <MyRegistrationsPage subPagePath={paths.myRegistrations.path} />,
  },
  {
    path: paths.myRegistrationsNewRegistration.path,
    element: <MyRegistrationsPage subPagePath={paths.myRegistrationsNewRegistration.path} />,
  },
  {
    path: paths.myRegistrationsCopyRegistration.path,
    element: <MyRegistrationsPage subPagePath={paths.myRegistrationsCopyRegistration.path} />,
  },
  {
    path: paths.myRegistrationsNewChildRegistration.path,
    element: <MyRegistrationsPage subPagePath={paths.myRegistrationsNewChildRegistration.path} />,
  },
  {
    path: paths.myRegistrationsRegistrationComplete.path,
    element: <MyRegistrationsPage subPagePath={paths.myRegistrationsRegistrationComplete.path} />,
  },
  {
    path: paths.myRegistrationsChangeOfOwnership.path,
    element: <MyRegistrationsPage subPagePath={paths.myRegistrationsChangeOfOwnership.path} />,
  },
  {
    path: paths.myRegistrationsChangeOfOwnershipComplete.path,
    element: <ChangeOfOwnershipCompletePage />,
  },
  {
    path: paths.myRegistrationsChangeOfOwnershipConfirmed.path,
    element: <ConfirmOfOwnershipCompletePage />,
  },
  {
    path: paths.myRegistrationsRegistrations.path,
    element: <MyRegistrationsPage subPagePath={paths.myRegistrationsRegistrations.path} />,
  },
  {
    path: paths.myRegistrationsRegistrationDetails.path,
    element: <MyRegistrationsPage subPagePath={paths.myRegistrationsRegistrationDetails.path} />,
  },
  {
    path: paths.myRegistrationsStatus.path,
    element: <MyRegistrationsPage subPagePath={paths.myRegistrationsStatus.path} />,
  },
  {
    path: paths.myRegistrationsChangeOfOwnershipRegistrations.path,
    element: <MyRegistrationsPage subPagePath={paths.myRegistrationsChangeOfOwnershipRegistrations.path} />,
  },
  {
    path: paths.myRegistrationsChangeOfOwnershipRegistrationsDetails.path,
    element: <MyRegistrationsPage subPagePath={paths.myRegistrationsChangeOfOwnershipRegistrationsDetails.path} />,
  },
  {
    path: paths.financings.path,
    element: <FinancingsPage />,
  },
  {
    path: paths.leases.path,
    element: <LeasesPage />,
  },
  {
    path: paths.insurances.path,
    element: <InsurancesPage />,
  },
  {
    path: paths.myOwners.path,
    element: <MyOwnersPage />,
  },
];

export const ProtectedRoutes = () => {
  return (
    <>
      {myRegistrationsSubPages.map((page, index) => (
        <Route key={index} path={page.path} element={<ProtectedRoute outlet={page.element as JSX.Element} />} />
      ))}
    </>
  );
};

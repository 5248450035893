import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

const CarouselWrapper = styled.div`
  width: 100%;
  position: relative;
  min-height: 150px;
  display: flex;
  align-items: center;
  overflow: hidden;
`;
const CarouselItem = styled.div<{ current: boolean; next: boolean; animate: boolean }>`
  @keyframes slideIn {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  animation: ${(props) => (props.next && props.animate ? 'slideIn 0.8s ease-in-out forwards' : '')};
  animation: ${(props) => (props.current && props.animate ? 'slideOut 0.8s ease-in-out forwards' : '')};
  display: ${(props) => (props.current || props.next ? 'block' : 'none')};

  position: absolute;
  font-size: 1.8rem;
  font-weight: 300;
  color: #fff;
  width: 100%;
  text-align: center;
  @media (min-width: 600px) {
    font-size: 3.2rem;
  }
`;

type TextCarouselProps = {
  texts: string[];
};

export const TextCarousel = ({ texts }: TextCarouselProps) => {
  const [activeItem, setActiveItem] = useState(-1);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextActiveItem = activeItem + 1;
      setActiveItem(nextActiveItem === texts.length ? 0 : nextActiveItem);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeItem]);

  return (
    <CarouselWrapper>
      {texts.map((text, index) => {
        const next = index === activeItem + 1 || (activeItem === texts.length - 1 && index === 0);
        const current = index === activeItem;
        return (
          <CarouselItem key={index} current={current} next={next} animate={activeItem !== -1}>
            {text}
          </CarouselItem>
        );
      })}
    </CarouselWrapper>
  );
};
